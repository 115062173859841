import React, { useEffect, useState, useRef, PropsWithChildren } from 'react';
import Cookies from 'js-cookie';

import { usePokCore } from './hooks/usePokCore';
import { GetCompanyDto } from './pokCore/autogenerated/pokApiClient';

export interface CookiesProviderType {
  company: GetCompanyDto | undefined;
  setCompany: (unit: GetCompanyDto) => void;
  language: string | undefined;
  setLanguage: (lang: string) => void;
  soundEnabled: boolean;
  setSoundEnabled: (value: boolean) => void;
  remove: () => void;
}

const emptyHandler = () => {
  // typing purpose
};

export const CookiesContext = React.createContext({
  company: undefined,
  setCompany: emptyHandler,
  language: undefined,
  setLanguage: emptyHandler,
  soundEnabled: true,
  setSoundEnabled: emptyHandler,
  remove: emptyHandler,
} as CookiesProviderType);

const Company = 'company-object';
const SoundEnabled = 'sound-enabled';
const Language = 'language';

export const remove = () => {
  Cookies.remove(Company);
  Cookies.remove(SoundEnabled);
  Cookies.remove(Language);
};

export const CookiesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const pok = usePokCore();
  const [cookiesState, setCookiesState] = useState(
    new Map<string, string | undefined>(),
  );
  const loadingRef = useRef(false);
  const rawCompany = cookiesState.get(Company);

  const rawset = (name: string, value: string) => {
    Cookies.set(name, value, {
      sameSite: 'None',
      secure: true,
      path: '/',
    });
  };

  const set = (name: string, value: string) => {
    rawset(name, value);
    const newState = new Map(cookiesState);
    newState.set(name, value);
    setCookiesState(newState);
  };

  useEffect(() => {
    if (loadingRef.current) {
      return;
    }
    const knownCompany = Cookies.get(Company);
    const knownLanguage = Cookies.get(Language);

    const map = new Map<string, string | undefined>();
    map.set(Company, Cookies.get(Company));
    map.set(SoundEnabled, Cookies.get(SoundEnabled));
    map.set(Language, Cookies.get(Language));

    if (!knownCompany) {
      loadingRef.current = true;
    } else {
      setCookiesState(map);
      pok.setCompanyId(JSON.parse(knownCompany)['id']);
      if (knownLanguage) {
        pok.setLanguage(knownLanguage);
      }
    }
  }, [pok]);

  return (
    <CookiesContext.Provider
      value={{
        company: rawCompany
          ? (JSON.parse(rawCompany) as GetCompanyDto)
          : undefined,
        setCompany: (value: GetCompanyDto) =>
          set(Company, JSON.stringify(value)),
        language: cookiesState.get(Language),
        setLanguage: (value: string) => set(Language, value),
        soundEnabled: cookiesState.get(SoundEnabled) !== 'false',
        setSoundEnabled: (value: boolean) =>
          set(SoundEnabled, value.toString()),
        remove: remove,
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
};
