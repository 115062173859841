import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ForeignClientFormFieldsProps {
  client: CreateUpdateClientDto | CreateUpdateApplicationDto;
  propertyChange?: (obj: Partial<CreateUpdateClientDto>) => void;
  readonly?: boolean;
}

const ROWLENGHT = 30;

export const ForeignClientFormFields: React.FC<
  ForeignClientFormFieldsProps
> = props => {
  const [textAreaRows, setTextAreaRows] = useState(1);
  const { t, tk } = useTranslation('clientLng');

  useEffect(() => {
    setTextAreaRows(
      props.client?.name && props.client?.name.length > ROWLENGHT ? 2 : 1,
    );
  }, [props.client?.name]);

  return (
    <>
      <FormRow controlId="name" label={t(tk.common.name)}>
        <Form.Control
          as="textarea"
          rows={textAreaRows}
          value={props.client?.name || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ name: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="shortname" label={t(tk.common.shortname)}>
        <Form.Control
          type="text"
          value={props.client?.shortname || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ shortname: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="nip" label={t(tk.client.nip)}>
        <Form.Control
          type="text"
          value={props.client?.nip || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ nip: e.target.value?.trim() })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="addressEx" label={t(tk.client.addressEx)}>
        <Form.Control
          as="textarea"
          rows={2}
          value={props.client?.addressEx || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ addressEx: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>
    </>
  );
};
