import { Buffer } from 'buffer';

import React, { useEffect, useState, useMemo } from 'react';
import { Col, Form, Row, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateAttachmentDto,
  CreateUpdatePurchaseInvoiceDto,
  CreateUpdatePurchaseInvoicePositionDto,
  GetSalesInvoiceDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { SalesInvoiceSearch } from '../SalesInvoices/SalesInvoiceSearch';
import {
  InvoiceStatusEnum,
  VatEnum,
} from '../../../common/pokCore/validation/schemas';
import {
  GridGetterFunction,
  GridCountFunction,
} from '../../../common/components/Grid/GridDataTypes';
import CompanySelector from '../Selects/CompanySelector';
import { Option } from '../Selects/Selector';
import { FormRow66 } from '../../../app/components/FormRow';
import mathUtils from '../../../utils/mathUtils';
import numberFormatter from '../../../common/numberFormatter';
import { getVatAmountByVat } from '../../../utils/getVatAmountByVat';
import { PokCoreContextType } from '../../../common/pokCore';
import { createNewAttachment } from '../../../common/pokCore/contexts/PurchaseInvoiceContext';
import Button from '../../../common/components/Button/Button';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { NotificationsProviderType } from '../../../common/notifications';

interface PurchaseInvoiceGeneralImportFVSProps {
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto;
  readOnly?: boolean;
  handleClose?: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  propertyChange: (obj: Partial<CreateUpdatePurchaseInvoiceDto>) => void;
}

const getInvoiceFile = async (
  pok: PokCoreContextType,
  invoice: GetSalesInvoiceDto,
  companyId: string,
) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.target) {
          const target: FileReader = e.target;
          if (target.result) {
            const attachment = createNewAttachment();
            attachment.fileContent = Buffer.from(target.result as ArrayBuffer);
            attachment.name = `${invoice.number}.pdf`;
            attachment.companyId = companyId;
            resolve(attachment);
          }
        }
      };
      reader.onerror = error => {
        reject(error);
      };
      pok.pdfPrinters
        .getBlobInvoice(invoice.id)
        .then(file => {
          reader.readAsArrayBuffer(file);
        })
        .catch(error => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

const getPositionsByVat = async (
  invoice: GetSalesInvoiceDto,
  invoiceParent: GetSalesInvoiceDto | undefined,
) => {
  const groupedPositionsMap: Record<
    VatEnum,
    CreateUpdatePurchaseInvoicePositionDto
  > = Object.create(null);

  invoice.salesInvoicePositions?.forEach(pos => {
    const { amount, vat } = pos;
    const vatAmount = getVatAmountByVat(amount, vat as VatEnum);

    if (groupedPositionsMap[vat as VatEnum]) {
      groupedPositionsMap[vat as VatEnum].netAmount = mathUtils
        .add(groupedPositionsMap[vat as VatEnum].netAmount, amount)
        .toString();
      groupedPositionsMap[vat as VatEnum].vatAmount = mathUtils
        .add(groupedPositionsMap[vat as VatEnum].vatAmount, vatAmount)
        .toString();
    } else {
      groupedPositionsMap[vat as VatEnum] = {
        netAmount: amount,
        vat,
        purchaseInvoiceId: '',
        vatAmount,
      };
    }
  });

  invoiceParent?.salesInvoicePositions?.forEach(pos => {
    const { amount, vat } = pos;
    const vatAmount = getVatAmountByVat(amount, vat as VatEnum);

    if (groupedPositionsMap[vat as VatEnum]) {
      groupedPositionsMap[vat as VatEnum].netAmount = mathUtils
        .subtract(groupedPositionsMap[vat as VatEnum].netAmount, amount)
        .toString();
      groupedPositionsMap[vat as VatEnum].vatAmount = mathUtils
        .subtract(groupedPositionsMap[vat as VatEnum].vatAmount, vatAmount)
        .toString();
    } else {
      groupedPositionsMap[vat as VatEnum] = {
        netAmount: mathUtils.multiply(-1, amount).toString(),
        vat,
        purchaseInvoiceId: '',
        vatAmount: mathUtils.multiply(-1, vatAmount).toString(),
      };
    }
  });

  return Object.values(groupedPositionsMap) ?? [];
};

const importSalesInvoice = async (
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto,
  invoice: GetSalesInvoiceDto,
  pok: PokCoreContextType,
  notifications: NotificationsProviderType,
) => {
  const myCurrency = (await pok.companies.getById(pok.getCompanyId()))
    ?.mainCurrency?.id;
  const invCurrency = (await pok.companies.getById(invoice?.company?.id))
    ?.mainCurrency?.id;
  const paymentDate = new Date(invoice.invoiceDate);
  paymentDate.setDate(paymentDate.getDate() + +invoice.payableDays.value);

  let invoiceParent: GetSalesInvoiceDto | undefined;
  let invoiceAmount =
    myCurrency !== invCurrency
      ? 0
      : invoice.salesInvoicePositions
          ?.map(pos => Number(pos.amount))
          .reduce((a, b) => mathUtils.add(a, b)) || 0;

  if (invoice.parent?.id) {
    pok.salesInvoices
      .getById(invoice.parent.id)
      .then(parent => {
        invoiceParent = parent;
        invoiceAmount -=
          parent.salesInvoicePositions
            ?.map(pos => Number(pos.amount))
            .reduce((a, b) => mathUtils.add(a, b)) || 0;
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }

  const invoiceFile = await getInvoiceFile(
    pok,
    invoice,
    purchaseInvoice.companyId,
  );

  const payload = {
    number: invoice.number,
    currency: invoice.currency,
    clientId: invoice.company.client.id,
    issueDate: invoice.invoiceDate,
    sellDate: invoice.saleDate,
    paymentDate: paymentDate,
    type: invoice.type,
    amount: numberFormatter(invoiceAmount),
    amountWithoutPosition: numberFormatter(invoiceAmount),
    comment: `Dane zaimportowano z faktury sprzedaży nr ${
      invoice.number
    } z kampanii: ${invoice.salesInvoiceProjects
      ?.map(({ project }) => `${project.name} (${project.number})`)
      .join(', ')}`,
    positions:
      myCurrency !== invCurrency
        ? null
        : await getPositionsByVat(invoice, invoiceParent),
  } as CreateUpdatePurchaseInvoiceDto;

  if (invoiceFile) {
    payload.attachment = invoiceFile as CreateUpdateAttachmentDto;
  }

  return payload;
};

export const PurchaseInvoiceGeneralImportFVS: React.FC<
  PurchaseInvoiceGeneralImportFVSProps
> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const [show, setShow] = useState(false);
  const [companyId, setCompanyId] = useState<string>();
  const [year, setYear] = useState<number>();
  const [yearsOptions, setYearsOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const handleClose = () => {
    setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  const getData = useMemo(
    () => (status: InvoiceStatusEnum[]) =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.salesInvoices.getAllForCompanyYearByPortion(
          companyId ?? '',
          status,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          year ?? 0,
        )) as GridGetterFunction,
    [companyId, year, pok.salesInvoices],
  );

  const getCount = useMemo(
    () => (status: InvoiceStatusEnum[]) =>
      (filterText =>
        pok.salesInvoices.getAllForCompanyYearCount(
          companyId ?? '',
          status,
          filterText,
          year ?? 0,
        )) as GridCountFunction,
    [companyId, pok.salesInvoices, year],
  );

  const onRowClick = (salesInvoiceId: string) => {
    props.setIsLoading(true);
    void pok.salesInvoices
      .getByIdForImport(salesInvoiceId)
      .then(invoice =>
        importSalesInvoice(props.purchaseInvoice, invoice, pok, notifications),
      )
      .then(payload => props.propertyChange(payload))
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => props.setIsLoading(false));
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      const currentYear = new Date().getFullYear();
      const startYear: number = currentYear - 2;
      const options = [];
      for (let year: number = startYear; year <= currentYear; year++) {
        options.push({
          value: year,
          label: String(year),
        });
      }
      setYearsOptions(options);
      setYear(currentYear);
      setCompanyId(undefined);
    }
  }, [show]);

  return (
    <>
      <div>
        <Button
          disabled={props.readOnly}
          onClick={() => setShow(true)}
          variant="outline-success"
          className="w-100"
          isLoading={props.isLoading}
        >
          Importuj FVS
        </Button>
      </div>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          keyboard={true}
          backdrop="static"
          backdropClassName="modal-on-modal-backdrop"
          className="modal-on-modal"
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Wybierz fakturę sprzedaży do zaimportowania
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={6}>
                  <FormRow66 controlId="invoiceYear" label="Rok sprzedaży">
                    <Select
                      value={yearsOptions.find(({ value }) => value === year)}
                      options={yearsOptions}
                      onChange={option => setYear(Number(option?.value))}
                    />
                  </FormRow66>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormRow66
                    controlId="company"
                    label="Spółka"
                    className="pt-3"
                  >
                    <CompanySelector
                      onChange={option => {
                        setCompanyId((option as Option)?.value);
                      }}
                    />
                  </FormRow66>
                </Col>
              </Row>
            </Form>

            <SalesInvoiceSearch
              getCount={getCount([InvoiceStatusEnum.Sent])}
              getData={getData([InvoiceStatusEnum.Sent])}
              onRowClick={onRowClick}
              isImportView
            />
            <Alert variant="warning">
              W przypadku importowania faktury ze spółki rozliczanej w innej
              walucie, kwoty faktury nie zostaną zaimportowane.
            </Alert>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
