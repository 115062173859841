/*eslint complexity: ["error", 12]*/
import React from 'react';
import * as _ from 'lodash';
import * as Icon from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
  GridRecord,
  GridSummaryFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetPurchaseInvoiceExtendedDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  InvoiceTypeEnum,
  InvoiceTypeEnumText,
  PurchaseInvoiceStatusEnumText,
} from '../../../common/pokCore/validation/schemas';
import { DownloadAttachment } from '../../../common/components/File/DownloadAttachment';

import { NewWindowButton } from './NewWindowButton';

interface PurchaseInvoiceSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  getSummary?: GridSummaryFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  displayStatus?: boolean;
  displayCurrency?: boolean;
  displayLastNote?: boolean;
  displayTeamsProjects?: boolean;
  displayCreator?: boolean;
  mode?: 'estimateItem' | 'project' | 'finance';
  enableMultiSelect?: boolean;
  withFilterInColumns?: boolean;
  showNewWindowButton?: boolean;
  withPreview?: boolean;
  withCountDays?: boolean;
}

export const purchaseInvoiceForGrid = (
  purchaseInvoice: GetPurchaseInvoiceExtendedDto,
  orderBy: string = '',
) => {
  const projectProfileNames = _.uniq(
    purchaseInvoice.projects
      ?.map(({ estimatesByMonths }) =>
        estimatesByMonths?.map(estimate => estimate.profile.name),
      )
      .flat(),
  );
  const projectTeamsNames = _.uniq(
    purchaseInvoice.teams?.map(team => team.name),
  );
  let projectNumbers = _.uniq(
    purchaseInvoice.projects?.map(
      ({ name, number }) => name + ' (' + number + ')',
    ),
  );
  let projectOwners = _.uniq(
    purchaseInvoice.projects?.map(
      ({ responsibleOwner }) => responsibleOwner?.name,
    ),
  );
  if (projectNumbers && orderBy !== 'project.number') {
    projectNumbers = projectNumbers.sort((a, b) => a.localeCompare(b, 'pl'));
  }
  if (projectOwners && orderBy !== 'project.responsibleOwner') {
    projectOwners = projectOwners.sort((a, b) => a.localeCompare(b, 'pl'));
  }

  return {
    key: purchaseInvoice.id,
    values: {
      invoice_status: PurchaseInvoiceStatusEnumText[purchaseInvoice.status],
      'purchaseInvoice.systemNumber': purchaseInvoice.systemNumber,
      'purchaseInvoice.type': InvoiceTypeEnumText[purchaseInvoice.type],
      'purchaseInvoice.number': (
        <>
          {purchaseInvoice.number}
          <br />
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>{InvoiceTypeEnumText[purchaseInvoice.type]}</Tooltip>
            }
          >
            {purchaseInvoice.type === InvoiceTypeEnum.Foreign ? (
              <Icon.Globe2 className="icon-blue" />
            ) : (
              <Icon.House className="icon-blue" />
            )}
          </OverlayTrigger>
          {purchaseInvoice.tv && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Faktura TV</Tooltip>}
            >
              {purchaseInvoice.tv && (
                <Icon.DisplayFill className="icon-blue ms-2" />
              )}
            </OverlayTrigger>
          )}
        </>
      ),
      'purchaseInvoice.sellDate': momencik(purchaseInvoice.sellDate),
      'purchaseInvoice.creationTs': momencik(purchaseInvoice.creationTs),
      'purchaseInvoice.issueDate': momencik(purchaseInvoice.issueDate),
      'purchaseInvoice.comment': purchaseInvoice.comment,
      'purchaseInvoice.currency': purchaseInvoice.currency,
      'client.name': purchaseInvoice.client.name,
      'budget.name': _.uniq(
        purchaseInvoice.projects?.map(o => o.purchaser?.budget?.name),
      )?.join(', '),

      project_label: projectNumbers?.join(', '),
      'creator.name': purchaseInvoice.creator?.name,
      'responsibleOwner.name': projectOwners?.join(', '),
      'profile.name': projectProfileNames?.join(', '),
      'team.name': projectTeamsNames?.join(', '),
      'purchaseInvoice.amount': numberFormatter(purchaseInvoice.amount, true),
      'purchaseInvoice.amountWithoutPosition': numberFormatter(
        purchaseInvoice.amountWithoutPosition,
        true,
      ),
      'attachment.note': purchaseInvoice.note,
      count_days_from_creation: purchaseInvoice.countDaysFromCreation,
      newWindowButton: (
        <NewWindowButton purchaseInvoiceId={purchaseInvoice.id} />
      ),
      preview: purchaseInvoice.attachmentId ? (
        <DownloadAttachment
          id={purchaseInvoice.attachmentId}
          icon={Icon.FilePdf}
        />
      ) : (
        <></>
      ),
    },
    isInactive: !purchaseInvoice.active,
  };
};

export const getSummary = (data: GetPurchaseInvoiceExtendedDto) => {
  return {
    key: 'summary',
    values: {
      'purchaseInvoice.systemNumber': 'SUMA',
      'purchaseInvoice.amountWithoutPosition': numberFormatter(
        data.amountWithoutPosition || 0,
        true,
      ),
      'purchaseInvoice.amount': numberFormatter(data.amount || 0, true),
    },
  } as unknown as GridRecord;
};

export const PurchaseInvoiceSearch: React.FC<
  PurchaseInvoiceSearchProps
> = props => {
  const { t, tk } = useTranslation('fvzLng');

  const columns: GridColumn[] = [
    ...(props.showNewWindowButton
      ? [
          {
            header: '',
            property: 'newWindowButton',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
    { header: 'Nr sys.', property: 'purchaseInvoice.systemNumber' },
    { header: 'Numer faktury', property: 'purchaseInvoice.number' },
    { header: 'Data wystawienia', property: 'purchaseInvoice.issueDate' },
    { header: 'Data sprzedaży', property: 'purchaseInvoice.sellDate' },
    {
      header: 'Fakturujący (kontrahent)',
      property: 'client.name',
    },
    ...(props.displayCurrency
      ? [
          {
            header: 'Waluta',
            property: 'purchaseInvoice.currency',
          },
        ]
      : []),
    ...(props.displayStatus
      ? [
          {
            header: 'Status',
            property: 'invoice_status',
          },
        ]
      : []),
    ...(props.displayTeamsProjects
      ? [
          {
            header: t(tk.prLang.project),
            property: 'project_label',
          },
        ]
      : []),
    ...(props.mode === 'estimateItem'
      ? [
          {
            header: 'Budżet',
            property: 'budget.name',
          },
        ]
      : []),
    ...(props.mode === 'estimateItem'
      ? [
          {
            header: 'Aktywne profile',
            property: 'profile.name',
            noSorting: true,
          },
          {
            header: 'Autor ' + t(tk.prLang.genitiveProject),
            property: 'responsibleOwner.name',
          },
        ]
      : []),
    ...(props.displayTeamsProjects
      ? [
          {
            header: 'Zespół',
            property: 'team.name',
          },
        ]
      : []),
    {
      header: 'Komentarz',
      property: 'purchaseInvoice.comment',
    },
    {
      header: 'Kwota faktury',
      property: 'purchaseInvoice.amount',
      noFilter: true,
    },
    {
      header: 'Kwota nierozliczona',
      property: 'purchaseInvoice.amountWithoutPosition',
      noFilter: true,
    },
    ...(props.displayCreator
      ? [
          {
            header: 'Utworzył',
            property: 'creator.name',
          },
        ]
      : []),
    ...(props.withCountDays
      ? [
          {
            header: 'Data utworzenia',
            property: 'purchaseInvoice.creationTs',
          },
          {
            header: 'Liczba dni od utworzenia',
            property: 'count_days_from_creation',
            noFilter: true,
          },
        ]
      : []),
    ...(props.displayLastNote
      ? [
          {
            header: 'Notatka',
            property: 'attachment.note',
          },
        ]
      : []),
    ...(props.withPreview
      ? [
          {
            header: 'Załącznik',
            property: 'preview',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      getSummaryData={props.getSummary}
      columns={columns}
      showFilter={true}
      refresh={props.refresh}
      initialOrderColumn="purchaseInvoice.systemNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak faktur zakupu"
      onRowClick={props.onRowClick}
      withHistory={true}
      enableMultiSelect={props.enableMultiSelect}
      withFilterInColumns={props.withFilterInColumns}
      withOverflowXAuto={true}
    />
  );
};
