/*eslint complexity: ["error", 12]*/
/*eslint max-lines-per-function: ["error", 320]*/
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useAuth } from '../../common/hooks/useAuth';
import { currentMenuItems, useNavigation } from '../../common/navigation';
import logoConst from '../../assets/groupone_logo.png';
import { useCookies } from '../../common/hooks/useCookies';
import { usePokCore } from '../../common/hooks/usePokCore';
import CompanySelector from '../../pok/components/Selects/CompanySelector';
import { changeLang } from '../../translations/initTranslations';
import { GetCompanyDto } from '../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../common/hooks/useNotifications';
import { Option } from '../../pok/components/Selects/Selector';
import { UserMemos } from '../../pok/components/Memos/UserMemos';
import { useSockets } from '../../common/hooks/useSockets';
import { LanguageSelector } from '../../pok/components/Selects/LanguageSelector';
import { useTranslation } from '../../common/hooks/useTranslation';

interface NavMainProps {
  activePage: string;
  changeColor: (color: string) => void;
  // setContrast: (className: string) => void;
}

const NavMain: React.FC<NavMainProps> = ({ activePage, changeColor }) => {
  const darkBackground = '#20232a';
  const auth = useAuth();
  const sockets = useSockets();
  const notifications = useNotifications();
  const nav = useNavigation();
  const [name, setName] = useState('Jan Kowalski ');
  const cookies = useCookies();
  const pok = usePokCore();
  const currentLinks = currentMenuItems(activePage);
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(
    darkBackground,
  );
  const [logo, setLogo] = useState<string | undefined>(logoConst);
  const [companyN, setCompanyN] = useState<GetCompanyDto | undefined>(
    sessionStorage.getItem('pok-company')
      ? (JSON.parse(
          sessionStorage.getItem('pok-company') || '',
        ) as GetCompanyDto)
      : cookies.company,
  );
  const [languageN, setLanguageN] = useState<string | null | undefined>(
    sessionStorage.getItem('pok-language')
      ? JSON.parse(sessionStorage.getItem('pok-language') || '{}')
      : cookies.language,
  );
  const { t, tk } = useTranslation('menuLng');

  useEffect(() => {
    if (auth.currentUser) {
      setName(auth.currentUser.name);
      const company = sessionStorage.getItem('pok-company');
      let companyObject;
      if (company) {
        companyObject = JSON.parse(company);
        setCompanyN(companyObject);
      } else {
        if (cookies.company) {
          sessionStorage.setItem(
            'pok-company',
            JSON.stringify(cookies.company),
          );
          cookies.setCompany(cookies.company);
          companyObject = cookies.company;
          setCompanyN(companyObject);
        }
      }

      const language = sessionStorage.getItem('pok-language');
      if (language) {
        setLanguageN(JSON.parse(language || ''));
      } else {
        if (cookies.language) {
          sessionStorage.setItem(
            'pok-language',
            JSON.stringify(cookies.language),
          );
          cookies.setLanguage(cookies.language);
          setLanguageN(cookies.language);
        } else {
          if (companyObject?.language) {
            sessionStorage.setItem(
              'pok-language',
              JSON.stringify(companyObject?.language),
            );
            cookies.setLanguage(companyObject?.language);
            setLanguageN(companyObject?.language);
          } else {
            if (companyObject?.id) {
              pok.companies
                .getById(companyObject.id)
                .then(company => {
                  setLanguageN(company.language);
                  sessionStorage.setItem(
                    'pok-language',
                    JSON.stringify(company.language),
                  );
                  cookies.setLanguage(company.language);
                  cookies.setCompany(company);
                  sessionStorage.setItem(
                    'pok-company',
                    JSON.stringify(company),
                  );
                })
                .catch(errorResponse =>
                  notifications.caughtError(errorResponse),
                );
            }
          }
        }
      }

      const currency = sessionStorage.getItem('pok-currency');
      if (!currency) {
        if (companyObject?.mainCurrency) {
          sessionStorage.setItem(
            'pok-currency',
            JSON.stringify(companyObject.mainCurrency),
          );
        } else if (companyObject?.id) {
          pok.companies
            .getById(companyObject.id)
            .then(company => {
              sessionStorage.setItem(
                'pok-currency',
                JSON.stringify(company.mainCurrency),
              );
            })
            .catch(errorResponse => {
              notifications.caughtError(errorResponse);
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.currentUser,
    pok.companies,
    notifications,
    cookies,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sessionStorage.getItem('pok-company'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sessionStorage.getItem('pok-language'),
  ]);

  const changeCompany = (unitId: string) => {
    pok.companies
      .getById(unitId)
      .then(company => {
        cookies.setCompany({ ...company, logo: '' });
        pok.setCompanyId(company.id);
        sessionStorage.setItem('pok-company', JSON.stringify(company));
        sessionStorage.setItem(
          'pok-currency',
          JSON.stringify(company.mainCurrency),
        );
        setCompanyN(company);
        changeLang(company.personalLanguage, pok.getLanguage());
        setBackgroundColor(company.color);
        setLogo(company.logo);
        changeColor(company.color);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const changeLanguage = (language: string) => {
    cookies.setLanguage(language);
    pok.setLanguage(language);
    sessionStorage.setItem('pok-language', JSON.stringify(language));
  };

  const handleColor = (color: string) => {
    setBackgroundColor(color);
    changeColor(color);
  };

  return (
    <Navbar
      expand
      collapseOnSelect
      variant="dark"
      role="banner"
      as="header"
      className="mainbar"
      // style={{ backgroundColor: backgroundColor || darkBackground }}
      style={{
        boxShadow: `0px 5px 20px ${backgroundColor || darkBackground}`,
      }}
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo || logoConst} alt="pok" height={50} className="p-1" />
        </Navbar.Brand>

        <Nav role="navigation" id="top" className="d-none d-md-flex mx-5">
          {currentLinks
            .filter(o => !o.hr)
            .map(({ path, heading }) => (
              <Nav.Link
                className="mainbarlink"
                key={path}
                href={path + '#'}
                onClick={() => nav.menuItem(path)}
                active={activePage === path}
              >
                {heading}
              </Nav.Link>
            ))}
        </Nav>
        <Nav className="ms-auto col-md-6 col-lg-4 d-flex justify-content-end">
          <NavDropdown title={name} id="nav-dropdown" className="me-3 mt-1">
            <NavDropdown.Item
              eventKey="logout"
              onClick={() => {
                //sockets.removeOnThreadChanged(handler);
                sockets.unsubscribeMemos();
                auth.logout();
              }}
            >
              {t(tk.menu.logout)}
            </NavDropdown.Item>
          </NavDropdown>
          <div className="me-1">
            <LanguageSelector
              value={languageN || ''}
              darkVersion={true}
              onChange={option => changeLanguage((option as Option).value)}
              changeLanguageForSystem={true}
            />
          </div>
          <div className="me-3 d-flex">
            <UserMemos />
          </div>
          <Col md={6}>
            <CompanySelector
              value={companyN?.id}
              onChange={option => changeCompany((option as Option).value)}
              setBackgroundColor={handleColor}
              setLogo={setLogo}
              setLanguage={true}
            />
          </Col>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavMain;
