import React from 'react';

import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import { Grid } from '../../../common/components/Grid/Grid';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface SalesInvoicesGridProps {
  data: GridRecord[];
}

export const SalesInvoicesGrid: React.FC<SalesInvoicesGridProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  const columns: GridColumn[] = [
    {
      header: t(tk.common.sysNumber),
      property: 'salesInvoice.systemNumber',
    },
    { header: t(tk.common.number), property: 'salesInvoice.number' },
    {
      header: t(tk.estimates.issueDate),
      property: 'salesInvoice.invoiceDate',
    },
    { header: t(tk.estimates.settledAmount), property: 'salesInvoice.amount' },
    { header: t(tk.estimates.status), property: 'salesInvoice.status' },
    { header: t(tk.estimates.lastModification), property: 'employee.name' },
    {
      header: t(tk.estimates.attachment),
      property: 'preview',
      noSorting: false,
    },
  ];
  return (
    <Grid
      data={props.data}
      columns={columns}
      showFilter={false}
      initialOrderColumn="salesInvoice.systemNumber"
      initialOrderDirection="DESC"
      pageSize={20}
      emptyGridMessage={t(tk.estimates.noFVS)}
    />
  );
};
