import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { TeamEditorForm } from './TeamEditorForm';

interface TeamModalEditorProps {
  id?: string;
  systemNumber?: number;
  team: CreateUpdateTeamDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    team: CreateUpdateTeamDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const TeamModalEditor: React.FC<TeamModalEditorProps> = props => {
  const [team, setTeam] = useState(props.team);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('administrationLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setTeam(props.team);
    }
  }, [props.team, props.show]);

  const createTeam = async () => {
    if (!team.companyId) {
      team.companyId = pok.getCompanyId();
    }

    setIsLoading(true);
    props
      .onCompleted(team, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateTeamDto>) => {
    setTeam({ ...team, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `${t(tk.administration.teamEdit)} ${props.systemNumber}`
              : t(tk.administration.teamAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TeamEditorForm
            team={team}
            propertyChange={propertyChange}
            creatingTeam={props.id === undefined}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo id={props.id} method={pok.teams.getLastEditor} />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button variant="primary" onClick={createTeam} isLoading={isLoading}>
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
