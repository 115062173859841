export default {
  client: {
    client: 'Kontrahent',
    clients: 'Kontrahenci',
    clientAdd: 'Dodawanie nowego kontrahenta',
    clientEdit: 'Edycja kontrahenta nr sys.',
    clientNew: 'Nowy kontrahent',
    noClients: 'Brak kontrahentów w systemie',
    nip: 'NIP',
    dealGroupNumber: 'Grupowanie deali',
    country: 'Państwo',
    place: 'Miejscowość',
    postcode: 'Poczta',
    street: 'Ulica',
    numbers: 'Numer',
    flat: 'Lokal',
    addressEx: 'Adres zagraniczny',
    isRelated: 'Kontrahent powiązany',
    notToSymphony: 'Brak eksportu FVZ do Symfonii',
    symphony: 'Symfonia',
  },
  purchaser: {
    purchaser: 'Zamawiający',
    purchaserAdd: 'Dodawanie nowego zamawiającego',
    purchaserEdit: 'Edycja zamawiającego nr sys.',
    purchaserNew: 'Nowy zamawiający',
    noPurchasers: 'Brak zamawiających w systemie',
    noPurchasersForClient: 'Brak zamawiających dla tego kontrahenta.',
    manager: 'Szef budżetu',
  },
  position: {
    position: 'Pozycja profili',
    positions: 'Pozycje profili',
    positionsShortName: 'Pozycje',
    positionAdd: 'Dodawanie nowej pozycji profilu',
    positionEdit: 'Edycja pozycji profilu nr sys.',
    positionNew: 'Nowa pozycja profilu',
    noPositions: 'Brak pozycji profilu w systemie',
    noPositionsForClient: 'Brak pozycji profilu dla tego kontrahenta',
    chooseSpecialTeam: 'Proszę wybrać zespół specjalistyczny.',
    groupName: 'Nazwa grupy',
    type: 'Typ pozycji',
    communicationCanal: 'Kanał komunikacji',
    specialTeam: 'Zespół specjalistyczny',
    profilesEstimates: 'Profile mediaplanów',
    notPurchaseDeficiencies: 'Nie podlega brakom FVZ',
    profiles: 'Profile',
  },
  budget: {
    budget: 'Budżet',
    budgets: 'Budżety',
    budgetAdd: 'Dodawanie nowego budżetu',
    budgetEdit: 'Edycja budżetu nr sys.',
    budgetNew: 'Nowy budżet',
    noBudgets: 'Brak budżetów w systemie',
    managerName: 'Szef budżetu',
    downloadDocuments: 'Pobieranie hurtowe dokumentów',
  },
  brand: {
    brand: 'Brand',
    brands: 'Brandy',
    brandAdd: 'Dodawanie nowego brandu',
    brandEdit: 'Edycja brandu nr sys.',
    brandNew: 'Nowy brand',
    noBrands: 'Brak brandów w systemie',
    budget: 'Budżet brandu',
  },
  symphony: {
    symphonyNew: 'Nowy kod',
    code: 'Kod',
    noSymphonyForClient: 'Brak kodów Symfonii dla tego kontrahenta.',
    symphonyEdit: 'Edycja kodu',
    symphonyAdd: 'Dodawanie nowego kodu',
  },
} as const;
