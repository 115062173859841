import mathUtils from '../../../utils/mathUtils';
import {
  CreateUpdatePurchaseInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';

const checkEstimatesValue = (
  availableItem?: GetEstimateItemDto,
  itemAmount?: string,
  sumFromSaved?: string,
) => {
  if (!availableItem) {
    return false;
  }

  return (
    mathUtils.multiply(
      availableItem.purchaseNetTotal,
      mathUtils.add(
        mathUtils.subtract(
          availableItem.purchaseNetTotal,
          availableItem.purchaseInvoicesSum,
        ),
        sumFromSaved,
      ),
    ) < mathUtils.multiply(itemAmount, availableItem.purchaseNetTotal)
  );
};

export const checkDivergedPurchaseInvoiceAmounts = (
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[],
  availableEstimateItems: GetEstimateItemDto[],
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto,
) => {
  const hasNegativeAmounts = purchaseInvoiceEstimateItems.some(item => {
    const avItem = availableEstimateItems.find(
      availableItem => availableItem.id === item.estimateItemId,
    );
    return (
      avItem &&
      mathUtils.multiply(avItem.purchaseNetTotal, avItem.purchaseNetTotal) <
        mathUtils.multiply(avItem.purchaseNetTotal, item.amount)
    );
  });

  const hasMoreThenAvailableAmounts = purchaseInvoiceEstimateItems.some(
    item => {
      const availableItem = availableEstimateItems.find(
        availableItem => availableItem.id === item.estimateItemId,
      );

      const sumFromSaved =
        extendedPurchaseInvoice?.purchaseInvoiceEstimateItems?.find(
          curr => curr.estimateItem.id === availableItem?.id,
        )?.amount ?? '0';

      return checkEstimatesValue(availableItem, item.amount, sumFromSaved);
    },
  );

  return { hasNegativeAmounts, hasMoreThenAvailableAmounts };
};
