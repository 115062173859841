import React, { useMemo } from 'react';

import { GetApplicationDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  ApplicationStatusEnum,
  ApplicationStatusEnumText,
  ApplicationTypeEnum,
  ApplicationTypeEnumText,
} from '../../../common/pokCore/validation/schemas';
import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ApplicationSearchProps {
  status?: ApplicationStatusEnum;
  onlyActive?: boolean;
  withContextCompany?: boolean;
  hideAuthorColumn?: boolean;
  hideCompanyColumn?: boolean;
  columns?: GridColumn[];
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  onRowClick?: (key: string) => void;
  refresh?: boolean;
}

const getPurchaserName = (application: GetApplicationDto) => {
  const purchaserLabel =
    application?.purchaser?.name +
    ' (' +
    (application?.purchaser?.client?.shortname ||
      application?.purchaser?.client?.name) +
    ')';
  return application.purchaser ? purchaserLabel : '-';
};

const getBrandName = (application: GetApplicationDto) => {
  return application.brand?.name;
};

const getObjectName = (application: GetApplicationDto) => {
  if (application.applicationStatus !== ApplicationStatusEnum.Completed) {
    return '-';
  }
  switch (application.applicationType) {
    case ApplicationTypeEnum.Brand:
      return getBrandName(application);
    case ApplicationTypeEnum.Position:
      return application.position?.name || '-';
    case ApplicationTypeEnum.Purchaser:
      return getPurchaserName(application);
    case ApplicationTypeEnum.DictionaryValue:
      return '-';
    case ApplicationTypeEnum.Permission:
      return '-';
  }
};

const getObjectType = (application: GetApplicationDto) => {
  if (application.applicationStatus !== ApplicationStatusEnum.Completed) {
    return '-';
  }
  if (application.applicationType === ApplicationTypeEnum.Permission) {
    return ApplicationTypeEnumText.Permission + ' - konieczne przelogowanie';
  }

  return ApplicationTypeEnumText[application.applicationType];
};

export const applicationForGrid = (application: GetApplicationDto) => {
  return {
    key: application.id,
    values: {
      'application.applicationStatus':
        ApplicationStatusEnumText[application.applicationStatus],
      'application.applicationType':
        ApplicationTypeEnumText[application.applicationType],
      'application.createdDate': momencik(application.createdDate),
      'employee.name': application.author?.name,
      'company.shortname': application.company.shortname,
      'team.name': application.team.name,
      'object.type': getObjectType(application),
      'object.name': getObjectName(application),
    },
    isInactive: !application.active,
  };
};

export const ApplicationSearch: React.FC<ApplicationSearchProps> = props => {
  const { status, withContextCompany, onRowClick, refresh, onlyActive } = props;
  const { t, tk } = useTranslation('applicationLng');

  const getGridColumn = function () {
    return [
      {
        header: t(tk.common.creationDate),
        property: 'application.createdDate',
      },
      ...(props.hideAuthorColumn
        ? []
        : [{ header: t(tk.common.creator), property: 'employee.name' }]),
      {
        header: t(tk.common.status),
        property: 'application.applicationStatus',
      },
      { header: t(tk.common.type), property: 'application.applicationType' },
      ...(props.hideCompanyColumn
        ? []
        : [{ header: t(tk.common.company), property: 'company.shortname' }]),
      { header: t(tk.common.team), property: 'team.name' },
      {
        header: t(tk.app.sharedObjectType),
        property: 'object.type',
        noSorting: true,
      },
      {
        header: t(tk.app.sharedObject),
        property: 'object.name',
        noSorting: true,
      },
    ];
  };

  const columns: GridColumn[] = props.columns || getGridColumn();
  const pok = usePokCore();

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.applications.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          status,
          withContextCompany,
          onlyActive,
        )) as GridGetterFunction,
    [pok.applications, status, withContextCompany, onlyActive],
  );

  const getCount = useMemo(
    () =>
      (filterText =>
        pok.applications.getAllCount(
          filterText,
          status,
          withContextCompany,
          onlyActive,
        )) as GridCountFunction,
    [pok.applications, status, withContextCompany, onlyActive],
  );

  return (
    <Grid
      getData={props.getData || getData}
      getDataCount={props.getCount || getCount}
      columns={columns}
      showFilter={true}
      onRowClick={onRowClick}
      initialOrderColumn="application.createdDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.app.noApplications)}
      withHistory={true}
      refresh={refresh}
    />
  );
};
