import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap';

import { useTranslation } from '../../../common/hooks/useTranslation';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { newCloneProject } from '../../../common/pokCore/contexts/ProjectContext';
import { CloneProjectDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';

interface CloneProjectFormProps {
  projectId: string;
  projectName: string;
  show: boolean;
  projectStartDate: Date;
  onSave: (cloneProject: CloneProjectDto) => Promise<{
    saved: boolean;
    errors: string[];
    newProjectId?: string;
  }>;
  onClose: () => void;
}

export const CloneProjectForm: React.FC<CloneProjectFormProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  const [name, setName] = useState('');
  const [notes, setNotes] = useState(
    `Sklonowana na podstawie ${t(tk.prLang.genitiveProject)}: ${
      props.projectName
    }`,
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [waiting, setWaiting] = useState<boolean>(false);

  const notifications = useNotifications();
  const nav = useNavigation();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
    }
  }, [props.show]);

  const cloneProfile = async (withAmounts: boolean) => {
    const cloneProject = newCloneProject(
      props.projectId,
      name,
      notes,
      withAmounts,
    );
    setWaiting(true);
    props
      .onSave(cloneProject)
      .then(saveStatus => {
        setWaiting(false);
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
        if (saveStatus.newProjectId) {
          nav.projectDetails(saveStatus.newProjectId, false);
          return;
        }
        props.onClose();
      })
      .catch(async errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div onClick={e => e.stopPropagation()}>
      <Modal
        key={props.projectId}
        onHide={props.onClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tk.projects.clone)} {t(tk.prLang.genitiveProject)}{' '}
            {props.projectName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="name" label={t(tk.prLang.newProjectName)}>
              <Form.Control
                type="text"
                onChange={e => setName(e.target.value)}
              />
            </FormRow>
            <FormRow controlId="notes" label={t(tk.projects.note)}>
              <DynamicTextarea
                onChange={e => setNotes(e.target.value)}
                value={notes}
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={props.onClose}
            disabled={waiting}
          >
            {t(tk.button.close)}
          </Button>
          <DropdownButton
            title={t(tk.projects.clone)}
            id="clone-dropdown"
            variant="outline-primary"
            className="mx-1 d-inline-block"
            disabled={waiting}
          >
            <Dropdown.Item onClick={() => cloneProfile(false)} eventKey="1">
              {t(tk.projects.withoutAmounts)}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => cloneProfile(true)} eventKey="2">
              {t(tk.projects.withAmounts)}
            </Dropdown.Item>
          </DropdownButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
