import React, { useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { ProjectFinanceSearch } from '../../components/Projects/ProjectFinanceSearch';
import FormRow from '../../../app/components/FormRow';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { FilterColumn } from '../../../common/types/FilterColumn';

export const ProjectsFinanceView: React.FC = () => {
  const { t, tk } = useTranslation('projectLng');
  const pok = usePokCore();
  const nav = useNavigation();
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [rangeDate, setRangeDate] = useState<
    [Date | null, Date | null] | null
  >();

  const handleClick = async (id: string) => {
    nav.projectDetails(id, true);
  };

  const handleSearch = () => {
    setDateFrom(rangeDate && rangeDate[0] ? rangeDate[0] : undefined);
    setDateTo(rangeDate && rangeDate[1] ? rangeDate[1] : undefined);
  };

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.projects.getForFinanceByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          dateFrom,
          dateTo,
        )) as GridGetterFunction,
    [pok.projects, dateFrom, dateTo],
  );

  const getCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.projects.getForFinanceCount(
          filterText,
          filterColumns,
          dateFrom,
          dateTo,
        )) as GridCountFunction,
    [pok.projects, dateFrom, dateTo],
  );

  const getSummary = useMemo(
    () => (filterText?: string, filterColumns?: FilterColumn) =>
      pok.projects.getSumForFinance(
        filterText,
        filterColumns,
        dateFrom,
        dateTo,
      ),
    [pok.projects, dateFrom, dateTo],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.prLang.projects)}</Card.Title>
        <Form className="d-grid gap-4 mb-3">
          <FormRow
            controlId="month"
            label={`${t(tk.projects.dateRange)} ${t(tk.prLang.genitiveProject)}`}
          >
            <div className="small-calendar">
              <DateRangePicker
                onChange={value => {
                  setRangeDate(
                    value as unknown as [Date | null, Date | null] | null,
                  );
                }}
                value={rangeDate}
                defaultView="year"
                minDetail="year"
                maxDetail="year"
                format="MM.yyyy"
              />
            </div>
          </FormRow>
          <Row>
            <Col className="col-3"></Col>
            <Col className="col-9">
              <Button variant="primary" className="px-5" onClick={handleSearch}>
                {t(tk.projects.find)}
              </Button>
            </Col>
          </Row>
        </Form>
        <ProjectFinanceSearch
          getData={getData}
          getCount={getCount}
          getSummary={getSummary}
          onRowClick={handleClick}
          withFilterInColumns={true}
        />
      </Card.Body>
    </Card>
  );
};
