import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { CreateUpdateBudgetDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { BudgetModalEditor } from './BudgetModalEditor';

interface BudgetDetailsEditorProps {
  id?: string;
  systemNumber?: number;
  budget: CreateUpdateBudgetDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    budget: CreateUpdateBudgetDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const BudgetDetailsEditor: React.FC<
  BudgetDetailsEditorProps
> = props => {
  const [show, setShow] = useState(false);
  const { t, tk } = useTranslation('clientLng');

  useEffect(() => {
    if (props.show) {
      setShow(props.show);
    }
  }, [props.show]);

  const addEditTeam = async (budget: CreateUpdateBudgetDto) => {
    const retVal = props.onCompleted(budget, props.id);
    if ((await retVal).saved) {
      setShow(false);
    }
    return retVal;
  };

  const handleClose = () => {
    setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button onClick={() => setShow(true)} variant="outline-success">
          {t(tk.button.edit)}
        </Button>
      </div>
      <BudgetModalEditor
        id={props?.id}
        systemNumber={props.systemNumber}
        budget={props.budget}
        onCompleted={addEditTeam}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};
