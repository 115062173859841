/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProfileCategoryDto } from './GetProfileCategoryDto';
import {
    GetProfileCategoryDtoFromJSON,
    GetProfileCategoryDtoFromJSONTyped,
    GetProfileCategoryDtoToJSON,
} from './GetProfileCategoryDto';
import type { GetSalesInvoiceDto } from './GetSalesInvoiceDto';
import {
    GetSalesInvoiceDtoFromJSON,
    GetSalesInvoiceDtoFromJSONTyped,
    GetSalesInvoiceDtoToJSON,
} from './GetSalesInvoiceDto';

/**
 * 
 * @export
 * @interface GetSalesInvoicePositionDto
 */
export interface GetSalesInvoicePositionDto {
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoicePositionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoicePositionDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof GetSalesInvoicePositionDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoicePositionDto
     */
    financialAccount: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoicePositionDto
     */
    amount: string;
    /**
     * 
     * @type {GetSalesInvoiceDto}
     * @memberof GetSalesInvoicePositionDto
     */
    salesInvoice: GetSalesInvoiceDto;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoicePositionDto
     */
    vat: GetSalesInvoicePositionDtoVatEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetSalesInvoicePositionDto
     */
    active: boolean;
    /**
     * 
     * @type {GetProfileCategoryDto}
     * @memberof GetSalesInvoicePositionDto
     */
    profileCategory: GetProfileCategoryDto;
    /**
     * 
     * @type {string}
     * @memberof GetSalesInvoicePositionDto
     */
    projectId: string;
}


/**
 * @export
 */
export const GetSalesInvoicePositionDtoVatEnum = {
    _23: '23%',
    _19: '19%',
    _8: '8%',
    _5: '5%',
    _0: '0%',
    Zw: 'zw.',
    Np: 'np.'
} as const;
export type GetSalesInvoicePositionDtoVatEnum = typeof GetSalesInvoicePositionDtoVatEnum[keyof typeof GetSalesInvoicePositionDtoVatEnum];


/**
 * Check if a given object implements the GetSalesInvoicePositionDto interface.
 */
export function instanceOfGetSalesInvoicePositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "financialAccount" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "salesInvoice" in value;
    isInstance = isInstance && "vat" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "profileCategory" in value;
    isInstance = isInstance && "projectId" in value;

    return isInstance;
}

export function GetSalesInvoicePositionDtoFromJSON(json: any): GetSalesInvoicePositionDto {
    return GetSalesInvoicePositionDtoFromJSONTyped(json, false);
}

export function GetSalesInvoicePositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalesInvoicePositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'order': json['order'],
        'financialAccount': json['financialAccount'],
        'amount': json['amount'],
        'salesInvoice': GetSalesInvoiceDtoFromJSON(json['salesInvoice']),
        'vat': json['vat'],
        'active': json['active'],
        'profileCategory': GetProfileCategoryDtoFromJSON(json['profileCategory']),
        'projectId': json['projectId'],
    };
}

export function GetSalesInvoicePositionDtoToJSON(value?: GetSalesInvoicePositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'order': value.order,
        'financialAccount': value.financialAccount,
        'amount': value.amount,
        'salesInvoice': GetSalesInvoiceDtoToJSON(value.salesInvoice),
        'vat': value.vat,
        'active': value.active,
        'profileCategory': GetProfileCategoryDtoToJSON(value.profileCategory),
        'projectId': value.projectId,
    };
}

