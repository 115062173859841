import React from 'react';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { ApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';
import { Option } from '../Selects/Selector';
import EmployeeSelector from '../Selects/EmployeeSelector';
import CompanySelector from '../Selects/CompanySelector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ApplicationEditorBasicSpecificFormFieldsPermissionProps {
  application: UpdateAdminApplicationDto;
  show?: boolean;
  isReadOnly?: boolean;
  propertyChange?: (
    obj: Partial<CreateUpdateClientDto | CreateUpdateApplicationDto>,
  ) => void;
}

export const ApplicationEditorBasicSpecificFormFieldsPermission: React.FC<
  ApplicationEditorBasicSpecificFormFieldsPermissionProps
> = props => {
  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  const pok = usePokCore();
  const { t, tk } = useTranslation('applicationLng');

  return (
    <>
      {props.application.applicationType === ApplicationTypeEnum.Permission && (
        <>
          <FormRow controlId="company" label={t(tk.common.company)}>
            <CompanySelector value={pok.getCompanyId()} readOnly />
          </FormRow>
          <FormRow controlId="employee" label={t(tk.app.employeeAccess)}>
            <EmployeeSelector
              value={props.application.permissionEmployeeId}
              onChange={e =>
                e &&
                propertyChange({
                  permissionEmployeeId: (e as Option).value,
                })
              }
              readOnly={props.isReadOnly}
            />
          </FormRow>
          <FormRow controlId="employeeBoss" label={t(tk.app.employeeBoss)}>
            <EmployeeSelector
              value={props.application.permissionEmployeeBossId}
              onChange={e =>
                e &&
                propertyChange({
                  permissionEmployeeBossId: (e as Option).value,
                })
              }
              readOnly={props.isReadOnly}
            />
          </FormRow>
        </>
      )}
    </>
  );
};
