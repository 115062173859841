import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { GetExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PositionSearchProps {
  config: GetExcelConfigDto;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const PositionSearch: React.FC<PositionSearchProps> = props => {
  const { t, tk } = useTranslation('excelImportLng');

  const columns: GridColumn[] = [
    { header: t(tk.excel.positionInPok), property: 'positionName' },
    { header: t(tk.excel.positionInExcel), property: 'excelPositionName' },
    { header: t(tk.excel.positionParameters), property: 'parameters' },
  ];

  const data = props.config.excelConfigPositions
    ?.filter(position => position.active === true)
    .map(position => {
      return {
        key: position.id,
        values: {
          excelPositionName: position.excelPositionName,
          positionName: position.position?.name,
          parameters: position.selectedParameters,
        },
      };
    });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="excelPositionName"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.excel.noPosition)}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
