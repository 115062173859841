import React, { useState, useEffect, useCallback } from 'react';
// import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetCompanyWithoutLogoDto } from '../../../common/pokCore/autogenerated/pokApiClient/models/GetCompanyWithoutLogoDto';
import { changeLang } from '../../../translations/initTranslations';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface CompanySelectorProps {
  isMulti?: boolean;
  readOnly?: boolean;
  loadData?: () => Promise<GetCompanyWithoutLogoDto[]>;
  value?: string | string[];
  className?: string;
  onChange?: (option: Option | MultiValue<Option>) => void;
  setBackgroundColor?: (color: string) => void;
  setLogo?: (logo: string) => void;
  setLanguage?: boolean;
  clearable?: boolean;
}

export default (props: CompanySelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [companies, setCompanies] = useState<GetCompanyWithoutLogoDto[]>([]);
  const [badge, setBadge] = useState<string>();

  const setNameAndBadge = useCallback(() => {
    if (!props.value || props.isMulti) {
      return;
    }

    const unit = companies?.find(u => u.id === props.value);
    if (unit && badge !== unit.shortname) {
      setBadge(unit.shortname);
      props.setLanguage && changeLang(unit.personalLanguage, pok.getLanguage());
      props.setBackgroundColor && props.setBackgroundColor(unit.color);
      if (props.setLogo) {
        pok.companies
          .getById(unit.id)
          .then(company => {
            props.setLogo && props.setLogo(company.logo);
          })
          .catch(errorResponse => {
            notifications.caughtError(errorResponse);
          });
      }
    }
  }, [companies, badge, props, notifications, pok]);

  useEffect(() => {
    let mounted = true;
    if (!companies.length) {
      (props.loadData || pok.companies.getManyWithoutLogo)()
        .then(data => {
          if (mounted && data && data.length > 0) {
            setCompanies(data);
            setNameAndBadge();
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      setNameAndBadge();
    }

    return () => {
      mounted = false;
    };
  }, [
    pok.companies,
    props.loadData,
    companies,
    setNameAndBadge,
    notifications,
  ]);

  useEffect(() => {
    if (!props.value && badge) {
      setBadge(undefined);
    }
  }, [props.value, badge]);

  const provider = async (text: string) => {
    const values = companies.filter(v =>
      v.shortname.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );

    return values.map(v => {
      return {
        badge: v.shortname,
        badgeBgColor: v.color || '#C0C0C0',
        label: v.name,
        value: v.id,
      };
    });
  };

  return (
    <Selector
      uniqueKey={'comp-' + companies?.map(o => o.id).join('_') + badge}
      isMulti={props.isMulti}
      className={props.className}
      readOnly={props.readOnly}
      clearable={props.clearable}
      creatable={false}
      provider={provider}
      value={props.value}
      valueBadge={badge}
      badgeBgColor={
        badge
          ? companies.find(c => c.shortname === badge)?.color ?? '#C0C0C0'
          : ''
      }
      onChange={option => {
        if (!props.isMulti && !(option as Option)?.value) {
          setBadge(undefined);
        }
        props.onChange && props.onChange(option);
      }}
    />
  );
};
