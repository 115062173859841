import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { UpdateAdminApplicationDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { ApplicationStatusEnum } from '../../../common/pokCore/validation/schemas';
import momencik from '../../../common/momencik';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ApplicationAdminEditorInfo } from './ApplicationAdminEditorInfo';
import { ApplicationAdminEditorStatus } from './ApplicationAdminEditorStatus';

interface ApplicationAdminEditorProps {
  id?: string;
  application: UpdateAdminApplicationDto;
  authorName: string;
  createdDate?: Date | null;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    client: UpdateAdminApplicationDto,
    id: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ApplicationAdminEditor: React.FC<
  ApplicationAdminEditorProps
> = props => {
  const [application, setApplication] = useState(props.application);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('applicationLng');

  const saveApplication = async () => {
    if (!props.id) {
      return;
    }

    setIsLoading(true);
    props
      .onCompleted(application, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    setApplication({ ...application, ...obj });
  };

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setApplication({
        ...props.application,
        applicationStatus:
          props.application.applicationStatus === ApplicationStatusEnum.New
            ? ApplicationStatusEnum.Completed
            : props.application.applicationStatus,
      });
    }
  }, [props.application, props.show]);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tk.app.applicationExecutionWithDate, {
              createdDate: momencik(props.createdDate),
            })}
            <small>
              {' '}
              ({t(tk.app.creator)}: {props.authorName})
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-flex flex-wrap gap-1 mb-3">
            <ApplicationAdminEditorInfo application={application} />
            <ApplicationAdminEditorStatus
              application={application}
              propertyChange={propertyChange}
              isReadOnly={false}
            />
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.applications.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={saveApplication}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
