import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Option, Selector } from './Selector';

export interface TeamSelectorProps {
  projectId?: string;
  companyId?: string;
  companyIds?: string[];
  permission?: string | null;
  isMulti?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  value?: string | string[];
  className?: string;
  setOptions?: (employees: GetTeamDto[]) => void;
  onChange?: (option: Option | MultiValue<Option> | null | undefined) => void;
  tv?: boolean;
}

export default (props: TeamSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [teams, setTeams] = useState<GetTeamDto[] | undefined>();

  useEffect(() => {
    let mounted = true;
    let fetchTeams;

    if (props.projectId) {
      fetchTeams = pok.teams.getAllByProjectId(props.projectId);
    } else if (props.permission) {
      fetchTeams = pok.teams.getMyForPermissionByCompanyIds(
        props.companyId ? [props.companyId] : (props.companyIds as string[]),
        props.permission,
      );
    } else if (props.companyId || props.companyIds?.length) {
      fetchTeams = pok.teams.getAllByCompanyIds(
        props.companyId ? [props.companyId] : (props.companyIds as string[]),
      );
    }

    if (fetchTeams) {
      fetchTeams
        .then(data => {
          if (mounted) {
            props.setOptions?.(props.tv ? data.filter(o => o.tv) : data);
            setTeams(props.tv ? data.filter(o => o.tv) : data);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      setTeams([]);
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pok.teams,
    props.companyId,
    props.companyIds,
    notifications,
    props.projectId,
  ]);

  useEffect(() => {
    if (teams && props.onChange) {
      if (props.isMulti) {
        const newTeams = teams?.filter(({ id }) => props.value?.includes(id));
        if ((props.value?.length || 0) !== (newTeams?.length || 0)) {
          props.onChange(
            newTeams.map(v => ({ value: v.id, label: v.name }) as Option),
          );
        }
      } else if (props.value && !teams.find(team => team.id === props.value)) {
        props.onChange(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams, props.value, props.isMulti]);

  const provider = async (text: string) => {
    const values =
      teams?.filter(v =>
        v.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
      ) || [];
    return values.map(v => ({
      label: v.name + ' (' + v.company.shortname + ')',
      value: v.id,
    }));
  };

  if (!teams && (props.companyId || props.companyIds?.length)) {
    return <Skeleton />;
  }

  return (
    <Selector
      uniqueKey={'team-' + teams?.map(o => o.id).join('_')}
      className={props.className}
      isMulti={props.isMulti}
      readOnly={
        props.readOnly ||
        !teams ||
        (!props.companyId && !props.companyIds?.length && !props.projectId)
      }
      creatable={false}
      clearable={props.clearable}
      provider={provider}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
