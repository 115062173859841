/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetJobConfigTeamDto } from './GetJobConfigTeamDto';
import {
    GetJobConfigTeamDtoFromJSON,
    GetJobConfigTeamDtoFromJSONTyped,
    GetJobConfigTeamDtoToJSON,
} from './GetJobConfigTeamDto';

/**
 * 
 * @export
 * @interface GetJobConfigDto
 */
export interface GetJobConfigDto {
    /**
     * 
     * @type {string}
     * @memberof GetJobConfigDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetJobConfigDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetJobConfigDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetJobConfigDto
     */
    type: GetJobConfigDtoTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetJobConfigDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetJobConfigDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {Array<GetJobConfigTeamDto>}
     * @memberof GetJobConfigDto
     */
    jobConfigTeams?: Array<GetJobConfigTeamDto>;
    /**
     * 
     * @type {string}
     * @memberof GetJobConfigDto
     */
    crone: string;
    /**
     * 
     * @type {string}
     * @memberof GetJobConfigDto
     */
    queueName: string;
}


/**
 * @export
 */
export const GetJobConfigDtoTypeEnum = {
    OrderExecutionReminder: 'OrderExecutionReminder',
    OrderReminder: 'OrderReminder',
    DailyFvzReminder: 'DailyFVZReminder',
    WeekFvzReminder: 'WeekFVZReminder'
} as const;
export type GetJobConfigDtoTypeEnum = typeof GetJobConfigDtoTypeEnum[keyof typeof GetJobConfigDtoTypeEnum];


/**
 * Check if a given object implements the GetJobConfigDto interface.
 */
export function instanceOfGetJobConfigDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "emails" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "crone" in value;
    isInstance = isInstance && "queueName" in value;

    return isInstance;
}

export function GetJobConfigDtoFromJSON(json: any): GetJobConfigDto {
    return GetJobConfigDtoFromJSONTyped(json, false);
}

export function GetJobConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetJobConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'active': json['active'],
        'systemNumber': json['systemNumber'],
        'type': json['type'],
        'emails': json['emails'],
        'company': GetCompanyDtoFromJSON(json['company']),
        'jobConfigTeams': !exists(json, 'jobConfigTeams') ? undefined : ((json['jobConfigTeams'] as Array<any>).map(GetJobConfigTeamDtoFromJSON)),
        'crone': json['crone'],
        'queueName': json['queueName'],
    };
}

export function GetJobConfigDtoToJSON(value?: GetJobConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'active': value.active,
        'systemNumber': value.systemNumber,
        'type': value.type,
        'emails': value.emails,
        'company': GetCompanyDtoToJSON(value.company),
        'jobConfigTeams': value.jobConfigTeams === undefined ? undefined : ((value.jobConfigTeams as Array<any>).map(GetJobConfigTeamDtoToJSON)),
        'crone': value.crone,
        'queueName': value.queueName,
    };
}

