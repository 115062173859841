import React from 'react';

import { usePokCore } from '../../common/hooks/usePokCore';

import { ProceduresPLView } from './ProceduresPLView';
import { ProceduresENView } from './ProceduresENView';

export const ProceduresView: React.FC = () => {
  const pok = usePokCore();

  return (
    <>
      {pok.getLanguage() === 'PL' ? <ProceduresPLView /> : <ProceduresENView />}
    </>
  );
};
