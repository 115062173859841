import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { InvoiceStatusEnum } from '../../../common/pokCore/validation/schemas';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const OpenSaleInvoicePrintLink = (props: {
  id: string;
  status: InvoiceStatusEnum;
  icon?: Icon.Icon;
}) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');

  const IconA = props.icon ? props.icon : Icon.FileImage;

  const handleClick = () => {
    pok.pdfPrinters.openInvoice(props.id!, false, false).catch(error => {
      notifications.caughtError(error);
    });
  };

  return (
    <>
      {props.status !== InvoiceStatusEnum.Draft && (
        <OverlayTrigger overlay={<Tooltip>{t(tk.button.open)}</Tooltip>}>
          <Button
            className="ps-2 py-0"
            onClick={e => {
              e.stopPropagation();
              handleClick();
            }}
            variant="btn-info"
          >
            <IconA size={20} />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};
