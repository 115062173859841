/*eslint complexity: ["error", 15]*/
/*eslint max-lines-per-function: ["error", 200]*/
import { isFieldHidden } from '../../../pok/components/Estimates/CommissionService';
import mathUtils from '../../../utils/mathUtils';
import {
  CreatePaeImportDto,
  CreateUpdateEstimateItemDto,
  CreateUpdateEstimateItemDtoFromJSON,
  DefaultApi,
  GetEstimateByMonthDto,
  GetLastEditorDto,
  GetPaeImportDto,
  GetProfileDto,
  GetProjectDto,
  ProfileSchemaDto,
} from '../autogenerated/pokApiClient';
import {
  GetEstimateItemDto,
  GetEstimateItemDtoFromJSON,
} from '../autogenerated/pokApiClient/models/GetEstimateItemDto';
import { GetEstimateItemLockDto } from '../autogenerated/pokApiClient/models/GetEstimateItemLockDto';
import { EstimateItemSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import {
  defaultSelectorLimit,
  Option,
} from '../../../pok/components/Selects/Selector';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { estimateItemPurchaseDeficienciesForGrid } from '../../../pok/components/EstimateItemPurchaseDeficiencies/EstimateItemPurchaseDeficienciesSearch';
import { estimateItemDocumentControlForGrid } from '../../../pok/components/DocumentControl/DocumentControlSearch';
import { FilterColumn } from '../../types/FilterColumn';

export interface EstimateItemContextInterface {
  getById: (id: string) => Promise<GetEstimateItemDto>;
  create: (dto: CreateUpdateEstimateItemDto) => Promise<GetEstimateItemDto>;
  update: (
    id: string,
    dto: CreateUpdateEstimateItemDto,
  ) => Promise<GetEstimateItemDto>;
  delete: (id: string) => Promise<void>;
  findByEstimateMonth: (
    estimateByMonthId: string,
  ) => Promise<GetEstimateItemDto[]>;
  findReadyToInvoiceByProjects: (
    projectIds: string[],
    salesInvoiceId?: string,
  ) => Promise<GetEstimateItemDto[]>;
  findForPurchaseInvoiceByClientAndProjects: (
    purchaseInvoiceId: string,
    clientId: string,
    projectIds: string[],
  ) => Promise<GetEstimateItemDto[]>;
  findAllByProjectAndTeam: (
    projectId: string,
    teamId: string,
  ) => Promise<GetEstimateItemDto[]>;
  findByProjectAndMonth: (
    projectId: string,
    date: Date,
  ) => Promise<GetEstimateItemDto[]>;
  findByProject: (projectId: string) => Promise<GetEstimateItemDto[]>;
  findForOrderExecutionSum: (
    projectId: string,
    teamIds: string[],
    clientId: string,
    date: Date,
  ) => Promise<GetEstimateItemDto[]>;
  findByProjectAndClient(
    projectId: string,
    clientId: string,
  ): Promise<GetEstimateItemDto[]>;
  checkItemLock: (id: string) => Promise<GetEstimateItemLockDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getEstimateItemsFromPae: (
    projectId: string,
    year: number,
    month: number,
  ) => Promise<GetPaeImportDto>;
  createEstimateItemsFromPae: (
    paePositions: CreatePaeImportDto,
  ) => Promise<void>;
  getByProjectIdProfileIdAndDate: (
    projectId: string,
    profileId?: string,
    monthDate?: Date,
  ) => Promise<GetEstimateItemDto[]>;
  clone: (
    itemIds: string[],
    date: Date,
    withAmounts: boolean,
    projectId: string,
  ) => Promise<void>;
  getActionGroupsOptionsByTextByCompanyAndBudget: (
    text: string,
    companyId: string,
    budgetId: string,
  ) => Promise<Option[]>;
  getPurchaseDeficiencies: (
    refresh: boolean,
    setRefresh: (refresh: boolean) => void,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    tv?: boolean,
  ) => Promise<GridRecord[]>;
  getPurchaseDeficienciesCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
    tv?: boolean,
  ) => Promise<number>;
  getForDocumentControlByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    budgetId?: string,
    projectId?: string,
  ) => Promise<GridRecord[]>;
  getForDocumentControlCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
    budgetId?: string,
    projectId?: string,
  ) => Promise<number>;
  updatePurchaseNetTotal: (
    id: string,
    amount: string,
    purchaseInvoiceId: string,
  ) => Promise<void>;
}

export const EstimateItemContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.estimateItemControllerGet(id),
    create: (dto: CreateUpdateEstimateItemDto) =>
      api.estimateItemControllerCreate(dto),
    update: (id: string, dto: CreateUpdateEstimateItemDto) =>
      api.estimateItemControllerUpdate(id, dto),
    delete: (id: string) => api.estimateItemControllerDeactivate(id),
    findByEstimateMonth: (estimateByMonthId: string) =>
      api
        .estimateItemControllerFindByEstimateMonth(estimateByMonthId)
        .then(item =>
          item.sort((a, b) =>
            a.position.name.localeCompare(b.position.name, 'pl'),
          ),
        ),
    findReadyToInvoiceByProjects: (
      projectIds: string[],
      salesInvoiceId?: string,
    ) =>
      api.estimateItemControllerFindReadyToInvoiceByProjects(
        projectIds,
        salesInvoiceId || '',
      ),
    findForPurchaseInvoiceByClientAndProjects: (
      purchaseInvoiceId: string,
      clientId: string,
      projectIds: string[],
    ) =>
      api.estimateItemControllerFindForPurchaseInvoiceByClientAndProjects(
        purchaseInvoiceId,
        clientId,
        projectIds,
      ),
    findAllByProjectAndTeam: (projectId: string, teamId: string) =>
      api.estimateItemControllerFindAllByProjectAndTeam(projectId, teamId),
    findByProjectAndMonth: (projectId: string, date: Date) =>
      api.estimateItemControllerFindByProjectAndMonth(projectId, date),
    findByProject: (projectId: string) =>
      api.estimateItemControllerFindByProject(projectId),
    findForOrderExecutionSum: (
      projectId: string,
      teamIds: string[],
      clientId: string,
      date: Date,
    ) =>
      api.estimateItemControllerFindForOrderExecutionSum(
        projectId,
        teamIds,
        clientId,
        date,
      ),
    findByProjectAndClient: (projectId: string, clientId: string) =>
      api.estimateItemControllerFindByProjectAndClient(projectId, clientId),
    checkItemLock: (id: string) => api.estimateItemControllerCheckItemLock(id),
    getLastEditor: (id: string) => api.estimateItemControllerGetLastEditor(id),
    getEstimateItemsFromPae: (projectId: string, year: number, month: number) =>
      api
        .estimateItemControllerGetEstimateItemsFromPae(projectId, year, month)
        .then(data => {
          data.positions.sort((a, b) => {
            if (a.position === null && b.position === null) {
              return 0;
            }
            if (a.position === null) {
              return 1;
            }
            if (b.position === null) {
              return -1;
            }
            return a.position.name.localeCompare(b.position.name);
          });
          return data;
        }),
    createEstimateItemsFromPae: (paePositions: CreatePaeImportDto) =>
      api.estimateItemControllerCreateEstimateItemFromPae(paePositions),
    getByProjectIdProfileIdAndDate: (
      projectId: string,
      profileId?: string,
      monthDate?: Date,
    ) =>
      api.estimateItemControllerFindByProjectProfileMonth(
        projectId,
        profileId,
        monthDate,
      ),
    clone: (
      itemIds: string[],
      date: Date,
      withAmounts: boolean,
      projectId: string,
    ) => api.estimateItemControllerClone(itemIds, date, withAmounts, projectId),
    getActionGroupsOptionsByTextByCompanyAndBudget: async (
      text: string,
      companyId: string,
      budgetId: string,
    ) => {
      const data =
        await api.estimateItemControllerGetActionGroupsByCompanyAndBudget(
          text,
          companyId,
          budgetId,
          defaultSelectorLimit,
        );
      return Array.from(data, value => {
        return {
          value: value,
          label: value,
        };
      });
    },
    getPurchaseDeficiencies: async (
      refresh: boolean,
      setRefresh: (refresh: boolean) => void,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      tv?: boolean,
    ) => {
      const data = await api.estimateItemControllerGetPurchaseDeficiencies(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
        tv,
      );

      return data.map(ei =>
        estimateItemPurchaseDeficienciesForGrid(ei, refresh, setRefresh),
      );
    },

    getPurchaseDeficienciesCount: async (
      filterText?: string,
      filterColumns?: FilterColumn,
      tv?: boolean,
    ) =>
      api.estimateItemControllerFindPurchaseDeficienciesCount(
        filterText || '',
        filterColumns,
        tv,
      ),

    getForDocumentControlByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      budgetId?: string,
      projectId?: string,
    ) => {
      const data =
        await api.estimateItemControllerFindForDocumentControlByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
          budgetId,
          projectId,
        );

      return data.map(data => estimateItemDocumentControlForGrid(data));
    },

    getForDocumentControlCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
      budgetId?: string,
      projectId?: string,
    ) =>
      api.estimateItemControllerFindForDocumentControlCount(
        filterText || '',
        filterColumns,
        budgetId,
        projectId,
      ),
    updatePurchaseNetTotal: (
      id: string,
      amount: string,
      purchaseInvoiceId: string,
    ) =>
      api.estimateItemControllerUpdatePurchaseNetTotal(id, {
        purchaseNetTotal: amount,
        purchaseInvoiceId: purchaseInvoiceId,
      }),
  };
};

export const newEstimateItem = (
  estimateByMonth?: GetEstimateByMonthDto,
  project?: GetProjectDto,
): CreateUpdateEstimateItemDto => {
  let startDate = estimateByMonth?.date;
  let endDate = estimateByMonth?.date
    ? new Date(
        estimateByMonth?.date.getFullYear(),
        estimateByMonth?.date?.getMonth() + 1,
        0,
      )
    : null;
  endDate?.setHours(startDate?.getHours() || 0);
  if (endDate && project?.endDate) {
    endDate = endDate > project?.endDate ? project?.endDate : endDate;
  }
  if (startDate && project?.endDate) {
    startDate = startDate < project?.startDate ? project?.startDate : startDate;
  }
  return CreateUpdateEstimateItemDtoFromJSON({
    estimateByMonthId: estimateByMonth?.id,
    startDate: startDate,
    endDate: endDate,
  });
};

export const newGetEstimateItem = (): GetEstimateItemDto => {
  return GetEstimateItemDtoFromJSON({});
};

export const convert = (
  item: GetEstimateItemDto,
  estimateByMonth?: GetEstimateByMonthDto,
  project?: GetProjectDto,
) => {
  if (!item.id && !item.clone) {
    return newEstimateItem(estimateByMonth, project);
  }
  const converted = CreateUpdateEstimateItemDtoFromJSON(item);
  converted.estimateByMonthId = item.estimateByMonth.id;
  converted.positionId = item.position.id;
  converted.profileCategoryId = item.profileCategory?.id;
  converted.technologyDictionaryId = item.technologyDictionary?.id;
  converted.regionDictionaryId = item.regionDictionary?.id;
  converted.formatRadioId = item.formatRadio?.id;
  converted.formatPressId = item.formatPress?.id;
  converted.formatInternetId = item.formatInternet?.id;
  converted.formatInternetSTId = item.formatInternetST?.id;
  converted.emissionTypeRadioId = item.emissionTypeRadio?.id;
  converted.emissionTypeCinemaId = item.emissionTypeCinema?.id;
  converted.purchaseFormInternetId = item.purchaseFormInternet?.id;
  converted.purchaseFormInternetSTId = item.purchaseFormInternetST?.id;
  converted.categoryInternetId = item.categoryInternet?.id;
  converted.categoryInternetSTId = item.categoryInternetST?.id;
  converted.categoryLabconId = item.categoryLabcon?.id;
  converted.copySizeId = item.copySize?.id;
  converted.purchaseMethodId = item.purchaseMethod?.id;
  converted.mediumId = item.medium?.id;
  converted.labconChannelId = item.labconChannel?.id;

  converted.salesInvoicesSum = undefined;
  converted.salesCommissionSum = undefined;
  return converted;
};

export const validate = async (
  item: CreateUpdateEstimateItemDto,
  profile?: GetProfileDto,
  isSalesBlocked?: boolean,
  isPurchaseBlocked?: boolean,
) => {
  const errors = [];
  let amountFieldsValid = true;
  const notToValidate = ['isUpSell'];
  for (const key in profile?.schema) {
    const dictionaryKey = key + 'Id';
    if (
      // eslint-disable-next-line no-prototype-builtins
      profile?.schema.hasOwnProperty(key) &&
      profile?.schema[key as keyof ProfileSchemaDto]?.required
    ) {
      if (
        isFieldHidden(key, item.positionType, profile.commission) === true ||
        notToValidate.includes(key)
      ) {
        continue;
      }
      if (
        !item[key as keyof CreateUpdateEstimateItemDto] &&
        !item[dictionaryKey as keyof CreateUpdateEstimateItemDto] &&
        !amountFieldNotToValidate(key, isSalesBlocked, isPurchaseBlocked)
      ) {
        if (
          salesAmountFieldsKeys.includes(key) ||
          purchaseAmountFieldsKeys.includes(key) ||
          serviceAmountFieldsKeys.includes(key)
        ) {
          amountFieldsValid = false;
        }
        errors.push(
          `Proszę uzupełnić pole ${
            profile?.schema[key as keyof ProfileSchemaDto]?.displayNamePl
          }`,
        );
      }
    }
  }
  const sSum = specialSalesFieldsSum(item);
  if (sSum !== 100 && sSum !== 0) {
    amountFieldsValid = false;
    errors.push(
      `Kwoty procentowe muszą sumować się do 100%. Aktualnie suma to ${sSum}%`,
    );
  }
  if (errors.length > 0) {
    return {
      valid: false,
      errors: errors,
      amountFieldsValid: amountFieldsValid,
    };
  }
  const validation = await validateAgainst(EstimateItemSchema, item);
  return {
    valid: validation.valid,
    errors: validation.errors,
    amountFieldsValid: amountFieldsValid,
  };
};

const salesAmountFieldsKeys = [
  'rcSalesTotal',
  'salesSurchargePercent',
  'salesDiscountPercent',
  'salesNet',
  'salesCommissionPercent',
  'salesCommissionAmount',
  'salesNetTechnicalCost',
  'salesNetTotal',
];

const purchaseAmountFieldsKeys = [
  'rcPurchase',
  'purchaseTechnicalCost',
  'purchaseDiscountPercent',
  'purchaseNetTotal',
  'purchaseNet',
  'purchaseSurchargePercent',
];

const serviceAmountFieldsKeys = [
  'serviceExternalWorkPercent',
  'serviceCreationPercent',
  'serviceChangeProductionPercent',
  'serviceStrategyPercent',
  'serviceProductionBroadcastPercent',
  'serviceClientServicePercent',
];

const amountFieldNotToValidate = (
  key: string,
  isSalesBlocked: boolean = false,
  isPurchaseBlocked: boolean = false,
) => {
  if (
    (salesAmountFieldsKeys.includes(key) && isSalesBlocked) ||
    (purchaseAmountFieldsKeys.includes(key) && isPurchaseBlocked) ||
    serviceAmountFieldsKeys.includes(key)
  ) {
    return true;
  }
  return false;
};

const specialSalesFieldsSum = (item: CreateUpdateEstimateItemDto) => {
  return mathUtils.round(
    mathUtils.add(
      item.serviceExternalWorkPercent,
      item.serviceCreationPercent,
      item.serviceChangeProductionPercent,
      item.serviceStrategyPercent,
      item.serviceProductionBroadcastPercent,
      item.serviceClientServicePercent,
    ),
  );
};
