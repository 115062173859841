export default {
  prLang: {
    importExcel: 'Import campaigns from spreadsheets',
    projects: 'Projects',
    projectsAndTeams: 'Projects and teams',
    projectsApplications: 'Project applications',
    delayedProjects: 'Overdue projects',
    canceledProjects: 'Projects to be canceled',
    project: 'Project',
    genitiveProject: 'of the project',
    accusativeProject: 'the project',
    genitiveProjectPlural: 'of the projects',
    projectNumber: 'Project number',
    internalProject: 'Commissioning project',
    projectList: 'Project list',
    withProjects: 'with projects',
    connectionsWithProjectsFVZ:
      'Connections with projects will be explained by teams',
    estimate: 'Estimate',
    estimate2: 'of the estimate',
    estimates: 'Estimates',
    addNewProject: 'Add a new project',
    newProjectName: 'New project name',
    chooseProject: 'Select project',
    sourceProject: 'Source project',
    sourceProject2: 'source project',
    addProject: 'Add project',
    editProject: 'Edit project',
    emptyProjectsList: 'No projects in the system.',
    projectNotFound: 'No project found with such ID.',
    salesInvoiceToProject: 'to the project',
    estimateItemPosition: 'Estimate item position',
    addEstimateItemPosition: 'Add estimate item position',
    chooseEstimateItem: 'Select estimate item',
    estimateItemInvoiceModifyWarning:
      'Invoice items are already saved. Are you sure you want to update the estimate items?',
    estimateItemInvoiceTabTitle: 'Estimate items',
    deleteProjectWarning:
      'Are you sure you want to delete the selected project?',
    deleteProjectTooltip: 'Delete project',
    unsettledPurchaseInvoicesAssignedToProject:
      'Unsettled purchase invoices assigned to projects',
    purchaseInvoicesAssignedToProject: 'Purchase invoices assigned to projects',
    orderResultProject: 'Subcontracted project',
    readyForInvoicing: 'Projects ready for invoicing',
    readyForCorrection: 'Projects with negative invoicing amount',
    applicationUnblocks: 'Requests to unblock estimate',
    projectsListModifiedInvoiceAlert:
      'The project list has been modified. After saving, estimate positions and invoice positions will be reset.',
    selectProjectsPurchaseInvoiceAlert:
      'To add multiple projects, select them by clicking on the row; selected projects will turn yellow. After saving changes, the selected projects (one or more) will be added.',
    selectProjectPurchaseInvoiceAlertError:
      'Please select one or more projects.',
    sameProject: 'of the same project',
    projectLabel: 'Project label',
    projectControl: 'Project control',
    estimateMonth: 'Estimate month',
    monthRangeEstimates: 'Estimate month range',
    teamRelatedEstimates: 'Estimate positions related to the team',
    forProject: 'for project',
    projectPurchase: 'Project purchase',
    estimatePositionsSum: 'Estimate positions sum',
  },
} as const;
