import React from 'react';

import enFlag from '../../assets/en.png';
import plFlag from '../../assets/pl.png';

export default (props: { country: 'PL' | 'EN' }) => {
  const flag = props.country === 'PL' ? plFlag : enFlag;
  return (
    <div className="d-flex align-items-center ms-4 me-1">
      <img src={flag} alt="plFlag" height="20" />
    </div>
  );
};
