/*eslint max-lines-per-function: ["error", 201]*/
import React, { useRef, useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import ReactHtmlParser from 'react-html-parser';

import { Option } from '../../components/Selects/Selector';
import FormRow from '../../../app/components/FormRow';
import { FileSelector } from '../../components/Attachments/FileSelector';
import {
  CreateUpdateExcelDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useTranslation } from '../../../common/hooks/useTranslation';
import ProjectExcelConfigSelector from '../../components/Selects/ProjectExcelConfigSelector';
import { newExcel } from '../../../common/pokCore/contexts/ProjectExcelImportContext';
import ProjectSelector from '../../components/Selects/ProjectSelector';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Waiting } from '../../../common/components/Waiting/Waiting';

interface ExcelImportFormProps {
  onImport: (excel: CreateUpdateExcelDto) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ExcelImportForm: React.FC<ExcelImportFormProps> = props => {
  const [fileName, setFileName] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [configId, setConfigId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const [projectNotInConfig, setProjectNotInConfig] = useState<boolean>(false);
  const [dateNotInConfig, setDateNotInConfig] = useState<boolean>(false);
  const [month, setMonth] = useState<Date | undefined>(undefined);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('excelImportLng');

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileNameRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }
    const f: File = files[0];
    setFile(f);
    setFileName(f.name);
  };

  const handleImport = async () => {
    setShowSaveErrors(false);
    if (projectNotInConfig && !projectId) {
      setShowSaveErrors(true);
      setSaveErrors([`Proszę wybrać ${t(tk.prLang.accusativeProject)}`]);
      return;
    }
    if (dateNotInConfig && !month) {
      setShowSaveErrors(true);
      setSaveErrors(['Proszę wybrać miesiąc']);
      return;
    }
    setIsLoading(true);
    const excel = newExcel();
    excel.companyId = pok.getCompanyId();
    try {
      excel.fileContent = file
        ? Buffer.from(await file.arrayBuffer())
        : Buffer.from('');
    } catch (e) {
      setSaveErrors(['Plik został zmodyfikowany, proszę wybrać go ponownie.']);
      setShowSaveErrors(true);
      setIsLoading(false);
      setFileName('');
      setFile(undefined);
      return;
    }
    excel.configId = configId;
    excel.projectId = projectId;
    excel.fileName = fileName;
    excel.date = month;
    props
      .onImport(excel)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          setFileName('');
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));

    setFile(undefined);
  };

  const setDate = (date: Date | undefined) => {
    if (date) {
      //ten kalendarz inaczej ustawia godzinę niż nasz główny kalendarz
      //dlatego trzeba skorygować godzinę
      date.setMinutes(-date.getTimezoneOffset());
      return date;
    }
    return undefined;
  };

  const configChanged = (config: string) => {
    pok.projectExcelConfigs
      .getById(config)
      .then(c => {
        setProjectNotInConfig(
          !c.excelConfigHeaders?.some(
            a => a.pokColumnName === 'projectNumber' && a.active,
          ),
        );
        setDateNotInConfig(
          !c.excelConfigHeaders?.some(
            a => a.pokColumnName === 'month' && a.active,
          ) &&
            (!c.excelConfigHeaders?.some(
              a => a.pokColumnName === 'startDate' && a.active,
            ) ||
              !c.excelConfigHeaders?.some(
                a => a.pokColumnName === 'endDate' && a.active,
              )),
        );
        setProjectId(undefined);
        setMonth(undefined);
        setDescription(c.description);
      })
      .catch(async errorResponse => {
        const errors = await responseErrors(errorResponse as ResponseError);
        setSaveErrors(errors);
        setShowSaveErrors(true);
      });
    setConfigId(config);
  };

  return (
    <>
      {isLoading && (
        <div>
          <h2 className="text-center pt-5">Trwa pobieranie danych...</h2>
          <Waiting />
        </div>
      )}
      {!isLoading && (
        <>
          <Card.Title className="pb-3">
            Import {t(tk.prLang.genitiveProjectPlural)} z arkuszy
          </Card.Title>
          <Form className="d-grid gap-3">
            <FormRow controlId="config" label="Konfiguracja">
              <ProjectExcelConfigSelector
                onChange={options => {
                  configChanged((options as Option).value);
                }}
                value={configId}
              />
            </FormRow>
            {projectNotInConfig && (
              <FormRow controlId="project" label={t(tk.prLang.project)}>
                <ProjectSelector
                  onChange={options => {
                    setProjectId((options as Option).value);
                  }}
                  value={projectId}
                />
              </FormRow>
            )}
            {dateNotInConfig && (
              <FormRow controlId="date" label="Miesiąc">
                <DatePicker
                  onChange={value =>
                    setMonth(setDate(value as Date | undefined))
                  }
                  defaultView="year"
                  minDetail="year"
                  maxDetail="year"
                  format="MM.yyyy"
                  value={month}
                  className="small-calendar"
                />
              </FormRow>
            )}
            <FormRow
              controlId="excel"
              label="Plik Excel zawierający dane do zaimportowania"
            >
              <FileSelector
                fileInputRef={fileInputRef}
                fileNameRef={fileNameRef}
                value={fileName}
                handleFileChange={handleFileChange}
                accept=".xlsx"
              />
            </FormRow>
          </Form>
          {description && (
            <FormRow controlId="description" label="Instrukcja importu">
              <Alert variant="info" className="ql-editor">
                {ReactHtmlParser(description)}
              </Alert>
            </FormRow>
          )}
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
          <div className="d-flex justify-content-end mt-3">
            <Button onClick={handleImport} variant="outline-primary">
              Importuj
            </Button>
          </div>
        </>
      )}
    </>
  );
};
