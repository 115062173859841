import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdatePurchaserDto,
  GetClientDto,
  GetTeamDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import CompanySelector from '../Selects/CompanySelector';
import { Option } from '../Selects/Selector';
import BudgetSelector from '../Selects/BudgetSelector';
import TeamSelector from '../Selects/TeamSelector';
import { ClientSelector } from '../Selects/ClientSelector';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PurchaserEditorProps {
  id?: string;
  systemNumber?: number;
  client?: GetClientDto;
  purchaser: CreateUpdatePurchaserDto;
  teams?: GetTeamDto[];
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    purchaser: CreateUpdatePurchaserDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const PurchaserEditor: React.FC<PurchaserEditorProps> = props => {
  const [purchaser, setPurchaser] = useState(props.purchaser);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('clientLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setPurchaser(props.purchaser);
    }
  }, [props.purchaser, props.show]);

  const createPurchaser = async () => {
    setIsLoading(true);
    props
      .onCompleted(purchaser, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdatePurchaserDto>) => {
    setPurchaser({ ...purchaser, ...obj });
  };

  const handleBudgetSelected = async (option: Option) => {
    if (option.value) {
      propertyChange({ budgetId: option.value });
    }
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `${t(tk.purchaser.purchaserEdit)} ${props.systemNumber}`
              : t(tk.purchaser.purchaserAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="client" label={t(tk.client.client)}>
              <ClientSelector
                onChange={e => {
                  propertyChange &&
                    propertyChange({
                      clientId: (e as Option).value,
                    });
                }}
                value={props?.client}
                readOnly={!!props.client?.id}
              />
            </FormRow>
            <FormRow controlId="name" label={t(tk.common.name)}>
              <Form.Control
                type="text"
                value={purchaser.name || ''}
                onChange={e => propertyChange({ name: e.target.value })}
              />
            </FormRow>
            <FormRow controlId="budget" label={t(tk.common.budget)}>
              <BudgetSelector
                value={purchaser?.budgetId}
                onChange={handleBudgetSelected}
              />
            </FormRow>
            <FormRow controlId="company" label={t(tk.common.company)}>
              <CompanySelector
                value={purchaser.companyId}
                onChange={e => {
                  propertyChange &&
                    propertyChange({
                      companyId: (e as Option).value,
                      teamIds: [],
                    });
                }}
                readOnly={props.id !== undefined}
              />
            </FormRow>
            <FormRow controlId="team" label={t(tk.common.teams)}>
              <TeamSelector
                isMulti={true}
                value={purchaser.teamIds}
                companyId={purchaser.companyId}
                onChange={options => {
                  propertyChange({
                    teamIds: options
                      ? (options as Option[]).map(({ value }) => value)
                      : [],
                  });
                }}
              />
            </FormRow>
            <FormRow controlId="active" label={t(tk.common.activeHe)}>
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={purchaser.active ?? true}
                onChange={e => propertyChange({ active: e.target.checked })}
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.purchasers.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={createPurchaser}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
