import React from 'react';

import { usePokCore } from '../../common/hooks/usePokCore';

import { InstructionsPLView } from './InstructionsPLView';
import { InstructionsENView } from './InstructionsENView';

export const InstructionsView: React.FC = () => {
  const pok = usePokCore();

  return (
    <>
      {pok.getLanguage() === 'PL' ? (
        <InstructionsPLView />
      ) : (
        <InstructionsENView />
      )}
    </>
  );
};
