import { CurrencyIsoTypeEnum } from './pokCore/validation/schemas';

export default (
  value: string | number | null,
  isCurrency = false,
  currencyIso?: CurrencyIsoTypeEnum | undefined,
) => {
  const currencyItem = sessionStorage.getItem('pok-currency');
  if (!currencyItem) {
    throw new Error('Brak ustawionej waluty w spółce!');
  }
  const currency = JSON.parse(currencyItem);
  const countryCode = currency?.countryCode;
  const currencyCode = currencyIso ?? currency?.currencyCode;

  return value !== null && value !== undefined
    ? isCurrency
      ? Intl.NumberFormat(countryCode, {
          style: 'currency',
          currency: currencyCode,
          useGrouping: true,
          currencyDisplay: 'narrowSymbol',
        }).format(Number(value))
      : Intl.NumberFormat(countryCode).format(Number(value))
    : '';
};
