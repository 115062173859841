import React from 'react';
import { Card } from 'react-bootstrap';

import CopyButton from '../../../common/components/CopyButton/CopyButton';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const EmailMessageText: React.FC = () => {
  const { t, tk } = useTranslation('applicationLng');
  const rawHtml = `<p>Dear Sir/Madam,</p>
            <p>In connection with the purchase of advertising services from you, I kindly ask you to:</p>
            <ol>
            <li>send a certificate of tax residence for the year 2024</li>
            <li>send an extract from the commercial register confirming the legal name and place of incorporation of the Recipient</li>
            <li>provide answers to the questions below</li>
            </ol>
            <p>The above documents are necessary for us to avoid withholding tax. If we do not receive all documents and information that we need before the payment deadline indicated on the invoice, we have to deduct 20% of withholding tax from all transfers to you and pay the tax to our tax office.
            The regulations regarding the settlement of withholding tax require due diligence in verifying whether the contractor actually operates in the country where the tax is to be settled. Therefore, we kindly ask you to answer a few questions.</p>
            <p>WHT QUESTIONNAIRE:</p>
            <ul>
            <li><p>Does your Company employ staff, does it have an office and equipment used to conduct business (e.g. the company employs staff, we have an office and computer and office equipment)?</p>
            </li>
            <li><p>Do your company's managers/directors work only for your company, or do they also professionally perform similar functions elsewhere? (Management persons perform such functions only in our company)? </p>
            </li>
            <li><p>Are documents concerning important matters of your company signed mainly in the country of registration? (YES/NO - if no, please elaborate, e.g. give examples and reasons for this)?</p>
            </li>
            <li><p>In the country where your company is registered, is there an office (or other place) where management meetings can be held and where important decisions for the company can be taken? (YES/NO - in case of a negative answer, please elaborate, e.g. indicate the reasons for this and where such decisions are made)?</p>
            </li>
            <li><p>Are there other companies also registered at your company address? If so, are they affiliated with your company?</p>
            </li>
            <li><p>Do your company's managers have business phone numbers or email addresses that are associated with the company's place of registration (e.g., Yes, board members have business phones registered with a national network)?</p>
            </li>
            </ul>`;

  const clipboardItem = new ClipboardItem({
    'text/html': new Blob([rawHtml], { type: 'text/html' }),
    'text/plain': new Blob([rawHtml.replace(/<\/?[^>]+(>|$)/g, '')], {
      type: 'text/plain',
    }),
  });
  return (
    <Card className="card mt-2">
      <Card.Header className="text-danger">
        {t(tk.app.alertBeforeAddingForeignClient)}
      </Card.Header>
      <Card.Body className="m-3">
        <div className="copy-button">
          <CopyButton
            clipboardItem={clipboardItem}
            tooltipText={t(tk.app.copyEmailContent)}
            buttonText={t(tk.app.copyEmailContent)}
            variant="outline-primary"
          />
        </div>
        <div
          className="d-grid gap-4 mb-3"
          dangerouslySetInnerHTML={{
            __html: rawHtml,
          }}
        ></div>
      </Card.Body>
    </Card>
  );
};
