import * as projects from './plProjects';
import * as campaigns from './plCampaigns';
import * as en from './en';

export const schema = projects;
export type TranslationSchema = typeof projects;

export const resources = {
  projects,
  campaigns,
  en,
} as const;
