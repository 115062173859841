import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import {
  CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
  GetProjectExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import TeamSelector from '../Selects/TeamSelector';
import { Option } from '../Selects/Selector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';
import usePurchaseInvoicePermissions from '../PurchaseInvoices/usePurchaseInvoicePermissions';

interface PurchaseInvoiceEditorFormTeamsAndProjectsProps {
  teamsAndProjects: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto;
  propertyChange: (
    obj: Partial<CreateUpdatePurchaseInvoiceTeamsAndProjectsDto> & {
      projects?: GetProjectExtendedDto[];
    },
  ) => void;
  readOnly: boolean;
}

const getOptionValues = (
  options: Option | MultiValue<Option> | null | undefined,
) => (options ? (options as Option[]).map(({ value }) => value) : []);

export const PurchaseInvoiceTeamsForm: React.FC<
  PurchaseInvoiceEditorFormTeamsAndProjectsProps
> = props => {
  const { t, tk } = useTranslation('fvzLng');
  const pok = usePokCore();
  const { isFinanceUser, isProjectUser } = usePurchaseInvoicePermissions();
  const [isTeams, setIsTeams] = useState<boolean>(
    !!props.teamsAndProjects?.teamIds?.length,
  );
  const isAllowedUser = isFinanceUser || isProjectUser;

  useEffect(() => {
    setIsTeams(!!props.teamsAndProjects?.teamIds?.length);
  }, [props.teamsAndProjects?.teamIds?.length]);

  return (
    <Form className="d-grid gap-4 mb-3">
      {props.teamsAndProjects?.teamIds?.length ? null : (
        <FormRow
          controlId="isTeams"
          label={t(tk.prLang.connectionsWithProjectsFVZ)}
        >
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={isTeams || false}
            onChange={e => {
              setIsTeams(e.target.checked);
              if (!e.target.checked) {
                props.propertyChange({ teamIds: [] });
              }
            }}
            disabled={!isAllowedUser || props.readOnly}
          />
        </FormRow>
      )}
      {isTeams ? (
        <FormRow controlId="teamIds" label="Zespoły do wyjaśnienia">
          <TeamSelector
            isMulti={true}
            value={props.teamsAndProjects?.teamIds || []}
            clearable={true}
            companyId={pok.getCompanyId()}
            onChange={options =>
              props.propertyChange({
                teamIds: getOptionValues(options),
              })
            }
            readOnly={!isTeams || !isAllowedUser || props.readOnly}
          />
        </FormRow>
      ) : null}
    </Form>
  );
};
