import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateBudgetDto,
  GetBudgetDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { BudgetModalEditor } from '../../components/Budgets/BudgetModalEditor';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/BudgetContext';
import { BudgetSearch } from '../../components/Budgets/BudgetSearch';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const BudgetsView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const [budget, setBudget] = useState<GetBudgetDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t, tk } = useTranslation('clientLng');

  const handleAdd = () => {
    setShow(true);
    setBudget(undefined);
  };

  const addEditBudget = async (
    budgetCU: CreateUpdateBudgetDto,
    id?: string,
  ) => {
    const status = await validate(budgetCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.budgets.update(id, budgetCU);
        } else {
          await pok.budgets.create(budgetCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.budget.budgets)}</Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.budget.budgetNew)}
        </Button>
        <BudgetSearch
          getData={pok.budgets.getAllByPortion}
          getCount={pok.budgets.getAllCount}
          onRowClick={nav.budget}
          refresh={refresh}
        />
        {show && (
          <BudgetModalEditor
            id={budget?.id}
            systemNumber={budget?.systemNumber}
            budget={convert(budget)}
            onCompleted={addEditBudget}
            show={show}
            handleClose={handleClose}
          />
        )}
      </Card.Body>
    </Card>
  );
};
