import React, { Dispatch, SetStateAction, useMemo } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Col, Form, Row } from 'react-bootstrap';
import { maxBy, minBy } from 'lodash';

import { GetEstimateItemDto } from '../../../common/pokCore/autogenerated/pokApiClient';

export type RangeFilter = [Date | null, Date | null];

interface InvoiceEstimateItemsFiltersProps {
  estimateItems: GetEstimateItemDto[];
  dateFilter: RangeFilter;
  setDateFilter: Dispatch<SetStateAction<RangeFilter>>;
  additionalFilters?: React.ReactNode;
  disableMinMaxBorders?: boolean;
}

export const InvoiceEstimateItemsFilters: React.FC<
  InvoiceEstimateItemsFiltersProps
> = props => {
  const minDate = useMemo(
    () =>
      minBy(props.estimateItems, item => item.estimateByMonth?.date)
        ?.estimateByMonth.date,
    [props.estimateItems],
  );
  const maxDate = useMemo(
    () =>
      maxBy(props.estimateItems, item => item.estimateByMonth?.date)
        ?.estimateByMonth.date,
    [props.estimateItems],
  );

  return (
    <>
      <Row className="p-2">
        <Form.Group as={Row} controlId="monthFilter">
          <Form.Label column sm="2">
            Filtruj po miesiącu
          </Form.Label>
          <Col>
            <div className="small-calendar">
              <DateRangePicker
                onChange={value =>
                  props.setDateFilter(
                    value ? (value as RangeFilter) : [null, null],
                  )
                }
                value={props.dateFilter}
                defaultView="year"
                minDetail="year"
                maxDetail="year"
                format="MM.yyyy"
                minDate={props.disableMinMaxBorders ? undefined : minDate}
                maxDate={props.disableMinMaxBorders ? undefined : maxDate}
              />
            </div>
          </Col>
        </Form.Group>
      </Row>
      {props.additionalFilters}
      <hr />
    </>
  );
};
