import React from 'react';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ProjectDatesFormProps {
  project: CreateUpdateProjectWithTeamDto;
  readOnly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateProjectWithTeamDto>) => void;
  startDateChanged?: (date: Date) => void;
}

const MIN_PROJECT_DATE = '2024-01-01';

export const ProjectDatesForm: React.FC<ProjectDatesFormProps> = props => {
  const { t, tk } = useTranslation('projectLng');

  return (
    <>
      <FormRow controlId="startDate" label={t(tk.common.startDate)}>
        <Form.Control
          type="date"
          name="startDatePic"
          placeholder="DateRange"
          value={momencik(props.project.startDate, 'YYYY-MM-DD')}
          onChange={e =>
            props.startDateChanged &&
            props.startDateChanged(new Date(e.target.value))
          }
          disabled={props.readOnly}
          min={MIN_PROJECT_DATE}
        />
      </FormRow>
      <FormRow controlId="endDate" label={t(tk.common.endDate)}>
        <Form.Control
          type="date"
          name="endDatePic"
          placeholder="DateRange"
          value={momencik(props.project.endDate, 'YYYY-MM-DD')}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({
              endDate: new Date(e.target.value),
            })
          }
          disabled={props.readOnly}
        />
      </FormRow>
    </>
  );
};
