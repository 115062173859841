import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import {
  CreateUpdateOrderDto,
  GetEmployeeDto,
  GetEstimateItemDto,
  GetOrderEstimateItemDto,
  GetProjectDto,
  GetTeamDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import FormRow from '../../../app/components/FormRow';
import { Option } from '../Selects/Selector';
import TeamSelector from '../Selects/TeamSelector';
import EmployeeSelector from '../Selects/EmployeeSelector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { OrderEstimateItemsGrid } from './OrderEstimateItemsGrid';

interface OrderFormProps {
  order: CreateUpdateOrderDto;
  propertyChange?: (obj: Partial<CreateUpdateOrderDto>) => void;
  emails: string[];
  setEmails?: (emails: string[]) => void;
  setOrderEstimateItems?: (emails: string[]) => void;
  project?: GetProjectDto;
  resultProject?: GetProjectDto;
  readOnly?: boolean;
  orderEstimateItems?: GetOrderEstimateItemDto[];
  currencyCode?: string | undefined;
}

export const OrderForm: React.FC<OrderFormProps> = ({
  order,
  propertyChange,
  setOrderEstimateItems,
  emails,
  setEmails,
  readOnly,
  project,
  resultProject,
  orderEstimateItems,
  currencyCode,
}) => {
  const { t, tk } = useTranslation('orderLng');
  const pok = usePokCore();
  const notifications = useNotifications();

  const [estimateItems, setEstimateItems] = useState<GetEstimateItemDto[]>([]);
  const [fetchedEmployees, setFetchedEmployees] = useState<GetEmployeeDto[]>(
    [],
  );
  const [fetchedTeams, setFetchedTeams] = useState<GetTeamDto[]>([]);

  const onChangeEmployees = (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && Array.isArray(option)) {
      propertyChange?.({
        orderEmployeesIds: (option as Option[]).map(({ value }) => value),
      });
    }
  };

  const generateEmailsList = (): string[] => {
    const teamEmail = fetchedTeams.find(
      team => team.id === order.teamId,
    )?.email;

    const employeesEmails = order.orderEmployeesIds.map(employee => {
      return fetchedEmployees.find(fE => fE.id === employee)?.email;
    });

    const emailsList = order.addTeamToEmails
      ? [teamEmail, ...employeesEmails]
      : employeesEmails;

    return emailsList.filter(value => !!value) as string[];
  };

  const handleSetFetchedEmployees = (options: GetEmployeeDto[]) =>
    setFetchedEmployees(options);

  const handleSetFetchedTeams = (options: GetTeamDto[]) =>
    setFetchedTeams(options);

  useEffect(() => {
    if (order.teamId) {
      pok.estimateItems
        .findAllByProjectAndTeam(order.projectId, order.teamId)
        .then(data => {
          if (order.id && orderEstimateItems) {
            const orderEstimateItemsIds = orderEstimateItems.map(
              ({ estimateItem }) => estimateItem.id,
            );
            data = data.filter(({ id }) => orderEstimateItemsIds.includes(id));
          }

          setEstimateItems(data);
          setOrderEstimateItems?.(data.map(({ id }) => id));
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, order.projectId, order.teamId, pok.estimateItems]);

  useEffect(() => {
    setEmails?.(generateEmailsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedTeams,
    order.teamId,
    fetchedEmployees,
    order.orderEmployeesIds,
    order.addTeamToEmails,
  ]);

  return (
    <Form className="d-grid gap-4 mb-3">
      {project && (
        <FormRow controlId="project" label={t(tk.prLang.project)}>
          <Form.Control
            value={`${project.name} (${project?.number})`}
            disabled
          />
        </FormRow>
      )}
      {resultProject && (
        <FormRow
          controlId="resultProject"
          label={t(tk.prLang.orderResultProject)}
        >
          <Form.Control
            value={`${resultProject.name} (${resultProject?.number})`}
            disabled
          />
        </FormRow>
      )}
      <FormRow controlId="team" label={t(tk.order.specialTeam)}>
        <TeamSelector
          value={order.teamId}
          projectId={order.projectId}
          setOptions={handleSetFetchedTeams}
          onChange={option => {
            const newOption = option as Option;
            propertyChange?.({
              teamId: newOption?.value || undefined,
            });
          }}
          readOnly={readOnly}
        />
      </FormRow>
      <FormRow controlId="employees" label={t(tk.order.receivers)}>
        <EmployeeSelector
          value={order.orderEmployeesIds}
          isMulti
          fetchForOrders
          setOptions={handleSetFetchedEmployees}
          onChange={onChangeEmployees}
          readOnly={readOnly}
        />
      </FormRow>
      <FormRow
        controlId="sendEmail"
        label={t(tk.order.sendEmailOfToSpecialTeam)}
      >
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={order.addTeamToEmails}
          onChange={e => {
            const { checked } = e.target;
            propertyChange?.({
              addTeamToEmails: checked,
            });
          }}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow controlId="emails" label={t(tk.common.emails)}>
        <DynamicTextarea value={emails.join(', ')} disabled />
      </FormRow>
      <FormRow
        controlId="estimateItems"
        label={t(tk.prLang.teamRelatedEstimates)}
      >
        <OrderEstimateItemsGrid
          data={estimateItems}
          currencyCode={currencyCode}
        />
      </FormRow>
    </Form>
  );
};
