import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { GetRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { UsersInRoleLink } from './UsersInRoleLink';

interface RoleSearchProps {
  data: GetRoleDto[];
  onRowClick: (key: string) => Promise<void> | void | undefined;
  selectedKeys?: string[];
  withHistory?: boolean;
}

export const RoleSearch: React.FC<RoleSearchProps> = props => {
  const { t, tk } = useTranslation('roleLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.name), property: 'role.name' },
    { header: t(tk.common.description), property: 'role.description' },
    { header: t(tk.role.permissions), property: 'role.permissions' },
  ];

  if (props.withHistory) {
    columns.push({ header: '', property: 'link' });
  }

  const data = props.data.map(role => {
    return {
      key: role.id,
      values: {
        'role.name': role.name,
        'role.description': role.description,
        'role.permissions': role.permissions.join(', '),
        link: <UsersInRoleLink roleId={role.id} />,
      },
      orderByStringValues: {
        name: role.name,
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="role.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.role.noRoles)}
      onRowClick={props.onRowClick}
      enableMultiSelect={props.selectedKeys ? true : false}
      selectedKeys={props.selectedKeys}
      withHistory={props.withHistory}
    />
  );
};
