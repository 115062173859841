import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { uniq } from 'lodash';

import {
  CreateUpdateSalesInvoiceDto,
  GetProjectDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Grid } from '../../../common/components/Grid/Grid';

import { SalesInvoiceAddProjectEditor } from './SalesInvoiceAddProjectEditor';

interface SalesInvoiceProjectsFormGridProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
  projects: GetProjectDto[];
  projectsIdsInParent?: string[];
  setProjects: Dispatch<SetStateAction<GetProjectDto[]>>;
  purchaser?: GetPurchaserDto;
}

export const SalesInvoiceProjectsFormGrid: React.FC<
  SalesInvoiceProjectsFormGridProps
> = props => {
  const { t, tk } = useTranslation('fvsLng');
  const pok = usePokCore();
  const notifications = useNotifications();
  const [show, setShow] = useState(false);
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const handleDelete = (id: string) => {
    props.propertyChange({
      projectIds: props.salesInvoice.projectIds.filter(
        projectId => projectId !== id,
      ),
    });
    props.setProjects(prevState =>
      prevState.filter(project => project.id !== id),
    );
  };

  const COLUMNS: GridColumn[] = [
    { header: 'Numer', property: 'number' },
    { header: 'Nazwa', property: 'name' },
    { header: 'Zamawiający', property: 'purchaser' },
    { header: 'Brand', property: 'brand' },
    ...(props.readOnly || props.projects.length <= 1
      ? []
      : [{ header: '', property: 'deleteLink', noSorting: true }]),
  ];

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShowSaveErrors(false);
    setSaveErrors([]);
    setSelectedProjectsIds([]);
    setShow(false);
  };

  const handleAdd = (id: string) => {
    setSelectedProjectsIds(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(prevId => prevId !== id);
      }

      return [...prevState, id];
    });
  };

  const handleSave = async () => {
    if (selectedProjectsIds.length) {
      props.propertyChange({
        projectIds: uniq([
          ...props.salesInvoice.projectIds,
          ...selectedProjectsIds,
        ]),
      });

      setIsLoading(true);
      await Promise.all(selectedProjectsIds.map(id => pok.projects.getById(id)))
        .then(projects => {
          props.setProjects(prevState => [...prevState, ...projects]);
          handleClose();
        })
        .catch(err => {
          notifications.caughtError(err);
        })
        .finally(() => setIsLoading(false));
    } else {
      setSaveErrors([t(tk.prLang.selectProjectPurchaseInvoiceAlertError)]);
      setShowSaveErrors(true);
    }
  };

  const data = (props.projects || []).map(project => {
    const shortName = project.purchaser?.client?.shortname || '';
    const purchaserLabel = `${project.purchaser?.name} (${
      shortName.length ? shortName : project.purchaser?.client?.name
    })`;
    const existInParent = props.projectsIdsInParent?.includes(project.id);

    return {
      key: project.id,
      values: {
        name: project.name,
        number: project.number,
        purchaser: purchaserLabel,
        brand: project.brand?.name,
        deleteLink: existInParent ? (
          <></>
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className="d-flex justify-content-center"
            onClick={e => e.stopPropagation()}
          >
            <ConfirmationButton
              variant="outline-danger"
              confirmation={t(tk.prLang.deleteProjectWarning)}
              tooltip={t(tk.prLang.deleteProjectTooltip)}
              onOK={() => handleDelete(project.id)}
              disabled={props.projects.length <= 1}
            >
              <Trash size={16} />
            </ConfirmationButton>
          </div>
        ),
      },
    };
  }) as GridRecord[];

  if (process.env.REACT_APP_ALLOW_MANY_PROJECTS_TO_INVOICE !== 'on') {
    return;
  }

  return (
    <div>
      {props.readOnly ? null : (
        <Button variant="outline-primary" className="mb-4" onClick={handleOpen}>
          {t(tk.prLang.addProject)}
        </Button>
      )}
      <Card.Title className="mb-3">
        {t(tk.prLang.projectList)} dodanych w ramach budżetu{' '}
        <b>{props.purchaser?.budget.name}</b>
      </Card.Title>
      <Grid
        data={data}
        columns={COLUMNS}
        initialOrderColumn="number"
        initialOrderDirection="DESC"
      />
      <SalesInvoiceAddProjectEditor
        show={show}
        handleClose={handleClose}
        handleAdd={handleAdd}
        handleSave={handleSave}
        salesInvoice={props.salesInvoice}
        purchaser={props.purchaser}
        isLoading={isLoading}
        saveErrors={saveErrors}
        showSaveErrors={showSaveErrors}
      />
    </div>
  );
};
