import React, { useState, useEffect } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetCurrencyDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface CurrencySelectorProps {
  readOnly?: boolean;
  className?: string;
  onChange?: (option: Option) => void;
  value?: string;
}

export default (props: CurrencySelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [currencies, setCurrencies] = useState<GetCurrencyDto[]>([]);

  useEffect(() => {
    let mounted = true;

    pok.currencies
      .getAll()
      .then(data => {
        if (mounted) {
          setCurrencies(data);
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });

    return () => {
      mounted = false;
    };
  }, [notifications, pok.currencies]);

  const provider = async (text: string) => {
    const values = currencies
      .sort((a, b) => a.name?.localeCompare(b.name || '') || 0)
      .filter(v =>
        v.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
      );

    return values.map(v => {
      return {
        badge: '',
        label: v.name,
        value: v.id,
      };
    });
  };

  return (
    <Selector
      uniqueKey={'currency-' + JSON.stringify(currencies)}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      clearable={false}
      provider={provider}
      value={props.value}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
