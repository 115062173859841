import React from 'react';
import * as Icon from 'react-bootstrap-icons';

import { usePokCore } from '../../../common/hooks/usePokCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface DeleteButtonProps {
  employeeId?: string;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('administrationLng');

  const handleClick = async () => {
    if (props.employeeId) {
      await notifications.onPromise(
        pok.employeesRoles.delete(props.employeeId),
      );
      props.setRefresh(!props.refresh);
    }
  };

  return (
    <ConfirmationButton
      className="px-2"
      confirmation={t(tk.administration.askDeleteUserPermissions)}
      variant="btn-info"
      onOK={handleClick}
      tooltip={t(tk.administration.deleteUserPermissions)}
    >
      <Icon.Trash className="icon-blue" size={16} />
    </ConfirmationButton>
  );
};
