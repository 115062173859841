import React from 'react';

import { ReportFVSByFilterRawDataView } from './ReportFVSByFilterRawDataView';

export const ReportFVSWithPositionsByFilterRawDataView: React.FC = () => {
  return (
    <ReportFVSByFilterRawDataView
      title="Zestawienie faktur sprzedaży z nazwami pozycji"
      withPositions={true}
    />
  );
};
