import {
  CreateUpdateJobConfigDto,
  CreateUpdateJobConfigDtoFromJSON,
  DefaultApi,
  GetJobConfigDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { FilterColumn } from '../../types/FilterColumn';
import { JobConfigSchema } from '../validation/schemas';
import { jobConfigForGrid } from '../../../pok/components/JobConfig/JobConfigSearch';

export interface JobConfigContextInterface {
  getById: (id: string) => Promise<GetJobConfigDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateJobConfigDto) => Promise<void>;
  create: (dto: CreateUpdateJobConfigDto) => Promise<GetJobConfigDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  synchronizeAllJobs: () => Promise<void>;
}

export const JobConfigContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.jobConfigControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.jobConfigControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(jobConfigForGrid);
    },
    getAllCount: async (filterText?: string, filterColumns?: FilterColumn) =>
      api.jobConfigControllerGetAllCount(filterText || '', filterColumns),
    update: (id: string, dto: CreateUpdateJobConfigDto) =>
      api.jobConfigControllerUpdate(id, dto),
    create: (dto: CreateUpdateJobConfigDto) =>
      api.jobConfigControllerCreate(dto),
    getLastEditor: (id: string) => api.jobConfigControllerGetLastEditor(id),
    synchronizeAllJobs: () => api.jobConfigControllerSynchronizeAllJobs(),
  };
};

export const newJobConfig = () => {
  return CreateUpdateJobConfigDtoFromJSON({
    teamIds: [],
    emails: [],
  });
};

export const validate = (jobConfig: CreateUpdateJobConfigDto) => {
  return validateAgainst(JobConfigSchema, jobConfig);
};

export const convert = (jobConfig?: GetJobConfigDto) => {
  if (!jobConfig) {
    return newJobConfig();
  }
  const converted = CreateUpdateJobConfigDtoFromJSON(jobConfig);

  converted.companyId = jobConfig.company?.id;
  converted.teamIds =
    jobConfig.jobConfigTeams?.map(({ team }) => team.id) || [];

  return converted;
};
