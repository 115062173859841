import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateProfileDto,
  GetCompanyDto,
  GetTeamDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ProfileEditorForm } from './ProfileEditorForm';

interface ProfileEditorProps {
  id?: string;
  profile: CreateUpdateProfileDto;
  companies?: GetCompanyDto[];
  teams?: GetTeamDto[];
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    profile: CreateUpdateProfileDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ProfileEditor: React.FC<ProfileEditorProps> = props => {
  const [profile, setProfile] = useState(props.profile);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('administrationLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setProfile(props.profile);
    }
  }, [props.profile, props.show]);

  const createProfile = async () => {
    setIsLoading(true);
    props
      .onCompleted(profile, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateProfileDto>) => {
    setProfile({ ...profile, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? t(tk.administration.estimateProfileEdit)
              : t(tk.administration.estimateProfileAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProfileEditorForm
            id={props.id}
            profile={profile}
            propertyChange={propertyChange}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo id={props.id} method={pok.profiles.getLastEditor} />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={createProfile}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
