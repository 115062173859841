import React, { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { BankAccountEditor } from '../../components/BankAccounts/BankAccountEditor';
import {
  CreateUpdateBankAccountDto,
  GetBankAccountDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/BankAccountContext';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { BankAccountSearch } from '../../components/BankAccounts/BankAccountSearch';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const BankAccountsView: React.FC = () => {
  const [bankAccount, setBankAccount] = useState<GetBankAccountDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const pok = usePokCore();
  const { t, tk } = useTranslation('administrationLng');

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.bankAccounts.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok.bankAccounts],
  );

  const getCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.bankAccounts.getAllCount(
          filterText,
          filterColumns,
        )) as GridCountFunction,
    [pok.bankAccounts],
  );

  const handleAdd = () => {
    setShow(true);
    setBankAccount(undefined);
  };

  const addEditBankAccount = async (
    bankAccountCU: CreateUpdateBankAccountDto,
    id?: string,
  ) => {
    if (
      bankAccountCU &&
      bankAccountCU.companyId &&
      (!bankAccountCU.company ||
        bankAccountCU.company.id !== bankAccountCU.companyId)
    ) {
      await pok.companies.getById(bankAccountCU.companyId).then(company => {
        bankAccountCU.company = company;
      });
    }
    const status = await validate(bankAccountCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveUpdateBankAccount(bankAccountCU, id);
  };

  const saveUpdateBankAccount = async (
    bankAccountCU: CreateUpdateBankAccountDto,
    id?: string,
  ) => {
    try {
      if (id) {
        await pok.bankAccounts.update(id, bankAccountCU);
      } else {
        await pok.bankAccounts.create(bankAccountCU);
      }
      setShow(false);
      setRefresh(!refresh);
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const fetchedBankAccount = await pok.bankAccounts.getById(id);
    setBankAccount(fetchedBankAccount);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.administration.bankAccounts)}
        </Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.administration.bankAccountNew)}
        </Button>
        <BankAccountSearch
          getData={getData}
          getCount={getCount}
          onRowClick={handleClick}
          refresh={refresh}
        />
        {show && (
          <BankAccountEditor
            id={bankAccount?.id}
            bankAccount={convert(bankAccount)}
            client={bankAccount?.client}
            onCompleted={addEditBankAccount}
            show={show}
            handleClose={handleClose}
          />
        )}
      </Card.Body>
    </Card>
  );
};
