/*eslint max-lines-per-function: ["error", 164]*/
import React, { useState } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';

import {
  CreateUpdateProfileDto,
  ProfileSchemaDto,
  SchemaAttributesDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import CompanySelector from '../Selects/CompanySelector';
import { Option } from '../Selects/Selector';
import TeamSelector from '../Selects/TeamSelector';
import { nameof } from '../../../utils/objectUtils';
import { SchemaItemInput } from '../SchemaItemInput/SchemaItemInput';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ProfileEditorFormProps {
  id?: string;
  profile: CreateUpdateProfileDto;
  propertyChange: (obj: Partial<CreateUpdateProfileDto>) => void;
}

const PROFILE_SCHEMA_ITEMS = [
  {
    id: 'sale',
    title: 'sales',
    items: [
      {
        key: nameof<ProfileSchemaDto>('rcSalesTotal'),
        label: 'rcSalesTotal',
      },
      {
        key: nameof<ProfileSchemaDto>('salesSurchargePercent'),
        label: 'salesSurchargePercent',
      },
      {
        key: nameof<ProfileSchemaDto>('salesDiscountPercent'),
        label: 'salesDiscountPercent',
      },
      { key: nameof<ProfileSchemaDto>('salesNet'), label: 'salesNet' },
      {
        key: nameof<ProfileSchemaDto>('salesCommissionPercent'),
        label: 'salesCommissionPercent',
      },
      {
        key: nameof<ProfileSchemaDto>('salesCommissionAmount'),
        label: 'salesCommissionAmount',
      },
      {
        key: nameof<ProfileSchemaDto>('salesNetTechnicalCost'),
        label: 'salesNetTechnicalCost',
      },
      {
        key: nameof<ProfileSchemaDto>('salesNetTotal'),
        label: 'salesNetTotal',
      },
    ],
  },
  {
    id: 'buy',
    title: 'buy',
    items: [
      { key: nameof<ProfileSchemaDto>('rcPurchase'), label: 'rcPurchase' },
      {
        key: nameof<ProfileSchemaDto>('purchaseSurchargePercent'),
        label: 'purchaseSurchargePercent',
      },
      {
        key: nameof<ProfileSchemaDto>('purchaseDiscountPercent'),
        label: 'purchaseDiscountPercent',
      },
      { key: nameof<ProfileSchemaDto>('purchaseNet'), label: 'purchaseNet' },
      {
        key: nameof<ProfileSchemaDto>('purchaseTechnicalCost'),
        label: 'purchaseTechnicalCost',
      },
      {
        key: nameof<ProfileSchemaDto>('purchaseNetTotal'),
        label: 'purchaseNetTotal',
      },
    ],
  },
  {
    id: 'media',
    title: 'media',
    items: [
      {
        key: nameof<ProfileSchemaDto>('numberOfMedia'),
        label: 'numberOfMedia',
      },
      {
        key: nameof<ProfileSchemaDto>('technologyDictionary'),
        label: 'technologyDictionary',
      },
      {
        key: nameof<ProfileSchemaDto>('regionDictionary'),
        label: 'regionDictionary',
      },
      {
        key: nameof<ProfileSchemaDto>('additionalDescription'),
        label: 'additionalDescription',
      },
    ],
  },
  {
    id: 'format',
    title: 'format',
    items: [
      {
        key: nameof<ProfileSchemaDto>('formatRadio'),
        label: 'formatRadio',
      },
      {
        key: nameof<ProfileSchemaDto>('formatPress'),
        label: 'formatPress',
      },
      {
        key: nameof<ProfileSchemaDto>('formatInternet'),
        label: 'formatInternet',
      },
      {
        key: nameof<ProfileSchemaDto>('formatInternetST'),
        label: 'formatInternetST',
      },
    ],
  },
  {
    id: 'category',
    title: 'category',
    items: [
      {
        key: nameof<ProfileSchemaDto>('categoryInternet'),
        label: 'categoryInternet',
      },
      {
        key: nameof<ProfileSchemaDto>('categoryInternetST'),
        label: 'categoryInternetST',
      },
      {
        key: nameof<ProfileSchemaDto>('categoryLabcon'),
        label: 'categoryLabcon',
      },
    ],
  },
  {
    id: 'purchase',
    title: 'purchase',
    items: [
      {
        key: nameof<ProfileSchemaDto>('purchaseFormInternet'),
        label: 'purchaseFormInternet',
      },
      {
        key: nameof<ProfileSchemaDto>('purchaseFormInternetST'),
        label: 'purchaseFormInternetST',
      },
      {
        key: nameof<ProfileSchemaDto>('purchaseMethod'),
        label: 'purchaseMethod',
      },
    ],
  },
  {
    id: 'emissionType',
    title: 'emissionType',
    items: [
      {
        key: nameof<ProfileSchemaDto>('emissionTypeRadio'),
        label: 'emissionTypeRadio',
      },
      {
        key: nameof<ProfileSchemaDto>('emissionTypeCinema'),
        label: 'emissionTypeCinema',
      },
    ],
  },
  {
    id: 'service',
    title: 'service',
    items: [
      {
        key: nameof<ProfileSchemaDto>('service'),
        label: 'service',
      },
      {
        key: nameof<ProfileSchemaDto>('action'),
        label: 'action',
      },
      {
        key: nameof<ProfileSchemaDto>('actionGroup'),
        label: 'actionGroup',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceExternalWorkPercent'),
        label: 'serviceExternalWorkPercent',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceCreationPercent'),
        label: 'serviceCreationPercent',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceChangeProductionPercent'),
        label: 'serviceChangeProductionPercent',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceStrategyPercent'),
        label: 'serviceStrategyPercent',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceProductionBroadcastPercent'),
        label: 'serviceProductionBroadcastPercent',
      },
      {
        key: nameof<ProfileSchemaDto>('serviceClientServicePercent'),
        label: 'serviceClientServicePercent',
      },
    ],
  },
  {
    id: 'additionalInformation',
    title: 'additionalInformation',
    items: [
      {
        key: nameof<ProfileSchemaDto>('isUpSell'),
        label: 'isUpSell',
      },
      {
        key: nameof<ProfileSchemaDto>('netCustomerAccount'),
        label: 'netCustomerAccount',
      },
      {
        key: nameof<ProfileSchemaDto>('copySize'),
        label: 'copySize',
      },
      {
        key: nameof<ProfileSchemaDto>('orderNumber'),
        label: 'orderNumber',
      },
      {
        key: nameof<ProfileSchemaDto>('medium'),
        label: 'medium',
      },
      {
        key: nameof<ProfileSchemaDto>('ownName'),
        label: 'ownName',
      },
      {
        key: nameof<ProfileSchemaDto>('labconChannel'),
        label: 'labconChannel',
      },
    ],
  },
];

export const ProfileEditorForm: React.FC<ProfileEditorFormProps> = props => {
  const [activeTab, setActiveTab] = useState(PROFILE_SCHEMA_ITEMS[0].id);
  const { t, i18n, tk } = useTranslation('administrationLng');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dictEn = (i18n.getDataByLanguage('en') as { [key: string]: any })[
    'administrationLng'
  ]['administration'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dictPl = (i18n.getDataByLanguage('projects') as { [key: string]: any })[
    'administrationLng'
  ]['administration'];

  const propertyChange = (
    data: Partial<SchemaAttributesDto> | undefined,
    key: keyof ProfileSchemaDto,
  ) => {
    props.propertyChange({
      schema: {
        ...props.profile.schema,
        [key]: data,
      },
    });
  };

  return (
    <Form className="d-grid gap-4 mb-3">
      <FormRow controlId="name" label={t(tk.common.name)}>
        <Form.Control
          type="text"
          value={props.profile.name || ''}
          onChange={e => props.propertyChange({ name: e.target.value })}
        />
      </FormRow>
      <FormRow controlId="companyIds" label={t(tk.common.companies)}>
        <CompanySelector
          isMulti={true}
          value={props.profile.companyIds}
          onChange={options => {
            props.propertyChange({
              companyIds: options
                ? (options as Option[])?.map(({ value }) => value) || []
                : [],
            });
          }}
        />
      </FormRow>
      <FormRow controlId="teamIds" label={t(tk.common.teams)}>
        <TeamSelector
          isMulti={true}
          value={props.profile.teamIds}
          companyIds={props.profile.companyIds}
          onChange={options => {
            props.propertyChange({
              teamIds: options
                ? (options as Option[]).map(({ value }) => value)
                : [],
            });
          }}
        />
      </FormRow>
      <FormRow
        controlId="relatedTeams"
        label={t(tk.administration.relatedTeams)}
      >
        <TeamSelector
          isMulti={true}
          value={props.profile.relatedTeamIds}
          companyIds={props.profile.companyIds}
          onChange={options => {
            props.propertyChange({
              relatedTeamIds: options
                ? (options as Option[]).map(({ value }) => value)
                : [],
            });
          }}
        />
      </FormRow>
      <FormRow controlId="active" label={t(tk.administration.commissions)}>
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.commission}
          onChange={e => props.propertyChange({ commission: e.target.checked })}
        />
      </FormRow>
      <FormRow controlId="tv" label={t(tk.administration.tv)}>
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.tv ?? false}
          onChange={e => props.propertyChange({ tv: e.target.checked })}
        />
      </FormRow>
      <FormRow controlId="tvBuying" label={t(tk.administration.tvBuying)}>
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.tvBuying ?? false}
          onChange={e => props.propertyChange({ tvBuying: e.target.checked })}
        />
      </FormRow>
      <FormRow
        controlId="salesBlockDisabled"
        label={t(tk.administration.autoSalesBlockDisabled)}
      >
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.autoSalesBlockDisabled ?? false}
          onChange={e =>
            props.propertyChange({ autoSalesBlockDisabled: e.target.checked })
          }
        />
      </FormRow>
      <FormRow controlId="active" label={t(tk.common.activeHe)}>
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profile.active ?? true}
          onChange={e => props.propertyChange({ active: e.target.checked })}
        />
      </FormRow>
      <Tabs
        activeKey={activeTab}
        id={props.id}
        onSelect={tab => setActiveTab(tab as string)}
      >
        {props.profile.schema &&
          PROFILE_SCHEMA_ITEMS.map(({ id, title, items }) => (
            <Tab
              key={id}
              eventKey={id}
              title={t(
                tk.administration[title as keyof typeof tk.administration],
              )}
              className="m-3"
            >
              <div className="d-grid gap-4 mb-3">
                {items.map(item => (
                  <SchemaItemInput
                    key={item.key}
                    id={item.key}
                    label={t(
                      tk.administration[
                        item.label as keyof typeof tk.administration
                      ],
                    )}
                    labelPl={
                      dictPl[item.label as keyof typeof tk.administration]
                    }
                    labelEn={
                      dictEn[item.label as keyof typeof tk.administration]
                    }
                    value={props.profile.schema[item.key]}
                    onChange={data => propertyChange(data, item.key)}
                  />
                ))}
              </div>
            </Tab>
          ))}
      </Tabs>
    </Form>
  );
};
