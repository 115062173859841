import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetEmailConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { EmailConfigTypeEnumText } from '../../../common/pokCore/validation/schemas';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface EmailConfigSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const emailConfigForGrid = (emailConfig: GetEmailConfigDto) => {
  return {
    key: emailConfig.id,
    values: {
      'emailConfig.systemNumber': emailConfig.systemNumber,
      type_pl: EmailConfigTypeEnumText[emailConfig.type],
      'team.name': emailConfig.emailConfigTeams
        ?.map(({ team }) => team?.name)
        .join(', '),
      'company.shortname': emailConfig.company?.shortname,
      'emailConfig.emails': emailConfig.emails.join(', '),
    },
    isInactive: !emailConfig.active,
  };
};

export const EmailConfigSearch: React.FC<EmailConfigSearchProps> = props => {
  const { t, tk } = useTranslation('administrationLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'emailConfig.systemNumber' },
    { header: t(tk.administration.type), property: 'type_pl' },
    { header: t(tk.common.company), property: 'company.shortname' },
    { header: t(tk.common.teams), property: 'team.name' },
    { header: t(tk.administration.emails), property: 'emailConfig.emails' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="emailConfig.systemNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.administration.noMailConfigs)}
      onRowClick={props.onRowClick}
      withHistory={true}
      refresh={props.refresh}
      withFilterInColumns={true}
    />
  );
};
