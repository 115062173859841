import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import numberFormatter from '../../../common/numberFormatter';
import { GetPaeEstimateItemsDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PaePositionsGridProps {
  data: GetPaeEstimateItemsDto[];
  enableMultiSelect?: boolean;
  purchaseOnly?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
}

export const PaePositionsGrid: React.FC<PaePositionsGridProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  const columns: GridColumn[] = [
    { header: t(tk.estimates.positionName), property: 'positionName' },
    { header: t(tk.estimates.purchaseForm), property: 'purchaseForm' },
    ...(!props.purchaseOnly
      ? [{ header: t(tk.estimates.sales), property: 'sale' }]
      : []),
    { header: t(tk.estimates.purchase), property: 'purchase' },
    { header: t(tk.estimates.paePurchaseForm), property: 'paePurchaseForm' },
    {
      header: t(tk.estimates.paePositionNumber),
      property: 'paePositionNumber',
    },
  ];

  const data = props.data.map(paePosition => {
    const incomplete =
      paePosition.position === null || paePosition.purchaseFormDict === null;
    return {
      key: paePosition.idx,
      values: {
        positionName: paePosition.position?.name
          ? `${paePosition.position?.name} (${paePosition.position?.client.name})`
          : '-',
        paePositionNumber: paePosition.paePositionSystemNumber,
        purchaseForm: paePosition.purchaseFormDict?.value ?? '-',
        paePurchaseForm: paePosition.paePurchaseForm,
        purchase: numberFormatter(paePosition.purchaseAmount, true),
        sale: numberFormatter(paePosition.salesAmount, true),
      },
      readOnly: incomplete,
      isInactive: incomplete,
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.estimates.noPositions)}
      trClassName="amounts-align-right font-90"
      enableMultiSelect={props.enableMultiSelect}
      onRowClick={props.onRowClick}
    />
  );
};
