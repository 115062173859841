import React from 'react';
import { Button } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface OrderExecutionPositionsControlsProps {
  handleResetPositions?: () => void;
  handleMergePositionsByActionGroup?: (keys: number[]) => void;
  selected: number[];
  handleMergePositions?: (keys: number[]) => void;
  handleClonePosition?: (key: number) => void;
  readOnly?: boolean;
  isActionGroupAvailable?: boolean;
}

export const OrderExecutionPositionsControls: React.FC<
  OrderExecutionPositionsControlsProps
> = ({
  handleResetPositions,
  selected,
  handleMergePositions,
  handleClonePosition,
  readOnly,
  isActionGroupAvailable,
  handleMergePositionsByActionGroup,
}) => {
  const { t, tk } = useTranslation('orderLng');
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-1">
        {isActionGroupAvailable && (
          <Button
            onClick={() => handleMergePositionsByActionGroup?.(selected)}
            disabled={readOnly}
          >
            {t(tk.orderExec.mergePositionsByActionGroup, {
              selected: selected.length ? ` ${t(tk.orderExec.selected)} ` : ' ',
            })}
          </Button>
        )}
        {selected.length > 1 && (
          <Button
            onClick={() => handleMergePositions?.(selected)}
            disabled={readOnly}
          >
            {t(tk.orderExec.mergePositions)}
          </Button>
        )}
        {selected.length === 1 && (
          <Button
            onClick={() => handleClonePosition?.(selected[0])}
            disabled={readOnly}
          >
            {t(tk.orderExec.clonePosition)}
          </Button>
        )}
        <ConfirmationButton
          variant="outline-danger"
          onOK={() => handleResetPositions?.()}
          confirmation={t(tk.orderExec.askResetPositions)}
          disabled={readOnly}
        >
          {t(tk.orderExec.resetPositions)}
        </ConfirmationButton>
      </div>
    </div>
  );
};
