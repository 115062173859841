export default {
  menu: {
    report: 'Zestawienie',
    reports: 'Zestawienia',
  },
  filter: {
    invoiceType: 'Typ faktury',
    invoiceStatus: 'Status faktury',
    dateSale: 'Data sprzedaży',
    datePurchase: 'Data zakupu',
    dateInvoices: 'Data wystawienia',
    yearGive: 'Proszę podać rok.',
  },
  finance: {
    purchaseInvoices: 'Faktury zakupu',
    salesInvoices: 'Faktury sprzedaży',
    FVS: 'FVS',
    FVZ: 'FVZ',
    budgetReport: 'Raport do budżetu',
    projectsByMonths: 'Kampanie wg miesięcy',
    estimates: 'Pozycje mediaplanu',
    controling: 'Kontroling',
    FVZWithEstimates: 'FVZ - przypisanie do pozycji mediaplanów',
    FVZWithEstimatesLong: 'Faktury zakupu w powiązaniu z pozycjami mediaplanów',
    FVZWithEstimatesShort: 'FVZ, pozycje mediaplanu',
    FVSControling: 'FVS - raport dla kontrolingu',
    FVSControlingLong: 'Faktury sprzedaży w podziale na miesiąc dowiązania',
    FVSControlingShort: 'FVS, pozycje mediaplanu',
  },
  administration: {
    estimatesPositions: 'Pozycje mediaplanów',
    rawData: 'Surówka',
    extensiveDataFromEstimatesByMonth: 'Rozbudowane dane z pozycji mediaplanów',
  },
} as const;
