import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import {
  CreateUpdateSpecialInvoicingDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';

import { SpecialInvoicingProjectEditor } from './SpecialInvoicingProjectEditor';

interface SpecialInvoicingProjectsProps {
  convertedSpecialInvoicing: CreateUpdateSpecialInvoicingDto;
  propertyChange?: (obj: Partial<CreateUpdateSpecialInvoicingDto>) => void;
  projects: GetProjectDto[];
  setProjects?: (projects: GetProjectDto[]) => void;
  readOnly: boolean;
}

export const SpecialInvoicingProjects: React.FC<
  SpecialInvoicingProjectsProps
> = props => {
  const [show, setShow] = useState(false);
  const { t, tk } = useTranslation('applicationLng');

  const handleDelete = (id: string) => {
    props.propertyChange?.({
      projectIds: props.convertedSpecialInvoicing.projectIds.filter(
        projectId => projectId !== id,
      ),
    });
    props.setProjects?.(props.projects.filter(project => project.id !== id));
  };

  const COLUMNS: GridColumn[] = [
    { header: t(tk.common.number), property: 'number' },
    { header: t(tk.common.name), property: 'name' },
    { header: t(tk.common.team), property: 'team' },
    { header: t(tk.common.budget), property: 'budget' },
    { header: t(tk.common.startDate), property: 'startDate' },
    { header: t(tk.common.endDate), property: 'endDate' },
    ...(props.readOnly
      ? []
      : [{ header: '', property: 'deleteLink', noSorting: true }]),
  ];

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const data = (props.projects || []).map(project => {
    const shortName = project.purchaser?.client?.shortname || '';
    const purchaserLabel = `${project.purchaser?.name} (${
      shortName.length ? shortName : project.purchaser?.client?.name
    })`;

    return {
      key: project.id,
      values: {
        name: project.name,
        number: project.number,
        team: project.projectTeams
          ?.map(({ team }) => `${team?.name} (${team?.company?.shortname})`)
          ?.join(', '),
        purchaser: purchaserLabel,
        budget: project.purchaser.budget.name,
        startDate: momencik(project.startDate),
        endDate: momencik(project.endDate),
        deleteLink: (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className="d-flex justify-content-center"
            onClick={e => e.stopPropagation()}
          >
            <ConfirmationButton
              variant="outline-danger"
              confirmation={t(tk.prLang.deleteProjectWarning)}
              tooltip={t(tk.prLang.deleteProjectTooltip)}
              onOK={() => handleDelete(project.id)}
            >
              <Trash size={16} />
            </ConfirmationButton>
          </div>
        ),
      },
    };
  }) as GridRecord[];

  return (
    <div>
      {props.readOnly ? null : (
        <Button variant="outline-primary" className="mb-4" onClick={handleOpen}>
          {t(tk.prLang.addProject)}
        </Button>
      )}
      <Card.Title className="mb-3">{t(tk.prLang.projectList)}</Card.Title>
      <Grid
        data={data}
        columns={COLUMNS}
        initialOrderColumn="number"
        initialOrderDirection="DESC"
      />
      <SpecialInvoicingProjectEditor
        show={show}
        onClose={handleClose}
        {...props}
      />
    </div>
  );
};
