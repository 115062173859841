import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  CreateUpdateProjectWithTeamDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ProjectContext';
import { useNotifications } from '../../../common/hooks/useNotifications';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { ProjectApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';
import { useNavigation } from '../../../common/navigation';

import { ProjectForm } from './ProjectForm';
import { ProjectEditor } from './ProjectEditor';
import ProjectCancelButton from './ProjectCancelButton';
import { SpecialInvoicingButton } from './SpecialInvoicingButton';
interface ProjectDetailsTabProps {
  project: GetProjectDto;
  refresh(): void;
}

export const ProjectDetailsTab: React.FC<ProjectDetailsTabProps> = props => {
  const [show, setShow] = useState(false);

  const pok = usePokCore();
  const nav = useNavigation();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');

  const addEditProject = async (
    projectCU: CreateUpdateProjectWithTeamDto,
    id?: string,
  ) => {
    const status = await validate(projectCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveUpdateProject(projectCU, id);
  };

  const saveUpdateProject = async (
    projectCU: CreateUpdateProjectWithTeamDto,
    id?: string,
  ) => {
    try {
      if (id) {
        await pok.projects.update(id, projectCU);
        if (projectCU.delayVerification) {
          void sendEmail(id, ProjectApplicationTypeEnum.Delayed);
        }
      } else {
        const projectNew = await pok.projects.create(projectCU);
        if (projectCU.delayVerification) {
          void sendEmail(projectNew.id, ProjectApplicationTypeEnum.Delayed);
        }
      }
      setShow(false);
      props.refresh();
      return {
        saved: true,
        errors: [],
      };
    } catch (err) {
      return {
        saved: false,
        errors: await responseErrors(err as ResponseError),
      };
    }
  };

  const createUpdateCancelApplication = async (reason?: string) => {
    await pok.projectsApplication.application(
      ProjectApplicationTypeEnum.ToCancel,
      props.project.id,
      true,
      reason ?? '',
    );
    void sendEmail(props.project.id, ProjectApplicationTypeEnum.ToCancel);
    props.refresh();
  };

  const removeCancelApplication = async () => {
    await pok.projectsApplication.application(
      ProjectApplicationTypeEnum.ToCancel,
      props.project.id,
      false,
    );
    void sendEmail(props.project.id, ProjectApplicationTypeEnum.ToCancel);
    props.refresh();
  };

  const sendEmail = async (id: string, type: ProjectApplicationTypeEnum) => {
    pok.emails.sendProjectEmail(id, type).catch(err => {
      notifications.caughtError(err);
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async () => {
    setShow(true);
  };

  return (
    <>
      <ProjectForm project={convert(props.project)} readOnly={true} />
      {props.project.cancelVerification === true && (
        <ValidationAlert
          show={true}
          errors={[
            t(tk.projects.cancelApplicationSubmitted, {
              project: t(tk.prLang.genitiveProject),
            }),
          ]}
          className="m-3"
        />
      )}
      <div
        className={
          process.env.REACT_APP_BUDGETS_TO_DOCUMENT_CONTROL?.split(
            ',',
          ).includes(props.project.purchaser.budget.systemNumber.toString()) &&
          props.project.company.shortname === 'VM'
            ? 'd-flex justify-content-between'
            : 'd-flex justify-content-end'
        }
      >
        {process.env.REACT_APP_BUDGETS_TO_DOCUMENT_CONTROL?.split(',').includes(
          props.project.purchaser.budget.systemNumber.toString(),
        ) &&
          props.project.company.shortname === 'VM' && (
            <div className="d-flex pt-4 gap-2">
              <Button variant="outline-primary" onClick={nav.documentsControl}>
                {t(tk.projects.documentsControl)}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => nav.documentsControlForProject(props.project.id)}
              >
                {t(tk.prLang.projectControl)}
              </Button>
            </div>
          )}
        <div className="d-flex pt-4 gap-2">
          <ProjectCancelButton
            objectGenitiveName={t(tk.prLang.genitiveProject)}
            applicationExists={props.project.cancelVerification}
            reason={props.project.cancelMessage}
            onCreateUpdateCancelApplication={createUpdateCancelApplication}
            onRemoveCancelApplication={removeCancelApplication}
          >
            {t(tk.projects.cancelApplication)}
          </ProjectCancelButton>
          <SpecialInvoicingButton project={props.project} />
          <Button variant="outline-primary" onClick={handleClick}>
            {t(tk.projects.edit)}
          </Button>
        </div>
      </div>
      {show && (
        <ProjectEditor
          id={props.project?.id}
          project={convert(props.project, pok.getCompanyId())}
          onCompleted={addEditProject}
          budgetId={props.project?.parent?.purchaser?.budget?.id}
          show={show}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
