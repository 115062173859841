import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import AlertModal from '../../../app/components/AlertModal';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';

import { downloadXML } from './downloadXML';

export const DownloadXMLLink = (props: { packageXMLId: string }) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState<string[] | null>(null);

  const handleClick = () => {
    pok.packagesXML
      .getXMLFromPackageXML(props.packageXMLId)
      .then(async xmlData => {
        const packageXML = await pok.packagesXML.getById(props.packageXMLId);
        await downloadXML(packageXML, xmlData);
      })
      .catch(async errorResponse => {
        const error = await (errorResponse as ResponseError).response
          .clone()
          .json();
        notifications.caughtError(errorResponse);
        if (
          error.statusCode === 404 ||
          error.statusCode === 403 ||
          error.statusCode === 400
        ) {
          setAlert(error.message);
          setShowAlert(true);
        }
      });
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }}>
            Paczka XML z fakturami
          </Tooltip>
        }
      >
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Download />
        </Button>
      </OverlayTrigger>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={e => e.stopPropagation()}>
        <AlertModal
          variant="danger"
          show={showAlert}
          buttonVariant="online-secondary"
          onHide={() => {
            setShowAlert(false);
            setAlert(null);
          }}
        >
          Nie można pobrać paczki. {alert}
        </AlertModal>
      </div>
    </>
  );
};
