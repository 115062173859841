import React, { Dispatch, SetStateAction, useState } from 'react';

import { CreateUpdateClientDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ClientEditorProps {
  id?: string;
  client: CreateUpdateClientDto;
  handleClose?: () => void;
  onCompleted: (
    client: CreateUpdateClientDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  setSaveErrors: Dispatch<SetStateAction<string[]>>;
  setShowSaveErrors: Dispatch<SetStateAction<boolean>>;
}

export const ClientEditorControls: React.FC<ClientEditorProps> = props => {
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('clientLng');

  const createClient = async () => {
    setIsLoading(true);
    props
      .onCompleted(props.client, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          props.setSaveErrors(saveStatus.errors);
          props.setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {props.id && (
        <LastEditorInfo id={props.id} method={pok.clients.getLastEditor} />
      )}
      <Button
        variant="outline-secondary"
        onClick={props.handleClose}
        disabled={isLoading}
      >
        {t(tk.button.close)}
      </Button>
      <Button variant="primary" onClick={createClient} isLoading={isLoading}>
        {t(tk.button.save)}
      </Button>
    </>
  );
};
