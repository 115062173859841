import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetExtendedProfileDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ProfileSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const profileForGrid = (
  profile: GetExtendedProfileDto,
  orderBy: string = '',
) => {
  let companyNames = profile.companies?.map(({ shortname }) => shortname);
  if (companyNames && orderBy !== 'companies.shortname') {
    companyNames = companyNames.sort((a, b) => a.localeCompare(b, 'pl'));
  }
  let teamNames = profile.teams?.map(
    team => team.name + ' (' + team.company.shortname + ')',
  );
  if (teamNames && orderBy !== 'teams.name') {
    teamNames = teamNames.sort((a, b) => a.localeCompare(b, 'pl'));
  }
  return {
    key: profile.id,
    values: {
      'profile.name': profile.name,
      'companies.shortname': companyNames?.join(', '),
      'teams.name': teamNames?.join(', '),
      'profile.commission': profile.commission ? 'Tak' : 'Nie',
    },
    isInactive: !profile.active,
  };
};

export const ProfileSearch: React.FC<ProfileSearchProps> = props => {
  const { t, tk } = useTranslation('administrationLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.name), property: 'profile.name' },
    { header: t(tk.common.companies), property: 'companies.shortname' },
    { header: t(tk.common.teams), property: 'teams.name' },
    {
      header: t(tk.administration.commissions),
      property: 'profile.commission',
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="profile.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.administration.noEstimateProfiles)}
      onRowClick={props.onRowClick}
      withHistory={true}
      refresh={props.refresh}
    />
  );
};
