import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { CreateUpdateJobConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { JobConfigFrom } from './JobConfigForm';

interface JobConfigEditorProps {
  id?: string;
  systemNumber?: number;
  jobConfig: CreateUpdateJobConfigDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    jobConfig: CreateUpdateJobConfigDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  readOnly: boolean;
}

export const JobConfigEditor: React.FC<JobConfigEditorProps> = props => {
  const [jobConfig, setJobConfig] = useState(props.jobConfig);
  const [show, setShow] = useState(props.show || false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('administrationLng');

  const createJobConfig = async () => {
    setIsLoading(true);
    props
      .onCompleted(jobConfig, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
        handleClose();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const createErrors = (errors: string[]) => {
    setSaveErrors(errors);
    setShowSaveErrors(!!errors.length);
  };

  const propertyChange = (obj: Partial<CreateUpdateJobConfigDto>) => {
    setJobConfig({ ...jobConfig, ...obj });
  };

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setJobConfig(props.jobConfig);
      setShow(true);
    }
  }, [props.jobConfig, props.show]);

  const handleClose = () => {
    setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  return (
    <Modal
      onHide={handleClose}
      show={show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id
            ? `${t(tk.administration.jobConfigEdit)} ${props.systemNumber}`
            : t(tk.administration.jobConfigAdd)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <JobConfigFrom
          jobConfig={jobConfig}
          readOnly={props.readOnly}
          propertyChange={propertyChange}
          createErrors={createErrors}
        />
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
      </Modal.Body>
      <Modal.Footer>
        {props.id && (
          <LastEditorInfo id={props.id} method={pok.jobConfig.getLastEditor} />
        )}
        <Button
          variant="outline-secondary"
          onClick={handleClose}
          disabled={isLoading}
        >
          {t(tk.button.close)}
        </Button>
        <Button
          variant="primary"
          onClick={createJobConfig}
          isLoading={isLoading}
        >
          {t(tk.button.save)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
