import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import Button from '../../../common/components/Button/Button';
import FormRow from '../../../app/components/FormRow';
import CurrencyControl from '../../../app/components/CurrencyControl';
import mathUtils from '../../../utils/mathUtils';

interface AmountEditorProps {
  amount: string;
  originalAmount: string;
  handleClose?: () => void;
  decimalScale?: number;
  show?: boolean;
  onCompleted: (amount: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const AmountEditor: React.FC<AmountEditorProps> = props => {
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [amount, setAmount] = useState<string>(props.amount);

  const notifications = useNotifications();

  const updateAmount = async () => {
    props
      .onCompleted(amount)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const propertyChange = (value: string | null) => {
    const a = mathUtils.Big(value || '0');
    setAmount(a.toString());
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    props.handleClose && props.handleClose();
  };

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div onClick={e => e.stopPropagation()}>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edycja kwoty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="amount" label="Kwota">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <CurrencyControl
                    value={amount}
                    decimalScale={props.decimalScale ?? 4}
                    onChange={value => propertyChange(value)}
                    allowNegative
                  />
                </div>
                <div className="flex-grow-0 pt-2">
                  <OverlayTrigger
                    overlay={<Tooltip>Przywróć oryginalną wartość</Tooltip>}
                    trigger={['hover', 'hover']}
                  >
                    <Button
                      className="py-0 btn-link btn-transparent"
                      onClick={() => propertyChange(props.originalAmount)}
                    >
                      <Icon.ArrowCounterclockwise
                        size={18}
                        className="icon-in-cell"
                      />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={e => handleClose(e)}>
            Zamknij
          </Button>
          <Button variant="primary" onClick={updateAmount}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
