import React, { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { ProfileEditor } from '../../components/Profiles/ProfileEditor';
import {
  CreateUpdateProfileDto,
  GetExtendedProfileDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ProfileContext';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { ProfileSearch } from '../../components/Profiles/ProfileSearch';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ProfilesView: React.FC = () => {
  const [profile, setProfile] = useState<GetExtendedProfileDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const pok = usePokCore();
  const { t, tk } = useTranslation('administrationLng');

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.profiles.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok.profiles],
  );

  const getCount = useMemo(
    () =>
      (filterText => pok.profiles.getAllCount(filterText)) as GridCountFunction,
    [pok.profiles],
  );

  const handleAdd = () => {
    setShow(true);
    setProfile(undefined);
  };

  const addEditProfile = async (
    profileCU: CreateUpdateProfileDto,
    id?: string,
  ) => {
    const status = await validate(profileCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveUpdateProfile(profileCU, id);
  };

  const saveUpdateProfile = async (
    profileCU: CreateUpdateProfileDto,
    id?: string,
  ) => {
    try {
      if (id) {
        await pok.profiles.update(id, profileCU);
      } else {
        await pok.profiles.create(profileCU);
      }
      setShow(false);
      setRefresh(!refresh);
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const fetchedProfile = await pok.profiles.getById(id);
    setProfile(fetchedProfile);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.administration.estimateProfiles)}
        </Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.administration.estimateProfileAdd)}
        </Button>
        <ProfileSearch
          getData={getData}
          getCount={getCount}
          onRowClick={handleClick}
          refresh={refresh}
        />
        {show && (
          <ProfileEditor
            id={profile?.id}
            profile={convert(profile)}
            companies={profile?.companies}
            teams={profile?.teams}
            onCompleted={addEditProfile}
            show={show}
            handleClose={handleClose}
          />
        )}
      </Card.Body>
    </Card>
  );
};
