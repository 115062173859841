// eslint-disable-next-line import/named
import i18next, { i18n as i18nType } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { resources } from './resources';

let i18n: i18nType;

const AddExclamation = (str: string) => {
  if (
    process.env.REACT_APP_PREFIX_BEFORE_TEXT &&
    str !== 'pl-PL' &&
    str !== 'en-GB'
  ) {
    return process.env.REACT_APP_PREFIX_BEFORE_TEXT + ' ' + str;
  } else {
    return str;
  }
};

export const initTranslations = (lng?: string) => {
  i18n = i18next.createInstance();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  i18n
    //TODO nastepny use do usuniecia, gdy skonczymy zajmowac sie przenoszeniem tekstu
    .use({
      type: 'postProcessor',
      name: 'addExclamation',
      process: function (value: string) {
        return AddExclamation(value);
      },
    })
    .use(initReactI18next)
    .init({
      debug: false,
      lng,
      fallbackLng: 'projects',
      interpolation: {
        escapeValue: false,
      },
      resources,
      postProcess: ['addExclamation'],
    });
};

export const changeLang = (personalLang?: string, language?: string) => {
  const personalLanguage =
    language === 'PL' || !language
      ? personalLang
        ? personalLang.toLowerCase()
        : undefined
      : language.toLowerCase();
  if (i18n.language !== personalLanguage) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(personalLanguage);
  }
};
