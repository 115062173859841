import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { ProjectSearch } from '../Projects/ProjectSearch';
import {
  CreateUpdateSpecialInvoicingDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import Button from '../../../common/components/Button/Button';

interface SpecialInvoicingProjectEditorProps {
  show?: boolean;
  onClose: () => void;
  readOnly: boolean;
  projects: GetProjectDto[];
  propertyChange?: (obj: Partial<CreateUpdateSpecialInvoicingDto>) => void;
  setProjects?: (projects: GetProjectDto[]) => void;
}

export const SpecialInvoicingProjectEditor: React.FC<
  SpecialInvoicingProjectEditorProps
> = props => {
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<string[]>([]);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t, tk } = useTranslation('applicationLng');
  const pok = usePokCore();
  const notifications = useNotifications();

  const onRowClick = (id: string) => {
    setSelectedProjectsIds(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(prevId => prevId !== id);
      }

      return [...prevState, id];
    });
  };

  const handleSave = async () => {
    setSaveErrors([]);
    setShowSaveErrors(false);
    if (selectedProjectsIds.length) {
      setIsLoading(true);
      props.propertyChange?.({ projectIds: selectedProjectsIds });
      const projectIds = props.projects.map(({ id }) => id);
      const projectsToFetch = selectedProjectsIds.filter(
        id => !projectIds.includes(id),
      );

      await Promise.all(projectsToFetch.map(id => pok.projects.getById(id)))
        .then(fetchedProjects => {
          props.setProjects?.([
            ...props.projects.filter(({ id }) =>
              selectedProjectsIds.includes(id),
            ),
            ...fetchedProjects,
          ]);
          props.onClose();
        })
        .catch(err => {
          notifications.caughtError(err);
        })
        .finally(() => setIsLoading(false));
    } else {
      setSaveErrors([t(tk.prLang.selectProjectPurchaseInvoiceAlertError)]);
      setShowSaveErrors(true);
    }
  };

  useEffect(() => {
    if (props.show) {
      setSelectedProjectsIds(props.projects.map(({ id }) => id));
      setShowSaveErrors(false);
      setSaveErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      onHide={props.onClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t(tk.prLang.chooseProject)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectSearch
          getData={pok.projects.getAllByPortion}
          getCount={pok.projects.getAllCount}
          enableMultiselect
          selectedKeys={selectedProjectsIds}
          onRowClick={onRowClick}
        />
        <Alert>
          {t(tk.prLang.selectProjectsPurchaseInvoiceAlert)} do wniosku.
        </Alert>
        <ValidationAlert show={showSaveErrors} errors={saveErrors} />
        <div className="d-flex justify-content-end gap-2">
          <Button variant="outline-secondary" onClick={props.onClose}>
            {t(tk.button.close)}
          </Button>
          <Button onClick={handleSave} isLoading={isLoading}>
            {t(tk.button.save)}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
