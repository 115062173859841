export default {
  app: {
    askDelete: 'Are you sure you want to delete the request?',
    addUnblock: 'Create a request to unblock the monthly profile',
    askBlockUnblockSale: 'Are you sure you want to {{block}} sales?',
    askBlockUnblockPurchase: 'Are you sure you want to {{block}} purchases?',
    unblock: 'unblock',
    block: 'block',
    unblockDoIt: 'Unblock',
    blockDoIt: 'Block',
    sale: 'sale',
    purchase: 'purchase',
    type: 'Type',
    reason: 'Reason',
    rejectedNote: 'Rejection justification',
    description: 'Reason for submission',
    noApplicationUnblock: 'No requests assigned to this monthly profile',
    noUnblock: 'No unblocking requests.',
    salesActiveBlock: 'Active global sales block for the month',
    purchaseActiveBlock: 'Active global purchase block for the month',
    addUnblockApp: 'Adding a new request to unblock the monthly profile',
    editUnblockApp:
      'Editing request to unblock the monthly profile (system no. {{sysNumber}})',
    typeApplication: 'Type of request',
    salesCorrect: 'The request concerns a sales correction',
    isDocumentation: 'The change is confirmed by documentation',
    copyEmailContent: 'Copy email content to clipboard',
    alertBeforeAddingForeignClient: `↓ WARNING: Due to the registration of the supplier from outside Poland, 
        please send the following message to the contractor to obtain the required documents ↓`,
    enterNIP: 'Please enter the NIP number.',
    editingApplication: `Edit application from {{createdDate}}`,
    addingNewApplication: 'Add a new application',
    creator: 'application author',
    applicationExecutionWithDate: 'Application execution from {{createdDate}}',
    applicationData: 'Application data',
    applicationType: 'Application type',
    purchaserName: 'Purchaser name',
    clientData: 'Client data',
    employeeAccess: 'Employee access',
    employeeBoss: 'Approving manager',
    applicationExecution: 'Application execution',
    valueName: 'Value name',
    brandName: 'Brand name',
    profilePosition: 'Profile position',
    sharedObjectType: 'Shared object type',
    sharedObject: 'Shared object',
    noApplications: 'No applications in the system.',
    applications: 'Applications',
    newApplication: 'New application',
    my: 'My',
    companies: 'Companies',
    announcements: 'Announcements',
    reportingContractsForWork: 'Reporting contracts for work',
    pending: 'Pending',
    all: 'All',
    archive: 'Archive',
    specialInvoicingApplicationExecution:
      'Execution of special invoicing application (sys. no. {{sysNumber}})',
    waiting: 'Pending',
    waitingForCorrection: 'Pending correction',
    specialInvoicingApplications: 'Special invoicing applications',
    specialInvoicingApplicationsRealization:
      'Special invoicing applications - realization',
    specialInvoicingApplicationDetails:
      'Special invoicing application details (sys. no. {{sysNumber}})',
    specialInvoicingApplicationEdit:
      'Edit special invoicing application (sys. no. {{sysNumber}})',
    createSpecialInvoicingApplication: 'Create special invoicing application',
    askDeleteApplication: 'Are you sure you want to delete this application?',
    requiredChanges: 'Required changes',
    acceptedFinanceNote: 'Accepted invoicing description',
    applicationDescription: 'Application content',
    financeNote: 'Invoicing description',
    noSpecialInvoiceApplications:
      'No special invoicing applications in the system.',
  },
} as const;
