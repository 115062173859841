import React from 'react';
import { Alert, Form, Table } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { UseSelectedGridRowReturn } from '../../../common/hooks/useSelectGridRow';
import {
  CreateUpdateOrderExecutionDto,
  CreateUpdateOrderExecutionPositionDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';
import { useTranslation } from '../../../common/hooks/useTranslation';

import OrderExecutionPositionsSumRow from './OrderExecutionPositionsSumRow';

interface OrderExecutionPositionsGridProps {
  select: UseSelectedGridRowReturn<number>;
  orderExecution: CreateUpdateOrderExecutionDto;
  propertyChange: (
    obj: Partial<CreateUpdateOrderExecutionPositionDto>,
    index: number,
  ) => void;
  readOnly?: boolean;
  positionsSalesSum: number;
  purchaseSum?: number;
  isDecisionView?: boolean;
  isActionGroupAvailable?: boolean;
  currencyCode?: string;
}

export const OrderExecutionPositionsGrid: React.FC<
  OrderExecutionPositionsGridProps
> = props => {
  const { select } = props;
  const { t, tk } = useTranslation('orderLng');

  if (!props.orderExecution.orderExecutionPositions) {
    return <Alert variant="warning">{t(tk.common.noData)}.</Alert>;
  }

  return (
    <Table striped>
      <thead>
        <tr>
          <th></th>
          <th>{t(tk.common.lp)}</th>
          <th>{t(tk.common.name)}</th>
          {props.isActionGroupAvailable && (
            <th>{t(tk.orderExec.actionGroup)}</th>
          )}
          <th>{t(tk.orderExec.amount)}</th>
        </tr>
      </thead>
      <tbody>
        {sortBy(props.orderExecution.orderExecutionPositions, ['order']).map(
          item => {
            const isSelected = select.selected.includes(item.order);

            return (
              <tr
                key={item.order}
                className={`align-middle ${isSelected ? 'table-warning' : ''}`}
              >
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={isSelected}
                    disabled={props.readOnly}
                    onChange={e => {
                      if (e.target.checked) {
                        return select.handleSelect(item.order);
                      }

                      return select.handleUnselect(item.order);
                    }}
                  />
                </td>
                <td>{item.order}</td>
                <td>
                  <Form.Control
                    type="text"
                    value={item.name || ''}
                    disabled={props.readOnly}
                    onChange={e =>
                      props.propertyChange({ name: e.target.value }, item.order)
                    }
                  />
                </td>
                {props.isActionGroupAvailable && <td>{item.actionGroup}</td>}
                <td>
                  <CurrencyControl
                    allowNegative
                    value={item.amount || ''}
                    decimalScale={2}
                    disabled={props.readOnly}
                    onChange={value =>
                      props.propertyChange(
                        {
                          amount: value || '',
                        },
                        item.order,
                      )
                    }
                    currencyCode={props.currencyCode}
                  />
                </td>
              </tr>
            );
          },
        )}
        <OrderExecutionPositionsSumRow
          orderExecution={props.orderExecution}
          positionsSalesSum={props.positionsSalesSum}
          purchaseSum={props.purchaseSum}
          isDecisionView={props.isDecisionView}
          isActionGroupAvailable={props.isActionGroupAvailable}
          currencyCode={props.currencyCode}
        />
      </tbody>
    </Table>
  );
};
