/*eslint max-lines-per-function: ["error", 280]*/
import {
  DefaultApi,
  GetDelayedProjectDto,
  GetLastEditorDto,
  GetOrderDto,
  GetProjectDto,
  GetProjectExtendedDto,
} from '../autogenerated/pokApiClient';
import {
  CreateUpdateProjectWithTeamDto,
  CreateUpdateProjectWithTeamDtoFromJSON,
} from '../autogenerated/pokApiClient';
import { CloneProjectSchema, ProjectSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { projectForGrid } from '../../../pok/components/Projects/ProjectSearch';
import * as AttachmentContext from '../../../common/pokCore/contexts/AttachmentContext';
import { PokCoreContextType } from '../pokCore';
import { NotificationsProviderType } from '../../notifications';
import {
  CloneProjectDto,
  CloneProjectDtoFromJSON,
} from '../autogenerated/pokApiClient/models/CloneProjectDto';
import momencik from '../../momencik';
import {
  Option,
  defaultSelectorLimit,
} from '../../../pok/components/Selects/Selector';
import {
  getSummary,
  projectFinanceForGrid,
} from '../../../pok/components/Projects/ProjectFinanceSearch';
import { purchaseInvoiceProjectForGrid } from '../../../pok/components/PurchaseInvoices/PurchaseInvoiceProjectsSearch';
import { FilterColumn } from '../../types/FilterColumn';

export interface ProjectContextInterface {
  getById: (id: string) => Promise<GetProjectDto>;
  getExtendedById: (id: string) => Promise<GetProjectExtendedDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllForPurchaseInvoiceByClientAndCompanyByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    clientId?: string,
    idsToExclude?: string[],
  ) => Promise<GridRecord[]>;
  getAllForPurchaseInvoiceByClientAndCompanyCount: (
    filterText?: string,
    clientId?: string,
    idsToExclude?: string[],
  ) => Promise<number>;
  getAllForPurchaseInvoiceByPurchaseInvoice: (
    purchaseInvoiceId: string,
  ) => Promise<GetProjectExtendedDto[]>;
  getAllReadyToCorrectionByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllReadyToInvoiceByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    clientId?: string,
    budgetId?: string,
    idsToExclude?: string[],
    hidePurchaserClient?: boolean,
    all?: boolean,
    dateFrom?: Date,
    dateTo?: Date,
  ) => Promise<GridRecord[]>;
  getAllReadyToInvoiceCount: (
    filterText?: string,
    clientId?: string,
    budgetId?: string,
    idsToExclude?: string[],
    all?: boolean,
    dateFrom?: Date,
    dateTo?: Date,
  ) => Promise<number>;
  getAllCorrectedByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
  ) => Promise<GridRecord[]>;
  getAllCorrectedCount: (
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
  ) => Promise<number>;
  getAllReadyToCorrectionCount: (filterText?: string) => Promise<number>;
  getForFinanceByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    dateFrom?: Date | null,
    dateTo?: Date | null,
  ) => Promise<GridRecord[]>;
  getForFinanceCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
    dateFrom?: Date | null,
    dateTo?: Date | null,
  ) => Promise<number>;
  getSumForFinance: (
    filterText?: string,
    filterColumns?: FilterColumn,
    dateFrom?: Date | null,
    dateTo?: Date | null,
  ) => Promise<GridRecord>;
  create: (dto: CreateUpdateProjectWithTeamDto) => Promise<GetProjectDto>;
  update: (id: string, dto: CreateUpdateProjectWithTeamDto) => Promise<void>;
  isDelayed: (date: Date) => Promise<GetDelayedProjectDto>;
  findInternalProjectsByPurchaserForCompanyAndTeams: (
    purchaserId: string,
    teamsIds: string[],
  ) => Promise<GetProjectDto[]>;
  getWithParent: (id: string) => Promise<GetProjectDto>;
  getUniqueServicesNames: (id: string) => Promise<string[]>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  clone: (clone: CloneProjectDto) => Promise<GetProjectDto>;
  getOptions: (text?: string) => Promise<Option[]>;
  getWarningsWithFVZ: (
    id: string,
    purchaseInvoicesIds: string[],
    month?: Date,
    profileId?: string,
  ) => Promise<string[]>;
}

export const ProjectContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.projectControllerGet(id),
    getExtendedById: (id: string) => api.projectControllerGetExtended(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.projectControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(data => projectForGrid(data, true, true));
    },
    getAllForPurchaseInvoiceByClientAndCompanyByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      clientId?: string,
      idsToExclude?: string[],
    ) => {
      const data =
        await api.projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          clientId || '',
          idsToExclude,
        );
      return data.map(purchaseInvoiceProjectForGrid);
    },
    getAllForPurchaseInvoiceByPurchaseInvoice: (purchaseInvoiceId: string) =>
      api.projectControllerGetAllForPurchaseInvoiceByPurchaseInvoice(
        purchaseInvoiceId,
      ),
    getAllReadyToCorrectionByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.projectControllerGetAllReadyToCorrectionByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );

      return data.map(data => projectForGrid(data));
    },
    getAllCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.projectControllerGetAllCount(filterText || '', filterColumns),
    getAllReadyToInvoiceByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      clientId?: string,
      budgetId?: string,
      idsToExclude?: string[],
      hidePurchaserClient?: boolean,
      all?: boolean,
      dateFrom?: Date,
      dateTo?: Date,
    ) => {
      const data = await api.projectControllerGetAllReadyToInvoiceByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        clientId || '',
        budgetId || '',
        idsToExclude,
        all,
        dateFrom,
        dateTo,
      );
      return data.map(data =>
        projectForGrid(data, false, false, hidePurchaserClient),
      );
    },
    getAllReadyToInvoiceCount: (
      filterText?: string,
      clientId?: string,
      budgetId?: string,
      idsToExclude?: string[],
      all?: boolean,
      dateFrom?: Date,
      dateTo?: Date,
    ) =>
      api.projectControllerGetAllReadyToInvoiceCount(
        filterText || '',
        clientId || '',
        budgetId || '',
        idsToExclude,
        all,
        dateFrom,
        dateTo,
      ),
    getAllCorrectedByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
    ) => {
      const data = await api.projectControllerGetAllCorrectedByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        dateFrom,
        dateTo,
      );
      return data.map(data => projectForGrid(data, false, false, false));
    },
    getAllCorrectedCount: (
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
    ) =>
      api.projectControllerGetAllCorrectedCount(
        filterText || '',
        dateFrom,
        dateTo,
      ),
    getAllReadyToCorrectionCount: (filterText?: string) =>
      api.projectControllerGetAllReadyToCorrectionCount(filterText || ''),
    getAllForPurchaseInvoiceByClientAndCompanyCount: (
      filterText?: string,
      clientId?: string,
      idsToExclude?: string[],
    ) =>
      api.projectControllerGetAllForPurchaseInvoiceByClientAndCompanyCount(
        filterText || '',
        clientId || '',
        idsToExclude,
      ),
    getForFinanceByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      dateFrom?: Date | null,
      dateTo?: Date | null,
    ) => {
      const data = await api.projectControllerGetForFinanceByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
        dateFrom || undefined,
        dateTo || undefined,
      );

      return data.map(data => projectFinanceForGrid(data));
    },
    getForFinanceCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
      dateFrom?: Date | null,
      dateTo?: Date | null,
    ) =>
      api.projectControllerGetForFinanceCount(
        filterText || '',
        filterColumns,
        dateFrom || undefined,
        dateTo || undefined,
      ),
    getSumForFinance: async (
      filterText?: string,
      filterColumns?: FilterColumn,
      dateFrom?: Date | null,
      dateTo?: Date | null,
    ) => {
      const data = await api.projectControllerGetSumForFinance(
        filterText || '',
        filterColumns,
        dateFrom || undefined,
        dateTo || undefined,
      );
      return getSummary(data);
    },
    create: (dto: CreateUpdateProjectWithTeamDto) =>
      api.projectControllerCreate(dto),
    update: (id: string, dto: CreateUpdateProjectWithTeamDto) =>
      api.projectControllerUpdate(id, dto),
    isDelayed: async (date: Date) => await api.projectControllerIsDelayed(date),
    findInternalProjectsByPurchaserForCompanyAndTeams: (
      purchaserId: string,
      teamsIds: string[],
    ) =>
      api.projectControllerInternalProjectsByPurchaserForCompanyAndTeams(
        purchaserId,
        teamsIds,
      ),
    getWithParent: (id: string) => api.projectControllerGetWithParent(id),
    getLastEditor: (id: string) => api.projectControllerGetLastEditor(id),
    getUniqueServicesNames: (id: string) =>
      api.projectControllerUniqueServicesNames(id),
    clone: (clone: CloneProjectDto) => api.projectControllerClone(clone),
    getOptions: async (text?: string) => {
      const data = await api.projectControllerGetAllByCompany(
        defaultSelectorLimit,
        text,
      );

      return Array.from(data, p => ({
        value: p.id,
        label: `${p.number} - ${p.name}   (${momencik(
          p.startDate,
          'L',
        )} - ${momencik(p.endDate, 'L')})`,
      }));
    },
    getWarningsWithFVZ: (
      id: string,
      purchaseInvoicesIds: string[],
      month?: Date,
      profileId?: string,
    ) =>
      api.projectControllerGetWarningsWithFVZ(
        id,
        purchaseInvoicesIds,
        month,
        profileId,
      ),
  };
};

export const newProject = (
  companyId?: string,
  countryDictionaryId?: string,
) => {
  return CreateUpdateProjectWithTeamDtoFromJSON({
    companyId: companyId,
    name: '',
    teamIds: [],
    countryDictionaryId: countryDictionaryId,
  });
};

export const newCloneProject = (
  projectId: string,
  name: string,
  notes: string,
  withAmounts: boolean,
) => {
  return CloneProjectDtoFromJSON({
    sourceProjectId: projectId,
    name: name,
    notes: notes,
    withAmounts: withAmounts,
  });
};

export const validate = (project: CreateUpdateProjectWithTeamDto) => {
  return validateAgainst(ProjectSchema, project);
};

export const validateCloneProject = (project: CloneProjectDto) => {
  return validateAgainst(CloneProjectSchema, project);
};

export const convert = (
  project?: GetProjectDto,
  companyId?: string,
  countryDictionaryId?: string,
) => {
  if (!project) {
    return newProject(companyId, countryDictionaryId);
  }
  const converted = CreateUpdateProjectWithTeamDtoFromJSON(project);
  converted.companyId = project.purchaser.company?.id;
  converted.teamIds = project.projectTeams?.map(pt => pt.teamId) || [];
  converted.purchaserId = project.purchaser?.id;
  converted.brandId = project.brand?.id;
  converted.parentId = project.parent?.id;
  converted.countryDictionaryId = project.countryDictionary?.id;
  converted.responsibleOwnerId = project.responsibleOwner?.id;
  converted.labelDictionaryId = project.labelDictionary?.id;
  converted.mainTeamId = project.projectTeams?.filter(o => o.main)[0]?.teamId;
  return converted;
};

export const convertToCreateFromOrder = (
  order: GetOrderDto,
  companyId: string,
) => {
  const converted = CreateUpdateProjectWithTeamDtoFromJSON(order.project);
  converted.id = undefined!;
  converted.number = undefined!;
  converted.delayMessage = undefined!;
  converted.notes = undefined!;
  converted.purchaserDocNumber = undefined!;
  converted.systemNumber = undefined!;
  converted.labelDictionaryId = undefined!;
  converted.companyId = companyId;
  converted.teamIds = [order.team.id];
  converted.brandId = order.project.brand?.id;
  converted.parentId = order.project.id;
  converted.countryDictionaryId = order.project.countryDictionary?.id;
  return converted;
};

export const createNewAttachment = (id?: string) => {
  const attachment = AttachmentContext.newAttachment();
  attachment.enObjectType = AttachmentContext.ObjectType.Project;
  attachment.objectId = id as string;
  return attachment;
};

export const forProjectView = async (
  pok: PokCoreContextType,
  notifications: NotificationsProviderType,
  projectId: string,
  budgetId: string,
  companyId: string,
  action: () => void,
) => {
  const projectAttachments = await pok.attachments.getAllForObject(
    AttachmentContext.ObjectType.Project,
    projectId,
  );
  const budgetAttachments = await pok.attachments.getAllForObjectInCompany(
    AttachmentContext.ObjectType.Budget,
    budgetId,
    companyId,
  );
  const attachments = [...projectAttachments, ...budgetAttachments];
  const ac = AttachmentContext.forAssociatedDocuments;

  const assocAttachments = ac(pok, notifications, attachments, action);

  assocAttachments.new = () => createNewAttachment(projectId as string);
  return {
    attachments: assocAttachments,
  };
};
