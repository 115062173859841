import React from 'react';
import { Card } from 'react-bootstrap';

import { PositionTab } from '../../components/Positions/PositionTab';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const AllPositionsView: React.FC = () => {
  const { t, tk } = useTranslation('clientLng');

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.position.positions)}</Card.Title>
        <PositionTab />
      </Card.Body>
    </Card>
  );
};
