export default {
  finance: {
    purchaseInvoices: 'Faktury zakupu',
    FVZ: 'FVZ',
    readyForInvoices: 'Gotowe do fakturowania',
    corrected: 'Korygowane',
    accountToInvoice: 'Kwota do zafakturowania',
    lastModification: 'Ostatnia modyfikacja dowiązań do pozycji',
  },
} as const;
