export default {
  menu: {
    report: 'Report',
    reports: 'Reports',
  },
  filter: {
    invoiceType: 'Invoice type',
    invoiceStatus: 'Invoice status',
    dateSale: 'Sale date',
    datePurchase: 'Purchase date',
    dateInvoices: 'Invoice issue date',
    yearGive: 'Please provide the year.',
  },
  finance: {
    purchaseInvoices: 'Purchase invoices',
    salesInvoices: 'Sales invoices',
    FVS: 'FVS',
    FVZ: 'FVZ',
    budgetReport: 'Budget report',
    projectsByMonths: 'Campaigns by months',
    estimates: 'Mediaplan items',
    controling: 'Controlling',
    FVZWithEstimates: 'FVZ - assignment to mediaplan items',
    FVZWithEstimatesLong: 'Purchase invoices linked with mediaplan items',
    FVZWithEstimatesShort: 'FVZ, mediaplan items',
    FVSControling: 'FVS - report for controlling',
    FVSControlingLong: 'Sales invoices divided by the month of attachment',
    FVSControlingShort: 'FVS, mediaplan items',
  },
  administration: {
    estimatesPositions: 'Mediaplan items',
    rawData: 'Raw data',
    extensiveDataFromEstimatesByMonth: 'Extensive data from mediaplan items',
  },
} as const;
