import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import { useNotifications } from '../../common/hooks/useNotifications';
import { usePokCore } from '../../common/hooks/usePokCore';
import { useTranslation } from '../../common/hooks/useTranslation';

import { getPdf } from './getPdf';

export const InstructionsPLView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('menuLng');

  const getTempPdf = (name: string) => {
    getPdf(pok, notifications, name);
  };

  return (
    <>
      <Card className="main-card">
        <Card.Body className="m-3">
          <Card.Title className="pb-3">{t(tk.menu.instructions)}</Card.Title>
          <div className="d-grid gap-2">
            <Row>
              <Col md={6}>
                <div className="d-grid gap-2">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() =>
                      getTempPdf(
                        'instructions/pl/POK - Zakładanie kampanii i uzupełnianie 1.pdf',
                      )
                    }
                  >
                    {t(tk.docs.settingUpProjects)}
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() =>
                      getTempPdf(
                        'instructions/pl/POK - Import pozycji z kampanii do kampanii.pdf',
                      )
                    }
                  >
                    {t(tk.docs.importFromProjectToProject)}
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() =>
                      getTempPdf(
                        'instructions/pl/POK - Zamówienia do kampanii.pdf',
                      )
                    }
                  >
                    {t(tk.docs.projectOrders)}
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() =>
                      getTempPdf(
                        'instructions/pl/POK - Realizacja zamówienia (specjaliści).pdf',
                      )
                    }
                  >
                    {t(tk.docs.orderFulfillment)}
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() =>
                      getTempPdf('instructions/pl/POK - akceptacja faktur.pdf')
                    }
                  >
                    {t(tk.docs.acceptanceInvoices)}
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() =>
                      getTempPdf(
                        'instructions/pl/POK - Import kampanii z arkuszy.pdf',
                      )
                    }
                  >
                    {t(tk.docs.importProjects)}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
