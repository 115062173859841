/*eslint max-lines-per-function: ["error", 374]*/
/*eslint complexity: ["error", 19]*/
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import {
  CreateUpdateEstimateItemDtoPositionTypeEnum,
  GetDictionaryDto,
  GetEstimateItemDto,
  GetExcelAndPokRowDto,
  GetExcelAndPokRowDtoPositionTypeEnum,
  GetExcelColumnsDto,
  GetExcelConfigDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { useNavigation } from '../../../common/navigation';
import { isFieldHidden } from '../Estimates/CommissionService';
import mathUtils from '../../../utils/mathUtils';
import { EstimateItemFields } from '../../../common/pokCore/validation/EstimateItemFields';

import { AmountForm } from './AmountForm';

interface ExcelCompareGridProps {
  data: GetExcelAndPokRowDto[];
  columns: GetExcelColumnsDto[];
  config: GetExcelConfigDto;
  selectedKeys?: string[];
  refresh?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  updateData?: (obj: Partial<GetExcelAndPokRowDto>) => void;
}

export const ExcelCompareGrid: React.FC<ExcelCompareGridProps> = props => {
  const { t, tk } = useTranslation('excelImportLng');
  const nav = useNavigation();

  const amount = (value?: string) => {
    if (!value) {
      return '';
    }
    return numberFormatter(value || '', true);
  };

  const diff = (
    value1: string | undefined,
    value2: string | undefined,
    update: boolean,
    key?: string,
    positionType?: GetExcelAndPokRowDtoPositionTypeEnum,
  ) => {
    if (!update) {
      return value1;
    }
    if (value1 === value2) {
      return value1;
    }
    if (
      key &&
      isFieldHidden(
        key,
        positionType as CreateUpdateEstimateItemDtoPositionTypeEnum,
        props.config.profile.commission,
      )
    ) {
      return '';
    }

    return <span className="bg-custom-light-red">{value1}</span>;
  };

  const amountEditor = (
    value: string | undefined,
    originalValue: string | undefined,
    fieldName: string,
    rowId: string,
    decimalScale?: number,
  ) => {
    return (
      <AmountForm
        amount={value || ''}
        originalAmount={originalValue || ''}
        decimalScale={decimalScale}
        handleUpdate={value =>
          props.updateData &&
          props.updateData({
            [fieldName]: value,
            rowId: rowId,
          })
        }
      />
    );
  };

  const openProject = (event: React.MouseEvent, projectId?: string) => {
    event.stopPropagation();
    nav.projectDetails(projectId || '', true, 'estimates');
  };

  const renderAmountField = (
    d: GetExcelAndPokRowDto,
    fieldName: string,
    update: boolean,
    positionType: GetExcelAndPokRowDtoPositionTypeEnum,
    decimalScale?: number,
  ) => {
    if (
      isFieldHidden(
        fieldName,
        positionType as CreateUpdateEstimateItemDtoPositionTypeEnum,
        props.config.profile.commission,
      )
    ) {
      return '';
    }
    const value1 = d[fieldName as keyof GetExcelAndPokRowDto] as string;
    const orgValue = d[
      ('excel' +
        fieldName.charAt(0).toUpperCase() +
        fieldName.slice(1)) as keyof GetExcelAndPokRowDto
    ] as string;
    let value2 = undefined;
    if (d.estimateItem) {
      value2 = d.estimateItem[fieldName as keyof GetEstimateItemDto] as string;
    }
    return (
      <div className="noWrap">
        {diff(amount(value1), amount(value2), update)}
        {amountEditor(value1, orgValue, fieldName, d.rowId, decimalScale)}
      </div>
    );
  };

  const getOrderByNumberColumns = (d: GetExcelAndPokRowDto, keys: string[]) => {
    const columns: Record<string, number> = {};
    for (const key of keys) {
      let value = d[key as keyof GetExcelAndPokRowDto] ?? '-9999999';
      let pokValue = d.estimateItem
        ? d.estimateItem[key as keyof GetEstimateItemDto]
        : '-9999999';
      if (
        isFieldHidden(
          key,
          d.positionType as CreateUpdateEstimateItemDtoPositionTypeEnum,
          props.config.profile.commission,
        )
      ) {
        value = '-9999999';
        pokValue = '-9999999';
      }
      if (typeof value === 'string') {
        columns[key] = mathUtils.parse(value);
        if (pokValue && typeof pokValue === 'string') {
          columns[key + 'Pok'] = mathUtils.parse(pokValue);
        }
      }
    }
    return columns;
  };

  const generateAmountFields = (d: GetExcelAndPokRowDto, update: boolean) => {
    const columns: Record<string, React.ReactNode> = {};
    for (const key of EstimateItemFields.amountFieldsKeys) {
      columns[key.name] = isHidden(key.name, d)
        ? ''
        : renderAmountField(d, key.name, update, d.positionType);
    }
    return columns;
  };

  const generatePercentageFields = (
    d: GetExcelAndPokRowDto,
    update: boolean,
  ) => {
    const columns: Record<string, React.ReactNode> = {};
    for (const key of EstimateItemFields.percentageFieldsKeys) {
      const value1 = d.estimateItem
        ? (d.estimateItem[key as keyof GetEstimateItemDto] as string)
        : undefined;
      const value2 =
        (d[key as keyof GetExcelAndPokRowDto] as string) ?? undefined;
      const percentageValue1 = value1
        ? `${mathUtils.parse(value1).toString()}%`
        : '';
      const percentageValue2 = value2
        ? `${mathUtils.parse(value2).toString()}%`
        : '';
      columns[key] = isHidden(key, d)
        ? ''
        : diff(percentageValue2, percentageValue1, update);
      columns[key + 'Pok'] = isHidden(key, d)
        ? ''
        : diff(percentageValue1, percentageValue2, update);
    }
    return columns;
  };

  const generatePokAmountFields = (
    d: GetExcelAndPokRowDto,
    update: boolean,
  ) => {
    const columns: Record<string, React.ReactNode> = {};
    for (const key of EstimateItemFields.amountFieldsKeys) {
      const value1 = d.estimateItem
        ? (d.estimateItem[key.name as keyof GetEstimateItemDto] as string)
        : undefined;
      const value2 = d[key.name as keyof GetExcelAndPokRowDto] as string;
      columns[key.name + 'Pok'] = isHidden(key.name, d)
        ? ''
        : diff(amount(value1), amount(value2), update);
    }
    return columns;
  };

  const generateStringFields = (d: GetExcelAndPokRowDto, update: boolean) => {
    const columns: Record<string, React.ReactNode> = {};
    for (const key of EstimateItemFields.stringFields) {
      const value1 =
        (d[key as keyof GetExcelAndPokRowDto] as string) ?? undefined;
      const value2 =
        d.estimateItem && d.estimateItem[key as keyof GetEstimateItemDto]
          ? (d.estimateItem[key as keyof GetEstimateItemDto] as string)
          : undefined;
      columns[key] = isHidden(key, d)
        ? ''
        : diff(value1, value2, update, key, d.positionType);
      columns[key + 'Pok'] = isHidden(key, d)
        ? ''
        : diff(value2, value1, update, key, d.positionType);
    }
    return columns;
  };

  const generateDictionaryFields = (
    d: GetExcelAndPokRowDto,
    update: boolean,
  ) => {
    const columns: Record<string, React.ReactNode> = {};
    for (const key of EstimateItemFields.dictionaryFields) {
      const value1 =
        (d[
          (key.fieldName + 'Value') as keyof GetExcelAndPokRowDto
        ] as string) ?? undefined;
      const value2 =
        d.estimateItem &&
        d.estimateItem[key.fieldName as keyof GetEstimateItemDto]
          ? (
              d.estimateItem[
                key.fieldName as keyof GetEstimateItemDto
              ] as GetDictionaryDto
            ).value
          : undefined;
      columns[key.fieldName] = isHidden(key.fieldName, d)
        ? ''
        : diff(value1, value2, update, key.fieldName, d.positionType);
      columns[key.fieldName + 'Pok'] = diff(
        value2,
        value1,
        update,
        key.fieldName,
        d.positionType,
      );
    }
    return columns;
  };

  const generateBoolFields = (d: GetExcelAndPokRowDto) => {
    const columns: Record<string, string> = {};
    for (const key of EstimateItemFields.boolFields) {
      const value = d[key as keyof GetExcelAndPokRowDto] as boolean;
      columns[key] = value === undefined ? '' : value === true ? 'Tak' : 'Nie';
    }
    return columns;
  };

  const isHidden = (key: string, d: GetExcelAndPokRowDto) => {
    return isFieldHidden(
      key,
      d.positionType as CreateUpdateEstimateItemDtoPositionTypeEnum,
      props.config.profile.commission,
    );
  };

  const columns: GridColumn[] = [{ header: 'Status', property: 'status' }];

  if (props.columns) {
    for (const column of props.columns) {
      if (column.columnName === 'projectNumber') {
        columns.push({
          header: `Nr ${t(tk.prLang.genitiveProject)}`,
          property: 'projectNumber',
          columnCssClass: 'noWrap',
        });
        continue;
      }
      if (
        column.columnName === 'month' ||
        column.columnName === 'startDate' ||
        column.columnName === 'endDate'
      ) {
        if (column.columnName !== 'endDate') {
          columns.push({
            header: `Od`,
            property: 'startDate',
            columnCssClass: 'noWrap',
          });
        }
        if (column.columnName !== 'startDate') {
          columns.push({
            header: `Do`,
            property: 'endDate',
            columnCssClass: 'noWrap',
          });
        }
        continue;
      }
      if (column.columnName === 'positionName') {
        columns.push({ header: `Pozycja w POK`, property: 'positionName' });
        continue;
      }
      columns.push({
        header: column.columnNamePl,
        property: column.columnName,
      });
      columns.push({
        header: column.columnNamePl + ' (POK)',
        property: column.columnName + 'Pok',
      });
    }
    columns.push({ header: '', property: 'comments', noFilter: true });
  }

  const data: GridRecord[] = [];

  for (const d of props.data) {
    const update = d.estimateItem?.id ? true : false;
    const orderByNumberValues = getOrderByNumberColumns(
      d,
      EstimateItemFields.amountFieldsKeys.map(key => key.name),
    );
    const amountFields = generateAmountFields(d, update);
    const percentageFields = generatePercentageFields(d, update);
    const stringFields = generateStringFields(d, update);
    const dictionaryFields = generateDictionaryFields(d, update);
    const pokAmountFields = generatePokAmountFields(d, update);
    const boolFields = generateBoolFields(d);
    data.push({
      key: d.rowId,
      rowCssClass: d.estimateItem?.id ? 'success-row' : '',
      values: {
        ...amountFields,
        ...percentageFields,
        ...stringFields,
        ...dictionaryFields,
        ...pokAmountFields,
        ...boolFields,
        rowId: d.rowId,
        status: d.estimateItem?.id ? 'Aktualizacja' : 'Nowy',
        positionName: d.positionName,
        projectNumber: d.projectId && (
          <Button
            variant="link"
            className="p-0 m-0"
            onClick={e => openProject(e, d.projectId)}
          >
            {d.projectNumber}
          </Button>
        ),
        startDate: d.startDate ? momencik(d.startDate, 'YYYY-MM-DD') : '',
        endDate: d.endDate ? momencik(d.endDate, 'YYYY-MM-DD') : '',
        invoiceInstructionAttachment: d.invoiceInstructionAttachment,
        comments: d.comment && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{d.comment}</Tooltip>}
          >
            <Icon.ExclamationCircle className="icon-red" size={18} />
          </OverlayTrigger>
        ),
      },
      orderByNumberValues: {
        ...orderByNumberValues,
      },
      orderByStringValues: {
        projectNumber: d.projectNumber ?? '',
      },
    });
  }

  return (
    <Grid
      refresh={props.refresh}
      data={data}
      columns={columns}
      showFilter={true}
      availablePageSizes={[10, 20, 50, 100]}
      pageSize={100}
      enableMultiSelect={true}
      onRowClick={props.onRowClick}
      selectedKeys={props.selectedKeys}
      emptyGridMessage="Brak pozycji"
      trClassName="amounts-align-right font-90"
      withFilterInColumns={true}
    />
  );
};
