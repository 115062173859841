/*eslint max-lines-per-function: ["error", 200]*/
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import { TFunction } from 'i18next/typescript/t';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  GetOrderExecutionWithCurrencyDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormSkeleton from '../../../app/components/FormSkeleton';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { convert } from '../../../common/pokCore/contexts/OrderExecutionContext';
import { OrderExecutionForm } from '../../components/OrdersExecution/OrderExecutionForm';
import { OrderExecutionDecisionControls } from '../../components/OrdersExecution/OrderExecutionDecisionControls';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';
import { buildProjectLabel } from '../../../utils/buildProjectLabel';
import numberFormatter from '../../../common/numberFormatter';
import { BadCompany } from '../../../common/components/NotAuthorized/BadCompany';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useAuth } from '../../../common/hooks/useAuth';
import {
  CurrencyIsoTypeEnum,
  LanguageType,
} from '../../../common/pokCore/validation/schemas';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { TKeys } from '../../../translations/keys';

import { OrdersExecutionTabs } from './OrdersExecutionView';

type ParamTypes = {
  orderExecutionId: string;
};

const getHeader = (
  orderExecution: GetOrderExecutionWithCurrencyDto,
  tk: TKeys<'orderLng'>,
  t: TFunction<'orderLng', 'orderLng'>,
  goToProject: (id: string) => void,
) => {
  const realizationTeams = orderExecution.project.projectTeams?.map(
    ({ team }) => `${team.name} (${team?.company?.shortname})`,
  );

  return [
    <>
      {t(tk.orderExec.acceptOrderExecSysNum, {
        sysNumber: orderExecution.systemNumber,
      })}
      ,{t(tk.prLang.forProject)}{' '}
      <b>{buildProjectLabel(orderExecution.resultProject)}</b>,
      {t(tk.orderExec.purchaser)}:{' '}
      <b>{orderExecution.resultProject.purchaser.name}</b>
    </>,
    <>
      {t(tk.orderExec.realizationMonth)}:{' '}
      <b>{moment(orderExecution.date).format('YYYY-MM')}</b>,{' '}
      {t(tk.orderExec.realizationTeam)}: <b>{realizationTeams?.join(', ')}</b>,{' '}
      {t(tk.orderExec.realizationAmount)}:{' '}
      <b>
        {numberFormatter(
          orderExecution?.salesSum || 0,
          true,
          (orderExecution.currencyCode as CurrencyIsoTypeEnum) || undefined,
        )}
      </b>
    </>,
    <>
      {t(tk.prLang.projectPurchase)}{' '}
      <Button
        variant="link"
        className="p-0"
        onClick={() => goToProject(orderExecution.resultProject.id)}
      >
        {orderExecution.resultProject.number}
      </Button>{' '}
      {t(tk.orderExec.forRealizationTeam)}:{' '}
      <b>{numberFormatter(orderExecution?.purchaseSum || 0, true)}</b>
    </>,
  ];
};

export const OrderExecutionDecisionView: React.FC = () => {
  const { orderExecutionId } = useParams<ParamTypes>();
  const pok = usePokCore();
  const notifications = useNotifications();
  const nav = useNavigation();
  const auth = useAuth();
  const { t, tk } = useTranslation('orderLng');

  const [orderExecution, setOrderExecution] =
    useState<GetOrderExecutionWithCurrencyDto>();
  const [showAcceptErrors, setShowAcceptErrors] = useState(false);
  const [acceptErrors, setAcceptErrors] = useState<string[]>([]);
  const [authorized, setAuthorized] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      orderExecution &&
      orderExecution?.resultProject?.company?.id !== pok.getCompanyId()
    ) {
      setAuthorized(false);
    } else {
      if (orderExecution) {
        setAuthorized(
          auth.check(
            PermissionsEnum.Projects,
            orderExecution?.resultProject?.company?.id,
          ),
        );
      }
    }
  }, [pok, orderExecution, auth]);

  const handleReject = (rejectedNote?: string) => {
    if (orderExecution) {
      setIsLoading(true);
      pok.ordersExecution
        .reject(orderExecution.id, rejectedNote!)
        .then(() => {
          notifications.saveCompleted();
          nav.ordersExecution(OrdersExecutionTabs.WAITING);
        })
        .catch(async err => {
          setAcceptErrors(await responseErrors(err as ResponseError));
          setShowAcceptErrors(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleAccept = () => {
    if (orderExecution) {
      setAcceptErrors([]);
      setShowAcceptErrors(false);
      setIsLoading(true);
      pok.ordersExecution
        .accept(orderExecution.id!)
        .then(() => {
          notifications.saveCompleted();
          nav.ordersExecution(OrdersExecutionTabs.WAITING);
        })
        .catch(async err => {
          setAcceptErrors(await responseErrors(err as ResponseError));
          setShowAcceptErrors(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleDownload = (lang: LanguageType) => {
    if (orderExecution) {
      pok.pdfPrinters
        .downloadOrderExecution(orderExecution.id!, lang)
        .catch(async err => {
          setAcceptErrors(await responseErrors(err as ResponseError));
          setShowAcceptErrors(true);
        });
    }
  };

  const handleInactive = () => {
    if (orderExecution) {
      setAcceptErrors([]);
      setShowAcceptErrors(false);
      pok.ordersExecution
        .deactivate(orderExecution?.id)
        .then(() => {
          notifications.saveCompleted();
          nav.ordersExecution(OrdersExecutionTabs.WAITING);
        })
        .catch(async errorResponse => {
          setShowAcceptErrors(true);
          setAcceptErrors(await responseErrors(errorResponse as ResponseError));
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (orderExecutionId) {
      pok.ordersExecution
        .getById(orderExecutionId)
        .then(setOrderExecution)
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [orderExecutionId, pok.ordersExecution, notifications]);

  return (
    <>
      {!authorized && orderExecution && (
        <BadCompany companyId={orderExecution?.resultProject?.company?.id} />
      )}
      {authorized &&
        (orderExecution ? (
          <Card className="main-card">
            <Card.Body className="m-3">
              <>
                {getHeader(orderExecution, tk, t, (id: string) =>
                  nav.projectDetails(id, true, 'estimates'),
                ).map((title, index) => (
                  <Card.Subtitle className="p-3" key={index}>
                    {title}
                  </Card.Subtitle>
                ))}
                <OrderExecutionForm
                  id={orderExecutionId}
                  orderExecution={convert(
                    orderExecution,
                    orderExecution.project.id,
                  )}
                  currencyCode={orderExecution.currencyCode}
                  emails={orderExecution.emails}
                  readOnly
                  project={orderExecution.project}
                  orderResultProject={orderExecution.resultProject}
                  orderProject={orderExecution.project}
                  status={orderExecution.status}
                  rejectedNote={orderExecution.rejectedNote}
                  disableSumsDiscrepancyAlert
                  isDecisionView
                  purchaseSum={orderExecution.purchaseSum || 0}
                />
                <ValidationAlert
                  show={showAcceptErrors}
                  errors={acceptErrors}
                  className="m-3"
                />
                <div className="d-flex">
                  {orderExecutionId && (
                    <LastEditorInfo
                      id={orderExecutionId}
                      className="color-primary"
                      method={pok.ordersExecution.getLastEditor}
                    />
                  )}
                  <OrderExecutionDecisionControls
                    orderExecution={orderExecution}
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                    handleDownload={handleDownload}
                    handleInactive={handleInactive}
                    isLoading={isLoading}
                  />
                </div>
              </>
            </Card.Body>
          </Card>
        ) : (
          <FormSkeleton />
        ))}
    </>
  );
};
