/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetCurrencyDto } from './GetCurrencyDto';
import {
    GetCurrencyDtoFromJSON,
    GetCurrencyDtoFromJSONTyped,
    GetCurrencyDtoToJSON,
} from './GetCurrencyDto';

/**
 * 
 * @export
 * @interface GetCompanyDto
 */
export interface GetCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    shortname: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCompanyDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    personalLanguage: GetCompanyDtoPersonalLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    logo: string;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyDto
     */
    projectMaxDaysDelay: number;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyDto
     */
    orderField: number;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetCompanyDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    invoicesEmail: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    footer: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    footerInEnglish: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCompanyDto
     */
    showMargin: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    templateType: GetCompanyDtoTemplateTypeEnum;
    /**
     * 
     * @type {GetCurrencyDto}
     * @memberof GetCompanyDto
     */
    mainCurrency: GetCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    language: GetCompanyDtoLanguageEnum;
}


/**
 * @export
 */
export const GetCompanyDtoPersonalLanguageEnum = {
    Projects: 'Projects',
    Campaigns: 'Campaigns'
} as const;
export type GetCompanyDtoPersonalLanguageEnum = typeof GetCompanyDtoPersonalLanguageEnum[keyof typeof GetCompanyDtoPersonalLanguageEnum];

/**
 * @export
 */
export const GetCompanyDtoTemplateTypeEnum = {
    Poland: 'Poland',
    UnitedStates: 'UnitedStates',
    Germany: 'Germany'
} as const;
export type GetCompanyDtoTemplateTypeEnum = typeof GetCompanyDtoTemplateTypeEnum[keyof typeof GetCompanyDtoTemplateTypeEnum];

/**
 * @export
 */
export const GetCompanyDtoLanguageEnum = {
    Pl: 'PL',
    En: 'EN'
} as const;
export type GetCompanyDtoLanguageEnum = typeof GetCompanyDtoLanguageEnum[keyof typeof GetCompanyDtoLanguageEnum];


/**
 * Check if a given object implements the GetCompanyDto interface.
 */
export function instanceOfGetCompanyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortname" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "personalLanguage" in value;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "projectMaxDaysDelay" in value;
    isInstance = isInstance && "orderField" in value;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "invoicesEmail" in value;
    isInstance = isInstance && "footer" in value;
    isInstance = isInstance && "footerInEnglish" in value;
    isInstance = isInstance && "showMargin" in value;
    isInstance = isInstance && "templateType" in value;
    isInstance = isInstance && "mainCurrency" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function GetCompanyDtoFromJSON(json: any): GetCompanyDto {
    return GetCompanyDtoFromJSONTyped(json, false);
}

export function GetCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'name': json['name'],
        'shortname': json['shortname'],
        'active': json['active'],
        'personalLanguage': json['personalLanguage'],
        'color': json['color'],
        'logo': json['logo'],
        'projectMaxDaysDelay': json['projectMaxDaysDelay'],
        'orderField': json['orderField'],
        'client': GetClientDtoFromJSON(json['client']),
        'invoicesEmail': json['invoicesEmail'],
        'footer': json['footer'],
        'footerInEnglish': json['footerInEnglish'],
        'showMargin': json['showMargin'],
        'templateType': json['templateType'],
        'mainCurrency': GetCurrencyDtoFromJSON(json['mainCurrency']),
        'language': json['language'],
    };
}

export function GetCompanyDtoToJSON(value?: GetCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'name': value.name,
        'shortname': value.shortname,
        'active': value.active,
        'personalLanguage': value.personalLanguage,
        'color': value.color,
        'logo': value.logo,
        'projectMaxDaysDelay': value.projectMaxDaysDelay,
        'orderField': value.orderField,
        'client': GetClientDtoToJSON(value.client),
        'invoicesEmail': value.invoicesEmail,
        'footer': value.footer,
        'footerInEnglish': value.footerInEnglish,
        'showMargin': value.showMargin,
        'templateType': value.templateType,
        'mainCurrency': GetCurrencyDtoToJSON(value.mainCurrency),
        'language': value.language,
    };
}

