export default {
  excel: {
    //konfiguracje importu
    profile: 'Profil',
    profileCategory: 'Kategoria profilu',

    importConfigsByName: 'Konfiguracja importu danych zewnętrznych o nazwie',

    importConfig: 'Konfiguracja importu',
    importConfigs: 'Konfiguracje importu danych zewnętrznych',
    importConfigAdd: 'Dodawanie nowej konfiguracji',
    importConfigNew: 'Nowa konfiguracja',
    importConfigEdit: 'Edycja konfiguracji',
    noImportConfigs: 'Brak konfiguracji importu w systemie.',
    importConfigType: 'Rodzaj importu',
    importConfigDescription: 'Instrukcja importu',
    companyIndependent: 'Niezależna od spółki',
    updateExisting: 'Aktualizacji istniejących pozycji',

    mappingNew: 'Nowe mapowanie',
    mappingEdit: 'Edycja mapowania',
    mappingAdd: 'Dodawanie nowego mapowania',
    columnInPOK: 'Kolumna w POK',
    columnInExcel: 'Kolumna w Excel',
    columnOrder: 'Kolejność kolumn w tabeli importu',
    noColumnMapping: 'Brak zdefiniowanych mapowań kolumn',

    mappingColumn: 'Mapowanie kolumn',
    mappingPosition: 'Mapowanie pozycji',
    positionInPok: 'Pozycja w POK',
    positionInExcel: 'Pozycja w Excel',
    positionParameters: 'Ustawione parametry pozycji',
    noPosition: 'Brak zdefiniowanych pozycji',
    parameters: 'Parametry',
  },
} as const;
