import React from 'react';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import CurrencyControl from '../../../app/components/CurrencyControl';

import ProfileFieldName from './ProfileFieldName';

interface InternetSTProfileParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const InternetSTProfileParams: React.FC<
  InternetSTProfileParamsProps
> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.purchaseFormInternetST && (
        <ProfileFieldName
          controlId="purchaseFormInternetST"
          schema={schema?.purchaseFormInternetST}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="InternetST - forma zakupu"
            value={props.estimateItem?.purchaseFormInternetSTId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseFormInternetSTId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.categoryInternetST && (
        <ProfileFieldName
          controlId="categoryInternetST"
          schema={schema?.categoryInternetST}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="InternetST - kategoria"
            value={props.estimateItem?.categoryInternetSTId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryInternetSTId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.formatInternetST && (
        <ProfileFieldName
          controlId="formatInternetST"
          schema={schema?.formatInternetST}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="InternetST - format"
            value={props.estimateItem?.formatInternetSTId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatInternetSTId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.netCustomerAccount && (
        <ProfileFieldName
          controlId="netCustomerAccount"
          schema={schema?.netCustomerAccount}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.netCustomerAccount}
            onChange={value =>
              propertyChange({
                netCustomerAccount: value,
              })
            }
          />
        </ProfileFieldName>
      )}
    </>
  );
};
