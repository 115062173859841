import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  CreateUpdateEmployeeRoleDto,
  GetEmployeeRoleDto,
  GetRoleDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { validateRoleEmployee } from '../../../common/pokCore/contexts/EmployeeRoleContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { UsersInRoleEditor } from '../../components/Roles/UsersInRoleEditor';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

type ParamTypes = {
  roleId: string;
};

export const UsersInRoleView: React.FC = () => {
  const [employeesRole, setEmployeesRole] = useState<GetEmployeeRoleDto[]>([]);
  const [role, setRole] = useState<GetRoleDto>();
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('roleLng');

  const { roleId: roleId } = useParams<ParamTypes>() as {
    roleId: string;
  };

  useEffect(() => {
    pok.employeesRoles
      .getWithTheSameRoleByRoleId(roleId)
      .then(getEmployeesRoles => {
        setEmployeesRole((getEmployeesRoles || []).map(o => o));
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [pok.employeesRoles, roleId, notifications]);

  useEffect(() => {
    pok.roles
      .getById(roleId)
      .then(roleDto => {
        setRole(roleDto);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [pok.roles, roleId, notifications]);

  const addEditUsersInRole = async (
    roleId: string,
    id?: string | null,
    employeeRole?: CreateUpdateEmployeeRoleDto,
  ) => {
    const status = await validateRoleEmployee({
      roleId,
      employeesIds: employeeRole ? [employeeRole.userId] : [],
    });

    if (status.valid && roleId && employeeRole) {
      try {
        let empl: GetEmployeeRoleDto | undefined;
        if (!id) {
          empl = await pok.employeesRoles.create({
            ...employeeRole,
            roleId,
          });
        } else {
          await pok.employeesRoles.update(id, {
            ...employeeRole,
            roleId,
          });
        }
        if (!id && empl) {
          setEmployeesRole(
            (employeesRole || []).concat(
              await pok.employeesRoles.getById(empl.id),
            ),
          );
        }
        if (id) {
          const newEmpR = await pok.employeesRoles.getById(id);
          setEmployeesRole(
            employeesRole?.map(item => (item.id === id ? newEmpR : item)),
          );
        }

        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  return (
    <>
      <Card className="main-card">
        <Card.Body className="m-3">
          <Card.Title className="pb-3">{t(tk.employee.employees)}</Card.Title>
          {role && (
            <UsersInRoleEditor
              role={role}
              employeesRole={employeesRole}
              changeEmployeesRole={setEmployeesRole}
              onCompleted={addEditUsersInRole}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};
