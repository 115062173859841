import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { RoleSearch } from '../../components/Roles/RoleSearch';
import {
  CreateUpdateRoleDto,
  GetRoleDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { RoleEditor } from '../../components/Roles/RoleEditor';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/RoleContext';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const RolesView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [roles, setRoles] = useState<GetRoleDto[]>([]);
  const [role, setRole] = useState<GetRoleDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t, tk } = useTranslation('roleLng');

  useEffect(() => {
    pok.roles
      .getMany()
      .then(setRoles)
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [pok.roles, notifications, refresh]);

  const handleAdd = () => {
    setShow(true);
    setRole(undefined);
  };

  const addEditRole = async (roleCU: CreateUpdateRoleDto, id?: string) => {
    const status = await validate(roleCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.roles.update(id, roleCU);
        } else {
          await pok.roles.create(roleCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getRole = await pok.roles.getById(id);
    setRole(getRole);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.role.roles)}</Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.role.roleNew)}
        </Button>
        <RoleSearch data={roles} onRowClick={handleClick} withHistory={true} />
        <RoleEditor
          id={role?.id}
          role={convert(role)}
          onCompleted={addEditRole}
          show={show}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      </Card.Body>
    </Card>
  );
};
