import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useNavigation } from '../../../common/navigation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import FormRow from '../../../app/components/FormRow';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import {
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { DocumentControlSearch } from '../../components/DocumentControl/DocumentControlSearch';
import { useNotifications } from '../../../common/hooks/useNotifications';
import BudgetSelector from '../../components/Selects/BudgetSelector';
import { downloadExcel } from '../../components/Excel/downloadExcel';
import { useTranslation } from '../../../common/hooks/useTranslation';

type ParamTypes = {
  id?: string;
};

export const DocumentControlView: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const { t, tk } = useTranslation('reportLng');
  const pok = usePokCore();
  const nav = useNavigation();
  const notifications = useNotifications();
  const [budgetId, setBudgetId] = useState<string>();
  const [project, setProject] = useState<GetProjectDto>();

  useEffect(() => {
    if (id) {
      pok.projects
        .getById(id)
        .then(setProject)
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [id, notifications, pok.projects]);

  const handleClick = async (id: string) => {
    pok.estimateItems
      .getById(id)
      .then(item => nav.projectDetails(item.estimateByMonth?.project?.id, true))
      .catch(responseErrors => {
        notifications.caughtError(responseErrors as ResponseError);
      });
  };

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.estimateItems.getForDocumentControlByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          budgetId,
          id,
        )) as GridGetterFunction,
    [pok.estimateItems, budgetId, id],
  );

  const getCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.estimateItems.getForDocumentControlCount(
          filterText,
          filterColumns,
          budgetId,
          id,
        )) as GridCountFunction,
    [pok.estimateItems, budgetId, id],
  );

  const exportToXls = async () => {
    if (budgetId || id) {
      const result = await pok.reports.getReportForDocumentControl(
        budgetId,
        id,
      );
      await downloadExcel(
        result,
        [],
        ['K', 'L', 'M'],
        [],
        'Kontrola dokumentów',
        id
          ? `Kontrola_${t(tk.prLang.genitiveProject)}_${project?.number}`
          : 'Kontrola_dokumentow',
      );
    }
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          Zestawienie do kontroli dokumentów{' '}
          {id && project
            ? `dla ${t(tk.prLang.genitiveProject)} ${project?.number} (${project?.name})`
            : ''}
        </Card.Title>
        {!id && (
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="budget" label="Budżet">
              <BudgetSelector
                documentControl={true}
                value={budgetId}
                onChange={option => setBudgetId(option?.value)}
                clearable
              />
            </FormRow>
          </Form>
        )}
        {(budgetId || id) && (
          <>
            <DocumentControlSearch
              getData={getData}
              getCount={getCount}
              onRowClick={handleClick}
              withFilterInColumns={true}
            />
            <Button variant="outline-secondary" onClick={exportToXls}>
              Eksport
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
};
