import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import {
  CreateUpdateExcelConfigDto,
  GetExcelConfigDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import Tabs from '../../../app/components/Tabs';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ProjectExcelConfigContext';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ExcelConfigModalEditor } from './ExcelConfigModalEditor';
import { ExcelConfigForm } from './ExcelConfigForm';
import { HeadersView } from './HeadersView';
import { PositionsView } from './PositionsView';

interface ExcelConfigEditorProps {
  config?: GetExcelConfigDto;
  refresh(): void;
}
export const ExcelConfigEditor: React.FC<ExcelConfigEditorProps> = props => {
  const pok = usePokCore();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const { t, tk } = useTranslation('excelImportLng');

  const addEditConfig = async (
    configCU: CreateUpdateExcelConfigDto,
    id?: string,
  ) => {
    const status = await validate(configCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.projectExcelConfigs.update(id, configCU);
        } else {
          await pok.projectExcelConfigs.create(configCU);
        }
        setRefresh(!refresh);
        props.refresh();
        setShow(false);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Card className="main-card">
        <Card.Body className="m-3">
          <Card.Title className="pb-3">
            {t(tk.excel.importConfigsByName)} <b>{props.config?.name}</b> (
            {t(tk.excel.profile)}: {props.config?.profile?.name})
          </Card.Title>
          <Tabs>
            <Tab eventKey="name" title={t(tk.common.name)} className="m-3">
              <ExcelConfigForm
                id={props.config?.id}
                config={convert(props.config)}
                readOnly={true}
              />
              <div className="d-flex justify-content-end pt-4">
                <Button variant="outline-primary" onClick={handleClick}>
                  {t(tk.button.edit)}
                </Button>
              </div>
              {show && (
                <ExcelConfigModalEditor
                  id={props.config?.id}
                  config={convert(props.config)}
                  onCompleted={addEditConfig}
                  show={show}
                  handleClose={handleClose}
                />
              )}
            </Tab>
            <Tab
              eventKey="headers"
              title={t(tk.excel.mappingColumn)}
              className="m-3"
            >
              {props.config && (
                <HeadersView config={props.config} refresh={props.refresh} />
              )}
            </Tab>
            <Tab
              eventKey="positions"
              title={t(tk.excel.mappingPosition)}
              className="m-3"
            >
              {props.config && (
                <PositionsView config={props.config} refresh={props.refresh} />
              )}
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};
