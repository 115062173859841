import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { usePokCore } from '../../hooks/usePokCore';
import { useNotifications } from '../../hooks/useNotifications';
import { GetCompanyDto } from '../../pokCore/autogenerated/pokApiClient';

interface BadCompanyProps {
  companyId?: string;
}

export const BadCompany: React.FC<BadCompanyProps> = ({ companyId }) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [company, setCompany] = useState<GetCompanyDto>();

  useEffect(() => {
    if (companyId) {
      pok.companies
        .getById(companyId)
        .then(company => {
          setCompany(company);
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }
  }, [pok, notifications, companyId]);

  return (
    <Alert variant="danger">
      <Alert.Heading>Niezgodna spółka</Alert.Heading>
      <hr />
      <p>
        Spółka, w której obecnie pracujesz, nie może obsługiwać wybranego
        obiektu.
      </p>
      {company && (
        <>
          Obiekt jest obsługiwany przez spółkę: <b>{company?.name}</b>
        </>
      )}
    </Alert>
  );
};
