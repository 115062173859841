import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import {
  GetEstimateByMonthDto,
  GetPaeEstimateItemsDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PaeImportForm } from './PaeImportForm';

interface PaeImportProps {
  estimateByMonth?: GetEstimateByMonthDto;
  project?: GetProjectDto;
  purchaseOnly: boolean;
  refresh(): void;
}

export const PaeImport: React.FC<PaeImportProps> = props => {
  const [show, setShow] = useState(false);
  const pok = usePokCore();
  const { t, tk } = useTranslation('projectLng');

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = async () => {
    setShow(true);
  };

  const importPositions = async (paePositions: GetPaeEstimateItemsDto[]) => {
    try {
      await pok.estimateItems.createEstimateItemsFromPae({
        estimateByMonthId: props.estimateByMonth?.id || '',
        positions: paePositions,
        purchaseOnly: props.purchaseOnly,
      });
      setShow(false);
      props.refresh();
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  return (
    <>
      <Button className="mx-2" variant="outline-primary" onClick={handleShow}>
        {t(tk.estimates.paeImport)}
      </Button>
      {show && props.estimateByMonth && props.project && (
        <PaeImportForm
          estimateByMonth={props.estimateByMonth}
          project={props.project}
          onCompleted={importPositions}
          show={show}
          purchaseOnly={props.purchaseOnly}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
