/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCurrencyDto
 */
export interface GetCurrencyDto {
    /**
     * 
     * @type {string}
     * @memberof GetCurrencyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrencyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrencyDto
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrencyDto
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrencyDto
     */
    unit: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrencyDto
     */
    subunit: string;
}

/**
 * Check if a given object implements the GetCurrencyDto interface.
 */
export function instanceOfGetCurrencyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "unit" in value;
    isInstance = isInstance && "subunit" in value;

    return isInstance;
}

export function GetCurrencyDtoFromJSON(json: any): GetCurrencyDto {
    return GetCurrencyDtoFromJSONTyped(json, false);
}

export function GetCurrencyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrencyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'currencyCode': json['currencyCode'],
        'countryCode': json['countryCode'],
        'unit': json['unit'],
        'subunit': json['subunit'],
    };
}

export function GetCurrencyDtoToJSON(value?: GetCurrencyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'currencyCode': value.currencyCode,
        'countryCode': value.countryCode,
        'unit': value.unit,
        'subunit': value.subunit,
    };
}

