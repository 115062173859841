export default {
  administration: {
    //spółki
    companyAdd: 'Dodawanie nowej spółki',
    companyEdit: 'Edycja spółki nr sys.',
    companyNew: 'Nowa spółka',
    noCompanies: 'Brak spółek w systemie',
    logo: 'Logo',
    personalLanguage: 'Nazewnictwo',
    language: 'Język',
    color: 'Kolor',
    invoiceTemplate: 'Szablon faktur',
    maxDelay: 'Maks. opóźnienie kampanii',
    noFull: 'Liczba porządkowa',
    emailInvoice: 'E-mail na faktury',
    footer: 'Stopka',
    footerEn: 'Stopka w j. angielskim',
    uploadGraphicFile: 'Wgraj plik graficzny',
    showMargin: 'Pokaż marżę',

    //pracownicy
    synchrWithOneTool: 'Synchronizuj z OneTool',
    all: 'Wszyscy',
    inactive: 'Nieaktywni z uprawnieniami',
    deletePermissions: 'Usuń uprawnienia',
    deleteUserPermissions: 'Usuń uprawnienia użytkownika',
    askDeleteUserPermissions:
      'Czy na pewno usunąć wszystkie uprawnienia przypisane do tego użytkownika?',
    askDeletePermissions:
      'Czy na pewno chcesz usunąć wszystkie uprawnienia wszystkich nieaktywnych użytkowników?',
    position: 'Stanowisko',
    oneToolId: 'One Tool ID',
    nameSurname: 'Imię i nazwisko',
    login: 'Login',
    email: 'E-mail',
    supervisor: 'Przełożony',
    manager: 'Szef',
    technical: 'Techniczny',
    noEmployees: 'Brak pracowników w systemie',

    //zespoły
    teamNew: 'Nowy zespół',
    teamEdit: 'Edycja zespołu nr sys.',
    teamAdd: 'Dodawanie nowego zespołu',
    managerName: 'Szef zespołu',
    noTeams: 'Brak zespołów w systemie',
    paeSupport: 'Obsługuje PAE',
    tvSupport: 'Obsługuje TV',

    //profile mediaplanów
    estimateProfiles: 'Profile mediaplanów',
    estimateProfileNew: 'Nowy profil',
    estimateProfileAdd: 'Dodawanie nowego profilu',
    estimateProfileEdit: 'Edycja profilu',
    noEstimateProfiles: 'Brak profili mediaplanów w systemie',
    commissions: 'Prowizje',
    relatedTeams: 'Zespoły powiązane',
    tv: 'TV',
    tvBuying: 'TV zakup',
    autoSalesBlockDisabled: 'Wyłączona auto blokada sprzedaży',
    absence: 'Brak',
    visible: 'Widoczne',
    obligatory: 'Obowiązkowe',

    //kategorie profili
    profileCategory: 'Kategoria profilu',
    profileCategories: 'Kategorie profili',
    profileCategoryAdd: 'Dodawanie nowej kategorii profilu',
    profileCategoryNew: 'Nowa kategoria profilu',
    profileCategoryEdit: 'Edycja kategorii profilu',
    noProfileCategories: 'Brak kategorii profili w systemie.',
    salesFinancialAccount: 'Konto księgowe sprzedaży',
    purchasesFinancialAccount: 'Konto księgowe zakupu',
    commissionsFinancialAccount: 'Konto księgowe prowizji',
    profile: 'Profil',

    //Pola profili
    sales: 'Sprzedaż',
    rcSalesTotal: 'RC sprzedaż total',
    salesSurchargePercent: '% Dopłata sprzedaż',
    salesDiscountPercent: '% Rabat sprzedaż',
    salesNet: 'Netto sprzedaż',
    salesCommissionPercent: '% Prowizja sprzedaż',
    salesCommissionAmount: 'Prowizja sprzedaż kwota',
    salesNetTechnicalCost: 'Netto sprzedaż koszt techniczny',
    salesNetTotal: 'Netto sprzedaż total',

    buy: 'Zakup',
    rcPurchase: 'RC zakup',
    purchaseSurchargePercent: '% Dopłata zakup',
    purchaseDiscountPercent: '% Rabat zakup',
    purchaseNet: 'Netto zakup',
    purchaseTechnicalCost: 'Netto zakup koszt techniczny',
    purchaseNetTotal: 'Netto zakup total',

    media: 'Media',
    numberOfMedia: 'Liczba nośników',
    technologyDictionary: 'Technologia (słownik)',
    regionDictionary: 'Rejon (słownik)',
    additionalDescription: 'Dodatkowy opis',

    format: 'Format',
    formatRadio: 'Format (radio)',
    formatPress: 'Format (prasa)',
    formatInternet: 'Format (internet)',
    formatInternetST: 'Format (internet ST)',

    category: 'Kategoria',
    categoryInternet: 'Kategoria (internet)',
    categoryInternetST: 'Kategoria (internet ST)',
    categoryLabcon: 'Kategoria (Labcon)',

    purchase: 'Forma zakupu',
    purchaseFormInternet: 'Forma zakupu (internet)',
    purchaseFormInternetST: 'Forma zakupu (internet ST)',
    purchaseMethod: 'Sposób zakupu (TV)',

    emissionType: 'Typ emisji',
    emissionTypeRadio: 'Typ emisji (radio)',
    emissionTypeCinema: 'Typ emisji (kino)',

    service: 'Usługa',
    action: 'Działanie',
    actionGroup: 'Grupa działań',
    serviceExternalWorkPercent: '% Prace zewnętrzne',
    serviceCreationPercent: '% Kreacja',
    serviceChangeProductionPercent: '% Produkcja Change',
    serviceStrategyPercent: '% Strategia',
    serviceProductionBroadcastPercent: '% Produkcja broadcast',
    serviceClientServicePercent: '% Client service',

    additionalInformation: 'Inne',
    isUpSell: 'Czy upsell',
    netCustomerAccount: 'Netto konto klienta',
    copySize: 'Długość kopii',
    orderNumber: 'Numer zlecenia',
    medium: 'Medium (zagraniczne)',
    ownName: 'Nazwa własna',
    labconChannel: 'Kanał Labcon',

    //konfiguracja maili
    mailConfig: 'Konfiguracja maili',
    mailConfigAdd: 'Dodawanie nowej konfiguracji',
    mailConfigNew: 'Nowa konfiguracja',
    mailConfigEdit: 'Edycja konfiguracji o nr sys.',
    noMailConfigs: 'Brak konfiguracji maili w systemie.',
    type: 'Typ',
    emails: 'Adresy e-mail',
    mailConfigType: 'Typ konfiguracji',

    //konfiguracja zadań
    jobConfig: 'Konfiguracja zadań cyklicznych',
    jobConfigAdd: 'Dodawanie nowej konfiguracji',
    jobConfigNew: 'Nowa konfiguracja',
    jobConfigEdit: 'Edycja konfiguracji o nr sys.',
    noJobConfigs: 'Brak konfiguracji zadań w systemie.',
    jobConfigType: 'Typ konfiguracji',
    cron: 'Częstotliwość',
    jobConfigSynchronize: 'Synchronizuj wszystkie zadania',
    cronError: 'Wystąpił błąd przy generowaniu częstotliwości:',

    //konta bankowe
    bankAccounts: 'Konta bankowe',
    bankAccountNew: 'Nowe konto bankowe',
    bankAccountAdd: 'Dodawanie nowego konta bankowego',
    bankAccountEdit: 'Edycja konta bankowego',
    noBankAccounts: 'Brak rachunków bankowych w systemie.',
    bankName: 'Nazwa banku',
    accountNumber: 'Numer konta bankowego',
    swift: 'Swift BIC',
    swiftLong: 'Swift Bank Identity Code',
    invoiceType: 'Typ faktury',
    VATAccountNumber: 'VAT - Nr konta bankowego',
    VATBankName: 'VAT - Nazwa banku',

    //kalendarz
    calendarWorkingDay: 'Kalendarz dni roboczych',
    createYear: 'Utwórz rok',
    askAdd: 'Czy na pewno dodać dzień',
    askDelete: 'Czy na pewno usunąć dzień',
    to: 'do kalendarza dni roboczych?',
    from: 'z kalendarza dni roboczych?',
    addCalendar: 'Dodawanie dnia roboczego',
    deleteCalendar: 'Usuwanie dnia roboczego',
    askAddYear: 'Czy na pewno chcesz utworzyć rok',
    askDeleteYear: 'Czy na pewno chcesz usunąć rok',
    inSystem: 'w systemie?',
    warning:
      'Uwaga, jeżeli rok jest już utworzony, to zostanie usunięty i utworzony ponownie.',

    //slowniki
    dictionaries: 'Słowniki systemowe',
    dictionarySelectedDelete: 'Usunięcie zaznaczonej wartości',
    dictionarySelectedEdit: 'Edycja zaznaczonej wartości',
    dictionarySelectedAdd: 'Dodanie elementu pod zaznaczoną wartością',
    dictionaryDelete: 'Usuwanie ze słownika',
    dictionaryAskDelete: 'Czy na pewno usunąć wartość:',
    dictionaryEdit: 'Edycja słownika ',
    dictionaryAdd: 'Dodawanie wartości do słownika ',
    value: 'Wartość',
    valueEN: 'Wartość w j. angielskim',
    symbol: 'Symbol',
  },
} as const;
