import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetEstimateItemDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';

import { Option, Selector } from './Selector';

export interface EstimateItemSelectorProps {
  projectId: string;
  clientId?: string;
  isMulti?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  value?: string | string[];
  className?: string;
  setOptions?: (estimateItems: GetEstimateItemDto[]) => void;
  onChange?: (option: Option | MultiValue<Option> | null | undefined) => void;
}

export default (props: EstimateItemSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [estimateItems, setEstimateItems] = useState<
    GetEstimateItemDto[] | undefined
  >();

  useEffect(() => {
    let mounted = true;

    if (props.projectId && props.clientId) {
      pok.estimateItems
        .findByProjectAndClient(props.projectId, props.clientId)
        .then(data => {
          if (mounted) {
            props.setOptions?.(data);
            setEstimateItems(data);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      setEstimateItems([]);
    }

    return () => {
      mounted = false;
    };
  }, [pok.estimateItems, notifications, props]);

  useEffect(() => {
    if (estimateItems && props.onChange) {
      if (props.isMulti) {
        const newEstimateItems = estimateItems?.filter(({ id }) =>
          props.value?.includes(id),
        );
        if ((props.value?.length || 0) !== (newEstimateItems?.length || 0)) {
          props.onChange(
            newEstimateItems.map(
              v => ({ value: v.id, label: v.position?.name }) as Option,
            ),
          );
        }
      } else if (
        props.value &&
        !estimateItems.find(team => team.id === props.value)
      ) {
        props.onChange(null);
      }
    }
  }, [estimateItems, props]);

  const provider = async (text: string) => {
    const values =
      estimateItems?.filter(v =>
        v.position.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
      ) || [];
    return values.map(v => ({
      label:
        `${v.position?.name || ''}, ${momencik(v.estimateByMonth.date, 'YYYY-MM')},` +
        ` ${v.estimateByMonth?.profile?.name} ` +
        `(sprzedaż: ${numberFormatter(v.salesNetTotal, true)}, zakup: ${numberFormatter(v.purchaseNetTotal, true)})`,
      value: v.id,
    }));
  };

  if (!estimateItems) {
    return <Skeleton />;
  }

  return (
    <Selector
      uniqueKey={
        'estimateItem-' +
        JSON.stringify(estimateItems) +
        JSON.stringify(props.clientId)
      }
      className={props.className}
      isMulti={props.isMulti}
      readOnly={props.readOnly || !estimateItems || !props.projectId}
      creatable={false}
      clearable={props.clearable}
      provider={provider}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
