/*eslint max-lines-per-function: ["error", 200]*/
/*eslint complexity: ["error", 15]*/
import React, { useState, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import * as BootstrapTypes from 'react-bootstrap/types';

import { GetDictionaryDto as Dictionary } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface DictionarySelectorProps {
  readOnly?: boolean;
  creatable?: boolean;
  value?: string;
  placeholder?: string;
  dictionary: string;
  provider?: (text: string) => Promise<Option[]>;
  onChange?: (option: Option) => void;
  maxDepth?: number;
  firstLevelShortname?: string;
  initialShortname?: string;
  clearable?: boolean;
  hideBadges?: boolean;
  badgeBg?: string;
  badgeText?: BootstrapTypes.Color;
  showInfoIcon?: boolean;
  infoIconTitle?: string;
  sortFunction?: (a: Dictionary, b: Dictionary) => number;
}

const getDictValue = (values: Dictionary[], id: string) => {
  const vals = values.filter(v => v.id === id);
  if (vals.length !== 1) {
    throw new Error('Dictionary value id is not OK: ' + id);
  }
  return vals[0];
};

const getDictValueByShortName = (values: Dictionary[], shortname: string) => {
  const vals = values.filter(v => v.shortname === shortname);
  if (vals.length !== 1) {
    throw new Error('Dictionary value shortname unknown ' + shortname);
  }
  return vals[0];
};

const sublevel = (values: Dictionary[], dictId: string, maxDepth?: number) => {
  if (maxDepth) {
    let level = 1;
    let id: string | undefined = dictId;
    while (id) {
      const value = getDictValue(values, id);
      if (!value.parentId) {
        id = undefined;
      } else {
        id = value.parentId;
        level++;
      }
    }

    if (level === maxDepth) {
      return false;
    }
  }

  const sublevel = values.filter(v => v.parentId === dictId && v.active);
  return sublevel.length > 0;
};

export default (props: DictionarySelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [values, setValues] = useState<Dictionary[]>([]);
  const [selectedEntries, setSelectedEntries] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [language, setLanguage] = useState<string>();

  useEffect(() => {
    setLanguage(pok.getLanguage());
  }, [pok]);

  const provider = useMemo(
    () => (parentId: string | null) => {
      return async (text: string) => {
        let ourValues = values
          .filter(v => v.parentId === parentId)
          .filter(v =>
            language === 'PL'
              ? v.value?.toLocaleLowerCase().includes(text.toLocaleLowerCase())
              : v.valueEn
                  ?.toLocaleLowerCase()
                  .includes(text.toLocaleLowerCase()),
          )
          .filter(v => v.active);

        if (props.sortFunction) {
          ourValues = ourValues.sort(props.sortFunction);
        }

        return ourValues.map(v => {
          return {
            label: language === 'PL' ? v.value : v.valueEn || '',
            value: v.id || '',
            ...(v.shortname && { badge: v.shortname }),
          };
        });
      };
    },
    [values, language, props.sortFunction],
  );

  const selectEntry = (option: Option, parentId: string | null) => {
    const newSelectedEntries: Option[] = [];
    // all levels above current selection are still there
    // all below we lose
    for (const selected of selectedEntries) {
      const dictValue = getDictValue(values, selected.value);
      if (dictValue.parentId !== parentId) {
        newSelectedEntries.push(selected);
      } else {
        break;
      }
    }

    if (option.value) {
      newSelectedEntries.push(option);
    }

    setSelectedEntries(newSelectedEntries);

    const leaf = !sublevel(values, option.value, props.maxDepth);
    if (leaf && option.value) {
      setSelectedOption(option);
      if (props.onChange) {
        props.onChange(option);
      }
    } else {
      const nv = null as unknown as string;
      setSelectedOption({ value: nv, label: nv });
      if (newSelectedEntries.length === 0 && props.onChange) {
        //for the first level, we inform about clearing the value (no need to leave the field).
        // In the case of lower levels, we allow manipulation of values until the last option is selected or the field is left
        props.onChange(option);
      }
    }
  };

  useEffect(() => {
    if (!values.length) {
      return;
    }
    const entries: Option[] = [];
    let dictId: string | undefined = props.value;
    while (dictId) {
      const val = getDictValue(values, dictId);
      entries.unshift({
        value: dictId,
        label: language === 'PL' ? val.value : val.valueEn || '',
        badge: val.shortname,
      });
      dictId = val.parentId;
    }
    if (props.firstLevelShortname && entries.length === 0) {
      const val = getDictValueByShortName(values, props.firstLevelShortname);
      entries.push({
        value: val.id,
        label: language === 'PL' ? val.value : val.valueEn,
        badge: val.shortname,
      });
    }
    if (props.initialShortname && entries.length === 0 && props.onChange) {
      const val = getDictValueByShortName(values, props.initialShortname);
      props.onChange({
        value: val.id,
        label: language === 'PL' ? val.value : val.valueEn,
        badge: val.shortname,
      });
    }
    setSelectedEntries(entries);
  }, [props, values, language]);

  useEffect(() => {
    /// TODO: we could optimize it in the near future
    /// getting data from database could be on start and after change
    let mounted = true;
    pok.dictionaries
      .getByDictionaryType(props.dictionary)
      .then(values => {
        if (mounted) {
          setValues(values);
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });

    return () => {
      mounted = false;
    };
  }, [props.dictionary, pok.dictionaries, notifications, language]);

  if (!values.length) {
    return <Skeleton />;
  }

  const visibleLevels = () => {
    const levels = selectedEntries.map(entry => {
      const dictValue = getDictValue(values, entry.value);
      return {
        parentId: dictValue.parentId || null,
        label: entry.label,
        badge: entry.badge,
      };
    });

    if (selectedEntries.length === 0) {
      levels.push({ parentId: null, label: '', badge: undefined });
    } else {
      const last = selectedEntries[selectedEntries.length - 1];
      if (sublevel(values, last.value, props.maxDepth)) {
        levels.push({ parentId: last.value, label: '', badge: '' });
      }
    }

    if (props.maxDepth) {
      return levels.slice(0, props.maxDepth);
    }

    return levels;
  };

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const focusInsideDiv = event.currentTarget.contains(event.relatedTarget);
    if (selectedOption && props.onChange && !focusInsideDiv) {
      if (props.value || selectedOption.value) {
        props.onChange(selectedOption);
      }
    }
  };

  return (
    <div onBlur={blurHandler}>
      {visibleLevels().map(level => (
        <Selector
          key={language + (level.parentId || 'zero-level')}
          className={level.parentId ? 'mt-1' : ''}
          readOnly={
            props.readOnly ||
            (level.parentId === null && props.firstLevelShortname !== undefined)
          }
          creatable={false}
          provider={provider(level.parentId)}
          value={level.label}
          valueBadge={level.badge}
          badgeBg={props.badgeBg}
          badgeText={props.badgeText}
          hideBadges={props.hideBadges}
          onChange={option => selectEntry(option as Option, level.parentId)}
          clearable={props.clearable}
          showInfoIcon={props.showInfoIcon}
          infoIconTitle={props.infoIconTitle}
        />
      ))}
    </div>
  );
};
