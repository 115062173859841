/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProfileDto } from './GetProfileDto';
import {
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './GetProfileDto';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';

/**
 * 
 * @export
 * @interface GetEstimateByMonthDto
 */
export interface GetEstimateByMonthDto {
    /**
     * 
     * @type {string}
     * @memberof GetEstimateByMonthDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetEstimateByMonthDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateByMonthDto
     */
    status: GetEstimateByMonthDtoStatusEnum;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetEstimateByMonthDto
     */
    profile: GetProfileDto;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetEstimateByMonthDto
     */
    project: GetProjectDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateByMonthDto
     */
    isSalesBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateByMonthDto
     */
    isPurchaseBlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateByMonthDto
     */
    active: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetEstimateByMonthDto
     */
    statusTs: Date;
}


/**
 * @export
 */
export const GetEstimateByMonthDtoStatusEnum = {
    New: 'New',
    AcceptedByClient: 'AcceptedByClient',
    ReadyForInvoice: 'ReadyForInvoice',
    Invoiced: 'Invoiced',
    Corrected: 'Corrected',
    Settled: 'Settled',
    Cancelled: 'Cancelled'
} as const;
export type GetEstimateByMonthDtoStatusEnum = typeof GetEstimateByMonthDtoStatusEnum[keyof typeof GetEstimateByMonthDtoStatusEnum];


/**
 * Check if a given object implements the GetEstimateByMonthDto interface.
 */
export function instanceOfGetEstimateByMonthDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "profile" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "isSalesBlocked" in value;
    isInstance = isInstance && "isPurchaseBlocked" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "statusTs" in value;

    return isInstance;
}

export function GetEstimateByMonthDtoFromJSON(json: any): GetEstimateByMonthDto {
    return GetEstimateByMonthDtoFromJSONTyped(json, false);
}

export function GetEstimateByMonthDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEstimateByMonthDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'status': json['status'],
        'profile': GetProfileDtoFromJSON(json['profile']),
        'project': GetProjectDtoFromJSON(json['project']),
        'isSalesBlocked': json['isSalesBlocked'],
        'isPurchaseBlocked': json['isPurchaseBlocked'],
        'active': json['active'],
        'statusTs': (new Date(json['statusTs'])),
    };
}

export function GetEstimateByMonthDtoToJSON(value?: GetEstimateByMonthDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString()),
        'status': value.status,
        'profile': GetProfileDtoToJSON(value.profile),
        'project': GetProjectDtoToJSON(value.project),
        'isSalesBlocked': value.isSalesBlocked,
        'isPurchaseBlocked': value.isPurchaseBlocked,
        'active': value.active,
        'statusTs': (value.statusTs.toISOString()),
    };
}

