/*eslint complexity: ["error", 15]*/
import React, { useState } from 'react';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';

import { GetLastEditorDto } from '../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../common/hooks/useNotifications';
import { useTranslation } from '../../common/hooks/useTranslation';

export default (props: {
  id: string;
  className?: string;
  iconSize?: number;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  method: (id: string) => Promise<GetLastEditorDto>;
  additionalText?: string;
}) => {
  const [lastEditor, setLastEditor] = useState<GetLastEditorDto>();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('commonLng');

  const getLastEditor = () => {
    if (!props.id) {
      return;
    }
    setLastEditor(undefined);
    props
      .method(props.id)
      .then(info => {
        setLastEditor(info);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <OverlayTrigger
      placement={props.placement ?? 'top'}
      onEnter={getLastEditor}
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3" className="text-center">
            {t(tk.common.history)}
          </Popover.Header>
          <Popover.Body className="text-center">
            {lastEditor ? (
              <>
                {props.additionalText && lastEditor.additionalTs && (
                  <>
                    <h6>{props.additionalText}:</h6>
                    {t(tk.common.who)}: {lastEditor.additionalOwner} <br />
                    {t(tk.common.when)}:{' '}
                    {lastEditor.additionalTs
                      ? moment(lastEditor.additionalTs).format('LLL')
                      : t(tk.common.noInformation)}
                    <hr />
                  </>
                )}
                <h6>{t(tk.common.lastModification)}:</h6>
                {t(tk.common.who)}:{' '}
                {lastEditor.owner || t(tk.common.noInformation)} <br />
                {t(tk.common.when)}:{' '}
                {lastEditor.ts
                  ? moment(lastEditor.ts).format('LLL')
                  : t(tk.common.noInformation)}
                {lastEditor.creator && (
                  <>
                    <hr />
                    <h6>{t(tk.common.creation)}:</h6>
                    {t(tk.common.who)}: {lastEditor.creator} <br />
                    {t(tk.common.when)}:{' '}
                    {lastEditor.creationTs
                      ? moment(lastEditor.creationTs).format('LLL')
                      : t(tk.common.noInformation)}
                  </>
                )}
              </>
            ) : (
              <Spinner animation="border" variant="secondary" />
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <Icon.InfoCircle
        className={props.className ?? 'last-editor'}
        size={props.iconSize ?? 22}
      />
    </OverlayTrigger>
  );
};
