/* eslint-disable  import/named */
import React, { useState, useEffect, useRef, KeyboardEvent } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import {
  StylesConfig,
  components,
  Options,
  OptionsOrGroups,
  GroupBase,
  MultiValue,
} from 'react-select';
import Badge from 'react-bootstrap/Badge';
import * as BootstrapTypes from 'react-bootstrap/types';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import SelectOptionInfo from './SelectOptionInfo';

export interface Option {
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label: any;
  badge?: string;
  badgeBgColor?: string;
  badgeText?: BootstrapTypes.Color;
  new?: boolean;
}

export interface ReactSelectOption {
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label: any;
  badge?: string;
  badgeBgColor?: string;
  badgeText?: BootstrapTypes.Color;
  __isNew__?: boolean;
}

type Provider = (text: string) => Promise<Option[]>;
type ProviderForDel = (id: string) => Promise<Option>;

export interface SelectProps {
  uniqueKey?: string;
  isMulti?: boolean;
  className?: string;
  readOnly?: boolean;
  creatable?: boolean;
  clearable?: boolean;
  value?: string | string[];
  valueOption?: Option | MultiValue<Option>;
  valueBadge?: string;
  placeholder?: string;
  hideBadges?: boolean;
  hideLabels?: boolean;
  badgeBg?: string;
  badgeBgColor?: string;
  badgeText?: BootstrapTypes.Color;
  noOptionsMessage?: (arg: { inputValue: string }) => React.ReactNode;
  provider?: Provider;
  deletedValueName?: ProviderForDel;
  onChange?: (option: Option | MultiValue<Option>) => void;
  isValidNewOption?: (
    inputValue: string,
    value: Options<ReactSelectOption>,
    options: OptionsOrGroups<ReactSelectOption, GroupBase<ReactSelectOption>>,
  ) => boolean;
  showInfoIcon?: boolean;
  infoIconTitle?: string;
}

export const defaultSelectorLimit = 20;
const nvm = null as unknown as string;

const OptionBadge = (props: {
  badge: string | undefined;
  badgeBgColor?: string;
  bg: string;
  text: BootstrapTypes.Color;
}) => {
  return (
    <>
      {props.badge && (
        <>
          {props.badgeBgColor && (
            <Badge
              bg=""
              style={{ backgroundColor: props.badgeBgColor }}
              text={props.text}
            >
              {props.badge}
            </Badge>
          )}
          {!props.badgeBgColor && (
            <Badge bg={props.bg} text={props.text}>
              {props.badge}
            </Badge>
          )}{' '}
        </>
      )}
    </>
  );
};

const prepareOption = async (
  value: string | undefined,
  valueOption: Option | undefined,
  badge: string | undefined,
  allOptions: Option[],
  deletedValueName: ProviderForDel | undefined,
) => {
  const foundOptionLabel = allOptions?.find(
    option => option.value === value,
  )?.label;
  if (
    value !== undefined &&
    foundOptionLabel === undefined &&
    deletedValueName
  ) {
    valueOption = await deletedValueName(value);
  }
  return {
    value: value ? (value as string) : '',
    label:
      foundOptionLabel ||
      (valueOption?.value === value ? valueOption?.label : null) ||
      (value ? (value as string) : null) ||
      '',
    badge,
  };
};

const shouldShowInfoIcon = (
  props: SelectProps,
  value: Option | MultiValue<Option> | undefined,
) =>
  props.showInfoIcon &&
  !props.isMulti &&
  !Array.isArray(value) &&
  (value as Option)?.label.length > 0;

// eslint-disable-next-line max-lines-per-function,complexity
export const Selector: React.FC<SelectProps> = props => {
  const [value, setValue] = useState<Option | MultiValue<Option> | undefined>(
    props.valueOption,
  );
  const [allOptions, setAllOptions] = useState<Option[]>([]);
  const selectRef = useRef(null);
  const notifications = useNotifications();

  const onChange = (
    choice: ReactSelectOption | null | MultiValue<ReactSelectOption>,
  ) => {
    if (!choice) {
      if (props.onChange) {
        props.onChange({ value: nvm, label: nvm, new: true });
      }
      setValue(null as unknown as Option);
      return;
    }

    let value;
    if (props.isMulti) {
      value = (choice as ReactSelectOption[]).map(option => ({
        value: option.value,
        label: option.label,
        badge: option.badge,
        new: option.__isNew__ || false,
      }));
    } else {
      const ch = choice as ReactSelectOption;
      value = {
        value: ch.value,
        label: ch.label,
        badge: ch.badge,
        new: ch.__isNew__ || false,
      };
    }

    if (props.onChange) {
      props.onChange(value);
    }
    setValue(value);
  };

  useEffect(() => {
    if (!props.value && !props.valueBadge) {
      if (props.isMulti) {
        setValue([] as Option[]);
      } else {
        setValue(null as unknown as Option);
      }
      return;
    }
    if (props.isMulti) {
      const preparedOptions =
        (props.value as string[])
          ?.map(propsValue => {
            let foundValue = allOptions?.find(
              option => option.value === propsValue,
            );
            if (!foundValue && props.valueOption) {
              foundValue = (props.valueOption as MultiValue<Option>)?.find(
                option => option.value === propsValue,
              );
            }
            if (foundValue) {
              return {
                value: foundValue.value || '',
                label: foundValue.label || foundValue.value || '',
                badge: foundValue.badge,
                new: !!foundValue.value,
              };
            }
            return null;
          })
          ?.filter(val => val) || [];
      setValue(preparedOptions as MultiValue<Option>);
    } else {
      prepareOption(
        props.value as string,
        props.valueOption as Option,
        props.valueBadge,
        allOptions,
        props.deletedValueName,
      )
        .then(v => setValue(v))
        .catch(err => notifications.caughtError(err));
    }
  }, [
    props.value,
    allOptions,
    props.valueOption,
    props.valueBadge,
    props.isMulti,
    props.deletedValueName,
    notifications,
  ]);

  const styling: StylesConfig<
    ReactSelectOption,
    boolean,
    GroupBase<ReactSelectOption>
  > = {
    option: (provided, state) => {
      return {
        ...provided,
        ...(state.data.__isNew__ && { color: 'blue' }),
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        ...(state.isDisabled && { backgroundColor: '#e9ecef' }),
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        ...(state.isDisabled && { color: '#212529' }),
      };
    },
    input: provided => {
      return {
        ...provided,
        overflow: 'hidden',
      };
    },
  };

  const tryToSelectNewOptionIfFocused = () => {
    if (
      props.isMulti ||
      !selectRef.current ||
      !selectRef.current['state'] ||
      !selectRef.current['state']['focusedOption']
    ) {
      return;
    }

    const option = selectRef.current['state'][
      'focusedOption'
    ] as ReactSelectOption;
    if (option.__isNew__) {
      onChange(option);
    }
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    const input = evt.target as HTMLInputElement;
    const len = input.value.length;

    switch (evt.key) {
      case 'Home':
        evt.preventDefault();
        if (evt.shiftKey) {
          input.selectionStart = 0;
        } else {
          input.setSelectionRange(0, 0);
        }
        break;
      case 'End':
        evt.preventDefault();
        if (evt.shiftKey) {
          input.selectionEnd = len;
        } else {
          input.setSelectionRange(len, len);
        }
        break;
    }
  };

  const bg = props.badgeBg || 'warning';
  const badgeBgColor = props.badgeBgColor;
  const text = props.badgeText || 'dark';
  const showBadges = !props.hideBadges;
  const showLabels = !props.hideLabels;
  const showTooltip = shouldShowInfoIcon(props, value);
  const { t, tk } = useTranslation('commonLng');

  return (
    <div className={showTooltip ? 'd-flex flex-row align-items-center' : ''}>
      <div style={{ width: showTooltip ? 'calc(100% - 50px)' : 'unset' }}>
        {props.creatable ? (
          <AsyncCreatableSelect
            key={props.uniqueKey}
            isMulti={props.isMulti}
            ref={selectRef}
            cacheOptions
            loadOptions={text =>
              props.provider &&
              props.provider(text).then(opts => {
                setAllOptions(
                  allOptions.concat(
                    opts.filter(
                      opt =>
                        !allOptions.some(option => option.value === opt.value),
                    ),
                  ),
                );
                return opts;
              })
            }
            defaultOptions
            isClearable={props.clearable}
            placeholder={
              props.placeholder
                ? props.placeholder
                : !props.readOnly
                  ? t(tk.component.pointOut)
                  : ''
            }
            className={props.className}
            classNamePrefix="pok-react-select"
            onChange={onChange}
            value={value}
            isDisabled={props.readOnly}
            isValidNewOption={props.isValidNewOption}
            formatCreateLabel={text => text}
            styles={styling}
            noOptionsMessage={
              props.noOptionsMessage || (() => t(tk.component.noValues))
            }
            onKeyDown={handleKeyDown}
            onBlur={tryToSelectNewOptionIfFocused}
          />
        ) : (
          <AsyncSelect
            key={props.uniqueKey}
            isMulti={props.isMulti}
            cacheOptions
            loadOptions={text =>
              props.provider &&
              props.provider(text).then(opts => {
                setAllOptions(
                  allOptions.concat(
                    opts.filter(
                      opt =>
                        !allOptions.some(option => option.value === opt.value),
                    ),
                  ),
                );
                return opts;
              })
            }
            defaultOptions
            isClearable={props.clearable}
            className={props.className}
            classNamePrefix="pok-react-select"
            placeholder={
              props.placeholder
                ? props.placeholder
                : !props.readOnly
                  ? t(tk.component.pointOut)
                  : ''
            }
            onChange={onChange}
            value={value}
            isDisabled={props.readOnly}
            noOptionsMessage={
              props.noOptionsMessage || (() => t(tk.component.noValues))
            }
            styles={styling}
            onKeyDown={handleKeyDown}
            components={{
              SingleValue: props => (
                <components.SingleValue {...props}>
                  {showBadges && (
                    <OptionBadge
                      badge={props.data.badge}
                      badgeBgColor={badgeBgColor}
                      bg={bg}
                      text={text}
                    />
                  )}
                  {showLabels && props.data.label}
                </components.SingleValue>
              ),
              Option: props => (
                <components.Option
                  {...props}
                  className="pok-react-select-option"
                >
                  {showBadges && (
                    <OptionBadge
                      badge={props.data.badge}
                      badgeBgColor={props.data.badgeBgColor}
                      bg={bg}
                      text={text}
                    />
                  )}
                  {showLabels && props.data.label}
                </components.Option>
              ),
            }}
          />
        )}
      </div>
      {showTooltip && (
        <SelectOptionInfo
          label={(value as Option)?.label}
          title={props.infoIconTitle}
        />
      )}
    </div>
  );
};
