import React from 'react';
import { Accordion, Table } from 'react-bootstrap';

import {
  GetEstimateItemDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import mathUtils from '../../../utils/mathUtils';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ServiceActionAccordionProps {
  service: string;
  items?: GetEstimateItemDto[];
  idx: string;
  project: GetProjectDto;
}

export const ServiceActionAccordion: React.FC<
  ServiceActionAccordionProps
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const showMargin = !!props.project?.purchaser?.company?.showMargin;
  const filteredItems = props.items?.filter(
    item => item.service === props.service,
  );
  const salesNetTotalSum =
    filteredItems?.reduce(
      (prev, curr) => prev + mathUtils.parse(curr.salesNetTotal),
      0,
    ) || 0;
  const purchaseNetTotalSum =
    filteredItems?.reduce(
      (prev, curr) => prev + mathUtils.parse(curr.purchaseNetTotal),
      0,
    ) || 0;
  const margin = mathUtils.subtract(salesNetTotalSum, purchaseNetTotalSum);

  const generateTitle = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-3">
            <b>{props.service}</b>
          </div>
          <div className={`col-${showMargin ? 3 : 5}`}></div>
          <div className="col-2 amounts-align-right">
            `${t(tk.projects.sales)}: `
            <b>{numberFormatter(salesNetTotalSum, true)}</b>{' '}
          </div>
          <div className="col-2 amounts-align-right">
            `${t(tk.projects.costs)}: `
            <b>{numberFormatter(purchaseNetTotalSum, true)}</b>{' '}
          </div>
          {showMargin && (
            <div className="col-2 amounts-align-right">
              `${t(tk.projects.margin)}: `<b>{numberFormatter(margin, true)}</b>{' '}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Accordion.Item eventKey={props.idx}>
      <Accordion.Header>{generateTitle()}</Accordion.Header>
      <Accordion.Body>
        <Table bordered striped>
          <thead>
            <tr>
              <th className="col-2">{t(tk.projects.action)}</th>
              <th className="col-3">{t(tk.common.name)}</th>
              <th className="col-2">{t(tk.common.month)}</th>
              <th className="col-2">{t(tk.projects.sales)}</th>
              <th className="col-2">{t(tk.projects.costs)}</th>
              {showMargin && <th className="col-2">{t(tk.projects.margin)}</th>}
            </tr>
          </thead>
          <tbody>
            {filteredItems &&
              filteredItems
                .sort((a, b) => {
                  const names = (a.action || '').localeCompare(b.action || '');
                  if (names !== 0) {
                    return names;
                  }
                  return (
                    a.estimateByMonth.date.getTime() -
                    b.estimateByMonth.date.getTime()
                  );
                })
                .map(item => (
                  <tr key={item.id}>
                    <td>{item.action ?? '-'}</td>
                    <td>{item.position.name}</td>
                    <td>{momencik(item.estimateByMonth.date, 'YYYY-MM')}</td>
                    <td className="amounts-align-right">
                      {numberFormatter(item.salesNetTotal, true)}
                    </td>
                    <td className="amounts-align-right">
                      {numberFormatter(item.purchaseNetTotal, true)}
                    </td>
                    {showMargin && (
                      <td className="amounts-align-right">
                        {numberFormatter(
                          mathUtils.subtract(
                            item.salesNetTotal,
                            item.purchaseNetTotal,
                          ),
                          true,
                        )}
                      </td>
                    )}
                  </tr>
                ))}
          </tbody>
          <tfoot className="grid-summary-foot">
            <tr>
              <td colSpan={3} className="bold">
                {t(tk.projects.sumUpperCase)}
              </td>
              <td className="bold amounts-align-right">
                {numberFormatter(salesNetTotalSum, true)}
              </td>
              <td className="bold amounts-align-right">
                {numberFormatter(purchaseNetTotalSum, true)}
              </td>
              {showMargin && (
                <td className="bold amounts-align-right">
                  {numberFormatter(
                    mathUtils.subtract(salesNetTotalSum, purchaseNetTotalSum),
                    true,
                  )}
                </td>
              )}
            </tr>
          </tfoot>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
};
