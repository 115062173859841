import React from 'react';
import { Form } from 'react-bootstrap';

import {
  CreateUpdateSpecialInvoicingDto,
  GetProjectDto,
  GetSpecialInvoicingDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import {
  SpecialInvoicingStatus,
  SpecialInvoicingStatusText,
} from '../../../common/pokCore/validation/schemas';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { SpecialInvoicingProjects } from './SpecialInvoicingProjects';

interface SpecialInvoicingProps {
  convertedSpecialInvoicing: CreateUpdateSpecialInvoicingDto;
  specialInvoicing?: GetSpecialInvoicingDto;
  propertyChange?: (obj: Partial<CreateUpdateSpecialInvoicingDto>) => void;
  readOnly: boolean;
  projects: GetProjectDto[];
  setProjects?: (projects: GetProjectDto[]) => void;
}

export const SpecialInvoicingForm: React.FC<SpecialInvoicingProps> = props => {
  const { t, tk } = useTranslation('applicationLng');
  return (
    <Form className="d-grid gap-4 my-3">
      <hr />
      <SpecialInvoicingProjects {...props} />
      <hr />
      <FormRow controlId="description" label={t(tk.app.applicationDescription)}>
        <DynamicTextarea
          value={props.convertedSpecialInvoicing?.description || ''}
          onChange={e =>
            props.propertyChange?.({ description: e.target.value })
          }
          disabled={props.readOnly}
          minRows={3}
        />
      </FormRow>
      <FormRow controlId="note" label={t(tk.app.financeNote)}>
        <DynamicTextarea
          value={props.convertedSpecialInvoicing?.note || ''}
          onChange={e => props.propertyChange?.({ note: e.target.value })}
          disabled={props.readOnly}
          minRows={3}
        />
      </FormRow>
      {props.specialInvoicing ? (
        <>
          <FormRow controlId="status" label={t(tk.common.status)}>
            <Form.Control
              type="text"
              value={
                SpecialInvoicingStatusText[props.specialInvoicing.status] || ''
              }
              disabled
            />
          </FormRow>
          {props.specialInvoicing.status ===
            SpecialInvoicingStatus.Rejected && (
            <FormRow controlId="rejectedNote" label={t(tk.app.rejectedNote)}>
              <DynamicTextarea
                value={props.specialInvoicing.rejectedNote || ''}
                disabled
              />
            </FormRow>
          )}
          {props.specialInvoicing.status ===
            SpecialInvoicingStatus.ToBeCorrected && (
            <FormRow
              controlId="correctionNote"
              label={t(tk.app.requiredChanges)}
            >
              <DynamicTextarea
                value={props.specialInvoicing.correctionNote || ''}
                disabled
              />
            </FormRow>
          )}
          {props.specialInvoicing.status ===
            SpecialInvoicingStatus.Accepted && (
            <FormRow
              controlId="financeNote"
              label={t(tk.app.acceptedFinanceNote)}
            >
              <DynamicTextarea
                value={props.specialInvoicing.financeNote || ''}
                disabled
              />
            </FormRow>
          )}
        </>
      ) : null}
    </Form>
  );
};
