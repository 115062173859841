export default {
  menu: {
    applications: 'Wnioski',
    specialInvoicingApplication: 'Wnioski o niestandardowe fakturowanie',
    purchaseInvoicesToBeClarified: 'Faktury zakupu do wyjaśnienia',
    purchaseInvoicesTv: 'Faktury TV',
    estimateItemPurchaseDeficiencies: 'Wyjaśnienia braków FVZ',
    orders: 'Zamówienia',
    ordersExecutions: 'Realizacje zamówień',
    finance: 'Finanse',
    salesInvoices: 'Faktury sprzedaży',
    salesInvoicesReadyToCorrection: 'Faktury sprzedaży do skorygowania',
    purchaseInvoices: 'Faktury zakupu',
    verifications: 'Weryfikacja',
    imports: 'Importy',
    specialInvoicingDecision: 'Wnioski o niestandardowe fakturowanie',
    blockade: 'Blokady',
    exportFVZToXml: 'Eksport FVZ do XML',
    exportFVSToXml: 'Eksport FVS do XML',
    exportFVSToSymphony: 'Eksport FVS do Symfonii Handel',
    clientsWithSymphony: 'Kontrahenci - Symfonia',
    reportsFinance: 'Zestawienia finanse',
    reportsFinanceAdministration: 'Finanse - administracja',
    bankAccounts: 'Konta bankowe',
    clients: 'Kontrahenci',
    clientsList: 'Lista kontrahentów',
    purchasers: 'Zamawiający',
    positions: 'Pozycje profili',
    budgets: 'Budżety',
    brands: 'Brandy',
    administrations: 'Administracja',
    companies: 'Spółki',
    employees: 'Pracownicy',
    teams: 'Zespoły',
    profiles: 'Profile mediaplanów',
    profileCategories: 'Kategorie profili',
    dictionaries: 'Słowniki',
    calendar: 'Kalendarz',
    roles: 'Role',
    employeesRoles: 'Uprawnienia',
    emailConfigs: 'Konfiguracja maili',
    jobConfig: 'Konfiguracja zadań cyklicznych',
    excelConfig: 'Konfiguracja importu',
    reports: 'Zestawienia',
    help: 'Pomoc',
    instructions: 'Instrukcje obsługi',
    procedures: 'Procedury',
    documentation: 'Dokumentacja',
    logout: 'Wyloguj',
  },
  docs: {
    addingPurchaseInvoices: 'Dodawanie i akceptacja faktur zakupu',
    settlementWithholdingTax: 'Rozliczanie podatku u źródła',
    settingUpProjects: 'Zakładanie kampanii i uzupełnianie wniosków',
    importFromProjectToProject: 'Import pozycji z kampanii do kampanii',
    projectOrders: 'Zamówienia do kampanii',
    orderFulfillment: 'Realizacja zamówienia (specjaliści)',
    acceptanceInvoices: 'Akceptacja faktur',
    importProjects: 'Import kampanii z arkuszy',
  },
} as const;
