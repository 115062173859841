/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateCompanyDto
 */
export interface CreateUpdateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    shortname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    clientId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCompanyDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    personalLanguage: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    logo: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateCompanyDto
     */
    projectMaxDaysDelay: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateCompanyDto
     */
    orderField: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    invoicesEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    footer: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    footerInEnglish: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCompanyDto
     */
    showMargin: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    templateType: CreateUpdateCompanyDtoTemplateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    mainCurrencyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    language: CreateUpdateCompanyDtoLanguageEnum;
}


/**
 * @export
 */
export const CreateUpdateCompanyDtoTemplateTypeEnum = {
    Poland: 'Poland',
    UnitedStates: 'UnitedStates',
    Germany: 'Germany'
} as const;
export type CreateUpdateCompanyDtoTemplateTypeEnum = typeof CreateUpdateCompanyDtoTemplateTypeEnum[keyof typeof CreateUpdateCompanyDtoTemplateTypeEnum];

/**
 * @export
 */
export const CreateUpdateCompanyDtoLanguageEnum = {
    Pl: 'PL',
    En: 'EN'
} as const;
export type CreateUpdateCompanyDtoLanguageEnum = typeof CreateUpdateCompanyDtoLanguageEnum[keyof typeof CreateUpdateCompanyDtoLanguageEnum];


/**
 * Check if a given object implements the CreateUpdateCompanyDto interface.
 */
export function instanceOfCreateUpdateCompanyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortname" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "personalLanguage" in value;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "projectMaxDaysDelay" in value;
    isInstance = isInstance && "orderField" in value;
    isInstance = isInstance && "invoicesEmail" in value;
    isInstance = isInstance && "footer" in value;
    isInstance = isInstance && "footerInEnglish" in value;
    isInstance = isInstance && "showMargin" in value;
    isInstance = isInstance && "templateType" in value;
    isInstance = isInstance && "mainCurrencyId" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function CreateUpdateCompanyDtoFromJSON(json: any): CreateUpdateCompanyDto {
    return CreateUpdateCompanyDtoFromJSONTyped(json, false);
}

export function CreateUpdateCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'shortname': json['shortname'],
        'clientId': json['clientId'],
        'active': json['active'],
        'personalLanguage': json['personalLanguage'],
        'color': json['color'],
        'logo': json['logo'],
        'projectMaxDaysDelay': json['projectMaxDaysDelay'],
        'orderField': json['orderField'],
        'invoicesEmail': json['invoicesEmail'],
        'footer': json['footer'],
        'footerInEnglish': json['footerInEnglish'],
        'showMargin': json['showMargin'],
        'templateType': json['templateType'],
        'mainCurrencyId': json['mainCurrencyId'],
        'language': json['language'],
    };
}

export function CreateUpdateCompanyDtoToJSON(value?: CreateUpdateCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'shortname': value.shortname,
        'clientId': value.clientId,
        'active': value.active,
        'personalLanguage': value.personalLanguage,
        'color': value.color,
        'logo': value.logo,
        'projectMaxDaysDelay': value.projectMaxDaysDelay,
        'orderField': value.orderField,
        'invoicesEmail': value.invoicesEmail,
        'footer': value.footer,
        'footerInEnglish': value.footerInEnglish,
        'showMargin': value.showMargin,
        'templateType': value.templateType,
        'mainCurrencyId': value.mainCurrencyId,
        'language': value.language,
    };
}

