/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateEstimateByMonthDto
 */
export interface CreateUpdateEstimateByMonthDto {
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateEstimateByMonthDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateByMonthDto
     */
    status: CreateUpdateEstimateByMonthDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateByMonthDto
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEstimateByMonthDto
     */
    projectId: string;
}


/**
 * @export
 */
export const CreateUpdateEstimateByMonthDtoStatusEnum = {
    New: 'New',
    AcceptedByClient: 'AcceptedByClient',
    ReadyForInvoice: 'ReadyForInvoice',
    Invoiced: 'Invoiced',
    Corrected: 'Corrected',
    Settled: 'Settled',
    Cancelled: 'Cancelled'
} as const;
export type CreateUpdateEstimateByMonthDtoStatusEnum = typeof CreateUpdateEstimateByMonthDtoStatusEnum[keyof typeof CreateUpdateEstimateByMonthDtoStatusEnum];


/**
 * Check if a given object implements the CreateUpdateEstimateByMonthDto interface.
 */
export function instanceOfCreateUpdateEstimateByMonthDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "profileId" in value;
    isInstance = isInstance && "projectId" in value;

    return isInstance;
}

export function CreateUpdateEstimateByMonthDtoFromJSON(json: any): CreateUpdateEstimateByMonthDto {
    return CreateUpdateEstimateByMonthDtoFromJSONTyped(json, false);
}

export function CreateUpdateEstimateByMonthDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateEstimateByMonthDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'status': json['status'],
        'profileId': json['profileId'],
        'projectId': json['projectId'],
    };
}

export function CreateUpdateEstimateByMonthDtoToJSON(value?: CreateUpdateEstimateByMonthDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'status': value.status,
        'profileId': value.profileId,
        'projectId': value.projectId,
    };
}

