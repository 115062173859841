/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterYearData
 */
export interface FilterYearData {
    /**
     * 
     * @type {number}
     * @memberof FilterYearData
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof FilterYearData
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterYearData
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterYearData
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterYearData
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterYearData
     */
    budget?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterYearData
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterYearData
     */
    clientId?: string;
    /**
     * 
     * @type {Date}
     * @memberof FilterYearData
     */
    saleDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FilterYearData
     */
    saleDateTo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FilterYearData
     */
    invoiceDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FilterYearData
     */
    invoiceDateTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FilterYearData
     */
    withPositions?: boolean;
}

/**
 * Check if a given object implements the FilterYearData interface.
 */
export function instanceOfFilterYearData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;

    return isInstance;
}

export function FilterYearDataFromJSON(json: any): FilterYearData {
    return FilterYearDataFromJSONTyped(json, false);
}

export function FilterYearDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterYearData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'budget': !exists(json, 'budget') ? undefined : json['budget'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'saleDateFrom': !exists(json, 'saleDateFrom') ? undefined : (new Date(json['saleDateFrom'])),
        'saleDateTo': !exists(json, 'saleDateTo') ? undefined : (new Date(json['saleDateTo'])),
        'invoiceDateFrom': !exists(json, 'invoiceDateFrom') ? undefined : (new Date(json['invoiceDateFrom'])),
        'invoiceDateTo': !exists(json, 'invoiceDateTo') ? undefined : (new Date(json['invoiceDateTo'])),
        'withPositions': !exists(json, 'withPositions') ? undefined : json['withPositions'],
    };
}

export function FilterYearDataToJSON(value?: FilterYearData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'companyId': value.companyId,
        'teamId': value.teamId,
        'type': value.type,
        'status': value.status,
        'budget': value.budget,
        'currency': value.currency,
        'clientId': value.clientId,
        'saleDateFrom': value.saleDateFrom === undefined ? undefined : (value.saleDateFrom.toISOString()),
        'saleDateTo': value.saleDateTo === undefined ? undefined : (value.saleDateTo.toISOString()),
        'invoiceDateFrom': value.invoiceDateFrom === undefined ? undefined : (value.invoiceDateFrom.toISOString()),
        'invoiceDateTo': value.invoiceDateTo === undefined ? undefined : (value.invoiceDateTo.toISOString()),
        'withPositions': value.withPositions,
    };
}

