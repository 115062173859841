import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { CompanySearch } from '../../components/Companies/CompanySearch';
import {
  CreateUpdateCompanyDto,
  GetCompanyDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { CompanyEditor } from '../../components/Companies/CompanyEditor';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/CompanyContext';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const CompaniesView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [companies, setCompanies] = useState<GetCompanyDto[]>([]);
  const [company, setCompany] = useState<GetCompanyDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t, tk } = useTranslation('administrationLng');

  useEffect(() => {
    pok.companies
      .getMany()
      .then(setCompanies)
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [pok.companies, notifications, refresh]);

  const handleAdd = () => {
    setShow(true);
    setCompany(undefined);
  };

  const addEditCompany = async (
    companyCU: CreateUpdateCompanyDto,
    id?: string,
  ) => {
    const status = await validate(companyCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.companies.update(id, companyCU);
        } else {
          await pok.companies.create(companyCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const getCompany = await pok.companies.getById(id);
    setCompany(getCompany);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.common.companies)}</Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.administration.companyNew)}
        </Button>
        <CompanySearch data={companies} onRowClick={handleClick} />
        <CompanyEditor
          id={company?.id}
          company={convert(company)}
          systemNumber={company?.systemNumber}
          onCompleted={addEditCompany}
          show={show}
          handleClose={handleClose}
        />
      </Card.Body>
    </Card>
  );
};
