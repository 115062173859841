import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { EmployeeRoleSearch } from '../../components/EmployeesRoles/EmployeeRoleSearch';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateEmployeeRoleDto,
  GetEmployeeRoleDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { EmployeeRoleEditor } from '../../components/EmployeesRoles/EmployeeRoleEditor';
import { validateEmployeeRoles } from '../../../common/pokCore/contexts/EmployeeRoleContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const EmployeesRolesView: React.FC = () => {
  const pok = usePokCore();
  const [employeeRole, setEmployeeRole] = useState<GetEmployeeRoleDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t, tk } = useTranslation('roleLng');

  const handleAdd = () => {
    setShow(true);
    setEmployeeRole(undefined);
  };

  const addEditEmployeeRoles = async (
    employeeId: string,
    employeeRoles: CreateUpdateEmployeeRoleDto[] | undefined,
  ) => {
    const status = await validateEmployeeRoles({
      employeeId: employeeId,
      rolesIds: (employeeRoles || []).map(o => o.roleId),
    });

    if (status.valid && employeeId && employeeRoles) {
      try {
        await pok.employeesRoles.createEmployeeWithRoles(
          employeeId,
          employeeRoles,
        );
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getEmployeeRole = await pok.employeesRoles.getById(id);
    setEmployeeRole(getEmployeeRole);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.permission.employeesRoles)}
        </Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.permission.employeeRoleNew)}
        </Button>
        <EmployeeRoleSearch
          getData={pok.employeesRoles.getAllByPortion}
          getCount={pok.employeesRoles.getAllCount}
          onRowClick={handleClick}
          refresh={refresh}
        />
        <EmployeeRoleEditor
          employeeId={employeeRole?.user.id}
          employeeName={employeeRole?.user?.name}
          handleClose={handleClose}
          handleDelete={handleDelete}
          show={show}
          onCompleted={addEditEmployeeRoles}
        />
      </Card.Body>
    </Card>
  );
};
