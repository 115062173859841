import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { SpecialInvoicingForm } from '../../components/SpecialInvoicing/SpecialInvoicingForm';
import useSpecialInvoicingData from '../../components/SpecialInvoicing/useSpecialInvoicingData';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { SpecialInvoicingDecisionForm } from '../../components/SpecialInvoicing/SpecialInvoicingDecisionForm';
import { SpecialInvoicingControls } from '../../components/SpecialInvoicing/SpecialInvoicingControls';
import { validateDecision } from '../../../common/pokCore/contexts/SpecialInvoicingContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { SpecialInvoicingStatus } from '../../../common/pokCore/validation/schemas';
import useIsAuthorized from '../../components/SpecialInvoicing/useIsAuthorized';
import { BadCompany } from '../../../common/components/NotAuthorized/BadCompany';
import { SubjectMemos } from '../../components/Memos/SubjectMemos';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const SpecialInvoicingDecisionEditorView: React.FC = () => {
  const {
    convertedSpecialInvoicing,
    specialInvoicing,
    projects,
    isLoading,
    decisionSpecialInvoicing,
    handleRefresh,
    propertyChangeDecision,
  } = useSpecialInvoicingData();
  const isAuthorized = useIsAuthorized(specialInvoicing);
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('applicationLng');

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const isInactive = !specialInvoicing?.active;
  const readOnly =
    [SpecialInvoicingStatus.Rejected, SpecialInvoicingStatus.Accepted].includes(
      specialInvoicing?.status as SpecialInvoicingStatus,
    ) || isInactive;

  const handleSave = async () => {
    if (!specialInvoicing) {
      return;
    }

    setSaveErrors([]);
    setShowSaveErrors(false);
    const status = await validateDecision(decisionSpecialInvoicing);
    if (!status.valid) {
      setShowSaveErrors(true);
      setSaveErrors(status.errors);
      return;
    }

    setIsLoadingSave(true);
    try {
      await pok.specialInvoicing.decision(
        specialInvoicing.id,
        decisionSpecialInvoicing,
      );
      void sendEmail(specialInvoicing.id);
      handleRefresh();
      notifications.saveCompleted();
    } catch (err) {
      setShowSaveErrors(true);
      setSaveErrors(await responseErrors(err as ResponseError));
    } finally {
      setIsLoadingSave(false);
    }
  };

  const sendEmail = async (id: string) => {
    pok.emails.sendSpecialInvoiceEmail(id).catch(err => {
      notifications.caughtError(err);
    });
  };

  if (isLoading) {
    return <Waiting />;
  }

  if (!isAuthorized) {
    return <BadCompany companyId={specialInvoicing?.company?.id} />;
  }

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title>
          {isInactive && <b>{t(tk.app.archive)} </b>}
          <span style={isInactive ? { textDecoration: 'line-through' } : {}}>
            {t(tk.app.specialInvoicingApplicationExecution, {
              sysNumber: specialInvoicing?.systemNumber,
            })}{' '}
          </span>
          {specialInvoicing && (
            <SubjectMemos
              subject={specialInvoicing}
              employeeId={specialInvoicing.creator?.id}
            />
          )}
        </Card.Title>
        <SpecialInvoicingForm
          convertedSpecialInvoicing={convertedSpecialInvoicing}
          projects={projects}
          readOnly
        />
        <SpecialInvoicingDecisionForm
          propertyChange={propertyChangeDecision}
          decisionSpecialInvoicing={decisionSpecialInvoicing}
          readOnly={readOnly}
        />
        <SpecialInvoicingControls
          id={specialInvoicing?.id}
          handleSave={handleSave}
          isLoadingSave={isLoadingSave}
          readOnly={readOnly}
        />
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
      </Card.Body>
    </Card>
  );
};
