/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';

/**
 * 
 * @export
 * @interface GetEmployeeWithRolesDto
 */
export interface GetEmployeeWithRolesDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    oneToolId: string;
    /**
     * 
     * @type {Date}
     * @memberof GetEmployeeWithRolesDto
     */
    ts: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeWithRolesDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeWithRolesDto
     */
    technical: boolean;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetEmployeeWithRolesDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    teamName: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    supervisor: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    manager: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmployeeWithRolesDto
     */
    position: string;
    /**
     * 
     * @type {Date}
     * @memberof GetEmployeeWithRolesDto
     */
    updatedDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmployeeWithRolesDto
     */
    roles: boolean;
}

/**
 * Check if a given object implements the GetEmployeeWithRolesDto interface.
 */
export function instanceOfGetEmployeeWithRolesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "oneToolId" in value;
    isInstance = isInstance && "ts" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "technical" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "login" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "teamName" in value;
    isInstance = isInstance && "supervisor" in value;
    isInstance = isInstance && "manager" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "updatedDate" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function GetEmployeeWithRolesDtoFromJSON(json: any): GetEmployeeWithRolesDto {
    return GetEmployeeWithRolesDtoFromJSONTyped(json, false);
}

export function GetEmployeeWithRolesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmployeeWithRolesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'oneToolId': json['oneToolId'],
        'ts': (new Date(json['ts'])),
        'active': json['active'],
        'technical': json['technical'],
        'company': GetCompanyDtoFromJSON(json['company']),
        'login': json['login'],
        'email': json['email'],
        'teamName': json['teamName'],
        'supervisor': json['supervisor'],
        'manager': json['manager'],
        'position': json['position'],
        'updatedDate': (new Date(json['updatedDate'])),
        'roles': json['roles'],
    };
}

export function GetEmployeeWithRolesDtoToJSON(value?: GetEmployeeWithRolesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'oneToolId': value.oneToolId,
        'ts': (value.ts.toISOString()),
        'active': value.active,
        'technical': value.technical,
        'company': GetCompanyDtoToJSON(value.company),
        'login': value.login,
        'email': value.email,
        'teamName': value.teamName,
        'supervisor': value.supervisor,
        'manager': value.manager,
        'position': value.position,
        'updatedDate': (value.updatedDate.toISOString()),
        'roles': value.roles,
    };
}

