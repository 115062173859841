/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCurrencyDto } from './GetCurrencyDto';
import {
    GetCurrencyDtoFromJSON,
    GetCurrencyDtoFromJSONTyped,
    GetCurrencyDtoToJSON,
} from './GetCurrencyDto';

/**
 * 
 * @export
 * @interface GetCompanyWithoutLogoDto
 */
export interface GetCompanyWithoutLogoDto {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyWithoutLogoDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyWithoutLogoDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyWithoutLogoDto
     */
    shortname: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCompanyWithoutLogoDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyWithoutLogoDto
     */
    personalLanguage: GetCompanyWithoutLogoDtoPersonalLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyWithoutLogoDto
     */
    color: string;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyWithoutLogoDto
     */
    projectMaxDaysDelay: number;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyWithoutLogoDto
     */
    orderField: number;
    /**
     * 
     * @type {GetCurrencyDto}
     * @memberof GetCompanyWithoutLogoDto
     */
    mainCurrency: GetCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyWithoutLogoDto
     */
    language: GetCompanyWithoutLogoDtoLanguageEnum;
}


/**
 * @export
 */
export const GetCompanyWithoutLogoDtoPersonalLanguageEnum = {
    Projects: 'Projects',
    Campaigns: 'Campaigns'
} as const;
export type GetCompanyWithoutLogoDtoPersonalLanguageEnum = typeof GetCompanyWithoutLogoDtoPersonalLanguageEnum[keyof typeof GetCompanyWithoutLogoDtoPersonalLanguageEnum];

/**
 * @export
 */
export const GetCompanyWithoutLogoDtoLanguageEnum = {
    Pl: 'PL',
    En: 'EN'
} as const;
export type GetCompanyWithoutLogoDtoLanguageEnum = typeof GetCompanyWithoutLogoDtoLanguageEnum[keyof typeof GetCompanyWithoutLogoDtoLanguageEnum];


/**
 * Check if a given object implements the GetCompanyWithoutLogoDto interface.
 */
export function instanceOfGetCompanyWithoutLogoDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortname" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "personalLanguage" in value;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "projectMaxDaysDelay" in value;
    isInstance = isInstance && "orderField" in value;
    isInstance = isInstance && "mainCurrency" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function GetCompanyWithoutLogoDtoFromJSON(json: any): GetCompanyWithoutLogoDto {
    return GetCompanyWithoutLogoDtoFromJSONTyped(json, false);
}

export function GetCompanyWithoutLogoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyWithoutLogoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'shortname': json['shortname'],
        'active': json['active'],
        'personalLanguage': json['personalLanguage'],
        'color': json['color'],
        'projectMaxDaysDelay': json['projectMaxDaysDelay'],
        'orderField': json['orderField'],
        'mainCurrency': GetCurrencyDtoFromJSON(json['mainCurrency']),
        'language': json['language'],
    };
}

export function GetCompanyWithoutLogoDtoToJSON(value?: GetCompanyWithoutLogoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'shortname': value.shortname,
        'active': value.active,
        'personalLanguage': value.personalLanguage,
        'color': value.color,
        'projectMaxDaysDelay': value.projectMaxDaysDelay,
        'orderField': value.orderField,
        'mainCurrency': GetCurrencyDtoToJSON(value.mainCurrency),
        'language': value.language,
    };
}

