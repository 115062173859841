import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetEstimateByMonthWithItemsDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { ProjectEstimatesStatusEnumText } from '../../../common/pokCore/validation/schemas';
import mathUtils from '../../../utils/mathUtils';
import numberFormatter from '../../../common/numberFormatter';
import { NewWindowButton } from '../PurchaseInvoices/NewWindowButton';

interface EstimateByMonthSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  isDelayed?: boolean;
  showTotalDifferenceColumn?: boolean;
  totalDifferenceLabel?: string;
  showNewWindowButton?: boolean;
}

const getSums = (data: GetEstimateByMonthWithItemsDto) => {
  let salesAmountToInvoice = 0;
  let purchaseAmountToAccept = 0;
  let salesNetTotal = 0;

  if (!data.estimateItems || data.estimateItems.length === 0) {
    return {
      salesAmountToInvoice: '-',
      purchaseAmountToAccept: '-',
      salesNetTotal: '-',
    };
  }

  data.estimateItems?.map(rec => {
    salesAmountToInvoice = mathUtils.add(
      salesAmountToInvoice,
      mathUtils.subtract(
        mathUtils.round(rec.salesNetTotal),
        mathUtils.round(rec.salesInvoicesSum),
      ),
    );
    purchaseAmountToAccept = mathUtils.add(
      purchaseAmountToAccept,
      mathUtils.subtract(
        mathUtils.round(rec.purchaseNetTotal),
        mathUtils.round(rec.purchaseInvoicesSum),
      ),
    );
    salesNetTotal = mathUtils.add(
      salesNetTotal,
      mathUtils.round(rec.salesNetTotal),
    );
  });
  return {
    salesAmountToInvoice: numberFormatter(salesAmountToInvoice, true),
    purchaseAmountToAccept: numberFormatter(purchaseAmountToAccept, true),
    salesNetTotal: numberFormatter(salesNetTotal, true),
  };
};

export const estimateByMonthForGrid = (
  estimateByMonth: GetEstimateByMonthWithItemsDto,
  blockedClick?: boolean,
) => {
  const sums = getSums(estimateByMonth);
  return {
    key: estimateByMonth.id,
    values: {
      'estimateByMonth.date': momencik(estimateByMonth.date, 'YYYY-MM'),
      'project.name': estimateByMonth.project.name,
      'responsibleOwner.name': estimateByMonth.project.responsibleOwner.name,
      'profile.name': estimateByMonth.profile.name,
      status_text: ProjectEstimatesStatusEnumText[estimateByMonth?.status],
      'project.number': estimateByMonth.project.number,
      'budget.name': estimateByMonth.project.purchaser?.budget.name,
      'team.name': estimateByMonth.project.projectTeams
        ?.map(({ team }) => team?.name)
        ?.join(', '),
      salesAmountToInvoice: sums.salesAmountToInvoice,
      purchaseAmountToAccept: sums.purchaseAmountToAccept,
      salesNetTotal: sums.salesNetTotal,
      newWindowButton: (
        <NewWindowButton
          projectId={estimateByMonth.project.id}
          estimates={true}
        />
      ),
    },
    readOnly: estimateByMonth.project.delayVerification && blockedClick,
  };
};

export const EstimateByMonthSearch: React.FC<
  EstimateByMonthSearchProps
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const columns: GridColumn[] = [
    ...(props.showNewWindowButton
      ? [
          {
            header: '',
            property: 'newWindowButton',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
    { header: t(tk.common.number), property: 'project.number' },
    { header: t(tk.common.name), property: 'project.name' },
    { header: t(tk.common.budget), property: 'budget.name' },
    { header: t(tk.common.profile), property: 'profile.name' },
    { header: t(tk.common.month), property: 'estimateByMonth.date' },
    { header: t(tk.common.status), property: 'status_text' },
    {
      header: t(tk.projects.responsiblePerson),
      property: 'responsibleOwner.name',
    },
    { header: t(tk.common.team), property: 'team.name' },
    {
      header: t(tk.projects.salesTotal),
      property: 'salesNetTotal',
      noSorting: true,
      noFilter: true,
    },
    {
      header: t(tk.estimates.amountToInvoice),
      property: 'salesAmountToInvoice',
      noSorting: true,
      noFilter: true,
    },
    {
      header: t(tk.estimates.amountToAccept),
      property: 'purchaseAmountToAccept',
      noSorting: true,
      noFilter: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      refresh={props.refresh}
      initialOrderColumn={'estimateByMonth.date'}
      initialOrderDirection={'DESC'}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.prLang.emptyProjectsList)}
      onRowClick={props.onRowClick}
      withHistory={true}
      withFilterInColumns={true}
    />
  );
};
