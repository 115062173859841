import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PolishClientFormFieldsProps {
  client: CreateUpdateClientDto | CreateUpdateApplicationDto;
  propertyChange?: (obj: Partial<CreateUpdateClientDto>) => void;
  findByNip?: (text: string) => void;
  readonly?: boolean;
}

const ROWLENGHT = 30;

export const PolishClientFormFields: React.FC<
  PolishClientFormFieldsProps
> = props => {
  const [textAreaRows, setTextAreaRows] = useState(1);
  const { t, tk } = useTranslation('clientLng');

  useEffect(() => {
    setTextAreaRows(
      props.client?.name && props.client?.name.length > ROWLENGHT ? 2 : 1,
    );
  }, [props.client?.name]);

  return (
    <>
      <FormRow controlId="name" label={t(tk.common.name)}>
        <Form.Control
          as="textarea"
          rows={textAreaRows}
          value={props.client?.name || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ name: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="shortname" label={t(tk.common.shortname)}>
        <Form.Control
          type="text"
          value={props.client?.shortname || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ shortname: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="nip" label={t(tk.client.nip)}>
        <div className="d-flex">
          <div className="flex-grow-1">
            <Form.Control
              type="text"
              value={props.client?.nip || ''}
              onChange={e =>
                props.propertyChange &&
                props.propertyChange({ nip: e.target.value?.trim() })
              }
              disabled={props.readonly}
            />
          </div>
          {!props.readonly && (
            <div className="flex-grow-0">
              <Button
                variant="outline-primary"
                onClick={() => {
                  props.findByNip && props.findByNip(props.client.nip);
                }}
              >
                <Icon.Search /> GUS
              </Button>
            </div>
          )}
        </div>
      </FormRow>

      <FormRow controlId="postcode" label={t(tk.client.postcode)}>
        <Form.Control
          type="text"
          value={props.client?.postcode || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ postcode: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="place" label={t(tk.client.place)}>
        <Form.Control
          type="text"
          value={props.client?.place || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ place: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="street" label={t(tk.client.street)}>
        <Form.Control
          type="text"
          value={props.client?.street || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ street: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="numbers" label={t(tk.client.numbers)}>
        <Form.Control
          type="text"
          value={props.client?.numbers || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ numbers: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>

      <FormRow controlId="flat" label={t(tk.client.flat)}>
        <Form.Control
          type="flat"
          value={props.client?.flat || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ flat: e.target.value })
          }
          disabled={props.readonly}
        />
      </FormRow>
    </>
  );
};
