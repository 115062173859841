import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import { TabsHistory } from '../../../app/components/TabsHistory';
import { OrdersExecutionSentTab } from '../../components/OrdersExecution/OrdersExecutionSentTab';
import { OrdersExecutionWaitingTab } from '../../components/OrdersExecution/OrdersExecutionWaitingTab';
import { OrdersExecutionCompletedTab } from '../../components/OrdersExecution/OrdersExecutionCompletedTab';
import { useTranslation } from '../../../common/hooks/useTranslation';

export enum OrdersExecutionTabs {
  SENT = 'sent',
  WAITING = 'waiting',
  COMPLETED = 'completed',
}

export const OrdersExecutionView: React.FC = () => {
  const { t, tk } = useTranslation('orderLng');
  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.orderExec.ordersExec)}</Card.Title>
        <TabsHistory
          activeKey={OrdersExecutionTabs.WAITING}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={OrdersExecutionTabs.WAITING}
            title={t(tk.order.waiting)}
            className="m-3"
          >
            <OrdersExecutionWaitingTab />
          </Tab>
          <Tab
            eventKey={OrdersExecutionTabs.COMPLETED}
            title={t(tk.order.completed)}
            className="m-3"
          >
            <OrdersExecutionCompletedTab />
          </Tab>
          <Tab
            eventKey={OrdersExecutionTabs.SENT}
            title={t(tk.order.sent)}
            className="m-3"
          >
            <OrdersExecutionSentTab />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
