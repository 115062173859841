import React from 'react';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';

import ProfileFieldName from './ProfileFieldName';

interface PurchaseParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const PurchaseParams: React.FC<PurchaseParamsProps> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.rcPurchase && (
        <ProfileFieldName
          controlId="rcPurchase"
          schema={schema?.rcPurchase}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.rcPurchase}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                rcPurchase: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
      {schema?.purchaseSurchargePercent && (
        <ProfileFieldName
          controlId="purchaseSurchargePercent"
          schema={schema?.purchaseSurchargePercent}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.purchaseSurchargePercent}
            percentage={true}
            decimalScale={12}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                purchaseSurchargePercent: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
      {schema?.purchaseDiscountPercent && (
        <ProfileFieldName
          controlId="purchaseDiscountPercent"
          schema={schema?.purchaseDiscountPercent}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.purchaseDiscountPercent}
            percentage={true}
            max={100}
            decimalScale={12}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                purchaseDiscountPercent: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
      {schema?.purchaseNet && (
        <ProfileFieldName
          controlId="purchaseNet"
          schema={schema?.purchaseNet}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.purchaseNet}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                purchaseNet: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
      {schema?.purchaseTechnicalCost && (
        <ProfileFieldName
          controlId="purchaseTechnicalCost"
          schema={schema?.purchaseTechnicalCost}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.purchaseTechnicalCost}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                purchaseTechnicalCost: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
    </>
  );
};
