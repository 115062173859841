export default {
  finance: {
    purchaseInvoices: 'Purchase invoices',
    FVZ: 'FVZ',
    readyForInvoices: 'Ready for invoices',
    corrected: 'Corrected',
    accountToInvoice: 'Account to invoice',
    lastModification: 'Last modification of item links',
  },
} as const;
