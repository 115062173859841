export default {
  estimates: {
    addProfile: 'Wybierz profil do dodania',
    selectProfile: 'Wybierz profil',
    selectMonth: 'Wybierz miesiąc',
    select: 'Proszę wskazać',
    noPositionSelected: 'Nie wybrano żadnych pozycji do importowania.',
    selectPositionsToImport: 'Wskaż pozycje do importowania na miesiąc',
    filterByProfile: 'Filtruj po profilu',
    filterByMonth: 'Filtruj po miesiącu',
    positionAccusative1: 'pozycję',
    positionAccusative2_4: 'pozycje',
    positionAccusative5: 'pozycji',
    importSelected: 'Importuj zaznaczone',
    importAll: 'Importuj wszystkie',
    withoutAmounts: 'Bez kwot',
    withAmounts: 'Z kwotami',
    clone: 'Klonuj',
    cloneSelectedEstimateByMonth: 'Klonuj wskazany profil na wybrany miesiąc',
    cloneEstimateByMonth: 'Klonuj miesięczny profil',
    saveStatus: 'Zapisz status',
    sales: 'Sprzedaż',
    purchase: 'Zakup',
    fvz: 'FVZ',
    fvs: 'FVS',
    fvzDiff: 'Różnica FVZ',
    margin: 'Marża',
    orderNumber: 'Nr zlecenia',

    noPositions: 'Brak pozycji do wyświetlenia.',
    month: 'Miesiąc',
    profile: 'Profil',
    parameters: 'Parametry',
    salesNetTotal: 'Netto sprzedaż total',
    purchaseNetTotal: 'Netto zakup total',
    positionName: 'Nazwa pozycji',
    amountToInvoice: 'Kwota do zafakturowania',
    amountToAccept: 'Kwota do zaakceptowania',

    globalSalesBlockade: 'Aktywna globalna blokada sprzedaży w miesiącu',
    globalPurchaseBlockade: 'Aktywna globalna blokada zakupu w miesiącu',
    addPosition: 'Dodaj pozycję',
    saveAddedPosition: 'Proszę zapisać dodaną wcześniej pozycję.',
    category: 'Kategoria',
    amounts: 'Kwoty',

    lastPositionModification: 'Ostatnia modyfikacja pozycji przez mediaplanera',
    deletePositionConfirmation: 'Czy na pewno usunąć widoczną pozycję?',
    deletePosition: 'Usuń pozycję',
    deleteNewPosition: 'Usuń nową pozycję',
    saveChanges: 'Zapisz zmiany',
    saveNewPosition: 'Zapisz nową pozycję',
    summary: 'Podsumowanie',
    positions: 'Pozycje',
    status: 'Status',
    applications: 'Wnioski',
    askDeleteProfile: 'Czy na pewno usunąć miesięczny profil?',
    deleteProfile: 'Usuń miesięczny profil',
    selectTargetMonth:
      'Proszę wskazać miesiąc docelowy (↑do wskazania na górze okienka↑).',
    addMonth: 'Dodaj miesiąc',
    importPositionFrom: 'Importuj pozycje z',

    //PAE
    paeImport: 'Import z PAE',
    openInPae: 'Otwórz w PAE',
    refreshData: 'Odśwież dane',
    paeImportWarning:
      'Uwaga. Importowany będzie tylko zakup -  sprzedaż nie zaimportuje się, ponieważ jest blokada sprzedaży na profilu.',
    paePositionsList:
      'Lista pozycji w PAE dla kampanii {{projectNo}} za {{date}}',
    purchaseForm: 'Forma zakupu',
    paePurchaseForm: 'Format z PAE',
    paePositionNumber: 'Nr pozycji z PAE',
    comment: 'Komentarz',

    issueDate: 'Data wystawienia',
    sellDate: 'Data sprzedaży',
    invoiceAmount: 'Kwota faktury',
    settledAmount: 'Kwota rozliczona',
    lastModification: 'Ostatnia modyfikacja',
    attachment: 'Załącznik',
    noFVZAssigned: 'Brak przypisanych faktur zakupu',

    noFVS: 'Brak wystawionych faktur sprzedaży',
    profileSalesBlockade: 'Aktywna blokada sprzedaży na profilu',
    profilePurchaseBlockade: 'Aktywna blokada zakupu na profilu',
    changeStatus: 'Czy na pewno zmienić status?',
    purchaseAmount: 'Kwota zakupu',
    noPositionsAssigned: 'Brak pozycji przypisanych do tego zespołu.',
  },

  projects: {
    for: 'Dla',
    from: 'Od',
    to: 'Do',
    leadTeam: 'Zespół prowadzący',
    dateRange: 'Zakres dat',
    find: 'Szukaj',
    parameters: 'Parametry',
    summary: 'Podsumowanie',
    orders: 'Zamówienia',
    ordersExecution: 'Realizacja zamówień',
    documents: 'Dokumenty',
    fvz: 'FVZ',
    fvs: 'FVS',
    services: 'Usługi',
    cancelRequestApproved: 'wniosek o anulowanie został zatwierdzony',
    country: 'Kraj/rynek',
    purchaserDocNumber: 'Numer dokumentu klienta',
    note: 'Notatka',
    responsiblePerson: 'Osoba odpowiedzialna',
    documentsControl: 'Kontrola dokumentów',
    cancelApplication: 'Wniosek o anulowanie',
    edit: 'Edycja',
    cancelApplicationSubmitted:
      'Dla {{project}} został złożony wniosek o anulowanie {{project}}.',
    cancelVerificationWaiting: 'Czeka na weryfikację wniosku o anulowanie',
    clone: 'Klonuj',
    withoutAmounts: 'Bez kwot',
    withAmounts: 'Z kwotami',
    applicationVerificationWaiting: 'Czeka na weryfikację wniosku',
    attachments: 'Załączniki',
    cancelReason: 'Powód anulowania',
    fillCancelReason: 'Proszę uzupełnić powód anulowania',
    saveApplication: 'Zapisz wniosek',
    createApplication: 'Utwórz wniosek',
    askRemoveApplication: 'Czy na pewno usunąć wniosek?',
    deleteApplication: 'Usuń wniosek',
    errCreateProjectSendApplication:
      'Założenie kampanii w wybranym okresie jest już zablokowane, wyślij wniosek o założenie kampanii.',
    applicationContent: 'Treść wniosku',
    sendApplication: 'Wyślij wniosek',
    salesNet: 'Netto sprzedaż',
    salesCommissionAmount: 'Prowizja sprzedaż',
    salesTotal: 'Total sprzedaż',
    salesInvoicesSum: 'Suma FVS',
    purchaseTotal: 'Total Zakup',
    purchaseInvoicesSum: 'Suma FVZ',
    margin: 'Marża',
    salesLock: 'Blokada sprzedaż',
    purchaseLock: 'Blokada zakup',
    purchaseDiff: 'Różnica zakup',
    noPositions: 'Brak pozycji',
    invoicesNumber: 'Numery faktur',
    activeProfiles: 'Aktywne profile',
    client: 'Kontrahent',
    salesInvoicesAmount: 'FVS netto',
    salesDiff: 'Różnica sprzedaż',
    purchaseInvoicesAmount: 'FVZ netto',
    complianceWithSalesTotal: 'Zgodność realizacji z total sprzedaż',
    parentProjectNumber: 'Kampania zleceniodawcy',
    askDownloadFVZ: 'Czy pobrać faktury FVZ dołączone do kampanii?',
    askDownloadFVZDespiteWarnings:
      'Czy pobrać faktury FVZ dołączone do kampanii pomimo następujących ostrzeżeń?',
    parentNumber: 'Nr zlecającego',
    sales: 'Sprzedaż',
    costs: 'Koszty',
    action: 'Działanie',
    sumUpperCase: 'SUMA',
    specialInvoicingApplication: 'Utwórz wniosek o niestandardowe fakturowanie',
  },
} as const;
