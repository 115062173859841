/*eslint max-lines-per-function: ["error", 180]*/
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { BadCompany } from '../../../common/components/NotAuthorized/BadCompany';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { GetPurchaseInvoiceExtendedDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { PurchaseInvoiceStatusEnum } from '../../../common/pokCore/validation/schemas';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { ConcurrencySignaler } from '../../../app/components/ConcurrencySignaler';
import { DataChangeScope } from '../../../common/pokCore/sockets/socket.datatypes';

import { PurchaseInvoiceEditorNotes } from './PurchaseInvoiceEditorNotes';
import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';
import useIsAuthorized from './useIsAuthorized';
import usePurchaseInvoiceData from './usePurchaseInvoiceData';
import buildPurchaseInvoiceTitle from './buildPurchaseInvoiceTitle';
import { PurchaseInvoiceGeneralTab } from './PurchaseInvoiceGeneralTab';
import { PurchaseInvoiceTeamsAndProjectsTab } from './PurchaseInvoiceTeamsAndProjectsTab';
import { PurchaseInvoiceEstimateItemPositions } from './PurchaseInvoiceEstimateItemPositions';
import usePurchaseInvoicePermissions from './usePurchaseInvoicePermissions';

interface PurchaseInvoiceViewProps {
  startingTab?: PurchaseInvoiceTabsEnum;
  onlyTV?: boolean;
}

interface TabProps {
  key: PurchaseInvoiceTabsEnum;
  title: PurchaseInvoiceTabsEnum;
  component: React.ReactNode;
  disabled?: boolean;
  notSaved?: boolean;
}

const checkIsReadOnly = (purchaseInvoice?: GetPurchaseInvoiceExtendedDto) => {
  if (!purchaseInvoice) {
    return false;
  }

  const isInActive = (purchaseInvoice.id && !purchaseInvoice.active) || false;
  const isApproved =
    !!purchaseInvoice.status &&
    purchaseInvoice.status !== PurchaseInvoiceStatusEnum.Open;

  return isInActive || isApproved;
};

const buildTabTitle = (
  key: PurchaseInvoiceTabsEnum,
  title: string,
  notSaved?: boolean,
) => {
  if (key === PurchaseInvoiceTabsEnum.NOTES) {
    return <i>{title}</i>;
  }

  return `${title} ${notSaved ? '(NIEZAPISANA)' : ''}`;
};

const getTabClassName = (notSaved?: boolean) =>
  notSaved ? 'not-saved-tab' : '';

export const PurchaseInvoiceView: React.FC<
  PurchaseInvoiceViewProps
> = props => {
  const { t, tk } = useTranslation('fvzLng');
  const {
    notSavedTab,
    setNotSavedTab,
    purchaseInvoice,
    extendedPurchaseInvoice,
    purchaseInvoiceId,
    teamsAndProjects,
    handleRefresh,
  } = usePurchaseInvoiceData();
  const { isFinanceUser } = usePurchaseInvoicePermissions();
  const isAuthorized = useIsAuthorized(extendedPurchaseInvoice);
  const isReadOnly = checkIsReadOnly(extendedPurchaseInvoice);
  const id = extendedPurchaseInvoice?.id || '';
  const [key, setKey] = useState({
    scope: DataChangeScope.purchaseInvoice,
    id: purchaseInvoiceId,
  });
  const [description, setDescription] = useState(
    'Faktura zakupu o nr sys. ' + purchaseInvoice?.systemNumber,
  );

  useEffect(() => {
    if (purchaseInvoice) {
      setKey({
        scope: DataChangeScope.purchaseInvoice,
        id: purchaseInvoiceId,
      });
      setDescription(
        'Faktura zakupu o nr sys. ' + purchaseInvoice?.systemNumber,
      );
    }
  }, [purchaseInvoice, purchaseInvoiceId]);

  const tabs: TabProps[] = useMemo(() => {
    const isTabBlocked = (tab: PurchaseInvoiceTabsEnum) =>
      (!!notSavedTab && notSavedTab !== tab) || isReadOnly;

    if (!purchaseInvoice || !teamsAndProjects) {
      return [];
    }

    const commonProps = {
      purchaseInvoice,
      extendedPurchaseInvoice,
      setNotSavedTab,
      handleRefresh,
    };

    return [
      {
        key: PurchaseInvoiceTabsEnum.GENERAL,
        title: PurchaseInvoiceTabsEnum.GENERAL,
        component: (
          <PurchaseInvoiceGeneralTab
            {...commonProps}
            readOnly={
              isTabBlocked(PurchaseInvoiceTabsEnum.GENERAL) || !isFinanceUser
            }
            changeAmountOwnerName={
              extendedPurchaseInvoice?.changeAmountsOwner?.name
            }
          />
        ),
        disabled: false,
      },
      {
        key: PurchaseInvoiceTabsEnum.TEAM_PROJECTS,
        title: t(tk.prLang[PurchaseInvoiceTabsEnum.TEAM_PROJECTS]),
        component: (
          <PurchaseInvoiceTeamsAndProjectsTab
            {...commonProps}
            teamsAndProjects={teamsAndProjects}
            readOnly={isTabBlocked(PurchaseInvoiceTabsEnum.TEAM_PROJECTS)}
          />
        ),
        disabled: !id,
      },
      {
        key: PurchaseInvoiceTabsEnum.ESTIMATE_ITEM_POSITIONS,
        title: t(tk.prLang[PurchaseInvoiceTabsEnum.ESTIMATE_ITEM_POSITIONS]),
        component: (
          <PurchaseInvoiceEstimateItemPositions
            {...commonProps}
            onlyTV={props.onlyTV}
            readOnly={isTabBlocked(
              PurchaseInvoiceTabsEnum.ESTIMATE_ITEM_POSITIONS,
            )}
          />
        ),
        disabled: !id,
      },
      {
        key: PurchaseInvoiceTabsEnum.NOTES,
        title: PurchaseInvoiceTabsEnum.NOTES,
        component: (
          <PurchaseInvoiceEditorNotes
            purchaseInvoice={extendedPurchaseInvoice}
            id={id}
          />
        ),
        disabled: !id,
      },
    ];
  }, [
    extendedPurchaseInvoice,
    handleRefresh,
    id,
    isFinanceUser,
    isReadOnly,
    notSavedTab,
    purchaseInvoice,
    setNotSavedTab,
    t,
    teamsAndProjects,
    tk.prLang,
    props.onlyTV,
  ]);

  if (!isAuthorized) {
    return <BadCompany companyId={purchaseInvoice?.companyId} />;
  }

  if (!purchaseInvoice && purchaseInvoiceId !== 'utworz') {
    return <Waiting />;
  }

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3 d-flex align-items-center">
          {buildPurchaseInvoiceTitle(
            purchaseInvoiceId,
            extendedPurchaseInvoice,
            purchaseInvoice?.tv,
          )}
        </Card.Title>
        <TabsHistory
          id="purchase-invoice-editor"
          activeKey={props.startingTab || PurchaseInvoiceTabsEnum.GENERAL}
          className="my-3"
        >
          {tabs.map(tab => {
            const notSaved = notSavedTab === tab.key;

            return (
              <Tab
                key={tab.key}
                eventKey={tab.key}
                title={buildTabTitle(tab.key, tab.title, notSaved)}
                disabled={tab.disabled}
                tabClassName={getTabClassName(notSaved)}
                className="m-3"
              >
                {tab.component}
              </Tab>
            );
          })}
        </TabsHistory>
        {purchaseInvoice && (
          <ConcurrencySignaler
            dataKey={key}
            objectDescription={description}
            editAllowed={true}
            refreshAction={handleRefresh}
          />
        )}
      </Card.Body>
    </Card>
  );
};
