import React from 'react';
import { Card, Tab, Row } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ReportsFinanceView: React.FC = () => {
  const nav = useNavigation();
  const { t, tk } = useTranslation('reportLng');

  return (
    <Card>
      <TabsHistory activeKey="finance" id="reports" className="mb-3">
        <Tab eventKey="finance" title={t(tk.menu.reports)}>
          <Row xs={1} md={3} className="g-4 mb-4">
            <ReportCardMenu
              title={t(tk.finance.budgetReport)}
              text={t(tk.finance.projectsByMonths)}
              footer={t(tk.finance.estimates)}
              onClick={nav.reportProjectByMonthDataRaw}
            />
            <ReportCardMenu
              title={t(tk.finance.FVS)}
              text={t(tk.finance.salesInvoices)}
              footer={t(tk.finance.FVS)}
              onClick={nav.reportFVSWithoutPositionsByFilterDataRaw}
            />
            <ReportCardMenu
              title={t(tk.finance.FVZ)}
              text={t(tk.finance.purchaseInvoices)}
              footer={t(tk.finance.FVZ)}
              onClick={nav.reportFVZByFilterDataRaw}
            />
          </Row>
          {/* <ReportCardMenu
              title="FVS z pozycjami"
              text="Faktury sprzedaży z nazwami pozycji"
              footer="FVS"
              onClick={nav.reportFVSWithPositionsByFilterDataRaw}
            />
          </Row>
          <Row xs={1} md={3} className="g-4  ">
            <ReportCardMenu
              title="FVZ"
              text="Faktury zakupu"
              footer="FVZ"
              onClick={nav.reportFVZByFilterDataRaw}
            />
          </Row> */}
        </Tab>
        <Tab eventKey="controlling" title={t(tk.finance.controling)}>
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title={t(tk.finance.FVZWithEstimates)}
              text={t(tk.finance.FVZWithEstimatesLong)}
              footer={t(tk.finance.FVZWithEstimatesShort)}
              onClick={nav.reportFVZBWithEstimateItemsByFilterDataRaw}
            />
            <ReportCardMenu
              title={t(tk.finance.FVSControling)}
              text={t(tk.finance.FVSControlingLong)}
              footer={t(tk.finance.FVSControlingShort)}
              onClick={nav.reportFVSControllingByFilterDataRaw}
            />
          </Row>
        </Tab>
      </TabsHistory>
    </Card>
  );
};
