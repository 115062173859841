/*eslint max-lines-per-function: ["error", 175]*/
import React, { ReactNode, useState } from 'react';
import { Alert, Table } from 'react-bootstrap';
import moment from 'moment';

import {
  CreateUpdateInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetProjectDto,
  GetSalesInvoiceDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import numberFormatter from '../../../common/numberFormatter';
import CurrencyControl from '../../../app/components/CurrencyControl';
import momencik from '../../../common/momencik';
import { InvoiceStatusEnum } from '../../../common/pokCore/validation/schemas';
import {
  RangeFilter,
  InvoiceEstimateItemsFilters,
} from '../Invoices/InvoiceEstimateItemsFilters';

import {
  calculateEstimateItemSalesNet,
  calculateEstimateItemCommission,
} from './calculateSalesInvoiceAmountsUtils';
import SalesInvoiceMediaPlanPositionsSumRow from './SalesInvoiceMediaPlanPositionsSumRow';

interface SalesInvoiceMediaplanPositionsGridProps {
  readOnly?: boolean;
  availableEstimateItems?: GetEstimateItemDto[];
  salesInvoice?: GetSalesInvoiceDto;
  salesInvoiceEstimateItems?: CreateUpdateInvoiceEstimateItemDto[];
  salesInvoiceOriginalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[];
  parentAmount?: string;
  projects: GetProjectDto[];
  propertyChange: (
    obj: Partial<CreateUpdateInvoiceEstimateItemDto>,
    id: string,
  ) => void;
}

export interface TableColumn {
  header: string;
  key: string;
  isHidden?: boolean;
  renderCell: (
    item: GetEstimateItemDto,
    sales: number,
    commission: number,
  ) => string | ReactNode | null;
}

export const getFinancialAccountName = (item: GetEstimateItemDto) =>
  `${item.profileCategory?.name} ${
    item.profileCategory.commissionFinancialAccount !==
    item.profileCategory.salesFinancialAccount
      ? '(pr.)'
      : ''
  }`;

const getColumns = (
  props: SalesInvoiceMediaplanPositionsGridProps,
  isGenerated: boolean,
  nameHeader: string,
  projectHeader: string,
): TableColumn[] => {
  const isCorrection = !!props.salesInvoice?.parent;

  const getAmount = (item: GetEstimateItemDto) =>
    props.salesInvoiceEstimateItems?.find(
      ({ estimateItemId }) => estimateItemId === item.id,
    )?.amount || '';

  const getCommission = (item: GetEstimateItemDto) =>
    props.salesInvoiceEstimateItems?.find(
      ({ estimateItemId }) => estimateItemId === item.id,
    )?.commissionAmount || '';

  const getOriginalAmount = (item: GetEstimateItemDto) =>
    props.salesInvoiceOriginalEstimateItems?.find(
      ({ estimateItemId }) => estimateItemId === item.id,
    )?.amount || '0';

  const getOriginalCommissionAmount = (item: GetEstimateItemDto) =>
    props.salesInvoiceOriginalEstimateItems?.find(
      ({ estimateItemId }) => estimateItemId === item.id,
    )?.commissionAmount || '0';

  const getPreviousAmount = (item: GetEstimateItemDto) =>
    props.salesInvoice?.parent.invoiceEstimateItems?.find(
      ({ estimateItem }) => estimateItem.id === item.id,
    )?.amount || '0';

  const getPreviousCommission = (item: GetEstimateItemDto) =>
    props.salesInvoice?.parent.invoiceEstimateItems?.find(
      ({ estimateItem }) => estimateItem.id === item.id,
    )?.commissionAmount || '0';

  const onChangeAmount = (value: string | null, id: string) =>
    props.propertyChange(
      {
        amount: value || '',
      },
      id,
    );

  const onChangeCommission = (value: string | null, id: string) =>
    props.propertyChange(
      {
        commissionAmount: value || '',
      },
      id,
    );

  return [
    {
      key: 'project',
      header: projectHeader,
      renderCell: item => item.estimateByMonth?.project?.number,
    },
    {
      key: 'name',
      header: nameHeader,
      renderCell: item => item.position.name,
    },
    {
      key: 'month',
      header: 'Miesiąc',
      renderCell: item => momencik(item.estimateByMonth?.date, 'YYYY-MM'),
    },
    {
      key: 'financial-account',
      header: 'Konto księgowe',
      renderCell: item => item.profileCategory?.name,
    },
    {
      key: 'sale',
      header: 'Sprzedaż',
      renderCell: (item, sales) => numberFormatter(sales, true),
      isHidden: isGenerated,
    },
    {
      key: 'sale-amount',
      header: 'Kwota',
      renderCell: (item, sales) => (
        <CurrencyControl
          value={getAmount(item)}
          disabled={
            (sales === 0 && getOriginalAmount(item) === '0') || props.readOnly
          }
          decimalScale={2}
          onChange={value => onChangeAmount(value, item.id)}
        />
      ),
      isHidden: isCorrection,
    },
    {
      key: 'sale-amount-previous',
      header: 'Było',
      renderCell: item => numberFormatter(getPreviousAmount(item), true),
      isHidden: !isCorrection,
    },
    {
      key: 'sale-amount-correction',
      header: 'Jest',
      renderCell: (item, sales) => (
        <CurrencyControl
          value={getAmount(item)}
          disabled={
            (sales === 0 && getPreviousAmount(item) === '0') || props.readOnly
          }
          decimalScale={2}
          onChange={value => onChangeAmount(value, item.id)}
        />
      ),
      isHidden: !isCorrection,
    },
    {
      key: 'commission-financial-account',
      header: 'Konto księgowe prowizji',
      renderCell: getFinancialAccountName,
    },
    {
      key: 'commission',
      header: 'Prowizja',
      renderCell: (item, sales, commission) =>
        numberFormatter(commission, true),
      isHidden: isGenerated,
    },
    {
      key: 'commission-amount',
      header: 'Kwota',
      renderCell: (item, sales, commission) => (
        <CurrencyControl
          value={getCommission(item)}
          disabled={
            (commission === 0 && getOriginalCommissionAmount(item) === '0') ||
            props.readOnly
          }
          decimalScale={2}
          onChange={value => onChangeCommission(value, item.id)}
        />
      ),
      isHidden: isCorrection,
    },
    {
      key: 'commission-amount-previous',
      header: 'Było',
      renderCell: item => numberFormatter(getPreviousCommission(item), true),
      isHidden: !isCorrection,
    },
    {
      key: 'commission-amount-correction',
      header: 'Jest',
      renderCell: (item, sales, commission) => (
        <CurrencyControl
          value={getCommission(item)}
          disabled={
            (commission === 0 && getPreviousCommission(item) === '0') ||
            props.readOnly
          }
          decimalScale={2}
          onChange={value => onChangeCommission(value, item.id)}
        />
      ),
      isHidden: !isCorrection,
    },
  ];
};

const GridHead: React.FC<{ columns: TableColumn[] }> = ({ columns }) => (
  <thead>
    <tr>
      {columns
        .filter(({ isHidden }) => !isHidden)
        .map(col => (
          <th key={col.key}>{col.header}</th>
        ))}
    </tr>
  </thead>
);

const GridRow: React.FC<{
  item: GetEstimateItemDto;
  columns: TableColumn[];
  isCorrection: boolean;
  salesInvoiceOriginalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[];
}> = ({ item, columns, salesInvoiceOriginalEstimateItems, isCorrection }) => {
  const sales =
    calculateEstimateItemSalesNet(item, salesInvoiceOriginalEstimateItems) || 0;
  const commission =
    calculateEstimateItemCommission(item, salesInvoiceOriginalEstimateItems) ||
    0;
  const originalSalesAmount =
    salesInvoiceOriginalEstimateItems?.find(
      ({ estimateItemId }) => estimateItemId === item.id,
    )?.amount || '0';
  const originalCommissionAmount =
    salesInvoiceOriginalEstimateItems?.find(
      ({ estimateItemId }) => estimateItemId === item.id,
    )?.commissionAmount || '0';

  if (
    sales === 0 &&
    commission === 0 &&
    !isCorrection &&
    originalSalesAmount === '0' &&
    originalCommissionAmount === '0'
  ) {
    return null;
  }

  return (
    <tr key={item.id} className="align-middle">
      {columns
        .filter(({ isHidden }) => !isHidden)
        .map(col => {
          return (
            <td key={col.key}>{col.renderCell(item, sales, commission)}</td>
          );
        })}
    </tr>
  );
};

export const SalesInvoiceMediaPlanPositionsGrid: React.FC<
  SalesInvoiceMediaplanPositionsGridProps
> = props => {
  const [dateFilter, setDateFilter] = useState<RangeFilter>([null, null]);
  const { t, tk } = useTranslation('fvsLng');
  const isGenerated = [
    InvoiceStatusEnum.Generated,
    InvoiceStatusEnum.Sent,
    InvoiceStatusEnum.Paid,
  ].includes(props.salesInvoice?.status as InvoiceStatusEnum);
  const isCorrection = !!props.salesInvoice?.parent;

  if (!props.availableEstimateItems || !props.salesInvoiceEstimateItems) {
    return <Alert variant="warning">Brak danych.</Alert>;
  }

  const columns = getColumns(
    props,
    isGenerated,
    t(tk.prLang.estimateItemPosition),
    t(tk.prLang.projectNumber),
  );

  const filteredAvailableEstimateItems = props.availableEstimateItems.filter(
    item =>
      !dateFilter[0] ||
      !dateFilter[1] ||
      moment(item.estimateByMonth?.date).isBetween(
        moment(dateFilter[0]),
        moment(dateFilter[1]),
      ),
  );

  return (
    <>
      {props.salesInvoice?.status === InvoiceStatusEnum.Draft &&
        !props.readOnly && (
          <InvoiceEstimateItemsFilters
            estimateItems={props.availableEstimateItems}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
          />
        )}
      <Table striped>
        <GridHead columns={columns} />
        <tbody>
          {filteredAvailableEstimateItems.map(item => (
            <GridRow
              key={item.id}
              item={item}
              salesInvoiceOriginalEstimateItems={
                props.salesInvoiceOriginalEstimateItems
              }
              columns={columns}
              isCorrection={isCorrection}
            />
          ))}
          <SalesInvoiceMediaPlanPositionsSumRow
            {...props}
            filteredAvailableEstimateItems={filteredAvailableEstimateItems}
            isGenerated={isGenerated}
            columns={columns}
          />
        </tbody>
      </Table>
    </>
  );
};
