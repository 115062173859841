export default {
  order: {
    specialTeam: 'Specialist team',
    receivers: 'Receivers',
    rejectedNote: 'Rejection justification',
    noOrders: 'No orders',
    askDeleteOrder: 'Are you sure you want to delete this order?',
    deleteOrder: 'Delete order',
    orderRejection: 'Order rejection',
    rejectionReason: 'Rejection reason',
    rejectOrder: 'Reject order',
    fillRejectionReason: 'Please fill in the rejection reason.',
    acceptOrder: 'Accept order',
    orderAcceptance: 'Order acceptance (sys. no. {{sysNumber}})',
    orderDetails: 'Order details (sys. no. {{sysNumber}})',
    orderEdit: 'Edit order (sys. no. {{sysNumber}})',
    createOrder: 'Create order',
    deleted: 'deleted',
    sendEmailOfToSpecialTeam: 'Send email to specialist team address',
    sendEmailToTeam: 'Send email to team address',
    newOrder: 'New order',
    askChangeMonthConsequences:
      'Changing the month will adjust the positions in the order execution. Are you sure you want to continue?',
    formalsConditions: 'Formal conditions',
    orders: 'Orders',
    sent: 'Sent',
    waiting: 'Waiting',
    completed: 'Completed',
  },
  orderExec: {
    askDeleteOrderExec: 'Are you sure you want to delete this order execution?',
    deleteOrderExecution: 'Delete execution',
    deleteOrderExec: 'Delete execution',
    getConfirmation: 'Get confirmation',
    rejectionOrderExec: 'Order execution rejection',
    rejectOrderExec: 'Reject order execution',
    acceptOrderExec: 'Accept order execution',
    pleaseFillRejectionReason: 'Please fill in the rejection reason',
    rejectionReason: 'Rejection reason',
    orderExecDetails: 'Order execution details (sys. no. {{sysNumber}})',
    orderExecEdit: 'Edit order execution (sys. no. {{sysNumber}})',
    createOrderExec: 'Create order execution',
    warningPurchaseAndSalesAmountDiff:
      'The total purchase amount from the parent campaign differs from the sales amount.',
    warningDifferentCurrencyCompanies:
      'The order execution involves companies working with different currencies. Amount compliance control has been disabled.',
    mergePositions: 'Merge positions',
    clonePosition: 'Clone position',
    askResetPositions: 'Are you sure you want to reset the positions?',
    resetPositions: 'Reset all positions',
    mergePositionsByActionGroup: 'Merge {{selected}}positions by action group',
    selected: 'selected',
    ordersExec: 'Order executions',
    acceptOrderExecSysNum:
      'Order execution acceptance (sys. no. {{sysNumber}})',
    purchaser: 'purchaser',
    forRealizationTeam: 'for the realization team',
    realizationMonth: 'Realization month',
    realizationTeam: 'realization team',
    realizationTeamUpperCase: 'Realization Team',
    realizationAmount: 'realization amount',
    actionGroup: 'Action group',
    amount: 'Amount',
    noOrderExec: 'No order executions',
    newOrderExec: 'New order execution',
    basicData: 'Basic data',
    positions: 'Positions',
    polishPrintout: 'printout in Polish',
    englishPrintout: 'printout in English',
  },
} as const;
