import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { FiltersParams } from './usePurchaseInvoiceEstimateItemsFilters';

interface PurchaseInvoiceEstimateItemPositionsFiltersProps {
  filters: FiltersParams;
  handleChangeFilters: (filters: Partial<FiltersParams>) => void;
}

export const PurchaseInvoiceEstimateItemPositionsFilters: React.FC<
  PurchaseInvoiceEstimateItemPositionsFiltersProps
> = props => {
  return (
    <>
      <Row className="p-2">
        <Form.Group as={Row} controlId="monthFilter">
          <Form.Label column sm="2">
            Filtruj po miesiącu
          </Form.Label>
          <Col>
            <div className="small-calendar">
              <DateRangePicker
                onChange={value => {
                  if (value) {
                    value = value as [Date | null, Date | null];
                    return props.handleChangeFilters({
                      dateFrom: value[0],
                      dateTo: value[1],
                    });
                  }

                  return props.handleChangeFilters({
                    dateFrom: null,
                    dateTo: null,
                  });
                }}
                value={
                  props.filters.dateFrom && props.filters.dateTo
                    ? [props.filters.dateFrom, props.filters.dateTo]
                    : null
                }
                defaultView="year"
                minDetail="year"
                maxDetail="year"
                format="MM.yyyy"
              />
            </div>
          </Col>
        </Form.Group>
      </Row>
      <Row className="p-2">
        <Form.Group as={Row} controlId="monthFilter">
          <Form.Label column sm="2">
            Pokaż rozliczone pozycje
          </Form.Label>
          <Col>
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={props.filters.showSettled}
              onChange={e =>
                props.handleChangeFilters({ showSettled: e.target.checked })
              }
            />
          </Col>
        </Form.Group>
      </Row>
      <hr />
    </>
  );
};
