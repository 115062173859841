// eslint-disable-next-line import/named
import { Locale } from 'material-ui-cron';

export const translationScheduler: Locale = {
  atEveryText: 'W / Każdy',
  betweenText: 'pomiędzy',
  inText: 'w ',
  onText: 'na',
  andText: 'i',
  onEveryText: 'Na każdym',
  everyText: 'Każdy',
  atOptionLabel: 'W',
  everyOptionLabel: 'Każda',
  periodLabel: 'Czas',
  minuteLabel: 'Minuta',
  monthLabel: 'Miesiąc',
  multiDayOfMonthLabel: 'Dzień miesiąca',
  dayOfMonthLabel: 'Dzień miesiąca',
  hourLabel: 'Godzina',
  dayOfWeekLabel: 'Dzień tygodnia',
  weekDaysOptions: [
    'niedziela',
    'poniedziałek',
    'wtorek',
    'środa',
    'czwartek',
    'piątek',
    'sobota',
  ],
  periodOptions: ['Godzina', 'Dzień', 'Tydzień', 'Miesiąc', 'Rok'],
  shortMonthOptions: [
    'sty',
    'lut',
    'mar',
    'kwi',
    'maj',
    'cze',
    'lip',
    'sie',
    'wrz',
    'paź',
    'lis',
    'gru',
  ],
  onOptionLabel: 'W',
  lastDayOfMonthLabel: 'Ostatni dzień miesiąca',
  cronDescriptionText: 'pl',
};
