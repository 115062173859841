import React from 'react';
import { Col, Dropdown, DropdownButton, Form } from 'react-bootstrap';

import { FormRow48 } from '../../../app/components/FormRow';
import { SchemaAttributesDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import CountryFlag from '../../../app/components/CountryFlag';

interface SchemaItemInputProps {
  id: string;
  label: string;
  labelPl?: string;
  labelEn?: string;
  value?: SchemaAttributesDto;
  onChange: (obj: Partial<SchemaAttributesDto> | undefined) => void;
}

export const SchemaItemInput: React.FC<SchemaItemInputProps> = props => {
  const { t, tk } = useTranslation('administrationLng');

  const activeColumnLabel = !props.value
    ? t(tk.administration.absence)
    : props.value && !props.value.required
      ? t(tk.administration.visible)
      : t(tk.administration.obligatory);

  return (
    <FormRow48 controlId={props.id} label={props.label}>
      <Col>
        <Form.Group className="d-flex">
          <DropdownButton
            variant="outline-secondary"
            title={activeColumnLabel}
            id="input-group-dropdown-1"
            className="me-2"
          >
            <Dropdown.Item onClick={() => props.onChange(undefined)}>
              {t(tk.administration.absence)}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() =>
                props.onChange({
                  displayNamePl: props.value
                    ? props.value.displayNamePl
                    : props.labelPl,
                  displayNameEn: props.value
                    ? props.value.displayNameEn
                    : props.labelEn,
                })
              }
            >
              {t(tk.administration.visible)}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                props.onChange({
                  required: true,
                  displayNamePl: props.value
                    ? props.value.displayNamePl
                    : props.labelPl || props.label,
                  displayNameEn: props.value
                    ? props.value.displayNameEn
                    : props.labelEn || props.label,
                })
              }
            >
              {t(tk.administration.obligatory)}
            </Dropdown.Item>
          </DropdownButton>
          {props.value && (
            <>
              <CountryFlag country="PL" />
              <Form.Control
                type="text"
                value={props.value?.displayNamePl || ''}
                onChange={e =>
                  props.onChange({
                    displayNamePl: e.target.value || undefined,
                    displayNameEn: props.value?.displayNameEn,
                    required: props.value?.required,
                  })
                }
                disabled={!props.value}
              />
              <CountryFlag country="EN" />
              <Form.Control
                className="ms-0"
                type="text"
                value={props.value?.displayNameEn || ''}
                onChange={e =>
                  props.onChange({
                    displayNameEn: e.target.value || undefined,
                    displayNamePl: props.value?.displayNamePl,
                    required: props.value?.required,
                  })
                }
                disabled={!props.value}
              />
            </>
          )}
        </Form.Group>
      </Col>
    </FormRow48>
  );
};
