import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { ClientSearch } from '../../components/Clients/ClientSearch';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ClientEditor } from '../../components/Clients/ClientEditor';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ClientContext';
import {
  CreateUpdateClientDto,
  GetClientDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ClientsView: React.FC = () => {
  const [client, setClient] = useState<GetClientDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const pok = usePokCore();
  const nav = useNavigation();
  const { t, tk } = useTranslation('clientLng');

  const handleAdd = () => {
    setShow(true);
    setClient(undefined);
  };

  const addEditClient = async (
    clientCU: CreateUpdateClientDto,
    id?: string,
  ) => {
    const status = await validate(clientCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.clients.update(id, clientCU);
        } else {
          await pok.clients.create(clientCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string, newWindow?: boolean) => {
    nav.purchaserEditor(id, newWindow);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.client.clients)}</Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.client.clientNew)}
        </Button>
        <ClientSearch
          getData={pok.clients.getAllByPortion}
          getCount={pok.clients.getAllCount}
          onRowClick={handleClick}
          refresh={refresh}
        />
        <ClientEditor
          id={client?.id}
          client={convert(client)}
          onCompleted={addEditClient}
          show={show}
          handleClose={handleClose}
          countriesProvider={pok.clients.getCountryOptionsByText}
        />
      </Card.Body>
    </Card>
  );
};
