import { DefaultApi, GetCurrencyDto } from '../autogenerated/pokApiClient';

export interface CurrencyContextInterface {
  getById: (id: string) => Promise<GetCurrencyDto>;
  getAll: () => Promise<GetCurrencyDto[]>;
}

export const CurrencyContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.currencyControllerGet(id),
    getAll: () => api.currencyControllerGetMany(),
  };
};
