import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import * as rstm from 'react-simple-tree-menu';
import Button from 'react-bootstrap/esm/Button';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useTranslation } from '../../hooks/useTranslation';

import { TreeActionModal } from './modals/TreeActionModal';

type TreeRowDeleteButtonProps = {
  variantTree: string;
  variantOperation: 'delete' | 'edit' | 'add';
  item: rstm.TreeMenuItem;
  onActionConfirmed: (id?: string) => void;
};

export const TreeRowActionButton: React.FC<TreeRowDeleteButtonProps> = ({
  variantTree,
  variantOperation,
  item,
  onActionConfirmed,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { t, tk } = useTranslation('administrationLng');

  const ActionIcon =
    variantOperation === 'delete'
      ? Icon.Trash
      : variantOperation === 'edit'
        ? Icon.Pencil
        : Icon.Plus;
  const buttonVariant = 'outline-light';

  const tooltip =
    variantOperation === 'delete'
      ? t(tk.administration.dictionarySelectedDelete)
      : variantOperation === 'edit'
        ? t(tk.administration.dictionarySelectedEdit)
        : t(tk.administration.dictionarySelectedAdd);
  return (
    <>
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
        <Button
          className="ms-2"
          variant={buttonVariant}
          size="sm"
          onClick={e => {
            e.stopPropagation();
            setShow(true);
          }}
        >
          <ActionIcon size={20} />
        </Button>
      </OverlayTrigger>

      {show && (
        <TreeActionModal
          variantTree={variantTree}
          variantOperation={variantOperation}
          item={item}
          onAction={id => {
            setShow(false);
            onActionConfirmed(id);
          }}
          show={show}
          onClose={handleClose}
        />
      )}
    </>
  );
};
