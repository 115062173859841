import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import {
  GetDictionaryDto,
  GetEstimateItemDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import numberFormatter from '../../../common/numberFormatter';
import momencik from '../../../common/momencik';
import {
  CurrencyIsoTypeEnum,
  PositionTypeEnum,
} from '../../../common/pokCore/validation/schemas';
import mathUtils from '../../../utils/mathUtils';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { OrderEstimateItemsGridMonthsSum } from './OrderEstimateItemsGridMonthsSum';

interface OrderEstimateItemsGridProps {
  data: GetEstimateItemDto[];
  currencyCode?: string;
}

const getItemsGridSummary = (
  data: GetEstimateItemDto[],
  currencyCode?: string,
) => {
  return {
    key: 'summary',
    values: {
      'estimateItem.comment': 'Suma',
      'estimateItem.purchaseNetTotal': numberFormatter(
        mathUtils.add(
          ...data.map(({ purchaseNetTotal }) =>
            mathUtils.round(purchaseNetTotal),
          ),
        ),
        true,
        (currencyCode as CurrencyIsoTypeEnum) || undefined,
      ),
    },
  };
};

export const buildParametersString = (estimateItem: GetEstimateItemDto) => {
  const result: { label: string; value: string }[] = [];
  const { schema } = estimateItem.estimateByMonth.profile;

  const isCommission = [
    PositionTypeEnum.Commission,
    PositionTypeEnum.TransferredCommission,
  ].includes(estimateItem?.positionType as PositionTypeEnum);
  const schemaKeysToGet: (keyof ProfileSchemaDto)[] = ['service', 'action'];

  if (!isCommission) {
    schemaKeysToGet.push(
      'numberOfMedia',
      'technologyDictionary',
      'regionDictionary',
      'additionalDescription',
      'formatPress',
      'formatRadio',
      'emissionTypeRadio',
      'emissionTypeCinema',
      'purchaseFormInternet',
      'categoryInternet',
      'formatInternet',
      'purchaseFormInternetST',
      'categoryInternetST',
      'formatInternetST',
      'netCustomerAccount',
      'copySize',
      'orderNumber',
      'purchaseMethod',
      'labconChannel',
      'categoryLabcon',
      'medium',
      'ownName',
      'isUpSell',
      'actionGroup',
    );
  }

  // eslint-disable-next-line complexity
  schemaKeysToGet.forEach(key => {
    if (schema[key] && estimateItem[key]) {
      if (typeof estimateItem[key] === 'string') {
        return result.push({
          label: schema[key]?.displayNamePl || '',
          value: (estimateItem[key] as string) || '',
        });
      }

      if (typeof estimateItem[key] === 'boolean') {
        return result.push({
          label: schema[key]?.displayNamePl || '',
          value: estimateItem[key] ? 'TAK' : 'NIE',
        });
      }

      return result.push({
        label: schema[key]?.displayNamePl || '',
        value: (estimateItem[key] as GetDictionaryDto)?.value || '',
      });
    }
  });

  return (
    <ul className="m-0 list-group-numbered p-0">
      {result.map(({ label, value }, index) => (
        <li key={index}>
          {label}: {value}
        </li>
      ))}
    </ul>
  );
};

export const OrderEstimateItemsGrid: React.FC<
  OrderEstimateItemsGridProps
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const columns: GridColumn[] = [
    { header: t(tk.common.name), property: 'position.name' },
    { header: t(tk.common.month), property: 'estimateByMonth.date' },
    { header: t(tk.common.client), property: 'client.name' },
    {
      header: t(tk.estimates.parameters),
      property: 'parameters',
      noSorting: true,
    },
    { header: t(tk.estimates.comment), property: 'estimateItem.comment' },
    {
      header: t(tk.estimates.purchaseAmount),
      property: 'estimateItem.purchaseNetTotal',
    },
  ];

  const data = props.data.map(estimateItem => {
    return {
      key: estimateItem.id,
      values: {
        'position.name': estimateItem.position.name,
        'estimateByMonth.date': momencik(
          estimateItem.estimateByMonth.date,
          'YYYY-MM',
        ),
        'client.name':
          estimateItem.position.client.shortname ||
          estimateItem.position.client.name,
        'estimateItem.purchaseNetTotal': numberFormatter(
          estimateItem.purchaseNetTotal || '0',
          true,
          (props.currencyCode as CurrencyIsoTypeEnum) || undefined,
        ),
        parameters: buildParametersString(estimateItem),
        'estimateItem.comment': estimateItem.comment,
      },
      orderByStringValues: {
        name: estimateItem.position.name,
      },
    };
  });

  return (
    <>
      <Grid
        data={data}
        columns={columns}
        initialOrderColumn="estimateByMonth.date"
        initialOrderDirection="DESC"
        hidePagination
        emptyGridMessage={t(tk.estimates.noPositionsAssigned)}
        summaryData={getItemsGridSummary(props.data, props.currencyCode)}
      />
      <OrderEstimateItemsGridMonthsSum
        data={props.data}
        currencyCode={props.currencyCode}
      />
    </>
  );
};
