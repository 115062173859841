/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateJobConfigDto
 */
export interface CreateUpdateJobConfigDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJobConfigDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJobConfigDto
     */
    companyId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateJobConfigDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateJobConfigDto
     */
    teamIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateJobConfigDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJobConfigDto
     */
    crone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJobConfigDto
     */
    queueName: string;
}

/**
 * Check if a given object implements the CreateUpdateJobConfigDto interface.
 */
export function instanceOfCreateUpdateJobConfigDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "emails" in value;
    isInstance = isInstance && "teamIds" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "crone" in value;
    isInstance = isInstance && "queueName" in value;

    return isInstance;
}

export function CreateUpdateJobConfigDtoFromJSON(json: any): CreateUpdateJobConfigDto {
    return CreateUpdateJobConfigDtoFromJSONTyped(json, false);
}

export function CreateUpdateJobConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateJobConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'companyId': json['companyId'],
        'emails': json['emails'],
        'teamIds': json['teamIds'],
        'active': json['active'],
        'crone': json['crone'],
        'queueName': json['queueName'],
    };
}

export function CreateUpdateJobConfigDtoToJSON(value?: CreateUpdateJobConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'companyId': value.companyId,
        'emails': value.emails,
        'teamIds': value.teamIds,
        'active': value.active,
        'crone': value.crone,
        'queueName': value.queueName,
    };
}

