export default {
  menu: {
    applications: 'Applications',
    specialInvoicingApplication: 'Special Invoicing Applications',
    purchaseInvoicesToBeClarified: 'Purchase Invoices to be Clarified',
    purchaseInvoicesTv: 'TV Purchase Invoices',
    estimateItemPurchaseDeficiencies: 'Clarifications of Purchase Deficiencies',
    orders: 'Orders',
    ordersExecutions: 'Order Executions',
    finance: 'Finance',
    salesInvoices: 'Sales Invoices',
    salesInvoicesReadyToCorrection: 'Sales Invoices Ready for Correction',
    purchaseInvoices: 'Purchase Invoices',
    verifications: 'Verifications',
    imports: 'Imports',
    specialInvoicingDecision: 'Special Invoicing Decisions',
    blockade: 'Blockades',
    exportFVZToXml: 'Export FVZ to XML',
    exportFVSToXml: 'Export FVS to XML',
    exportFVSToSymphony: 'Export FVS to Symphony Handel',
    clientsWithSymphony: 'Clients - Symphony',
    reportsFinance: 'Financial Reports',
    reportsFinanceAdministration: 'Finance - Administration',
    bankAccounts: 'Bank Accounts',
    clients: 'Clients',
    clientsList: 'Clients List',
    purchasers: 'Purchasers',
    positions: 'Profile Positions',
    budgets: 'Budgets',
    brands: 'Brands',
    administrations: 'Administration',
    companies: 'Companies',
    employees: 'Employees',
    teams: 'Teams',
    profiles: 'Mediaplan profiles',
    profileCategories: 'Profile categories',
    dictionaries: 'Dictionaries',
    calendar: 'Calendar',
    roles: 'Roles',
    employeesRoles: 'Permissions',
    emailConfigs: 'Email Configurations',
    jobConfig: 'Scheduled Task Configurations',
    excelConfig: 'Import Configuration',
    reports: 'Reports',
    help: 'Help',
    instructions: 'User Manuals',
    procedures: 'Procedures',
    documentation: 'Documentation',
    logout: 'Logout',
  },
  docs: {
    addingPurchaseInvoices: 'Adding and accepting purchase invoices',
    settlementWithholdingTax: 'Settlement of withholding tax',
    settingUpProjects: 'Setting up projects and completing applications',
    importFromProjectToProject: 'Importing items from a project to a project',
    projectOrders: 'Project orders',
    orderFulfillment: 'Order fulfillment (specialists)',
    acceptanceInvoices: 'Acceptance of invoices',
    importProjects: 'Import projects from sheets',
  },
} as const;
