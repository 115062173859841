import { Buffer } from 'buffer';

import { NotificationsProviderType } from '../../common/notifications';
import { PokCoreContextType } from '../../common/pokCore';

export const getPdf = (
  pok: PokCoreContextType,
  notifications: NotificationsProviderType,
  name: string,
) => {
  pok.docs
    .getForFilename(name)
    .then(doc => {
      const file = new Blob([Buffer.from(doc.content, 'base64')], {
        type: 'application/pdf',
      });
      window.open(URL.createObjectURL(file));
    })
    .catch(errorResponse => {
      notifications.caughtError(errorResponse);
    });
};
