import React from 'react';

import { ReportFVSByFilterRawDataView } from './ReportFVSByFilterRawDataView';

export const ReportFVSWithoutPositionsByFilterRawDataView: React.FC = () => {
  return (
    <ReportFVSByFilterRawDataView
      title="Zestawienie faktur sprzedaży"
      withPositions={false}
    />
  );
};
