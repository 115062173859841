export default {
  common: {
    locale: 'pl-PL',
    company: 'Spółka',
    companies: 'Spółki',
    team: 'Zespół',
    teams: 'Zespoły',
    budget: 'Budżet',
    client: 'Kontrahent',
    profile: 'Profil',
    currency: 'Waluta',
    employee: 'Pracownik',
    employees: 'Pracownicy',
    user: 'Użytkownik',
    year: 'Rok',
    month: 'Miesiąc',
    status: 'Status',
    lp: 'L.p.',
    name: 'Nazwa',
    shortname: 'Skrót',
    description: 'Opis',
    sysNumber: 'Nr sys.',
    sysNumberLowerCase: 'nr sys.',
    history: 'Historia',
    lastModification: 'Ostatnia modyfikacja',
    who: 'Kto',
    when: 'Kiedy',
    noInformation: 'Brak informacji',
    creation: 'Utworzenie',
    activeShe: 'Aktywna',
    activeHe: 'Aktywny',
    nonActiveHe: 'Nieaktywny',
    yes: 'Tak',
    no: 'Nie',
    yesUpperCase: 'TAK',
    noUpperCase: 'NIE',
    sumUpperCase: 'SUMA',
    sum: 'Suma',
    creator: 'Autor',
    creationDate: 'Data utworzenia',
    emails: 'Adresy e-mail',
    noData: 'Brak danych',
    downloadDocs: 'Pobierz dokumenty',
    selectMonth: 'Wybierz miesiąc',
    positionName: 'Nazwa pozycji',
    country: 'Państwo',
    purchaser: 'Zamawiający',
    brand: 'Brand',
    profilePosition: 'Pozycja profilu',
    type: 'Typ',
    number: 'Numer',
    startDate: 'Data rozpoczęcia',
    endDate: 'Data zakończenia',
  },
  component: {
    pointOut: 'Wskaż...',
    enter: 'Wpisz...',
    quickSearch: 'Szybkie wyszukiwanie',
    searchInProgress: 'Trwa wyszukiwanie...',
    numberOfRows: 'Liczba wierszy',
    cleanFilter: 'Wyczyść filtr',
    noValues: 'Brak wartości',
    selectAll: 'Zaznacz wszystko',
    deselectAll: 'Odznacz wszystko',
    selected: 'Wybrano',
  },
  button: {
    clean: 'Wyczyść',
    export: 'Eksportuj',
    close: 'Zamknij',
    save: 'Zapisz',
    delete: 'Usuń',
    add: 'Dodaj',
    edit: 'Edycja',
    addDots: 'Dodaj...',
    open: 'Otwórz',
    cancel: 'Anuluj',
    find: 'Szukaj',
  },
  attachment: {
    attachments: 'Załączniki',
    attachment: 'Załącznik',
    attachmentNew: 'Nowy załącznik / notatka',
    calculatorCurrency: 'Kalkulator walut',
    note: 'Notatka',
    noteNew: 'Nowa notatka',
    type: 'Typ',
    title: 'Tytuł',
    file: 'Plik',
    invoicing: 'Fakturujący',
    positionsSum: 'Powiązane pozycje - suma zakupu',
    relatedItems: 'Powiązane pozycje',
  },
} as const;
