import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  CreateUpdateClientDto,
  GetClientDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { ClientEditor } from '../../components/Clients/ClientEditor';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ClientContext';
import FormRow from '../../../app/components/FormRow';
import CompanySelector from '../Selects/CompanySelector';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PolishClient } from './PolishClient';
import { ForeignClient } from './ForeignClient';

interface ClientTabProps {
  client: GetClientDto;
  refresh(): void;
}

export const ClientTab: React.FC<ClientTabProps> = props => {
  const [show, setShow] = useState(false);
  const [clientCompanyId, setClientCompanyId] = useState<string | undefined>();

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('clientLng');

  const loadClientCompany = useCallback(() => {
    if (props.client?.id) {
      pok.companies
        .getCompanyIdByClientId(props.client.id)
        .then(setClientCompanyId)
        .catch(notifications.caughtError);
    }
  }, [
    props.client?.id,
    setClientCompanyId,
    pok.companies,
    notifications.caughtError,
  ]);

  useEffect(() => {
    loadClientCompany();
  }, [loadClientCompany]);

  const editClient = async (clientCU: CreateUpdateClientDto, id?: string) => {
    const status = await validate(clientCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.clients.update(id, clientCU);
        }
        setShow(false);
        loadClientCompany();
        props.refresh();
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async () => {
    setShow(true);
  };

  return (
    <>
      <Form className="d-grid gap-4 mb-3">
        <FormRow controlId="country" label={t(tk.client.country)}>
          <Form.Control
            type="text"
            value={props.client?.country || ''}
            disabled={true}
          />
        </FormRow>
        <FormRow controlId="companyId" label={t(tk.common.company)}>
          <CompanySelector value={clientCompanyId} readOnly />
        </FormRow>
      </Form>
      {props.client?.country === 'Polska' ? (
        <PolishClient client={props.client} readonly={true} />
      ) : (
        <ForeignClient client={props.client} readonly={true} />
      )}
      <Form className="d-grid gap-4 mb-4 mt-4">
        <FormRow
          controlId="dealGroupNumber"
          label={t(tk.client.dealGroupNumber)}
        >
          <Form.Control
            className="d-grid mb-2 mt-2"
            type="number"
            value={props.client?.dealGroupNumber ?? ''}
            disabled={true}
          />
        </FormRow>
        <FormRow controlId="isRelated" label={t(tk.client.isRelated)}>
          <Form.Check
            className="d-grid mb-2 mt-2"
            type="checkbox"
            checked={props.client?.isRelated ?? false}
            disabled={true}
          />
        </FormRow>
        <FormRow controlId="notToSymphony" label={t(tk.client.notToSymphony)}>
          <Form.Check
            className="d-grid mb-2 mt-2"
            type="checkbox"
            checked={props.client?.notToSymphony ?? false}
            disabled={true}
          />
        </FormRow>
      </Form>
      <div className="d-flex justify-content-end pt-4">
        <Button variant="outline-primary" onClick={handleClick}>
          {t(tk.button.edit)}
        </Button>
      </div>
      {show && (
        <ClientEditor
          id={props.client?.id}
          systemNumber={props.client?.systemNumber}
          client={convert(props.client)}
          onCompleted={editClient}
          show={show}
          handleClose={handleClose}
          countriesProvider={pok.clients.getCountryOptionsByText}
        />
      )}
    </>
  );
};
