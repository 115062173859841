import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { PositionTypeEnum } from '../../../common/pokCore/validation/schemas';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';

import { InternetProfileParams } from './InternetProfileParams';
import { OfflineProfileParams } from './OfflineProfileParams';
import { LabconProfileParams } from './LabconProfileParams';
import { TvProfileParams } from './TvProfileParams';
import { ProfileParams } from './ProfileParams';
import { LabconSpecialParams } from './LabconSpecialParams';
import { InternetSTProfileParams } from './InternetSTProfileParams';

interface ParametersTabProps {
  estimateItem: CreateUpdateEstimateItemDto;
  project?: GetProjectDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const ParametersTab: React.FC<ParametersTabProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };
  const pok = usePokCore();
  const lang = pok.getLanguage();
  const commission =
    props.estimateItem?.positionType === PositionTypeEnum.Commission ||
    props.estimateItem?.positionType === PositionTypeEnum.TransferredCommission;

  return (
    <Row>
      <Col className="col-lg-6 col-12 mb-1">
        <Form className="d-grid gap-4 mb-3">
          {!commission && (
            <>
              <OfflineProfileParams
                estimateItem={props.estimateItem}
                profile={props.profile}
                propertyChange={propertyChange}
                language={lang}
              />

              <InternetProfileParams
                estimateItem={props.estimateItem}
                profile={props.profile}
                propertyChange={propertyChange}
                lang={lang}
              />
              <InternetSTProfileParams
                estimateItem={props.estimateItem}
                profile={props.profile}
                propertyChange={propertyChange}
                lang={lang}
              />
              <TvProfileParams
                estimateItem={props.estimateItem}
                profile={props.profile}
                propertyChange={propertyChange}
                lang={lang}
              />
              <ProfileParams
                estimateItem={props.estimateItem}
                profile={props.profile}
                propertyChange={propertyChange}
                lang={lang}
              />
            </>
          )}
          <LabconProfileParams
            estimateItem={props.estimateItem}
            profile={props.profile}
            propertyChange={propertyChange}
            lang={lang}
          />
          <LabconSpecialParams
            estimateItem={props.estimateItem}
            profile={props.profile}
            propertyChange={propertyChange}
            project={props.project}
            lang={lang}
          />
          <FormRow controlId="comment" label={t(tk.estimates.comment)}>
            <DynamicTextarea
              value={props.estimateItem?.comment ?? ''}
              onChange={e => propertyChange({ comment: e.target.value })}
            />
          </FormRow>
        </Form>
      </Col>
    </Row>
  );
};
