import role from './role';
import administration from './administration';
import project from './project';
import personalLanguage from './personalLanguage';
import fvs from './fvs';
import fvz from './fvz';
import order from './order';
import report from './report';
import menuNavigation from './menu';
import application from './application';
import excelImport from './excelImport';
import common from './common';
import client from './client';

export const menuLng = {
  ...menuNavigation,
  ...personalLanguage,
  ...common,
};

export const commonLng = {
  ...personalLanguage,
  ...common,
};

export const administrationLng = {
  ...administration,
  ...personalLanguage,
  ...common,
};

export const roleLng = {
  ...role,
  ...personalLanguage,
  ...common,
};

export const clientLng = {
  ...client,
  ...personalLanguage,
  ...common,
};

export const projectLng = {
  ...project,
  ...personalLanguage,
  ...common,
};

export const fvsLng = {
  ...fvs,
  ...personalLanguage,
  ...common,
};

export const fvzLng = {
  ...fvz,
  ...personalLanguage,
  ...common,
};

export const orderLng = {
  ...order,
  ...personalLanguage,
  ...common,
};

export const reportLng = {
  ...report,
  ...personalLanguage,
  ...common,
};

export const applicationLng = {
  ...application,
  ...personalLanguage,
  ...common,
};

export const excelImportLng = {
  ...excelImport,
  ...personalLanguage,
  ...common,
};
