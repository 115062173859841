import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateProjectWithTeamDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';
import InternalProjectSelector from '../Selects/InternalProjectSelector';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';

import { ProjectDelayForm } from './ProjectDelayForm';
import { ProjectMainFieldsForm } from './ProjectMainFieldsForm';
import { ProjectMainFieldsForm2 } from './ProjectMainFieldsForm2';
import { ProjectMainFieldsForm3 } from './ProjectMainFieldsForm3';

interface ProjectFormProps {
  project: CreateUpdateProjectWithTeamDto;
  readOnly?: boolean;
  isCreatedFromOrder?: boolean;
  budgetId?: string;
  show?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateProjectWithTeamDto>) => void;
}

export const ProjectForm: React.FC<ProjectFormProps> = props => {
  const [internalProjects, setInternalProjects] = useState<GetProjectDto[]>([]);
  const [delayedProject, setDelayedProject] = useState<boolean>(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');

  useEffect(() => {
    let mounted = true;
    if (props.project.purchaserId) {
      pok.projects
        .findInternalProjectsByPurchaserForCompanyAndTeams(
          props.project.purchaserId,
          props.project.teamIds,
        )
        .then(res => {
          if (mounted) {
            setInternalProjects(res);
          }
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }

    return () => {
      mounted = false;
      setInternalProjects([]);
    };
  }, [
    notifications,
    pok.projects,
    props.project.purchaserId,
    props.project.teamIds,
  ]);

  useEffect(() => {
    if (props.project.startDate && !props.project.id && props.show) {
      pok.projects
        .isDelayed(props.project.startDate)
        .then(res => {
          setDelayedProject(res.delayed);
          res.delayed &&
            props.propertyChange &&
            props.propertyChange({
              delayVerification: true,
            });
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const onChangeStartDate = async (date: Date) => {
    if (props.project.id) {
      props.propertyChange &&
        props.propertyChange({
          startDate: date,
        });
    } else {
      try {
        const delayed = await pok.projects.isDelayed(date);
        setDelayedProject(delayed.delayed);
        props.propertyChange &&
          props.propertyChange({
            startDate: date,
            delayVerification: delayed.delayed,
          });
      } catch (error) {
        notifications.caughtError(error as ResponseError);
      }
    }
  };

  return (
    <Form className="d-grid gap-4 mb-3">
      <ProjectMainFieldsForm
        project={props.project}
        readOnly={props.readOnly}
        propertyChange={props.propertyChange}
        isCreatedFromOrder={props.isCreatedFromOrder}
        budgetId={props.budgetId}
      />
      <ProjectMainFieldsForm2
        project={props.project}
        readOnly={props.readOnly}
        propertyChange={props.propertyChange}
        isCreatedFromOrder={props.isCreatedFromOrder}
        budgetId={props.budgetId}
      />
      {(internalProjects.length > 0 || props.project.parentId) && (
        <FormRow
          controlId="internalProject"
          label={t(tk.prLang.internalProject)}
        >
          <InternalProjectSelector
            projects={internalProjects}
            readOnly={props.readOnly || props.isCreatedFromOrder}
            value={props.project.parentId || ''}
            onChange={option =>
              props.propertyChange &&
              props.propertyChange({
                parentId: option?.value,
              })
            }
          />
        </FormRow>
      )}
      <ProjectMainFieldsForm3
        project={props.project}
        readOnly={props.readOnly}
        propertyChange={props.propertyChange}
        isCreatedFromOrder={props.isCreatedFromOrder}
        budgetId={props.budgetId}
        onChangeStartDate={onChangeStartDate}
      />
      {delayedProject && (
        <ProjectDelayForm
          project={props.project}
          readOnly={props.readOnly}
          propertyChange={props.propertyChange}
        />
      )}
    </Form>
  );
};
