import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  GetExtendedProfileDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Option, Selector } from './Selector';

export interface ProfileSelectorProps {
  companyIds?: string[];
  teamIds?: string[];
  budgetId?: string;
  provider?: (
    companyIds: string[],
    teamIds?: string[],
    budgetId?: string,
  ) => Promise<GetProfileDto[]>;
  isMulti?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  value?: string | string[];
  className?: string;
  onChange?: (option: Option | MultiValue<Option> | null | undefined) => void;
}

export default (props: ProfileSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [profiles, setProfiles] = useState<
    GetExtendedProfileDto[] | undefined
  >();

  useEffect(() => {
    let mounted = true;
    if (props.companyIds) {
      if (props.companyIds?.length) {
        const providerFunc =
          props.provider ?? pok.profiles.getAllByCompanyIdsTeamIds;
        providerFunc(props.companyIds, props.teamIds, props.budgetId)
          .then(data => {
            if (mounted) {
              setProfiles(data);
            }
          })
          .catch(errorResponse => {
            notifications.caughtError(errorResponse);
          });
      } else {
        setProfiles([]);
      }
    }
    return () => {
      mounted = false;
    };
  }, [
    pok.profiles,
    props.provider,
    props.companyIds,
    props.teamIds,
    props.budgetId,
    notifications,
  ]);

  useEffect(() => {
    if (profiles && props.onChange) {
      if (props.isMulti) {
        const newProfiles = profiles?.filter(({ id }) =>
          props.value?.includes(id),
        );
        if ((props.value?.length || 0) !== (newProfiles?.length || 0)) {
          props.onChange(
            newProfiles.map(v => ({ value: v.id, label: v.name }) as Option),
          );
        }
      } else if (
        props.value &&
        !profiles.find(profile => profile.id === props.value)
      ) {
        props.onChange(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles, props.value, props.isMulti]);

  const provider = async (text: string) => {
    const values =
      profiles?.filter(v =>
        v.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
      ) || [];
    return values.map(v => ({ label: v.name, value: v.id }));
  };

  if (!profiles && props.companyIds?.length) {
    return <Skeleton />;
  }

  return (
    <Selector
      uniqueKey={'profiles-' + profiles?.map(o => o.id).join('_')}
      className={props.className}
      isMulti={props.isMulti}
      readOnly={props.readOnly || !profiles || !props.companyIds?.length}
      creatable={false}
      clearable={props.clearable}
      provider={provider}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
