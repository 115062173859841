import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetOrderDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { OrderStatusEnumText } from '../../../common/pokCore/validation/schemas';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { buildProjectLabel } from '../../../utils/buildProjectLabel';
import momencik from '../../../common/momencik';

interface OrderSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  isProjectView?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  emptyGridMessage?: string;
  refresh?: boolean;
}

export const orderForGrid = (order: GetOrderDto, currentProjectId?: string) => {
  const buildEmployeeName = (order: GetOrderDto) => {
    const employees =
      order?.orderEmployees
        ?.filter(({ active }) => active)
        .map(({ employee }) => employee?.name) || [];
    if (order?.addTeamToEmails) {
      employees.push('Zespół specjalistyczny');
    }
    return employees.join(', ');
  };

  return {
    key: order.id,
    values: {
      'teamSpecial.name': `${order.team.name} (${order.team.company.shortname})`,
      'employee.name': buildEmployeeName(order),
      'order.status':
        OrderStatusEnumText[order.status as keyof typeof OrderStatusEnumText],
      'order.systemNumber': order.systemNumber,
      'order.rejectedNote': order.rejectedNote || '-',
      'order.isResultProject':
        order.resultProject?.id === currentProjectId ? (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Zamówienie przychodzące</Tooltip>}
          >
            <Icon.ArrowLeftSquareFill className="icon-red" />
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Zamówienie wychodzące</Tooltip>}
          >
            <Icon.ArrowRightSquareFill className="icon-green" />
          </OverlayTrigger>
        ),
      result_project_label: buildProjectLabel(order.resultProject),
      project_label: buildProjectLabel(order.project),
      'creator.name': order.creator.name,
      'order.creationTs': momencik(order.creationTs, 'YYYY-MM-DD'),
      'budget.name': order?.project?.purchaser?.budget?.name,
    },
    isInactive: !order.active,
  };
};

export const OrderSearch: React.FC<OrderSearchProps> = props => {
  const { t, tk } = useTranslation('orderLng');
  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'order.systemNumber' },
    { header: t(tk.prLang.project), property: 'project_label' },
    { header: t(tk.common.budget), property: 'budget.name' },
    {
      header: t(tk.prLang.orderResultProject),
      property: 'result_project_label',
    },
    { header: t(tk.order.specialTeam), property: 'teamSpecial.name' },
    { header: t(tk.order.receivers), property: 'employee.name' },
    { header: t(tk.common.creator), property: 'creator.name' },
    { header: t(tk.common.creationDate), property: 'order.creationTs' },
    { header: t(tk.common.status), property: 'order.status', noFilter: true },
    { header: t(tk.order.rejectedNote), property: 'order.rejectedNote' },
    ...(props.isProjectView
      ? [
          {
            header: '',
            property: 'order.isResultProject',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="order.systemNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={props.emptyGridMessage || t(tk.order.noOrders)}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withFilterInColumns={true}
    />
  );
};
