import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface TeamsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const teamForGrid = (team: GetTeamDto) => {
  return {
    key: team.id,
    values: {
      'team.systemNumber': team.systemNumber,
      'team.name': team.name,
      'team.email': team.email,
      'employee.name': team.manager?.name,
      'company.shortname': team.company?.shortname,
    },
    isInactive: !team.active,
  };
};

export const TeamsSearch: React.FC<TeamsSearchProps> = props => {
  const { t, tk } = useTranslation('administrationLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'team.systemNumber' },
    { header: t(tk.common.company), property: 'company.shortname' },
    { header: t(tk.common.name), property: 'team.name' },
    { header: t(tk.administration.email), property: 'team.email' },
    { header: t(tk.administration.managerName), property: 'employee.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="team.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.administration.noTeams)}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
