/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetOrderEmployeeDto } from './GetOrderEmployeeDto';
import {
    GetOrderEmployeeDtoFromJSON,
    GetOrderEmployeeDtoFromJSONTyped,
    GetOrderEmployeeDtoToJSON,
} from './GetOrderEmployeeDto';
import type { GetOrderEstimateItemDto } from './GetOrderEstimateItemDto';
import {
    GetOrderEstimateItemDtoFromJSON,
    GetOrderEstimateItemDtoFromJSONTyped,
    GetOrderEstimateItemDtoToJSON,
} from './GetOrderEstimateItemDto';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetOrderWithCurrencyDto
 */
export interface GetOrderWithCurrencyDto {
    /**
     * 
     * @type {string}
     * @memberof GetOrderWithCurrencyDto
     */
    id: string;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetOrderWithCurrencyDto
     */
    project: GetProjectDto;
    /**
     * 
     * @type {GetProjectDto}
     * @memberof GetOrderWithCurrencyDto
     */
    resultProject: GetProjectDto;
    /**
     * 
     * @type {GetTeamDto}
     * @memberof GetOrderWithCurrencyDto
     */
    team: GetTeamDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderWithCurrencyDto
     */
    addTeamToEmails: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetOrderWithCurrencyDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {Array<GetOrderEstimateItemDto>}
     * @memberof GetOrderWithCurrencyDto
     */
    orderEstimateItems?: Array<GetOrderEstimateItemDto>;
    /**
     * 
     * @type {Array<GetOrderEmployeeDto>}
     * @memberof GetOrderWithCurrencyDto
     */
    orderEmployees?: Array<GetOrderEmployeeDto>;
    /**
     * 
     * @type {number}
     * @memberof GetOrderWithCurrencyDto
     */
    systemNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrderWithCurrencyDto
     */
    status: GetOrderWithCurrencyDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrderWithCurrencyDto
     */
    rejectedNote: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetOrderWithCurrencyDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetOrderWithCurrencyDto
     */
    creationTs: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderWithCurrencyDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetOrderWithCurrencyDto
     */
    currencyCode: string;
}


/**
 * @export
 */
export const GetOrderWithCurrencyDtoStatusEnum = {
    New: 'New',
    Accepted: 'Accepted',
    Rejected: 'Rejected'
} as const;
export type GetOrderWithCurrencyDtoStatusEnum = typeof GetOrderWithCurrencyDtoStatusEnum[keyof typeof GetOrderWithCurrencyDtoStatusEnum];


/**
 * Check if a given object implements the GetOrderWithCurrencyDto interface.
 */
export function instanceOfGetOrderWithCurrencyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "resultProject" in value;
    isInstance = isInstance && "team" in value;
    isInstance = isInstance && "addTeamToEmails" in value;
    isInstance = isInstance && "emails" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "rejectedNote" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "creationTs" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "currencyCode" in value;

    return isInstance;
}

export function GetOrderWithCurrencyDtoFromJSON(json: any): GetOrderWithCurrencyDto {
    return GetOrderWithCurrencyDtoFromJSONTyped(json, false);
}

export function GetOrderWithCurrencyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderWithCurrencyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'project': GetProjectDtoFromJSON(json['project']),
        'resultProject': GetProjectDtoFromJSON(json['resultProject']),
        'team': GetTeamDtoFromJSON(json['team']),
        'addTeamToEmails': json['addTeamToEmails'],
        'emails': json['emails'],
        'orderEstimateItems': !exists(json, 'orderEstimateItems') ? undefined : ((json['orderEstimateItems'] as Array<any>).map(GetOrderEstimateItemDtoFromJSON)),
        'orderEmployees': !exists(json, 'orderEmployees') ? undefined : ((json['orderEmployees'] as Array<any>).map(GetOrderEmployeeDtoFromJSON)),
        'systemNumber': json['systemNumber'],
        'status': json['status'],
        'rejectedNote': json['rejectedNote'],
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'creationTs': (new Date(json['creationTs'])),
        'active': json['active'],
        'currencyCode': json['currencyCode'],
    };
}

export function GetOrderWithCurrencyDtoToJSON(value?: GetOrderWithCurrencyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'project': GetProjectDtoToJSON(value.project),
        'resultProject': GetProjectDtoToJSON(value.resultProject),
        'team': GetTeamDtoToJSON(value.team),
        'addTeamToEmails': value.addTeamToEmails,
        'emails': value.emails,
        'orderEstimateItems': value.orderEstimateItems === undefined ? undefined : ((value.orderEstimateItems as Array<any>).map(GetOrderEstimateItemDtoToJSON)),
        'orderEmployees': value.orderEmployees === undefined ? undefined : ((value.orderEmployees as Array<any>).map(GetOrderEmployeeDtoToJSON)),
        'systemNumber': value.systemNumber,
        'status': value.status,
        'rejectedNote': value.rejectedNote,
        'creator': GetEmployeeDtoToJSON(value.creator),
        'creationTs': (value.creationTs.toISOString()),
        'active': value.active,
        'currencyCode': value.currencyCode,
    };
}

