import React, { useMemo, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetPurchaseInvoiceForItem } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import { DownloadAttachment } from '../../../common/components/File/DownloadAttachment';
import { downloadZIP } from '../Projects/downloadZIPPurchaseInvoices';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PurchaseInvoicesGrid } from './PurchaseInvoicesGrid';

interface PurchaseInvoicesTabProps {
  estimateItemId?: string;
  downloadDocuments: boolean;
  number: string;
  name: string;
}

export const PurchaseInvoicesTab: React.FC<
  PurchaseInvoicesTabProps
> = props => {
  const [purchaseInvoices, setPurchaseInvoices] = useState<
    GetPurchaseInvoiceForItem[]
  >([]);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');

  useMemo(() => {
    if (props.estimateItemId) {
      pok.purchaseInvoices
        .getByEstimateItem(props.estimateItemId)
        .then(result => {
          if (result) {
            setPurchaseInvoices(result);
          }
        })
        .catch(error => {
          notifications.caughtError(error);
        });
    }
  }, [notifications, pok.purchaseInvoices, props.estimateItemId]);

  const generateData = () => {
    const data = purchaseInvoices.map(invoice => {
      return {
        key: invoice.id,
        values: {
          'invoice.number': invoice.purchaseInvoice.number,
          'invoice.issueDate': momencik(invoice.purchaseInvoice.issueDate),
          'invoice.sellDate': momencik(invoice.purchaseInvoice.sellDate),
          'invoice.systemNumber': invoice.purchaseInvoice.systemNumber,
          'invoice.amount': numberFormatter(invoice.amount, true),
          'invoice.netAmount': numberFormatter(invoice.netAmount, true),
          'client.name': invoice.purchaseInvoice.client?.name,
          'employee.name': `${invoice.owner?.name} (${momencik(
            invoice.ts,
            'L HH:mm:ss',
          )})`,
          preview: invoice.attachmentId ? (
            <DownloadAttachment id={invoice.attachmentId} icon={Icon.FilePdf} />
          ) : (
            <></>
          ),
        },
      };
    });
    return data;
  };

  const handleClick = async () => {
    await downloadZIP(
      props.name,
      props.number,
      purchaseInvoices?.map(invoiceItem => invoiceItem.purchaseInvoice.id) ||
        [],
      pok,
      notifications,
    );
  };

  return (
    <>
      <PurchaseInvoicesGrid data={generateData()} />
      {props.downloadDocuments &&
        purchaseInvoices &&
        purchaseInvoices.length > 0 && (
          <Button onClick={handleClick}>{t(tk.common.downloadDocs)}</Button>
        )}
    </>
  );
};
