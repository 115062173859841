/**
 * This file is shared between pok-core & pok-web
 * If you have to change it then do it in pok-core and run yarn get:validation-schemas @pok-web
 */

import * as yup from 'yup';
import * as _ from 'lodash';
import * as iban from 'iban';

import momencik from '../../momencik';

export enum ObjectEnum {
  Project = 'Project',
  Invoice = 'Invoice',
  CorrectInvoice = 'CorrectInvoice',
}

export enum PersonalLanguageType {
  Projects = 'Projects',
  Campaigns = 'Campaigns',
}

export enum PersonalLanguageTypeText {
  Projects = 'Projekty',
  Campaigns = 'Kampanie',
}

export enum LanguageType {
  PL = 'PL',
  EN = 'EN',
}

export enum LanguageTypeText {
  PL = 'Polski',
  EN = 'Angielski',
}

export enum PositionTypeEnum {
  Standard = 'Standardowa',
  Commission = 'Prowizja',
  TransferredCommission = 'Prowizja-Transferowana',
}

export enum ApplicationTypeEnum {
  Position = 'Position',
  Purchaser = 'Purchaser',
  DictionaryValue = 'DictionaryValue',
  Brand = 'Brand',
  Permission = 'Permission',
}

export enum ApplicationTypeEnumText {
  Position = 'Pozycja (dostawca)',
  Purchaser = 'Zamawiający (klient)',
  DictionaryValue = 'Wartość słownikowa',
  Brand = 'Brand',
  Permission = 'Uprawnienie (dostęp)',
}

export enum ApplicationStatusEnum {
  New = 'New',
  Completed = 'Completed',
  Rejected = 'Rejected',
}

export enum ApplicationStatusEnumText {
  New = 'Nowy',
  Completed = 'Zrealizowany',
  Rejected = 'Odrzucony',
}

export enum EmailStatus {
  NotSent = 'NotSent',
  Sent = 'Sent',
  Error = 'Error',
}

export enum EmailStatusText {
  NotSent = 'Niewysłany',
  Sent = 'Wysłany',
  Error = 'Błąd',
}

//TODO: usunac
export enum CurrencyIsoTypeEnum {
  PLN = 'PLN',
  USD = 'USD',
  EUR = 'EUR',
  CZK = 'CZK',
  RUB = 'RUB',
  HUF = 'HUF',
  GBP = 'GBP',
  BGN = 'BGN',
  RON = 'RON',
  HRK = 'HRK',
  AUD = 'AUD',
}

export enum ProjectEstimatesStatusEnum {
  New = 'New',
  AcceptedByClient = 'AcceptedByClient',
  ReadyForInvoice = 'ReadyForInvoice',
  Invoiced = 'Invoiced',
  Corrected = 'Corrected',
  Settled = 'Settled',
  Cancelled = 'Cancelled',
}

export enum ProjectEstimatesStatusEnumText {
  New = 'Nowy',
  AcceptedByClient = 'Zaakceptowany przez klienta',
  ReadyForInvoice = 'Gotowy do fakturowania',
  Invoiced = 'Zafakturowany',
  Corrected = 'Korygowany',
  Settled = 'Rozliczony',
  Cancelled = 'Anulowany',
}

export enum CustomExceptionType {
  ProjectDelayed = 'ProjectDelayed',
}

export enum PackageTypeEnum {
  Purchase = 'Purchase',
  Sale = 'Sale',
}

export enum InvoiceTypeEnum {
  Foreign = 'Foreign',
  Domestic = 'Domestic',
}

export enum InvoiceTypeEnumText {
  Foreign = 'Zagraniczna',
  Domestic = 'Krajowa',
}

export enum InvoiceStatusEnum {
  Draft = 'Draft',
  Generated = 'Generated',
  Sent = 'Sent',
  Paid = 'Paid',
}

export enum InvoiceStatusEnumText {
  Draft = 'Szkic',
  Generated = 'Zatwierdzona',
  Sent = 'Wysłana',
  Paid = 'Zapłacona',
}

export enum FilterInvoiceTypeEnum {
  PurchaseInvoice,
  SalesInvoice,
}

export enum PurchaseInvoiceStatusEnum {
  Open = 'Open',
  Approved = 'Approved',
  Sent = 'Sent',
}

export enum PurchaseInvoiceStatusEnumText {
  Open = 'Wprowadzona',
  Approved = 'Zatwierdzona',
  Sent = 'Wysłana',
}

export enum PaymentFormEnum {
  BankTransfer = 'BankTransfer',
  Compensation = 'Compensation',
  Prepayment = 'Prepayment',
  Paid = 'Paid',
}

export enum PaymentFormEnumText {
  BankTransfer = 'Przelew',
  Compensation = 'Kompensata',
  Prepayment = 'Przedpłata',
  Paid = 'Zapłacono',
}

export enum PaymentFormEnumSymfoniaText {
  BankTransfer = 'przelew',
  Compensation = 'kompensata',
  Prepayment = 'przedpłata',
  Paid = 'zapłacono',
}

export enum PaymentFormEnumEnglishText {
  BankTransfer = 'payment',
  Compensation = 'compensation',
  Prepayment = 'prepayment',
  Paid = 'paid',
}

export enum OrderStatusEnum {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum OrderStatusEnumText {
  New = 'Nowe',
  Accepted = 'Zaakceptowane',
  Rejected = 'Odrzucone',
}

export enum OrderExecutionStatusEnum {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum OrderExecutionStatusEnumText {
  New = 'Nowe',
  Accepted = 'Zaakceptowane',
  Rejected = 'Odrzucone',
}

export enum AttachmentObjectType {
  Budget = 'Budget',
  Project = 'Project',
  PurchaseInvoice = 'PurchaseInvoice',
  EstimateItemCommentMediaplanner = 'EstimateItemCommentMediaplanner',
  EstimateItemCommentFinance = 'EstimateItemCommentFinance',
}
export enum AttachmentObjectTypeText {
  Budget = 'Budżetem',
  Project = 'Projektem',
  PurchaseInvoice = 'Fakturą sprzedaży',
  EstimateItemCommentMediaplanner = 'Komentarzem mediaplanera do pozycji w kampanii',
  EstimateItemCommentFinance = 'Komentarzem finansów do pozycji w kampanii',
}

export enum PdfTemplateType {
  Invoice = 'Invoice',
  InvoiceEng = 'InvoiceEng',
  InvoiceCorrection = 'InvoiceCorrection',
  InvoiceCorrectionEng = 'InvoiceCorrectionEng',
  InvoiceDataPrintout = 'InvoiceDataPrintout',
  OrderConfirmation = 'OrderConfirmation',
}

export enum EmailTemplateType {
  Order = 'Order',
  OrderExecution = 'OrderExecution',
  Application = 'Application',
  ApplicationExecution = 'ApplicationExecution',
  Project = 'Project',
  ProjectCancel = 'ProjectCancel',
  UnblockEstimate = 'UnblockEstimate',
  PurchaseDeficiencies = 'PurchaseDeficiencies',
  SpecialInvoicing = 'SpecialInvoicing',
  UnsettledInvoices = 'UnsettledInvoices',
  DelayedInvoices = 'DelayedInvoices',
}

export enum ApplicationUnblockStatusEnum {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum ApplicationUnblockStatusEnumText {
  New = 'Nowy',
  Accepted = 'Zaakceptowany',
  Rejected = 'Odrzucony',
}

export enum ApplicationUnblockTypeEnum {
  Sales = 'Sales',
  Purchase = 'Purchase',
}

export enum ApplicationUnblockTypeEnumText {
  Sales = 'Sprzedaż',
  Purchase = 'Zakup',
}

export enum MemoSubjectType {
  Project = 'Project',
  PurchaseInvoice = 'PurchaseInvoice',
  SaleInvoice = 'SaleInvoice',
  SpecialInvoicing = 'SpecialInvoicing',
}

export enum MemoSubjectTypeText {
  Project = 'Kampania',
  PurchaseInvoice = 'Faktura zakupu',
  SaleInvoice = 'Faktura sprzedaży',
  SpecialInvoicing = 'Niestandardowe fakturowanie',
}

export enum ProjectApplicationTypeEnum {
  Delayed = 'Delayed',
  ToCancel = 'ToCancel',
  All = 'All',
}

export enum ProjectApplicationTypeEnumText {
  Delayed = 'Opóźniony',
  ToCancel = 'Do anulowania',
  All = 'Wszystkie',
}

export enum SpecialInvoicingStatus {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  ToBeCorrected = 'ToBeCorrected',
}

export enum SpecialInvoicingStatusText {
  New = 'Nowe',
  Accepted = 'Zaakceptowane',
  Rejected = 'Odrzucone',
  ToBeCorrected = 'Wymaga korekty',
}

export enum ExcelImportTypeEnum {
  Others = 'Others',
  TVBuying = 'TVBuying',
  MPC = 'MPC',
}

export enum ExcelImportTypeEnumText {
  Others = 'Standardowy',
  TVBuying = 'TV Zakup',
  MPC = 'MPC',
}

export enum EmailConfigTypeEnum {
  Applications = 'Applications',
  PositionAppForeign = 'PositionAppForeign',
  ApplicationExecutions = 'ApplicationExecutions',
  Orders = 'Orders',
  OrdersRemind = 'OrdersRemind',
  OrderExecutions = 'OrderExecutions',
  OrderExecutionsRemind = 'OrderExecutionsRemind',
  UnblockEstimateByMonth = 'UnblockEstimateByMonth',
  ProjectsDelayed = 'ProjectsDelayed',
  ProjectsToCancel = 'ProjectsToCancel',
  PurchaseDeficiencies = 'PurchaseDeficiencies',
  SpecialInvoicing = 'SpecialInvoicing',
}

export enum EmailConfigTypeEnumText {
  Applications = 'Wniosek',
  PositionAppForeign = 'Wniosek o pozycję zagraniczną',
  ApplicationExecutions = 'Realizacja wniosku',
  Orders = 'Zamówienie',
  OrdersRemind = 'Zamówienie - przypomnienie',
  OrderExecutions = 'Realizacja zamówienia',
  OrderExecutionsRemind = 'Realizacja zamówienia - przypomnienie',
  UnblockEstimateByMonth = 'Wniosek o odblokowanie sprzedaży/zakupu',
  ProjectsDelayed = 'Założenie kampanii/projektu po terminie',
  ProjectsToCancel = 'Anulowanie kampanii/projektu',
  PurchaseDeficiencies = 'Wyjaśnianie braków zakupowych',
  SpecialInvoicing = 'Niestandardowe fakturowanie',
}

export enum ExcelImportStatusEnum {
  OK = 'OK',
  Error = 'Error',
  Duplicate = 'Duplicate',
}

export enum ExcelImportStatusEnumText {
  OK = 'OK',
  Error = 'Błąd',
  Duplicate = 'Duplikat',
}

export enum JobTypeEnum {
  OrderExecutionReminder = 'OrderExecutionReminder',
  OrderReminder = 'OrderReminder',
  DailyFVZReminder = 'DailyFVZReminder',
  WeekFVZReminder = 'WeekFVZReminder',
}

export enum JobTypeEnumText {
  OrderExecutionReminder = 'Realizacja zamówienia - przypomnienie',
  OrderReminder = 'Zamówienie - przypomnienie',
  DailyFVZReminder = 'FVZ - przypomnienie dzienne',
  WeekFVZReminder = 'FVZ - przypomnienie tygodniowe',
}

export enum TemplateTypeEnum {
  Poland = 'Poland',
  UnitedStates = 'UnitedStates',
  Germany = 'Germany',
}

export enum TemplateTypeEnumText {
  Poland = 'Polska',
  UnitedStates = 'Stany Zjednoczone',
  Germany = 'Niemcy',
}

export const buildExchangeDescription = (table: string, date: Date): string => {
  return `Tabela kursów średnich NBP nr ${table} z dnia ${momencik(date)}.`;
};

const digits = /^[0-9]+$/;

function validNip(nip: string | null | undefined) {
  if (typeof nip !== 'string') {
    return false;
  }

  nip = nip.replace(/[ -]/gi, '');
  if (nip.length !== 10) {
    return false;
  }
  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }
  return sum % 11 === controlNumber;
}

function digitsOnly(text: string | null | undefined) {
  if (!text) {
    return true;
  }

  if (!text.match(digits)) {
    return false;
  }
  return true;
}

export function formatAccountNumber(number: string) {
  if (iban.isValid(number) || iban.isValid('PL' + number)) {
    const cleanNumber = number.replace(/\s+/g, '');
    const hasPrefix = isNaN(+cleanNumber.substring(0, 2));

    const format = (str: string) => str.replace(/(.{4})/g, '$1 ').trim();

    if (!hasPrefix) {
      const prefix = cleanNumber.substring(0, 2);
      const rest = cleanNumber.substring(2);
      return `${prefix} ${format(rest)}`;
    }

    return format(cleanNumber);
  } else {
    return number;
  }
}

function validPostcode(postcode: string | null | undefined) {
  if (!postcode) {
    return false;
  }

  const match = postcode.match(/^[0-9]{2}-[0-9]{3}$/);
  if (!match) {
    return false;
  }
  return true;
}

const IBANSchema = yup
  .string()
  .test(
    'is-valid-iban',
    'Niepoprawny numer konta',
    value =>
      value !== null &&
      (iban.isValid(value || '') || iban.isValid('PL' + value || '')),
  );

export const NipSchema = yup.object().shape({
  nip: yup
    .string()
    .nullable()
    .required('Proszę podać numer NIP.')
    .test('valid nip', 'Podany NIP jest niepoprawny.', validNip)
    .test(
      'only digits in nip',
      'Numer NIP powinien się składać wyłącznie z cyfr.',
      digitsOnly,
    ),
});

const notEarlierThan = (otherDate: string) => {
  function compare(value: Date | undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const date = this.parent[otherDate];
    if (!date || !value || !(date instanceof Date)) {
      return true;
    }
    const thisDate = new Date(value).setHours(0, 0, 0, 0);
    const thatDate = new Date(date).setHours(0, 0, 0, 0);

    return thisDate >= thatDate;
  }
  return compare;
};

const ClientSchemaFields = {
  name: yup.string().nullable().required('Proszę podać nazwę kontrahenta.'),
  country: yup.string().nullable().required('Proszę wskazać państwo.'),
  nip: yup.string().when('country', {
    is: (val: string) => val === 'Polska',
    then: schema =>
      schema
        .nullable()
        .test('valid nip', 'Podany NIP jest niepoprawny.', validNip)
        .test(
          'only digits in nip',
          'Numer NIP powinien się składać wyłącznie z cyfr.',
          digitsOnly,
        ),
    otherwise: schema => schema.nullable(),
  }),
  postcode: yup.string().when('country', {
    is: (val: string) => val === 'Polska',
    then: schema =>
      schema
        .nullable()
        .test(
          'valid postcode',
          'Poczta powinna być w formacie XX-XXX.',
          validPostcode,
        ),
    otherwise: schema => schema.nullable(),
  }),
  place: yup.string().when('country', {
    is: (val: string) => val === 'Polska',
    then: schema =>
      schema.nullable().required('Proszę wprowadzić miejscowość.'),
    otherwise: schema => schema.nullable(),
  }),
  addressEx: yup.string().when('country', {
    is: (val: string) => val === 'Polska',
    then: schema => schema.nullable(),
    otherwise: schema =>
      schema
        .nullable()
        .required('Dla podmiotów zagranicznych należy podać adres.'),
  }),
};

export const ClientSchema = yup.object().shape({
  ...ClientSchemaFields,
});

export const CompanySchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę spółki.'),
  shortname: yup.string().nullable().required('Proszę podać skrót spółki.'),
  personalLanguage: yup
    .string()
    .nullable()
    .required('Proszę wybrać nazewnictwo.'),
  projectMaxDaysDelay: yup
    .number()
    .integer('Maksymalne opóźnienie musi być liczbą całkowitą i nieujemną.')
    .typeError('Maksymalne opóźnienie musi być liczbą całkowitą i nieujemną.')
    .min(0, 'Maksymalne opóźnienie musi być liczbą całkowitą i nieujemną.')
    .nullable()
    .required(
      'Proszę podać maksymalne dopuszczalne opóźnienie zakładania kampanii.',
    ),
  orderField: yup
    .number()
    .integer('Liczba porządkowa musi być liczbą całkowitą i nieujemną.')
    .typeError('Liczba porządkowa musi być liczbą całkowitą i nieujemną.')
    .min(0, 'Liczba porządkowa musi być liczbą całkowitą i nieujemną.')
    .nullable(),
  templateType: yup
    .string()
    .nullable()
    .required('Proszę wybrać szablon faktur.'),
  mainCurrencyId: yup
    .string()
    .nullable()
    .required('Proszę wybrać walutę główną.'),
  language: yup.string().nullable().required('Proszę wybrać język spółki.'),
});

export const DictionarySchema = yup.object().shape({
  value: yup.string().nullable().required('Proszę podać wartość słownika.'),
  valueEn: yup
    .string()
    .nullable()
    .required('Proszę podać angielską wersję wartości słownika.'),
});

export const RoleSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę roli.'),
  permissions: yup
    .array()
    .of(yup.string())
    .required('Proszę wybrać uprawnienia.'),
});

export const EmployeeRoleSchema = yup.object().shape({
  userId: yup.string().nullable().required('Proszę wybrać pracownika.'),
  roleId: yup.string().nullable().required('Proszę wybrać rolę.'),
});

export const EmployeeRolesSchema = yup.object().shape({
  employeeId: yup.string().nullable().required('Proszę wybrać użytkownika.'),
  rolesIds: yup
    .array()
    .of(yup.string().nullable())
    .required('Proszę wybrać role.')
    .min(1, 'Proszę wybrać co najmniej jedną rolę.'),
});

export const RoleEmployeesSchema = yup.object().shape({
  roleId: yup.string().nullable().required('Proszę wybrać rolę.'),
});

export const TeamSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę zespołu.'),
  email: yup
    .string()
    .nullable()
    .required('Proszę podać adres e-mail.')
    .test({
      name: 'emails',
      test: function (value) {
        const firstInvalidEmail =
          value &&
          value
            .split(',')
            .map(email => email.trim())
            .filter(v => v.length > 0)
            .find(v => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: `Adres e-mail: '${firstInvalidEmail}' jest niepoprawny. Kilka adresów proszę rozdzielić przecinkiem. `,
            });
      },
    }),
  managerId: yup.string().required('Proszę podać szefa zespołu.'),
});

const isEmailSchema = yup.string().email();

export const BudgetSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę budżetu.'),
  managerId: yup.string().nullable().required('Proszę podać szefa budżetu.'),
});

export const BrandSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę brandu.'),
  budgetId: yup.string().nullable().required('Proszę podać budżet brandu.'),
});

export const PurchaserSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę zamawiającego.'),
  companyId: yup.string().nullable().required('Proszę wybrać spółkę.'),
  clientId: yup.string().nullable().required('Proszę wybrać kontrahenta.'),
  budgetId: yup
    .string()
    .nullable()
    .required('Proszę wybrać budżet kontrahenta.'),
  teamIds: yup.array().of(yup.string()).nullable(),
});

export const PositionSchema = yup.object().shape({
  clientId: yup.string().required('Proszę wybrać kontrahenta.'),
  name: yup.string().required('Proszę podać nazwę.'),
  groupName: yup.string().required('Proszę podać nazwę grupy.'),
  dictionaryId: yup.string().required('Proszę wybrać kanał komunikacji.'),
  positionType: yup
    .mixed<PositionTypeEnum>()
    .oneOf(Object.values(PositionTypeEnum))
    .required('Proszę wybrać typ pozycji.'),
  companyIds: yup
    .array()
    .required('Proszę wybrać spółkę.')
    .min(1, 'Proszę wybrać co najmniej jedną spółkę.'),
  profileIds: yup
    .array()
    .required('Proszę wybrać profil.')
    .min(1, 'Proszę wybrać co najmniej jeden profil.'),
});

export enum VatEnum {
  '23%' = '23%',
  '19%' = '19%',
  '8%' = '8%',
  '5%' = '5%',
  '0%' = '0%',
  'zw.' = 'zw.',
  'np.' = 'np.',
}

export const AttachmentSchema = yup
  .object()
  .shape({
    name: yup.string().nullable(),
    note: yup.string().nullable(),
    dictionaryShortname: yup.string().nullable(),
    dictionaryTypeId: yup
      .string()
      .nullable()
      .required('Należy wskazać typ załącznika.'),
    enObjectType: yup
      .mixed<AttachmentObjectType>()
      .oneOf(Object.values(AttachmentObjectType))
      .required('Typ obiektu skojarzonego z załącznikiem musi być ustalony.'),
    companyId: yup.string().nullable().required('Należy wskazać spółkę.'),
    clientId: yup.string().when('dictionaryShortname', {
      is: (val: string) => val === 'POZ',
      then: schema => schema.nullable().required('Proszę podać fakturującego.'),
      otherwise: schema => schema.nullable(),
    }),
    estimateItemsIds: yup
      .array()
      .of(yup.string().nullable())
      .when('dictionaryShortname', {
        is: (val: string) => val === 'POZ',
        then: schema =>
          schema
            .nullable()
            .required('Proszę wskazać pozycje, których dotyczy ten załącznik.')
            .min(1, 'Proszę wybrać co najmniej jedną powiązaną pozycję.'),
        otherwise: schema => schema.nullable(),
      }),
  })
  .test(
    'name-or-note',
    'Proszę dodać załącznik lub wpisać notatkę.',
    object => object?.name !== 'Notatka' || !!object?.note,
  );

export const ProjectSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę.'),
  purchaserId: yup.string().nullable().required('Proszę wybrać zamawiającego.'),
  teamIds: yup
    .array()
    .of(yup.string().nullable())
    .required('Proszę wybrać zespół.')
    .min(1, 'Proszę wybrać co najmniej jeden zespół.'),
  companyId: yup.string().nullable().required('Błąd przy ustawianiu spółki.'),
  countryDictionaryId: yup
    .string()
    .nullable()
    .required('Proszę wybrać kraj/rynek.'),
  startDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(
      new Date(2024, 0, 1),
      'Data rozpoczęcia nie może być wcześniejsza niż 1 stycznia 2024.',
    )
    .required('Proszę podać datę rozpoczęcia.'),
  endDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not earlier than date',
      'Data zakończenia nie może być wcześniejsza od daty rozpoczęcia.',
      notEarlierThan('startDate'),
    )
    .required('Proszę podać datę zakończenia.'),
  delayMessage: yup.string().when('delayVerification', {
    is: (val: boolean) => val === true,
    then: schema =>
      schema.nullable().required('Proszę uzupełnić treść wniosku.'),
    otherwise: schema => schema.nullable(),
  }),
  profileId: yup
    .string()
    .nullable()
    .when('id', {
      is: (value: string | undefined) => {
        return value === undefined;
      },
      then: schema => schema.nullable().required('Proszę wybrać profil'),
    }),
});

export const ProjectDeactivateSchema = yup.object().shape({
  rejectedNote: yup.string().required('Proszę podać uzasadnienie odrzucenia.'),
});

export const ProjectApplicationRejectSchema = yup.object().shape({
  rejectedNote: yup.string().required('Proszę podać uzasadnienie odrzucenia.'),
});

export const SymphonyClientSchema = yup.object().shape({
  code: yup
    .number()
    .integer('Wartość musi być liczbą całkowitą.')
    .typeError('Wartość musi być liczbą całkowitą.')
    .min(0, 'Kod musi być liczbą nieujemną.')
    .nullable()
    .required('Proszę podać kod Symfonii.'),
  companyId: yup.string().nullable().required('Proszę wybrać spółkę.'),
});

export const CloneProjectSchema = yup.object().shape({
  name: yup.string().required('Proszę podać nazwę.'),
});

const ProfileSchemaItemSchema = yup.object().shape({
  displayNamePl: yup.string().optional(),
  displayNameEn: yup.string().optional(),
  required: yup.boolean().optional(),
});

export const ProfileSchemaSchema = yup
  .object()
  .shape({
    rcSalesTotal: ProfileSchemaItemSchema.optional(),
    salesSurchargePercent: ProfileSchemaItemSchema.optional(),
    salesDiscountPercent: ProfileSchemaItemSchema.optional(),
    salesNet: ProfileSchemaItemSchema.optional(),
    salesCommissionPercent: ProfileSchemaItemSchema.optional(),
    salesCommissionAmount: ProfileSchemaItemSchema.optional(),
    salesNetTechnicalCost: ProfileSchemaItemSchema.optional(),
    salesNetTotal: ProfileSchemaItemSchema.optional(),
    rcPurchase: ProfileSchemaItemSchema.optional(),
    purchaseTechnicalCost: ProfileSchemaItemSchema.optional(),
    purchaseDiscountPercent: ProfileSchemaItemSchema.optional(),
    purchaseNetTotal: ProfileSchemaItemSchema.optional(),
    purchaseNet: ProfileSchemaItemSchema.optional(),
    purchaseSurchargePercent: ProfileSchemaItemSchema.optional(),
  })
  .optional()
  .test('is-unique-pl', function (value) {
    if (value) {
      const displayNames = Object.values(value)
        .map(schemaItem => schemaItem?.displayNamePl)
        .filter(displayNamePl => displayNamePl);
      if (displayNames.length) {
        const counts = _.countBy(displayNames);
        const duplicatedDisplayNames = _.keys(counts).filter(
          key => counts[key] > 1,
        );
        if (duplicatedDisplayNames.length) {
          return this.createError({
            message: `Nazwy nie są unikalne (${duplicatedDisplayNames.join(
              ', ',
            )}).`,
          });
        }
      }
    }
    return true;
  })
  .test('is-unique-en', function (value) {
    if (value) {
      const displayNames = Object.values(value)
        .map(schemaItem => schemaItem?.displayNameEn)
        .filter(displayNameEn => displayNameEn);
      if (displayNames.length) {
        const counts = _.countBy(displayNames);
        const duplicatedDisplayNames = _.keys(counts).filter(
          key => counts[key] > 1,
        );
        if (duplicatedDisplayNames.length) {
          return this.createError({
            message: `Nazwy angielskie nie są unikalne (${duplicatedDisplayNames.join(
              ', ',
            )}).`,
          });
        }
      }
    }
    return true;
  });

export const ProfileSchema = yup.object().shape({
  name: yup.string().required('Proszę podać nazwę profilu.'),
  schema: ProfileSchemaSchema.optional(),
});

export const ProfileCategorySchema = yup.object().shape({
  name: yup.string().required('Proszę podać nazwę kategorii profilu.'),
  purchasesFinancialAccount: yup
    .string()
    .required('Proszę podać konto księgowe zakupu.'),
  salesFinancialAccount: yup
    .string()
    .required('Proszę podać konto księgowe sprzedaży.'),
  commissionFinancialAccount: yup
    .string()
    .required('Proszę podać konto księgowe prowizji.'),
  profileId: yup.string().nullable().required('Proszę wybrać profil.'),
});

const ApplicationSchemaCommonFields = {
  applicationType: yup
    .string()
    .nullable()
    .required('Proszę wybrać typ wniosku.'),
  teamId: yup.string().nullable().required('Proszę wybrać zespół.'),
  elementName: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val !== ApplicationTypeEnum.Permission,
    then: schema =>
      schema
        .nullable()
        .required('Proszę uzupełnić nazwę wnioskowanego elementu.'),
    otherwise: schema => schema.nullable(),
  }),
  profileId: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val === ApplicationTypeEnum.Position,
    then: schema => schema.nullable().required('Proszę wybrać profil.'),
    otherwise: schema => schema.nullable(),
  }),
  description: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) =>
      val === ApplicationTypeEnum.DictionaryValue,
    then: schema => schema.nullable().required('Proszę podać opis.'),
    otherwise: schema => schema.nullable(),
  }),
  brandApplicationPurchaserId: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val === ApplicationTypeEnum.Brand,
    then: schema => schema.nullable().required('Proszę wybrać zamawiającego.'),
    otherwise: schema => schema.nullable(),
  }),
  permissionEmployeeId: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val === ApplicationTypeEnum.Permission,
    then: schema => schema.nullable().required('Proszę uzupełnić pracownika.'),
    otherwise: schema => schema.nullable(),
  }),
  permissionEmployeeBossId: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val === ApplicationTypeEnum.Permission,
    then: schema =>
      schema.nullable().required('Proszę uzupełnić przełożonego pracownika.'),
    otherwise: schema => schema.nullable(),
  }),
};
export const ApplicationSchema = yup.object().shape({
  ...ApplicationSchemaCommonFields,
});

export const ApplicationWithClientSchema = yup.object().shape({
  ...ApplicationSchemaCommonFields,
  ...ClientSchemaFields,
});

export const ApplicationAdminSchema = yup.object().shape({
  rejectedNote: yup
    .string()
    .nullable()
    .when('applicationStatus', {
      is: ApplicationStatusEnum.Rejected,
      then: schema => schema.required('Proszę uzupełnić powód odrzucenia.'),
      otherwise: schema => schema.nullable(),
    }),
  positionId: yup
    .string()
    .nullable()
    .when(
      ['applicationStatus', 'applicationType'],
      ([applicationStatus, applicationType], schema) =>
        applicationStatus === ApplicationStatusEnum.Completed &&
        applicationType === ApplicationTypeEnum.Position
          ? schema.required('Proszę uzupełnić pozycję.')
          : schema,
    ),
  purchaserId: yup
    .string()
    .nullable()
    .when(
      ['applicationStatus', 'applicationType'],
      ([applicationStatus, applicationType], schema) =>
        applicationStatus === ApplicationStatusEnum.Completed &&
        applicationType === ApplicationTypeEnum.Purchaser
          ? schema.required('Proszę uzupełnić zamawiającego.')
          : schema,
    ),
  brandId: yup
    .string()
    .nullable()
    .when(
      ['applicationStatus', 'applicationType'],
      ([applicationStatus, applicationType], schema) =>
        applicationStatus === ApplicationStatusEnum.Completed &&
        applicationType === ApplicationTypeEnum.Brand
          ? schema.required('Proszę uzupełnić brand.')
          : schema,
    ),
});

export const CurrencyIsoSchema = yup.object().shape({
  isoCode: yup
    .mixed<CurrencyIsoTypeEnum>()
    .oneOf(Object.values(CurrencyIsoTypeEnum))
    .required('Proszę podać prawidłowy kod ISO waluty.'),
});

export const EstimateByMonthSchema = yup.object().shape({
  profileId: yup.string().nullable().required('Proszę wybrać profil.'),
  projectId: yup
    .string()
    .nullable()
    .required('Nie wskazano kampanii/projektu.'),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę wskazać miesiąc.'),
});

export const EstimateItemSchema = yup.object().shape({
  profileCategoryId: yup
    .string()
    .nullable()
    .required('Proszę wybrać kategorię profilu.'),
  startDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę rozpoczęcia.'),
  endDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not earlier than date',
      'Data zakończenia nie może być wcześniejsza od daty rozpoczęcia.',
      notEarlierThan('startDate'),
    )
    .required('Proszę podać datę zakończenia.'),
  positionId: yup
    .string()
    .nullable()
    .required('Proszę wybrać pozycję profilu.'),
});

export const PurchaseInvoicePositionSchema = yup.object().shape({
  netAmount: yup.number().required('Proszę podać kwotę netto pozycji faktury.'),
  vat: yup
    .mixed<VatEnum>()
    .oneOf(Object.values(VatEnum))
    .required('Proszę wybrać stawkę VAT.'),
  vatAmount: yup.number().required('Proszę podać kwotę VAT pozycji faktury'),
});

export const PurchaseInvoiceGeneralTabSchema = yup.object().shape({
  number: yup.string().required('Proszę podać numer faktury.'),
  sellDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę sprzedaży.'),
  issueDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę wystawienia.'),
  paymentDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać termin płatności.'),
  clientId: yup.string().required('Proszę wybrać kontrahenta.'),
  companyId: yup.string().required('Proszę podać spółkę.'),
  positions: yup
    .array()
    .of(PurchaseInvoicePositionSchema)
    .min(1, 'Proszę dodać przynajmniej jedną pozycję.')
    .required('Proszę dodać przynajmniej jedną pozycję.'),
  currency: yup
    .mixed<CurrencyIsoTypeEnum>()
    .oneOf(Object.values(CurrencyIsoTypeEnum))
    .required('Proszę wybrać walutę.'),
  type: yup
    .mixed<InvoiceTypeEnum>()
    .oneOf(Object.values(InvoiceTypeEnum))
    .required('Proszę wybrać typ faktury.'),
  exchangeRate: yup.number().optional(),
  attachment: yup.object().required('Proszę dodać załącznik.'),
});

const PurchaseInvoiceTeamsProjectsTabSchemaShape = {
  teamIds: yup.array().of(yup.string()).optional(),
  projectIds: yup.array().of(yup.string()).optional(),
};

export const PurchaseInvoiceTeamsProjectsTabSchema = yup
  .object()
  .shape(PurchaseInvoiceTeamsProjectsTabSchemaShape)
  .test('has-teams-or-projects', function (value) {
    if (!value.projectIds?.length && !value.teamIds?.length) {
      return this.createError({
        message: 'Proszę dodać kampanie/projekty lub zespoły.',
      });
    }
    return true;
  });

export const PurchaseInvoiceEstimateItemSchema = yup
  .object()
  .shape({
    estimateItemId: yup.string().required('Proszę wybrać pozycję mediaplanu.'),
  })
  .required();

export const PurchaseInvoiceEstimateItemsTabSchema = yup
  .object()
  .shape({
    purchaseInvoiceEstimateItems: yup
      .array()
      .of(PurchaseInvoiceEstimateItemSchema)
      .optional(),
  })
  .test('hasAmounts', function () {
    for (const item of this.originalValue.purchaseInvoiceEstimateItems || []) {
      if (!item.amount) {
        return this.createError({
          message: 'Proszę podać kwotę.',
        });
      }
    }
    return true;
  })
  .required();

export const SalesInvoiceSchema = yup.object().shape({
  invoiceDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę wystawienia.'),
  saleDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę sprzedaży.'),
  payableDaysId: yup.string().when('paymentForm', {
    is: (paymentForm: string) => paymentForm === PaymentFormEnum.BankTransfer,
    then: schema => schema.required('Proszę podać termin płatności.'),
    otherwise: schema => schema.nullable(),
  }),

  onePositionName: yup
    .string()
    .nullable()
    .when(['isOnePositionOnInvoice'], ([isOnePositionOnInvoice], schema) =>
      isOnePositionOnInvoice
        ? schema.required('Proszę uzupełnić nazwę pozycji.')
        : schema,
    ),
});
export const SalesInvoicePositionSchema = yup.object().shape({
  name: yup.string().required('Proszę podać nazwę pozycji.'),
  amount: yup
    .number()
    .typeError('Proszę podać kwotę.')
    .min(0, 'Kwota pozycji musi być liczbą nieujemną.')
    .nullable()
    .required('Proszę podać kwotę.'),
  vat: yup
    .mixed<VatEnum>()
    .oneOf(Object.values(VatEnum))
    .required('Proszę wybrać stawkę VAT.'),
});
export const InvoiceEstimateItemSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError('Proszę podać kwotę sprzedaży.')
    .min(0, 'Kwota pozycji musi być liczbą nieujemną.')
    .nullable()
    .required('Proszę podać kwotę sprzedaży.'),
  commissionAmount: yup
    .number()
    .typeError('Proszę podać kwotę prowizji.')
    .min(0, 'Kwota prowizji musi być liczbą nieujemną.')
    .nullable()
    .required('Proszę podać kwotę prowizji.'),
  estimateItemId: yup.string().required('Proszę wybrać pozycję mediaplanu.'),
  salesInvoiceId: yup.string().required('Proszę wybrać fakturę.'),
});

export const BankAccountSchema = yup.object().shape({
  companyId: yup.string().nullable().required('Proszę wybrać spółkę.'),
  company: yup.object().shape({
    mainCurrency: yup.object().shape({
      currencyCode: yup.string().nullable(),
    }),
  }),
  number: yup.string().when('company.mainCurrency.currencyCode', {
    is: (cur: string) => cur !== 'USD',
    then: () => IBANSchema.nullable().required('Proszę podać numer konta.'),
    otherwise: schema =>
      schema.nullable().required('Proszę podać numer konta.'),
  }),
  currency: yup
    .mixed<CurrencyIsoTypeEnum>()
    .oneOf(Object.values(CurrencyIsoTypeEnum))
    .required('Proszę wybrać walutę.'),
  invoiceType: yup
    .mixed<InvoiceTypeEnum>()
    .oneOf(Object.values(InvoiceTypeEnum))
    .required('Proszę wybrać typ faktury.'),
  swiftBIC: yup.string().nullable().required('Proszę podać kod SWIFT'),
  bankName: yup.string().nullable().required('Proszę podać nazwę banku'),
  vatNumber: yup
    .string()
    .nullable()
    .when(
      ['invoiceType', 'currency', 'company.mainCurrency.currencyCode'],
      ([invoiceType, currency, currencyCode], schema) =>
        invoiceType === InvoiceTypeEnum.Domestic &&
        currency !== CurrencyIsoTypeEnum.PLN &&
        currencyCode === 'PLN'
          ? IBANSchema.nullable().required('Proszę podać numer konta VAT.')
          : schema,
    ),
  vatBankName: yup
    .string()
    .nullable()
    .when(
      ['invoiceType', 'currency', 'company.mainCurrency.currencyCode'],
      ([invoiceType, currency, currencyCode], schema) =>
        invoiceType === InvoiceTypeEnum.Domestic &&
        currency !== CurrencyIsoTypeEnum.PLN &&
        currencyCode === 'PLN'
          ? schema.required('Proszę podać nazwę banku VAT.')
          : schema,
    ),
});

export const OrderSchema = yup
  .object()
  .shape({
    addTeamToEmails: yup.boolean().nullable(),
    emails: yup.array(),
    projectId: yup.string().nullable().required('Proszę podać projekt.'),
    teamId: yup
      .string()
      .nullable()
      .required('Proszę wybrać zespół specjalistyczny.'),
  })
  .test(
    'mail',
    'Wybierz odbiorców lub zaznacz wysyłkę maila na adres zespołu specjalistycznego.',
    object =>
      object?.addTeamToEmails || (object?.emails && object?.emails?.length > 0),
  );

export const OrderRejectSchema = yup.object().shape({
  rejectedNote: yup.string().required('Proszę podać uzasadnienie odrzucenia.'),
});

export const OrderExecutionSchema = yup
  .object()
  .shape({
    addTeamToEmails: yup.boolean().nullable(),
    emails: yup.array(),
    orderExecutionPositions: yup
      .array()
      .min(1, 'Wymagana jest minimum jedna pozycja w realizacji zamówienia.')
      .of(
        yup.object().shape({
          name: yup.string().test('name-length', function (value) {
            const { path, createError } = this;
            return (
              (value && value.length > 0) ||
              createError({
                path,
                message: `L.p. ${this.parent.order}: proszę podać nazwę pozycji.`,
              })
            );
          }),
          amount: yup.string().test('amount-length', function (value) {
            const { path, createError } = this;
            return (
              (value && value.length > 0) ||
              createError({
                path,
                message: `L.p. ${this.parent.order}: proszę podać kwotę pozycji.`,
              })
            );
          }),
        }),
      ),
    date: yup.date().required('Proszę wybrać miesiąc.'),
  })
  .test(
    'mail',
    'Wybierz odbiorców lub zaznacz wysyłkę maila na adres zespołu.',
    object =>
      object?.addTeamToEmails || (object?.emails && object?.emails?.length > 0),
  );

export const OrderExecutionRejectSchema = yup.object().shape({
  rejectedNote: yup.string().required('Proszę podać uzasadnienie odrzucenia.'),
});

export const ApplicationUnblockDecisionSchema = yup.object().shape({
  rejectedNote: yup
    .string()
    .nullable()
    .when('status', {
      is: ApplicationUnblockStatusEnum.Rejected,
      then: schema => schema.required('Proszę uzupełnić powód odrzucenia.'),
      otherwise: schema => schema.nullable(),
    }),
  status: yup
    .mixed<ApplicationUnblockStatusEnum>()
    .oneOf(Object.values(ApplicationUnblockStatusEnum))
    .required('Proszę wybrać status.'),
});

export const ApplicationUnblockSchema = yup.object().shape({
  estimateByMonthId: yup
    .string()
    .nullable()
    .required('Proszę wybrac miesięczny profil.'),
  description: yup
    .string()
    .nullable()
    .required('Proszę podać powód zgłoszenia.'),
  type: yup
    .mixed<ApplicationUnblockTypeEnum>()
    .oneOf(Object.values(ApplicationUnblockTypeEnum))
    .required('Proszę wybrać typ zgłoszenia.'),
  inDocumentation: yup
    .boolean()
    .nullable()
    .required('Proszę zaznaczyć, czy zmiana ma potwierdzenie w dokumentacji.'),
  salesCorrect: yup
    .boolean()
    .nullable()
    .required('Proszę zaznaczyć, czy zgłoszenie dotyczy korekty sprzedaży.'),
});

export const BlockadeMonthSchema = yup
  .object()
  .shape({
    companyId: yup.string().nullable().required('Proszę wybrac spółkę.'),
    date: yup.date().required('Proszę wybrać miesiąc.'),
    isSalesBlocked: yup.boolean().nullable(),
    isPurchaseBlocked: yup.boolean().nullable(),
  })
  .test(
    'sale-or-purchase',
    'Proszę zaznaczyć blokadę sprzedaży lub zakupu.',
    object => object?.isSalesBlocked || !!object?.isPurchaseBlocked,
  );

export const PrivateMemoSchema = yup.object().shape({
  recipientId: yup
    .string()
    .nullable()
    .required('Wiadomość musi być skierowana do kogoś.'),
  content: yup.string().nullable().required('Należy podać treść notatki.'),
});

export const PublicMemoSchema = yup.object().shape({
  content: yup.string().nullable().required('Należy podać treść notatki.'),
});

export const ExcelConfigSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę konfiguracji.'),
  profileId: yup.string().nullable().required('Proszę wybrać profil.'),
  profileCategoryId: yup
    .string()
    .nullable()
    .required('Proszę wybrać kategorię profilu.'),
  excelConfigTeamsIds: yup
    .array()
    .of(yup.string().nullable())
    .required('Proszę wybrać zespół.')
    .min(1, 'Proszę wybrać co najmniej jeden zespół.'),
});

export const SpecialInvoicingSchema = yup.object().shape({
  description: yup.string().nullable().required('Proszę podać treść wniosku.'),
  note: yup.string().nullable().required('Proszę podać opis fakturowania.'),
  projectIds: yup
    .array()
    .of(yup.string())
    .required('Proszę dodać przynajmniej jedną kampanię/projekt.')
    .min(1, 'Proszę dodać przynajmniej jedną kampanię/projekt.'),
});

export const SpecialInvoicingDecisionSchema = yup.object().shape({
  rejectedNote: yup
    .string()
    .nullable()
    .when('status', {
      is: SpecialInvoicingStatus.Rejected,
      then: schema => schema.required('Proszę uzupełnić powód odrzucenia.'),
      otherwise: schema => schema.nullable(),
    }),
  correctionNote: yup
    .string()
    .nullable()
    .when('status', {
      is: SpecialInvoicingStatus.ToBeCorrected,
      then: schema => schema.required('Proszę uzupełnić wymagane zmiany.'),
      otherwise: schema => schema.nullable(),
    }),
  status: yup
    .mixed<SpecialInvoicingStatus>()
    .notOneOf([SpecialInvoicingStatus.New], 'Proszę ustawić status wniosku.')
    .required('Proszę ustawić status wniosku.'),
  financeNote: yup
    .string()
    .nullable()
    .when('status', {
      is: SpecialInvoicingStatus.Accepted,
      then: schema =>
        schema.required('Proszę uzupełnić zaakceptowany opis fakturowania.'),
      otherwise: schema => schema.nullable(),
    }),
});

export const ExcelConfigHeaderSchema = yup.object().shape({
  pokColumnName: yup.string().nullable().required('Proszę wybrać pole w POK.'),
  excelColumnName: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę kolumny w Excelu.'),
  order: yup
    .number()
    .integer('Kolejność musi być liczbą całkowitą i nieujemną.')
    .typeError('Kolejność musi być liczbą całkowitą i nieujemną.')
    .min(0, 'Kolejność musi być liczbą całkowitą i nieujemną.')
    .nullable()
    .required('Proszę podać kolejność kolumny.'),
});

export const EmailConfigSchema = yup.object().shape({
  type: yup
    .mixed<EmailConfigTypeEnum>()
    .oneOf(Object.values(EmailConfigTypeEnum))
    .required('Proszę wybrać typ konfiguracji.'),
  emails: yup
    .array()
    .of(yup.string().nullable())
    .required('Proszę podać adres e-mail.')
    .min(1, 'Proszę podać co najmniej jeden adres e-mail.')
    .test({
      name: 'emails',
      test: function (value) {
        const firstInvalidEmail =
          value &&
          value
            .map(email => email?.trim())
            .filter(v => v && v.length > 0)
            .find(v => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: `Adres e-mail: '${firstInvalidEmail}' jest niepoprawny. Kilka adresów proszę rozdzielić przecinkiem. `,
            });
      },
    })
    .test({
      name: 'nonEmptyEmails',
      test: function (value) {
        return value.filter(email => email && email.trim() !== '').length > 0
          ? true
          : this.createError({
              message: 'Proszę podać co najmniej jeden niepusty adres e-mail.',
            });
      },
    }),
});

export const JobConfigSchema = yup.object().shape({
  type: yup
    .mixed<JobTypeEnum>()
    .oneOf(Object.values(JobTypeEnum))
    .required('Proszę wybrać typ konfiguracji.'),
  crone: yup.string().nullable().required('Proszę podać częstotliwość.'),
  emails: yup
    .array()
    .of(yup.string().nullable())
    .test({
      name: 'emails',
      test: function (value) {
        const firstInvalidEmail =
          value &&
          value
            .map(email => email?.trim())
            .filter(v => v && v.length > 0)
            .find(v => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: `Adres e-mail: '${firstInvalidEmail}' jest niepoprawny. Kilka adresów proszę rozdzielić przecinkiem. `,
            });
      },
    }),
});

export const ExcelConfigPositionSchema = yup.object().shape({
  excelPositionName: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę pozycji w Excelu.'),
  positionId: yup.string().required('Proszę wybrać pozycję.'),
});

export const ProjectExcelImportSchema = yup.object().shape({
  fileName: yup.string().nullable().required('Proszę załadować plik Excel.'),
  configId: yup.string().required('Proszę wybrać konfigurację importu.'),
});
