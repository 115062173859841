import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import {
  buildExchangeDescription,
  CurrencyIsoTypeEnum,
} from '../../../common/pokCore/validation/schemas';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import momencik from '../../../common/momencik';
import { getMainCurrencySymbol } from '../../../utils/currencyFormatUtils';

export default (props: {
  currency: CurrencyIsoTypeEnum;
  rate: string | null;
  description: string | null;
  date: Date;
  onChange: (rate: string, description?: string) => void;
  readOnly?: boolean;
}) => {
  const prevCurrencyRef = useRef(props.currency);
  const prevDateRef = useRef(props.date);
  const pok = usePokCore();
  const notifications = useNotifications();
  const [isLoading, setIsLoading] = useState(false);

  const handleFetch = () => {
    if (props.currency !== getMainCurrencySymbol() && !props.readOnly) {
      setIsLoading(true);
      pok.exchangeApi
        .getExchangeRate(props.currency as string, props.date || new Date())
        .then(data => {
          const newRate = data.rate?.toString() || '';
          const newDescription = buildExchangeDescription(
            data.exchangeTableNo!,
            data.effectiveDate!,
          );

          if (props.rate !== newRate || props.description !== newDescription) {
            props.onChange(
              data.rate?.toString() || '',
              buildExchangeDescription(
                data.exchangeTableNo!,
                data.effectiveDate!,
              ),
            );
          }
        })
        .catch(async errorResponse => {
          notifications.caughtError(errorResponse);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (prevCurrencyRef.current !== props.currency && !isLoading) {
      handleFetch();
    }

    prevCurrencyRef.current = props.currency;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currency]);

  useEffect(() => {
    if (momencik(prevDateRef.current) !== momencik(props.date) && !isLoading) {
      handleFetch();
    }

    prevDateRef.current = props.date;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date]);

  useEffect(() => {
    if (!props.description || !props.rate) {
      handleFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col md={2}>
        <Form.Control
          type="number"
          value={(props.rate as string) || ''}
          readOnly
          disabled
        />
      </Col>
      <Col md={8}>
        <DynamicTextarea value={(props.description as string) || ''} disabled />
      </Col>
      <Col md={2}>
        <Button
          variant="outline-primary"
          className="w-100"
          onClick={() => handleFetch()}
          disabled={isLoading || props.readOnly}
        >
          {isLoading ? <Spinner size={'sm'} /> : 'NBP'}
        </Button>
      </Col>
    </Row>
  );
};
