import React, { useEffect, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { ClientTab } from '../../components/Clients/ClientTab';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { PurchaserTab } from '../../components/Purchasers/PurchaserTab';
import { PositionTab } from '../../components/Positions/PositionTab';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetClientDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { SymphonyTab } from '../../components/Symphony/SymphonyTab';
import { useTranslation } from '../../../common/hooks/useTranslation';

type ParamTypes = {
  clientId: string;
};

export const ClientView: React.FC = () => {
  const [client, setClient] = useState<GetClientDto>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const { clientId } = useParams<ParamTypes>() as {
    clientId: string;
  };
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('clientLng');

  useEffect(() => {
    pok.clients
      .getById(clientId)
      .then(client => {
        setClient(client);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [clientId, notifications, pok.clients, refresh]);

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.client.client)} <b>{client?.name}</b>
          {client
            ? `, ${t(tk.common.sysNumberLowerCase)} ${client.systemNumber}`
            : null}
        </Card.Title>
        {client && (
          <TabsHistory
            activeKey="details"
            id="product"
            className="mb-3"
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <Tab eventKey="details" title={t(tk.client.client)} className="m-3">
              <ClientTab client={client} refresh={onRefresh} />
            </Tab>
            <Tab
              eventKey="purchaser"
              title={t(tk.purchaser.purchaser)}
              className="m-3"
            >
              <PurchaserTab client={client} />
            </Tab>
            <Tab
              eventKey="position"
              title={t(tk.position.positionsShortName)}
              className="m-3"
            >
              <PositionTab client={client} />
            </Tab>
            <Tab
              eventKey="symphony"
              title={t(tk.client.symphony)}
              className="m-3"
            >
              <SymphonyTab clientId={client.id} />
            </Tab>
          </TabsHistory>
        )}
      </Card.Body>
    </Card>
  );
};
