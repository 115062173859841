import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetOrderWithCurrencyDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormSkeleton from '../../../app/components/FormSkeleton';
import { OrderForm } from '../../components/Orders/OrderForm';
import { convert } from '../../../common/pokCore/contexts/OrderContext';
import { OrderDecisionControls } from '../../components/Orders/OrderDecisionControls';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import FormRow from '../../../app/components/FormRow';
import { OrderStatusEnumText } from '../../../common/pokCore/validation/schemas';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { BadCompany } from '../../../common/components/NotAuthorized/BadCompany';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useTranslation } from '../../../common/hooks/useTranslation';

type ParamTypes = {
  orderId: string;
};

export const OrderDecisionView: React.FC = () => {
  const { orderId } = useParams<ParamTypes>();
  const pok = usePokCore();
  const notifications = useNotifications();
  const auth = useAuth();
  const { t, tk } = useTranslation('orderLng');

  const [order, setOrder] = useState<GetOrderWithCurrencyDto>();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [authorized, setAuthorized] = useState<boolean>();

  useEffect(() => {
    if (order && order?.team?.company?.id !== pok.getCompanyId()) {
      setAuthorized(false);
    } else {
      if (order) {
        setAuthorized(
          auth.check(PermissionsEnum.Projects, order?.team?.company?.id),
        );
      }
    }
  }, [pok, order, auth]);

  useEffect(() => {
    if (orderId) {
      pok.orders
        .getById(orderId)
        .then(setOrder)
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [orderId, pok.orders, notifications]);

  return (
    <>
      {!authorized && order && (
        <BadCompany companyId={order?.team?.company?.id} />
      )}
      {authorized && order && (
        <Card className="main-card">
          <Card.Body className="m-3">
            {order ? (
              <>
                <Card.Title className="pb-3">
                  {t(tk.order.orderAcceptance, {
                    sysNumber: order.systemNumber,
                  })}
                </Card.Title>
                <OrderForm
                  project={order.project}
                  currencyCode={order.currencyCode}
                  resultProject={order.resultProject}
                  order={convert(order)}
                  emails={order.emails}
                  orderEstimateItems={order.orderEstimateItems}
                  readOnly
                />
                <Form className="d-grid gap-4 mb-3">
                  {order.status && (
                    <FormRow controlId="status" label={t(tk.common.status)}>
                      <Form.Control
                        value={
                          OrderStatusEnumText[order.status] +
                          (!order.active ? ` - ${t(tk.order.deleted)}` : '')
                        }
                        disabled
                      />
                    </FormRow>
                  )}
                  {order.rejectedNote && (
                    <FormRow
                      controlId="rejectedNote"
                      label={t(tk.order.rejectedNote)}
                    >
                      <DynamicTextarea
                        disabled
                        readOnly
                        value={order.rejectedNote}
                      />
                    </FormRow>
                  )}
                </Form>
                <div className="d-flex">
                  {orderId && (
                    <LastEditorInfo
                      id={orderId}
                      className="color-primary"
                      method={pok.orders.getLastEditor}
                    />
                  )}
                  <OrderDecisionControls
                    order={order}
                    setShowSaveErrors={setShowSaveErrors}
                    setSaveErrors={setSaveErrors}
                  />
                </div>
                <ValidationAlert
                  show={showSaveErrors}
                  errors={saveErrors}
                  className="m-3"
                />
              </>
            ) : (
              <FormSkeleton />
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};
