import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetBrandDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface BrandSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const brandForGrid = (brand: GetBrandDto) => {
  return {
    key: brand.id,
    values: {
      'brand.systemNumber': brand.systemNumber,
      'brand.name': brand.name,
      'budget.name': brand.budget.name,
    },
    isInactive: !brand.active,
  };
};

export const BrandSearch: React.FC<BrandSearchProps> = props => {
  const { t, tk } = useTranslation('clientLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'brand.systemNumber' },
    { header: t(tk.common.name), property: 'brand.name' },
    { header: t(tk.brand.budget), property: 'budget.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="brand.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.brand.noBrands)}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
