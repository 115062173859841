import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetSalesInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  CurrencyIsoTypeEnum,
  InvoiceStatusEnum,
  InvoiceStatusEnumText,
  InvoiceTypeEnum,
  InvoiceTypeEnumText,
  PaymentFormEnumText,
} from '../../../common/pokCore/validation/schemas';
import { buildProjectLabel } from '../../../utils/buildProjectLabel';
import numberFormatter from '../../../common/numberFormatter';

import { NewWindowButton } from './NewWindowButton';

interface SalesInvoiceSearchProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  status?: InvoiceStatusEnum;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  withFilterInColumns?: boolean;
  gridMessage?: string;
  isImportView?: boolean;
  hideStatusColumn?: boolean;
  enableMultiSelect?: boolean;
  showNewWindowButton?: boolean;
  initialOrderDirection?: 'ASC' | 'DESC';
}

export const salesInvoiceForGrid = (salesInvoice: GetSalesInvoiceDto) => {
  return {
    key: salesInvoice.id,
    values: {
      'salesInvoice.systemNumber': salesInvoice.systemNumber,
      'salesInvoice.currency': salesInvoice.currency,
      'salesInvoice.number': (
        <>
          {salesInvoice.number}
          {salesInvoice.number && <br />}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>{InvoiceTypeEnumText[salesInvoice.type]}</Tooltip>
            }
          >
            {salesInvoice.type === InvoiceTypeEnum.Foreign ? (
              <Icon.Globe2 className="icon-blue me-2" />
            ) : (
              <Icon.House className="icon-blue me-2" />
            )}
          </OverlayTrigger>
          {!!salesInvoice.parent && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Korekta do faktury {salesInvoice.originalInvoice?.number}
                </Tooltip>
              }
            >
              <Icon.FileEarmarkMinusFill className="icon-blue me-2" />
            </OverlayTrigger>
          )}
          {!salesInvoice.isSymphonyCode && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Brak kodu Symfonii dla kontrahenta</Tooltip>}
            >
              <Icon.ExclamationCircle className="icon-red me-2" />
            </OverlayTrigger>
          )}
        </>
      ),
      'salesInvoice.invoiceDate': momencik(salesInvoice.invoiceDate),
      'salesInvoice.saleDate': momencik(salesInvoice.saleDate),
      invoice_status: InvoiceStatusEnumText[salesInvoice.status],
      'salesInvoice.paymentForm': PaymentFormEnumText[salesInvoice.paymentForm],
      'payableDays.value': salesInvoice.payableDays?.value,
      project_label: salesInvoice.salesInvoiceProjects
        ?.map(({ project }) => buildProjectLabel(project))
        .join(', '),
      'client.name': salesInvoice.purchaser?.client?.name,
      'client.nip': salesInvoice.purchaser?.client?.nip,
      'budget.name': salesInvoice.purchaser?.budget?.name,
      netSum: numberFormatter(
        salesInvoice.netSum || 0,
        true,
        salesInvoice.company.mainCurrency.currencyCode as CurrencyIsoTypeEnum,
      ),
      newWindowButton: <NewWindowButton salesInvoiceId={salesInvoice.id} />,
    },
    isInactive: !salesInvoice.active,
  };
};

export const SalesInvoiceSearch: React.FC<SalesInvoiceSearchProps> = props => {
  const { t, tk } = useTranslation('fvsLng');

  const columns: GridColumn[] = [
    ...(props.showNewWindowButton
      ? [
          {
            header: '',
            property: 'newWindowButton',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
    { header: 'Nr sys.', property: 'salesInvoice.systemNumber' },
    { header: 'Numer', property: 'salesInvoice.number' },
    { header: 'Data wystawienia', property: 'salesInvoice.invoiceDate' },
    { header: 'Data sprzedaży', property: 'salesInvoice.saleDate' },
    ...(props.isImportView
      ? []
      : [
          {
            header: 'Forma płatności',
            property: 'salesInvoice.paymentForm',
            noFilter: true,
          },
          {
            header: 'Termin płatności',
            property: 'payableDays.value',
          },
        ]),
    { header: 'Waluta', property: 'salesInvoice.currency' },
    ...(props.hideStatusColumn
      ? []
      : [{ header: 'Status', property: 'invoice_status' }]),
    { header: t(tk.prLang.project), property: 'project_label' },
    { header: 'Nazwa nabywcy', property: 'client.name' },
    { header: 'NIP', property: 'client.nip' },
    { header: 'Budżet', property: 'budget.name' },
    { header: 'Netto', property: 'netSum', noSorting: true, noFilter: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      refresh={props.refresh}
      initialOrderColumn="salesInvoice.systemNumber"
      initialOrderDirection={props.initialOrderDirection || 'DESC'}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={
        props.gridMessage || 'Brak faktur sprzedaży w systemie.'
      }
      onRowClick={props.onRowClick}
      withHistory={true}
      withFilterInColumns={props.withFilterInColumns}
      enableMultiSelect={props.enableMultiSelect}
      withOverflowXAuto={true}
    />
  );
};
