import React, { Dispatch, SetStateAction, useEffect } from 'react';

import {
  CreateUpdateOrderExecutionDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import useSelectGrid from '../../../common/hooks/useSelectGridRow';

import useOrderExecutionPositions from './useOrderExecutionPositions';
import { OrderExecutionPositionsGrid } from './OrderExecutionPositionsGrid';
import { OrderExecutionPositionsControls } from './OrderExecutionPositionsControls';

interface OrderExecutionPositionsProps {
  orderExecution: CreateUpdateOrderExecutionDto;
  propertyChange?: (obj: Partial<CreateUpdateOrderExecutionDto>) => void;
  emails: string[];
  setEmails?: (emails: string[]) => void;
  project?: GetProjectDto;
  resultProject?: GetProjectDto;
  theSameCurrency?: boolean;
  readOnly?: boolean;
  disableSumsDiscrepancyAlert?: boolean;
  purchaseSum?: number;
  isDecisionView?: boolean;
  setShowSumsDiscrepancyAlert: Dispatch<SetStateAction<boolean>>;
  currencyCode?: string;
}

export const OrderExecutionPositions: React.FC<
  OrderExecutionPositionsProps
> = props => {
  const select = useSelectGrid<number>();
  const {
    positionChange,
    resetPositions,
    clonePosition,
    mergePositions,
    mergePositionsByActionGroup,
    positionsSalesSum,
    showSumsDiscrepancyAlert,
    isActionGroupAvailable,
  } = useOrderExecutionPositions(
    select,
    props.orderExecution,
    props.project,
    props.theSameCurrency,
    props.propertyChange,
  );

  useEffect(() => {
    props.setShowSumsDiscrepancyAlert(showSumsDiscrepancyAlert);
  }, [props, showSumsDiscrepancyAlert]);

  return (
    <>
      <OrderExecutionPositionsGrid
        orderExecution={props.orderExecution}
        propertyChange={positionChange}
        select={select}
        positionsSalesSum={positionsSalesSum}
        readOnly={props.readOnly}
        isDecisionView={props.isDecisionView}
        purchaseSum={props.purchaseSum}
        isActionGroupAvailable={isActionGroupAvailable}
        currencyCode={props.currencyCode}
      />
      {!props.readOnly && (
        <OrderExecutionPositionsControls
          selected={select.selected}
          handleResetPositions={resetPositions}
          handleClonePosition={clonePosition}
          handleMergePositions={mergePositions}
          handleMergePositionsByActionGroup={mergePositionsByActionGroup}
          isActionGroupAvailable={isActionGroupAvailable}
        />
      )}
    </>
  );
};
