import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import {
  convert,
  newEmployeeRole,
} from '../../../common/pokCore/contexts/EmployeeRoleContext';
import {
  CreateUpdateEmployeeRoleDto,
  GetEmployeeRoleDto,
  GetRoleDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { EmployeeRoleSearch } from '../EmployeesRoles/EmployeeRoleSearch';
import { UserInRoleEditor } from '../EmployeesRoles/UserInRoleEditor';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface UsersInRoleEditorProps {
  role: GetRoleDto;
  employeesRole: GetEmployeeRoleDto[];
  changeEmployeesRole: (employeesRole: GetEmployeeRoleDto[]) => void;
  onCompleted: (
    roleId: string,
    id?: string | null,
    employeeRole?: CreateUpdateEmployeeRoleDto,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const UsersInRoleEditor: React.FC<UsersInRoleEditorProps> = props => {
  const [role, setRole] = useState(props.role);
  const [show, setShow] = useState(false);
  const [employeeRole, setEmployeeRole] =
    useState<CreateUpdateEmployeeRoleDto>();
  const [employeeRoleId, setEmployeeRoleId] = useState<string | null>(null);
  const pok = usePokCore();
  const { t, tk } = useTranslation('roleLng');

  useEffect(() => {
    setRole(props.role || []);
  }, [props.role]);

  const handleAdd = () => {
    setShow(true);
    setEmployeeRole(newEmployeeRole());
    setEmployeeRoleId(null);
  };

  const handleOnRowClick = async (key: string) => {
    let tk = props.employeesRole?.find(o => o.id === key);
    if (!tk) {
      tk = await pok.employeesRoles.getById(key);
    }
    setEmployeeRole(convert(tk));
    setEmployeeRoleId(tk?.id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = (
    rId: string,
    id?: string | null,
    empRl?: CreateUpdateEmployeeRoleDto,
  ) => {
    const ret = props.onCompleted(rId, id, empRl);
    setShow(false);
    return ret;
  };

  return (
    <>
      <Form className="d-grid gap-3 mb-3">
        <FormRow controlId="name" label={t(tk.common.name)}>
          <DynamicTextarea value={role?.name || ''} readOnly={true} />
        </FormRow>
        <FormRow controlId="description" label={t(tk.common.description)}>
          <DynamicTextarea value={role?.description} readOnly={true} />
        </FormRow>
        <EmployeeRoleSearch
          data={props.employeesRole}
          onRowClick={handleOnRowClick}
        />
      </Form>
      <Button variant="outline-primary" onClick={handleAdd}>
        {t(tk.employee.employeeNew)}
      </Button>
      {role && show && employeeRole && (
        <UserInRoleEditor
          id={role?.id}
          employeeRoleId={employeeRoleId}
          employeeRole={employeeRole}
          onCompleted={handleSave}
          show={show}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
