/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';

/**
 * 
 * @export
 * @interface CreateUpdateBankAccountDto
 */
export interface CreateUpdateBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    invoiceType: CreateUpdateBankAccountDtoInvoiceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    currency: CreateUpdateBankAccountDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    companyId: string;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof CreateUpdateBankAccountDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    swiftBIC: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    bankName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateBankAccountDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    vatNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBankAccountDto
     */
    vatBankName: string;
}


/**
 * @export
 */
export const CreateUpdateBankAccountDtoInvoiceTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type CreateUpdateBankAccountDtoInvoiceTypeEnum = typeof CreateUpdateBankAccountDtoInvoiceTypeEnum[keyof typeof CreateUpdateBankAccountDtoInvoiceTypeEnum];

/**
 * @export
 */
export const CreateUpdateBankAccountDtoCurrencyEnum = {
    Pln: 'PLN',
    Usd: 'USD',
    Eur: 'EUR',
    Czk: 'CZK',
    Rub: 'RUB',
    Huf: 'HUF',
    Gbp: 'GBP',
    Bgn: 'BGN',
    Ron: 'RON',
    Hrk: 'HRK',
    Aud: 'AUD'
} as const;
export type CreateUpdateBankAccountDtoCurrencyEnum = typeof CreateUpdateBankAccountDtoCurrencyEnum[keyof typeof CreateUpdateBankAccountDtoCurrencyEnum];


/**
 * Check if a given object implements the CreateUpdateBankAccountDto interface.
 */
export function instanceOfCreateUpdateBankAccountDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "invoiceType" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "swiftBIC" in value;
    isInstance = isInstance && "bankName" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "vatNumber" in value;
    isInstance = isInstance && "vatBankName" in value;

    return isInstance;
}

export function CreateUpdateBankAccountDtoFromJSON(json: any): CreateUpdateBankAccountDto {
    return CreateUpdateBankAccountDtoFromJSONTyped(json, false);
}

export function CreateUpdateBankAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateBankAccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'invoiceType': json['invoiceType'],
        'currency': json['currency'],
        'companyId': json['companyId'],
        'company': GetCompanyDtoFromJSON(json['company']),
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'swiftBIC': json['swiftBIC'],
        'bankName': json['bankName'],
        'active': json['active'],
        'vatNumber': json['vatNumber'],
        'vatBankName': json['vatBankName'],
    };
}

export function CreateUpdateBankAccountDtoToJSON(value?: CreateUpdateBankAccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'invoiceType': value.invoiceType,
        'currency': value.currency,
        'companyId': value.companyId,
        'company': GetCompanyDtoToJSON(value.company),
        'clientId': value.clientId,
        'swiftBIC': value.swiftBIC,
        'bankName': value.bankName,
        'active': value.active,
        'vatNumber': value.vatNumber,
        'vatBankName': value.vatBankName,
    };
}

