import {
  CreateUpdateOrderDto,
  CreateUpdateOrderDtoFromJSON,
  DefaultApi,
  GetLastEditorDto,
  GetOrderDto,
  GetOrderWithCurrencyDto,
} from '../autogenerated/pokApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { OrderSchema } from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { orderForGrid } from '../../../pok/components/Orders/OrderSearch';
import { FilterColumn } from '../../types/FilterColumn';
import { getMainCurrencySymbol } from '../../../utils/currencyFormatUtils';

export interface OrderContextInterface {
  getById: (id: string) => Promise<GetOrderWithCurrencyDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllByPortionForProject: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    projectId?: string,
    resultProjectId?: string,
    currentProjectId?: string,
  ) => Promise<GridRecord[]>;
  getAllCountForProject: (
    filterText?: string,
    filterColumns?: FilterColumn,
    projectId?: string,
    resultProjectId?: string,
  ) => Promise<number>;
  getAllByPortionWaiting: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCountWaiting: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllByPortionCompleted: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCountCompleted: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateOrderDto) => Promise<void>;
  create: (dto: CreateUpdateOrderDto) => Promise<GetOrderDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  reject: (id: string, rejectedNote: string) => Promise<void>;
  accept: (id: string) => Promise<string>;
  deactivate: (id: string) => Promise<void>;
}

export const OrderContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.orderControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.orderControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      return data.map(item => orderForGrid(item));
    },
    getAllCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.orderControllerGetAllCount(filterText || '', filterColumns),
    getAllByPortionForProject: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      projectId?: string,
      resultProjectId?: string,
      currentProjectId?: string,
    ) => {
      const data = await api.orderControllerGetAllByPortionForProject(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
        projectId || '',
        resultProjectId || '',
      );
      return data.map(item => orderForGrid(item, currentProjectId));
    },
    getAllCountForProject: (
      filterText?: string,
      filterColumns?: FilterColumn,
      projectId?: string,
      resultProjectId?: string,
    ) =>
      api.orderControllerGetAllCountForProject(
        filterText || '',
        filterColumns,
        projectId || '',
        resultProjectId || '',
      ),
    getAllByPortionWaiting: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.orderControllerGetAllByPortionWaiting(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      return data.map(item => orderForGrid(item));
    },
    getAllCountWaiting: (filterText?: string, filterColumns?: FilterColumn) =>
      api.orderControllerGetAllCountWaiting(filterText || '', filterColumns),
    getAllByPortionCompleted: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.orderControllerGetAllByPortionCompleted(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      return data.map(item => orderForGrid(item));
    },
    getAllCountCompleted: (filterText?: string, filterColumns?: FilterColumn) =>
      api.orderControllerGetAllCountCompleted(filterText || '', filterColumns),
    create: (dto: CreateUpdateOrderDto) => api.orderControllerCreate(dto),
    update: (id: string, dto: CreateUpdateOrderDto) =>
      api.orderControllerUpdate(id, dto),
    getLastEditor: (id: string) => api.orderControllerGetLastEditor(id),
    reject: (id: string, rejectedNote: string) =>
      api.orderControllerReject(id, { rejectedNote }),
    accept: (id: string) => api.orderControllerAccept(id),
    deactivate: (id: string) => api.orderControllerDeactivate(id),
  };
};

export const newOrder = (projectId?: string) => {
  return CreateUpdateOrderDtoFromJSON({
    projectId,
    addTeamToEmails: false,
    orderEstimateItemsIds: [],
    orderEmployeesIds: [],
    emails: [],
    currency: getMainCurrencySymbol(),
  });
};

export const validate = (project: CreateUpdateOrderDto) => {
  return validateAgainst(OrderSchema, project);
};

export const convert = (order?: GetOrderDto, projectId?: string) => {
  if (!order) {
    return newOrder(projectId);
  }

  const converted = CreateUpdateOrderDtoFromJSON(order);
  converted.orderEmployeesIds =
    order.orderEmployees
      ?.filter(({ active }) => active)
      .map(({ employee }) => employee?.id) || [];
  converted.orderEstimateItemsIds =
    order.orderEstimateItems?.map(({ id }) => id) || [];
  converted.projectId = order.project.id;
  converted.teamId = order.team.id;

  return converted;
};
