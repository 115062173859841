export default {
  client: {
    client: 'Client',
    clients: 'Clients',
    clientAdd: 'Adding a new client',
    clientEdit: 'Editing client no. sys.',
    clientNew: 'New client',
    noClients: 'No clients in the system',
    nip: 'Tax Identification Number (NIP)',
    dealGroupNumber: 'Deal grouping',
    country: 'Country',
    place: 'City',
    postcode: 'Postal code',
    street: 'Street',
    numbers: 'Number',
    flat: 'Apartment',
    addressEx: 'Foreign address',
    isRelated: 'Related client',
    notToSymphony: 'No export of FVZ to Symphony',
    symphony: 'Symphony',
  },
  purchaser: {
    purchaser: 'Purchaser',
    purchaserAdd: 'Adding a new purchaser',
    purchaserEdit: 'Editing purchaser no. sys.',
    purchaserNew: 'New purchaser',
    noPurchasers: 'No purchasers in the system',
    noPurchasersForClient: 'No purchasers for this client.',
    manager: 'Budget manager',
  },
  position: {
    position: 'Profile position',
    positions: 'Profile positions',
    positionsShortName: 'Positions',
    positionAdd: 'Adding a new profile position',
    positionEdit: 'Editing profile position no. sys.',
    positionNew: 'New profile position',
    noPositions: 'No profile positions in the system',
    noPositionsForClient: 'No profile positions for this client',
    chooseSpecialTeam: 'Please select a special team.',
    groupName: 'Group name',
    type: 'Position type',
    communicationCanal: 'Communication channel',
    specialTeam: 'Specialized team',
    profilesEstimates: 'Media plan profiles',
    notPurchaseDeficiencies: 'Not subject to FVZ deficiencies',
    profiles: 'Profiles',
  },
  budget: {
    budget: 'Budget',
    budgets: 'Budgets',
    budgetAdd: 'Adding a new budget',
    budgetEdit: 'Editing budget no. sys.',
    budgetNew: 'New budget',
    noBudgets: 'No budgets in the system',
    managerName: 'Budget manager',
    downloadDocuments: 'Bulk document download',
  },
  brand: {
    brand: 'Brand',
    brands: 'Brands',
    brandAdd: 'Adding a new brand',
    brandEdit: 'Editing brand no. sys.',
    brandNew: 'New brand',
    noBrands: 'No brands in the system',
    budget: 'Brand budget',
  },
  symphony: {
    symphonyNew: 'New code',
    code: 'Code',
    noSymphonyForClient: 'No Symphony codes for this client.',
    symphonyEdit: 'Editing code',
    symphonyAdd: 'Adding a new code',
  },
} as const;
