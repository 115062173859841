export default {
  estimates: {
    addProfile: 'Select profile to add',
    selectProfile: 'Select profile',
    selectMonth: 'Select month',
    select: 'Please select',
    noPositionSelected: 'No positions selected for import.',
    selectPositionsToImport: 'Select positions to import for the month',
    filterByProfile: 'Filter by profile',
    filterByMonth: 'Filter by month',
    positionAccusative1: 'position',
    positionAccusative2_4: 'positions',
    positionAccusative5: 'positions',
    importSelected: 'Import selected',
    importAll: 'Import all',
    withoutAmounts: 'Without amounts',
    withAmounts: 'With amounts',
    clone: 'Clone',
    cloneSelectedEstimateByMonth: 'Clone selected profile for the chosen month',
    cloneEstimateByMonth: 'Clone monthly profile',
    saveStatus: 'Save status',
    sales: 'Sales',
    purchase: 'Purchase',
    fvz: 'FVZ',
    fvs: 'FVS',
    fvzDiff: 'FVZ difference',
    margin: 'Margin',
    orderNumber: 'Order no.',

    noPositions: 'No positions to display.',
    name: 'Name',
    month: 'Month',
    profile: 'Profile',
    client: 'Client',
    parameters: 'Parameters',
    salesNetTotal: 'Net sales total',
    purchaseNetTotal: 'Net purchase total',
    positionName: 'Position name',
    amountToInvoice: 'Amount to invoice',
    amountToAccept: 'Amount to accept',

    globalSalesBlockade: 'Active global sales blockade for the month',
    globalPurchaseBlockade: 'Active global purchase blockade for the month',
    addPosition: 'Add position',
    saveAddedPosition: 'Please save the previously added position.',
    category: 'Category',
    amounts: 'Amounts',

    lastPositionModification: 'Last position modification by media planner',
    deletePositionConfirmation:
      'Are you sure you want to delete the visible position?',
    deletePosition: 'Delete position',
    deleteNewPosition: 'Delete new position',
    saveChanges: 'Save changes',
    saveNewPosition: 'Save new position',
    summary: 'Summary',
    positions: 'Positions',
    status: 'Status',
    applications: 'Requests',
    askDeleteProfile: 'Are you sure you want to delete the monthly profile?',
    deleteProfile: 'Delete monthly profile',
    selectTargetMonth:
      'Please select the target month (↑ select at the top of the window ↑).',
    addMonth: 'Add month',
    importPositionFrom: 'Import positions from',

    paeImport: 'Import from PAE',
    openInPae: 'Open in PAE',
    refreshData: 'Refresh data',
    paeImportWarning:
      'Warning. Only purchases will be imported - sales will not be imported due to a sales blockade on the profile.',
    paePositionsList:
      'PAE position list for campaign {{projectNo}} for {{date}}',
    purchaseForm: 'Purchase form',
    paePurchaseForm: 'Format from PAE',
    paePositionNumber: 'PAE position number',
    comment: 'Comment',

    issueDate: 'Issue date',
    sellDate: 'Sell date',
    invoiceAmount: 'Invoice amount',
    settledAmount: 'Settled amount',
    lastModification: 'Last modification',
    attachment: 'Attachment',
    noFVZAssigned: 'No purchase invoices assigned',

    noFVS: 'No issued sales invoices',
    profileSalesBlockade: 'Active sales blockade on the profile',
    profilePurchaseBlockade: 'Active purchase blockade on the profile',
    changeStatus: 'Are you sure you want to change the status?',
    purchaseAmount: 'Purchase amount',
    noPositionsAssigned: 'No positions assigned to this team.',
  },

  projects: {
    for: 'For',
    from: 'From',
    to: 'To',
    leadTeam: 'Lead team',
    dateRange: 'Date range',
    find: 'Search',
    parameters: 'Parameters',
    summary: 'Summary',
    orders: 'Orders',
    ordersExecution: 'Orders execution',
    documents: 'Documents',
    fvz: 'FVZ',
    fvs: 'FVS',
    services: 'Services',
    cancelRequestApproved: 'cancellation request has been approved',
    country: 'Country/market',
    purchaserDocNumber: 'Client document number',
    note: 'Note',
    responsiblePerson: 'Responsible person',
    documentsControl: 'Document control',
    cancelApplication: 'Cancellation request',
    edit: 'Edit',
    cancelApplicationSubmitted:
      'A cancellation request has been submitted for {{project}} {{project}}.',
    cancelVerificationWaiting: 'Awaiting cancellation request verification',
    clone: 'Clone',
    withoutAmounts: 'Without amounts',
    withAmounts: 'With amounts',
    applicationVerificationWaiting: 'Awaiting application verification',
    attachments: 'Attachments',
    cancelReason: 'Cancellation reason',
    fillCancelReason: 'Please provide a cancellation reason',
    saveApplication: 'Save application',
    createApplication: 'Create application',
    askRemoveApplication: 'Are you sure you want to delete the application?',
    deleteApplication: 'Delete application',
    errCreateProjectSendApplication:
      'Creating a campaign in the selected period is blocked, send an application to create the campaign.',
    applicationContent: 'Application content',
    sendApplication: 'Send application',
    salesNet: 'Sales net',
    salesCommissionAmount: 'Sales commission',
    salesTotal: 'Sales total',
    salesInvoicesSum: 'FVS sum',
    purchaseTotal: 'Purchase total',
    purchaseInvoicesSum: 'FVZ sum',
    margin: 'Margin',
    salesLock: 'Sales lock',
    purchaseLock: 'Purchase lock',
    purchaseDiff: 'Purchase difference',
    noPositions: 'No positions',
    invoicesNumber: 'Invoice numbers',
    activeProfiles: 'Active profiles',
    client: 'Client',
    salesInvoicesAmount: 'Sales net FVS',
    salesDiff: 'Sales difference',
    purchaseInvoicesAmount: 'Purchase net FVZ',
    complianceWithSalesTotal: 'Compliance with sales total',
    parentProjectNumber: 'Parent campaign number',
    askDownloadFVZ: 'Download FVZ invoices attached to the campaign?',
    askDownloadFVZDespiteWarnings:
      'Download FVZ invoices attached to the campaign despite the following warnings?',
    parentNumber: 'Parent number',
    sales: 'Sales',
    costs: 'Costs',
    action: 'Action',
    sumUpperCase: 'SUM',
    specialInvoicingApplication: 'Create custom invoicing application',
  },
} as const;
