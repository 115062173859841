import React, { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import {
  CreateUpdateProfileCategoryDto,
  GetProfileCategoryDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ProfileCategoryContext';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { ProfileCategorySearch } from '../../components/ProfileCategories/ProfileCategorySearch';
import { ProfileCategoryEditor } from '../../components/ProfileCategories/ProfileCategoryEditor';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ProfileCategoriesView: React.FC = () => {
  const [profileCategory, setProfileCategory] =
    useState<GetProfileCategoryDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t, tk } = useTranslation('administrationLng');

  const pok = usePokCore();

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.profileCategories.getAllByPortion(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok.profileCategories],
  );

  const getCount = useMemo(
    () =>
      (filterText =>
        pok.profileCategories.getAllCount(filterText)) as GridCountFunction,
    [pok.profileCategories],
  );

  const handleAdd = () => {
    setShow(true);
    setProfileCategory(undefined);
  };

  const addEditProfileCategory = async (
    profileCU: CreateUpdateProfileCategoryDto,
    id?: string,
  ) => {
    const status = await validate(profileCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveUpdateProfileCategory(profileCU, id);
  };

  const saveUpdateProfileCategory = async (
    profileCU: CreateUpdateProfileCategoryDto,
    id?: string,
  ) => {
    try {
      if (id) {
        await pok.profileCategories.update(id, profileCU);
      } else {
        await pok.profileCategories.create(profileCU);
      }
      setShow(false);
      setRefresh(!refresh);
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const fetchedProfileCategory = await pok.profileCategories.getById(id);
    setProfileCategory(fetchedProfileCategory);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.administration.profileCategories)}
        </Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.administration.profileCategoryNew)}
        </Button>
        <ProfileCategorySearch
          getData={getData}
          getCount={getCount}
          onRowClick={handleClick}
          refresh={refresh}
        />
        {show && (
          <ProfileCategoryEditor
            id={profileCategory?.id}
            profileCategory={convert(profileCategory)}
            onCompleted={addEditProfileCategory}
            show={show}
            handleClose={handleClose}
          />
        )}
      </Card.Body>
    </Card>
  );
};
