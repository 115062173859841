import { excelConfigForGrid } from '../../../pok/components/ExcelConfig/ExcelConfigSearch';
import {
  defaultSelectorLimit,
  Option,
} from '../../../pok/components/Selects/Selector';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdateExcelConfigDto,
  CreateUpdateExcelConfigDtoFromJSON,
  DefaultApi,
  GetExcelConfigDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { ExcelConfigSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface ProjectExcelConfigContextInterface {
  getById: (id: string) => Promise<GetExcelConfigDto>;
  update: (id: string, dto: CreateUpdateExcelConfigDto) => Promise<void>;
  create: (dto: CreateUpdateExcelConfigDto) => Promise<GetExcelConfigDto>;
  getAllByPortion: (
    companyId: string,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (companyId: string, filterText?: string) => Promise<number>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getOptions: (text?: string) => Promise<Option[]>;
}

export const ProjectExcelConfigContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.excelConfigControllerGet(id),
    getAllByPortion: async (
      companyId: string,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.excelConfigControllerGetAllByPortion(
        companyId,
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(excelConfigForGrid);
    },
    getAllCount: (companyId: string, filterText?: string) =>
      api.excelConfigControllerGetAllCount(companyId, filterText || ''),
    update: (id: string, dto: CreateUpdateExcelConfigDto) =>
      api.excelConfigControllerUpdate(id, dto),
    create: (dto: CreateUpdateExcelConfigDto) =>
      api.excelConfigControllerCreate(dto),
    getLastEditor: (id: string) => api.excelConfigControllerGetLastEditor(id),
    getOptions: async (text?: string) => {
      const data = await api
        .excelConfigControllerSearch(defaultSelectorLimit, text)
        .then(data => data.sort((a, b) => a.name.localeCompare(b.name, 'pl')));

      return Array.from(data, p => ({
        value: p.id,
        label: `${p.name} (${p.profile?.name})`,
      }));
    },
  };
};

export const validate = (config: CreateUpdateExcelConfigDto) =>
  validateAgainst(ExcelConfigSchema, config);

export const newConfig = (companyId?: string) => {
  return CreateUpdateExcelConfigDtoFromJSON({
    companyId: companyId,
    active: true,
    updateExisting: true,
  });
};

export const convert = (config?: GetExcelConfigDto, companyId?: string) => {
  if (!config) {
    return newConfig(companyId);
  }
  const converted = CreateUpdateExcelConfigDtoFromJSON(config);
  converted.excelConfigTeamsIds =
    config?.excelConfigTeams?.map(c => c.team.id) || [];
  converted.companyId = config.company?.id || undefined;
  converted.profileId = config.profile?.id || '';
  converted.companyIndependent = config.company ? false : true;
  converted.profileCategoryId = config.profileCategory?.id || '';
  return converted;
};
