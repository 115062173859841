import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateApplicationUnblockDto,
  GetApplicationUnblockDtoStatusEnum,
  GetEstimateByMonthDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ApplicationUnblockEditorForm } from './ApplicationUnblockEditorForm';

interface ApplicationUnblockEditorProps {
  id?: string;
  systemNumber?: number;
  applicationUnblock: CreateUpdateApplicationUnblockDto;
  handleClose?: () => void;
  handleRefresh?: () => void;
  show?: boolean;
  onCompleted: (
    applicationUnblock: CreateUpdateApplicationUnblockDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  estimateByMonth?: GetEstimateByMonthDto;
  readOnly?: boolean;
  status?: GetApplicationUnblockDtoStatusEnum;
  rejectedNote?: string;
  salesBlockedMonth: boolean;
  purchaseBlockedMonth: boolean;
}

export const ApplicationUnblockEditor: React.FC<
  ApplicationUnblockEditorProps
> = props => {
  const [applicationUnblock, setApplicationUnblock] = useState(
    props.applicationUnblock,
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('applicationLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setApplicationUnblock(props.applicationUnblock);
    }
  }, [props.applicationUnblock, props.show]);

  const createApplicationUnblock = async () => {
    setIsLoadingCreate(true);
    props
      .onCompleted(applicationUnblock, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingCreate(false));
  };

  const handleRemove = () => {
    if (props.id) {
      setIsLoadingRemove(true);
      pok.applicationsUnblock
        .deactivate(props.id)
        .then(() => {
          notifications.saveCompleted();
          props.handleRefresh?.();
          props.handleClose?.();
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        })
        .finally(() => setIsLoadingRemove(false));
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateApplicationUnblockDto>) => {
    setApplicationUnblock({ ...applicationUnblock, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? t(tk.app.editUnblockApp, {
                  sysNumber: props.systemNumber,
                })
              : t(tk.app.addUnblockApp)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.salesBlockedMonth && (
            <Alert variant="warning">{t(tk.app.salesActiveBlock)}</Alert>
          )}
          {props.purchaseBlockedMonth && (
            <Alert variant="warning">{t(tk.app.purchaseActiveBlock)}</Alert>
          )}
          <ApplicationUnblockEditorForm
            applicationUnblock={applicationUnblock}
            estimateByMonth={props.estimateByMonth}
            propertyChange={propertyChange}
            readOnly={props.readOnly}
            status={props.status}
            rejectedNote={props.rejectedNote}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.applicationsUnblock.getLastEditor}
            />
          )}
          {!props.readOnly && props.id && (
            <ConfirmationButton
              onOK={handleRemove}
              confirmation={t(tk.app.askDelete)}
              variant="outline-danger"
              isLoading={isLoadingRemove}
            >
              {t(tk.button.delete)}
            </ConfirmationButton>
          )}
          <Button variant="outline-secondary" onClick={props.handleClose}>
            {t(tk.button.close)}
          </Button>
          {!props.readOnly && (
            <Button
              variant="primary"
              onClick={createApplicationUnblock}
              isLoading={isLoadingCreate}
            >
              {t(tk.button.save)}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
