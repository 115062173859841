import React, { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useNavigation } from '../../../common/navigation';
import { GetExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient/models/GetExcelConfigDto';
import { CreateUpdateExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient/models/CreateUpdateExcelConfigDto';
import { ExcelConfigSearch } from '../../components/ExcelConfig/ExcelConfigSearch';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ProjectExcelConfigContext';
import { ExcelConfigModalEditor } from '../../components/ExcelConfig/ExcelConfigModalEditor';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ExcelConfigsView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const [config, setConfig] = useState<GetExcelConfigDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t, tk } = useTranslation('excelImportLng');

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.projectExcelConfigs.getAllByPortion(
          pok.getCompanyId(),
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok],
  );

  const getCount = useMemo(
    () =>
      (filterText =>
        pok.projectExcelConfigs.getAllCount(
          pok.getCompanyId(),
          filterText,
        )) as GridCountFunction,
    [pok],
  );

  const handleAdd = () => {
    setShow(true);
    setConfig(undefined);
  };

  const addEditConfig = async (
    configCU: CreateUpdateExcelConfigDto,
    id?: string,
  ) => {
    const status = await validate(configCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.projectExcelConfigs.update(id, configCU);
        } else {
          await pok.projectExcelConfigs.create(configCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.excel.importConfigs)}</Card.Title>
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.excel.importConfigAdd)}
        </Button>
        <ExcelConfigSearch
          getData={getData}
          getCount={getCount}
          onRowClick={nav.excelConfig}
          refresh={refresh}
        />
        {show && (
          <ExcelConfigModalEditor
            id={config?.id}
            config={convert(config, pok.getCompanyId())}
            onCompleted={addEditConfig}
            show={show}
            handleClose={handleClose}
          />
        )}
      </Card.Body>
    </Card>
  );
};
