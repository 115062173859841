/*eslint max-lines-per-function: ["error", 300]*/
/*eslint complexity: ["error", 15]*/

import * as XLSX from 'xlsx';
import React, { useRef, useState } from 'react';
import { Alert, Card, Form } from 'react-bootstrap';

import { Waiting } from '../../../common/components/Waiting/Waiting';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { newPurchaseInvoice } from '../../../common/pokCore/contexts/PurchaseInvoiceContext';
import { newAttachment } from '../../../common/pokCore/contexts/AttachmentContext';
import {
  AttachmentObjectType,
  InvoiceTypeEnum,
  VatEnum,
} from '../../../common/pokCore/validation/schemas';
import {
  CreateUpdatePurchaseInvoiceDtoCurrencyEnum,
  CreateUpdatePurchaseInvoicePositionDtoVatEnum,
  GetClientDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { ClientSelector } from '../../components/Selects/ClientSelector';
import { getVatAmountByVat } from '../../../utils/getVatAmountByVat';
import { FileSelector } from '../../components/Attachments/FileSelector';
import ValidationAlert from '../../../app/components/ValidationAlert';
import mathUtils from '../../../utils/mathUtils';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import {
  getMainCurrencySymbol,
  isMainCurrencyPLN,
} from '../../../utils/currencyFormatUtils';

export type GoogleXlsx = {
  number: string;
  currency: string;
  sellDate: Date;
  issueDate: Date;
  payment_date: Date;
  amount: string;
  product: string;
  advertiserName: string;
  accountName: string;
};

export const PurchasesInvoicesImportView: React.FC = () => {
  const pok = usePokCore();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileNameRef = useRef<HTMLInputElement>(null);
  const directoryInputRef = useRef<HTMLInputElement>(null);
  const directoryNameRef = useRef<HTMLInputElement>(null);
  const [showNewAlert, setShowNewAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [alert, setAlert] = useState<string[]>([]);
  const [excel, setExcel] = useState<string>('');
  const [directory, setDirectory] = useState<string>('');
  const [ws, setWs] = useState<XLSX.WorkSheet>();
  const [client, setClient] = useState<GetClientDto>();
  const [fileList, setFileList] = useState<FileList | null>(null);

  const handleFileChange = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }
    const f: File = files[0];
    const reader = new FileReader();
    const name = f.name;
    setExcel(name);

    reader.onload = function (e) {
      if (e.target) {
        const target: FileReader = e.target;
        if (target.result) {
          const workbook = XLSX.read(target.result, { type: 'array' });
          setWs(workbook.Sheets[workbook.SheetNames[0]]);
        }
      }
    };
    reader.readAsArrayBuffer(f);
  };

  async function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as ArrayBuffer;
        resolve(result);
      };

      reader.onerror = () => {
        reject(new Error('Error reading file as ArrayBuffer'));
      };

      reader.readAsArrayBuffer(file);
    });
  }

  const findFileByName = (fileList: FileList, targetFileName: string) => {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.name.trim() === String(targetFileName).trim()) {
        return file;
      }
    }
    return null;
  };

  const handleDirectoryChange = async (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }
    const directoryName = files[0].webkitRelativePath
      ? files[0].webkitRelativePath.split('/')[0]
      : '';
    setDirectory(directoryName);
    setFileList(files);
  };

  const getExchangeRate = async (currency: string, date: Date) => {
    if (currency !== getMainCurrencySymbol()) {
      if (!isMainCurrencyPLN()) {
        return '';
      }
      const dataExchR = await pok.exchangeApi.getExchangeRate(
        currency as string,
        date || new Date(),
      );
      return dataExchR.rate?.toString() || '';
    }
  };

  const handleSearch = async () => {
    if (!ws || !fileList || !client) {
      setShowNewAlert(true);
      return;
    }
    setShowNewAlert(false);
    setAlert(['Trwa pobieranie danych. Proszę czekać...']);
    setShowAlert(true);
    setWaiting(true);

    const googleJson = XLSX.utils.sheet_to_json(ws, {
      header: [
        'number',
        'currency',
        'sth1',
        'sth2',
        'sellDate',
        'issueDate',
        'payment_date',
        'amount',
        'sth3',
        'product',
        'sth4',
        'sth5',
        'advertiserName',
        'sth6',
        'accountName',
      ],
    });

    const googlesXlsx = googleJson as unknown as GoogleXlsx[];

    const googles = googlesXlsx.slice(1);
    const errors = [];
    const type =
      (await pok.clients.getById(client.id))?.country === 'Polska'
        ? InvoiceTypeEnum.Domestic
        : InvoiceTypeEnum.Foreign;
    for (const o of googles) {
      const file = findFileByName(fileList, String(o.number + '.pdf'));

      if (file !== null) {
        const arrayBuffer = await readFileAsArrayBuffer(file);
        const buffer = Buffer.from(arrayBuffer);

        const excelEpoch = new Date('1899-12-30');
        const comment = `${o.product || ''}${
          o.advertiserName ? ' | ' + o.advertiserName : ''
        }${o.accountName ? ' | ' + o.accountName : ''}`;

        const issueDate = new Date(
          excelEpoch.getTime() + Number(o.issueDate) * 24 * 60 * 60 * 1000,
        );

        const exchangeRate = await getExchangeRate(o.currency, issueDate);
        const amount = exchangeRate
          ? mathUtils
              .round(mathUtils.multiply(o.amount, exchangeRate))
              .toString()
          : o.amount;

        try {
          await pok.purchaseInvoices.create({
            ...newPurchaseInvoice(),
            clientId: client?.id || '',
            companyId: pok.getCompanyId(),
            number: o.number.toString(),
            currency:
              o.currency as unknown as CreateUpdatePurchaseInvoiceDtoCurrencyEnum,
            type: type,
            sellDate: issueDate,
            issueDate: issueDate,
            paymentDate: o.payment_date
              ? new Date(
                  excelEpoch.getTime() +
                    Number(o.payment_date) * 24 * 60 * 60 * 1000,
                )
              : new Date(
                  excelEpoch.getTime() +
                    Number(o.issueDate) * 24 * 60 * 60 * 1000,
                ),
            amount: amount,
            amountWithoutPosition: amount,
            exchangeRate: exchangeRate?.toString(),
            comment: comment,
            positions: [
              {
                netAmount: amount,
                vat: CreateUpdatePurchaseInvoicePositionDtoVatEnum.Zw,
                purchaseInvoiceId: '',
                vatAmount: getVatAmountByVat(amount, 'zw.' as VatEnum),
              },
            ],
            attachment: {
              ...newAttachment,
              fileContent: buffer,
              name: file.name,
              enObjectType: AttachmentObjectType.PurchaseInvoice,
              objectId: '',
              companyId: pok.getCompanyId(),
            },
          });
        } catch (response) {
          errors.push(
            `Faktura ${o.number} - ${await responseErrors(response as ResponseError)}`,
          );
        }
      } else {
        errors.push(
          `Faktura ${o.number} - Nie znaleziono pliku ${o.number}.pdf`,
        );
      }
    }

    setShowNewAlert(false);
    setWaiting(false);
    setAlert(errors.length === 0 ? ['Zakończono pobieranie danych.'] : errors);
  };

  return (
    <>
      <Card className="mb-2">
        <Card.Header>Import faktur zakupu z Google</Card.Header>
        <Card.Body>
          <Alert variant="danger" className="mt-3">
            Użytkownik musi być w kontekście spółki, do której mają trafić
            faktury FVZ
          </Alert>
          <Alert variant="info" className="mt-3">
            Założenia:
            <li>W pierwszym wierszu w excelu znajduje się wiersz nagłówkowy</li>
            <li>
              Jeśli kontrahent ma państwo Polska - faktury są importowane jako
              krajowe, wpp. jako zagraniczne
            </li>
            <li>Pole z kwotą musi być przez Excel rozpoznawane jako liczba</li>
            <li>
              Trzy pola z datami powinny być rozpoznawane przez Excel jako data
            </li>
          </Alert>
          {!waiting && (
            <>
              <Form className="d-grid gap-3">
                <FormRow controlId="client" label="Fakturujący (kontrahent)">
                  <ClientSelector
                    onChange={option =>
                      setClient({
                        id: option.value,
                        name: option.label,
                      } as GetClientDto)
                    }
                    value={client}
                  />
                </FormRow>
                <FormRow
                  controlId="excel"
                  label="Plik Excel zawierający dane o fakturach z Google"
                >
                  <FileSelector
                    fileInputRef={fileInputRef}
                    fileNameRef={fileNameRef}
                    value={excel}
                    handleFileChange={handleFileChange}
                    accept=".xlsx"
                  />
                </FormRow>
                <FormRow
                  controlId="directory"
                  label="Katalog zawierający faktury wystawione przez Google"
                >
                  <FileSelector
                    fileInputRef={directoryInputRef}
                    fileNameRef={directoryNameRef}
                    value={directory}
                    handleFileChange={handleDirectoryChange}
                    accept=".xlsx"
                    isDirectory={true}
                    buttonText="Wybierz katalog"
                  />
                </FormRow>
              </Form>
              {showNewAlert && (
                <Alert variant="danger" className="mt-3">
                  Proszę wskazać fakturującego, plik w formacie Excel z danymi i
                  katalog z załącznikami.
                </Alert>
              )}
              <div className="d-flex justify-content-end mt-3">
                {/* <Button variant="outline-primary" onClick={handleSearch}>
                  Importuj
                </Button> */}
                <ConfirmationButton
                  variant="outline-primary"
                  onOK={handleSearch}
                  confirmation={`Czy na pewno zaimportować faktury?`}
                  isLoading={waiting}
                >
                  Importuj
                </ConfirmationButton>
              </div>
            </>
          )}
          {waiting && <Waiting />}
        </Card.Body>
      </Card>
      <ValidationAlert show={showAlert} errors={alert} className="m-3" />
    </>
  );
};
