import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetBudgetDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { BudgetInfo } from './BudgetInfo';

interface BudgetsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const budgetForGrid = (budget: GetBudgetDto) => {
  return {
    key: budget.id,
    values: {
      'budget.systemNumber': budget.systemNumber,
      'budget.name': budget.name,
      'employee.name': budget.manager?.name,
      status: <BudgetInfo id={budget.id} />,
    },
    isInactive: !budget.active,
  };
};

export const BudgetSearch: React.FC<BudgetsSearchProps> = props => {
  const { t, tk } = useTranslation('clientLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'budget.systemNumber' },
    { header: t(tk.common.name), property: 'budget.name' },
    { header: t(tk.budget.managerName), property: 'employee.name' },
    { header: '', property: 'status', noSorting: true, noFilter: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="budget.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.budget.noBudgets)}
      onRowClick={props.onRowClick}
      withFilterInColumns={true}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
