import React from 'react';
import { Card, Tab, Row } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ReportsView: React.FC = () => {
  const nav = useNavigation();
  const { t, tk } = useTranslation('reportLng');

  return (
    <Card>
      <TabsHistory activeKey="byPositions" id="reports" className="mb-3">
        <Tab
          eventKey="byPositions"
          title={t(tk.administration.estimatesPositions)}
        >
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title={t(tk.administration.rawData)}
              text={t(tk.administration.extensiveDataFromEstimatesByMonth)}
              footer={t(tk.administration.estimatesPositions)}
              onClick={nav.reportEstimateItemsDataRaw}
            />
          </Row>
        </Tab>
      </TabsHistory>
    </Card>
  );
};
