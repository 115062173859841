import React from 'react';
import { Alert } from 'react-bootstrap';
import { isEqual } from 'lodash';

import { useTranslation } from '../../../common/hooks/useTranslation';
import { CreateUpdateSalesInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface SalesInvoiceModifiedProjectsAlertProps {
  initialProjectIds: string[] | undefined;
  salesInvoice: CreateUpdateSalesInvoiceDto;
}

export const SalesInvoiceModifiedProjectsAlert: React.FC<
  SalesInvoiceModifiedProjectsAlertProps
> = props => {
  const { t, tk } = useTranslation('fvsLng');
  if (
    !props.initialProjectIds ||
    isEqual(props.initialProjectIds, props.salesInvoice.projectIds)
  ) {
    return null;
  }

  return (
    <Alert variant="danger">
      {t(tk.prLang.projectsListModifiedInvoiceAlert)}
    </Alert>
  );
};
