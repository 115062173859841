import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Table } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateEmployeeRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import EmployeeSelector from '../Selects/EmployeeSelector';
import { EmployeeRole } from '../Roles/EmployeeRole';
import {
  convert,
  newEmployeeRole,
} from '../../../common/pokCore/contexts/EmployeeRoleContext';
import { Option } from '../Selects/Selector';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { EmployeeRoleEditorControls } from './EmployeeRoleEditorControls';

interface EmployeeRoleEditorProps {
  employeeId: string | undefined;
  employeeName: string | undefined;
  handleClose?: () => void;
  handleDelete?: () => void;
  show?: boolean;
  onCompleted: (
    employeeId: string,
    employeeRoles: CreateUpdateEmployeeRoleDto[] | undefined,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const EmployeeRoleEditor: React.FC<EmployeeRoleEditorProps> = props => {
  const [employeeId, setEmployeeId] = useState<string>();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [employeeRoles, setEmployeeRoles] = useState<
    CreateUpdateEmployeeRoleDto[] | undefined
  >();

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('roleLng');

  const refreshEmployeeRoles = useCallback(
    (empId?: string) => {
      if (empId) {
        pok.employeesRoles
          .getWithTheSameUserByUserId(empId)
          .then(getEmployeesRoles =>
            setEmployeeRoles(getEmployeesRoles.map(o => convert(o))),
          )
          .catch(errorResponse => notifications.caughtError(errorResponse));
      } else {
        setEmployeeRoles(undefined);
      }
    },
    [pok.employeesRoles, notifications],
  );

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setEmployeeRoles(undefined);
      setEmployeeId(props.employeeId);
      refreshEmployeeRoles(props.employeeId);
    }
  }, [props.employeeId, props.show, refreshEmployeeRoles]);

  useEffect(() => {
    refreshEmployeeRoles(employeeId);
  }, [employeeId, refreshEmployeeRoles]);

  const propertyChange = (
    obj: Partial<CreateUpdateEmployeeRoleDto>,
    keyObj: number,
  ) => {
    setEmployeeRoles(
      employeeRoles?.map((item, key) =>
        key === keyObj ? { ...item, ...obj } : item,
      ),
    );
  };

  const deleteRole = (keyObj: number) => {
    setEmployeeRoles(employeeRoles?.filter((item, key) => key !== keyObj));
  };

  const addRole = () => {
    setEmployeeRoles(employeeRoles?.concat([newEmployeeRole()]));
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        size="xl"
        centered={true}
        keyboard={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {employeeId
              ? t(tk.permission.employeeRoleEdit)
              : t(tk.permission.employeeRoleAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-3 mb-3">
            <FormRow controlId="employee" label={t(tk.common.employee)}>
              <EmployeeSelector
                value={employeeId || ''}
                onChange={option => {
                  if (option) {
                    setEmployeeId((option as Option)?.value);
                  }
                }}
                onlyCurrentCompany={false}
                readOnly={!!props.employeeId}
                withTechnical={true}
                withOneToolId={true}
              />
            </FormRow>
            <Table striped>
              <thead className="text-center">
                <tr>
                  <th className="col-3">{t(tk.role.role)}</th>
                  <th className="col-3">{t(tk.common.company)}</th>
                  <th className="col-3">{t(tk.common.team)}</th>
                  <th className="col-3">{t(tk.common.budget)}</th>
                  <th className="col-1"></th>
                </tr>
              </thead>
              <tbody>
                {employeeRoles &&
                  employeeRoles.map((emplRole, key) => (
                    <EmployeeRole
                      key={key.toString()}
                      value={emplRole}
                      onUpdate={dto => propertyChange(dto, key)}
                      onDelete={() => deleteRole(key)}
                    />
                  ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end">
              <Button variant="outline-primary" onClick={addRole}>
                {t(tk.button.add)}
              </Button>
            </div>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <EmployeeRoleEditorControls
            {...props}
            employeeRoles={employeeRoles}
            employeeId={employeeId}
            setSaveErrors={setSaveErrors}
            setShowSaveErrors={setShowSaveErrors}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
