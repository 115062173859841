import React, { useMemo, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { ProjectEditor } from '../../components/Projects/ProjectEditor';
import {
  CreateUpdateProjectWithTeamDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ProjectContext';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { ProjectSearch } from '../../components/Projects/ProjectSearch';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { EstimateByMonthSearch } from '../../components/EstimatesByMonth/EstimateByMonthSearch';
import { ProjectApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';

enum ProjectsTabs {
  PROJECTS = 'Projekty',
  MEDIAPLANS = 'Mediaplany',
}

export const ProjectsView: React.FC = () => {
  const [project, setProject] = useState<GetProjectDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [defaultCountryId, setDefaultCountryId] = useState<string>();

  const { t, tk } = useTranslation('projectLng');
  const pok = usePokCore();
  const nav = useNavigation();
  const notifications = useNotifications();

  useMemo(() => {
    pok.dictionaries
      .getByDictionaryTypeAndShortname('Kraj/rynek', 'PL')
      .then(res => {
        setDefaultCountryId(res.id);
      })
      .catch(err => {
        notifications.caughtError(err);
      });
  }, [notifications, pok.dictionaries]);

  const handleAdd = () => {
    setShow(true);
    setProject(undefined);
  };

  const addEditProject = async (
    projectCU: CreateUpdateProjectWithTeamDto,
    id?: string,
  ) => {
    const status = await validate(projectCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveUpdateProject(projectCU, id);
  };

  const saveUpdateProject = async (
    projectCU: CreateUpdateProjectWithTeamDto,
    id?: string,
  ) => {
    try {
      let newProject;
      if (id) {
        await pok.projects.update(id, projectCU);
        if (projectCU.delayVerification) {
          void sendEmail(id, ProjectApplicationTypeEnum.Delayed);
        }
      } else {
        newProject = await pok.projects.create(projectCU);
        if (projectCU.delayVerification) {
          void sendEmail(newProject.id, ProjectApplicationTypeEnum.Delayed);
        }
      }
      setShow(false);
      setRefresh(!refresh);
      if (newProject && !newProject.delayVerification) {
        nav.projectDetails(newProject.id);
      }
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  const sendEmail = async (id: string, type: ProjectApplicationTypeEnum) => {
    pok.emails.sendProjectEmail(id, type).catch(err => {
      notifications.caughtError(err);
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string, newWindow?: boolean) => {
    nav.projectDetails(id, newWindow);
  };

  const handleEstimateByMonthClick = async (id: string, newWindow?: true) => {
    pok.estimatesByMonth
      .getById(id)
      .then(est => nav.projectDetails(est.project.id, newWindow, 'estimates'))
      .catch(err => {
        notifications.caughtError(err);
      });
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.prLang.projects)}</Card.Title>
        <Button className="mb-4" variant="outline-primary" onClick={handleAdd}>
          {t(tk.prLang.addNewProject)}
        </Button>
        <TabsHistory
          activeKey={ProjectsTabs.PROJECTS}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={ProjectsTabs.PROJECTS}
            title={t(tk.prLang.projects)}
            className="m-3"
          >
            <ProjectSearch
              getData={pok.projects.getAllByPortion}
              getCount={pok.projects.getAllCount}
              onRowClick={handleClick}
              refresh={refresh}
              showActionColumn={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
          <Tab
            eventKey={ProjectsTabs.MEDIAPLANS}
            title={t(tk.prLang.estimates)}
            className="m-3"
          >
            <EstimateByMonthSearch
              getData={pok.estimatesByMonth.getAllByPortion}
              getCount={pok.estimatesByMonth.getAllCount}
              onRowClick={handleEstimateByMonthClick}
              refresh={refresh}
              showNewWindowButton={true}
            />
          </Tab>
        </TabsHistory>
        <ProjectEditor
          id={project?.id}
          project={convert(project, pok.getCompanyId(), defaultCountryId)}
          onCompleted={addEditProject}
          show={show}
          handleClose={handleClose}
        />
      </Card.Body>
    </Card>
  );
};
