import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';
import '../../../theme/calendar.css';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface WorkDaysCalendarProps {
  initialSelectedDays: string[];
  year?: string;
  setYear?: (year: string) => void;
  addDay?: (dailyDate: Date) => void;
  removeDay?: (dailyDate: Date) => void;
}

const WorkDaysCalendar: React.FC<WorkDaysCalendarProps> = props => {
  const [selectedDays, setSelectedDays] = useState<string[]>(
    props.initialSelectedDays,
  );
  const { t, tk } = useTranslation('commonLng');

  useEffect(() => {
    setSelectedDays(props.initialSelectedDays);
  }, [props.initialSelectedDays]);

  const handleDateClick = (date: Date) => {
    const formattedDate = momencik(date, 'YYYY-MM-DD');

    if (selectedDays.includes(formattedDate)) {
      props.removeDay && props.removeDay(date);
    } else {
      props.addDay && props.addDay(date);
    }
  };

  const isSelected = (date: Date) => {
    const formattedDate = momencik(date, 'YYYY-MM-DD');
    return selectedDays.includes(formattedDate);
  };

  const handleActiveStartDateChange = ({
    activeStartDate,
  }: {
    activeStartDate: Date | null;
  }) => {
    if (activeStartDate) {
      const newYear = activeStartDate.getFullYear().toString();
      if (props.setYear && newYear !== props.year) {
        props.setYear(newYear);
      }
    }
  };

  return (
    <div className="big-calendar">
      <Calendar
        onClickDay={handleDateClick}
        tileClassName={({ date, view }) =>
          view === 'month' && isSelected(date) ? 'highlight' : null
        }
        onActiveStartDateChange={handleActiveStartDateChange}
        className="react-calendar"
        locale={t(tk.common.locale)}
      />
    </div>
  );
};

export default WorkDaysCalendar;
