import React from 'react';
import { Badge } from 'react-bootstrap';
import { SquareFill } from 'react-bootstrap-icons';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { GetCompanyDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { PersonalLanguageTypeText } from '../../../common/pokCore/validation/schemas';
import enFlag from '../../../assets/en.png';
import plFlag from '../../../assets/pl.png';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface CompanySearchProps {
  data: GetCompanyDto[];
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const CompanySearch: React.FC<CompanySearchProps> = props => {
  const { t, tk } = useTranslation('administrationLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.lp), property: 'company.orderField' },
    { header: t(tk.common.sysNumber), property: 'company.systemNumber' },
    { header: t(tk.common.shortname), property: 'company.shortname' },
    { header: t(tk.common.name), property: 'company.name' },
    { header: t(tk.administration.logo), property: 'company.logo' },
    {
      header: t(tk.administration.personalLanguage),
      property: 'company.personalLanguage',
    },
    { header: t(tk.administration.color), property: 'company.color' },
    { header: t(tk.administration.language), property: 'company.language' },
  ];

  const data = props.data.map(company => {
    return {
      key: company.id,
      values: {
        'company.orderField': company.orderField,
        'company.name': company.name,
        'company.shortname': (
          <Badge bg="warning" text="dark">
            {company.shortname}
          </Badge>
        ),
        'company.logo': company.logo && (
          <img src={company.logo} alt="logo" height="20" />
        ),
        'company.language': (
          <img
            src={company.language === 'PL' ? plFlag : enFlag}
            alt="flag"
            height="20"
          />
        ),
        'company.color': company.color && (
          <SquareFill color={company.color} size={20} />
        ),
        'company.personalLanguage':
          company.personalLanguage &&
          PersonalLanguageTypeText[company.personalLanguage],
        'company.systemNumber': company.systemNumber,
      },
      orderByStringValues: {
        'company.orderField':
          company.orderField?.toString().padStart(10, '0') ?? '9999999999',
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="company.orderField"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.administration.noCompanies)}
      onRowClick={props.onRowClick}
      withHistory={true}
    />
  );
};
