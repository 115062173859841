import React from 'react';

import FormRow from '../../../app/components/FormRow';
import { SchemaAttributesDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface ProfileFieldNameProps {
  children: React.ReactNode;
  controlId: string;
  schema: SchemaAttributesDto;
  lang: string;
}

const ProfileFieldName: React.FC<ProfileFieldNameProps> = props => {
  return (
    <FormRow
      controlId={props.controlId}
      label={
        props.lang === 'PL'
          ? props.schema?.displayNamePl
          : props.schema?.displayNameEn
      }
    >
      {props.children}
    </FormRow>
  );
};
export default ProfileFieldName;
