import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';

import FormRow from '../../../app/components/FormRow';
import ProfileSelector from '../Selects/ProfileSelector';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { Option } from '../Selects/Selector';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { CreateUpdateEstimateByMonthDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import 'react-calendar/dist/Calendar.css';
import '../../../theme/calendar.css';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';
import Button from '../../../common/components/Button/Button';

interface AddProfileFormProps {
  estimateByMonth: CreateUpdateEstimateByMonthDto;
  teamIds?: string[];
  budgetId: string;
  show?: boolean;
  handleClose?: () => void;
  onCompleted: (estimateByMonth: CreateUpdateEstimateByMonthDto) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const AddProfileForm: React.FC<AddProfileFormProps> = props => {
  const [estimateByMonth, setEstimateByMonth] =
    useState<CreateUpdateEstimateByMonthDto>(props.estimateByMonth);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const notifications = useNotifications();
  const pok = usePokCore();
  const companyId = pok.getCompanyId();
  const { t, tk } = useTranslation('projectLng');

  const createProfile = async () => {
    setIsLoading(true);
    props
      .onCompleted(estimateByMonth)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const setDate = (date: Date): Date | null => {
    //ten kalendarz inaczej ustawia godzinę niż nasz główny kalendarz
    //dlatego trzeba skorygować godzinę
    date.setMinutes(-date.getTimezoneOffset());
    return date;
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t(tk.estimates.addProfile)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="d-grid gap-4 mb-3">
          <FormRow
            controlId="profile"
            label={`${t(tk.estimates.selectProfile)} ${t(tk.prLang.estimate2)}`}
          >
            <ProfileSelector
              provider={pok.profiles.getAllByCompanyIdsTeamIdsAuth}
              companyIds={[companyId || '']}
              teamIds={props.teamIds}
              budgetId={props.budgetId}
              onChange={options => {
                setEstimateByMonth({
                  ...estimateByMonth,
                  profileId: options ? (options as Option).value : '',
                });
              }}
            />
          </FormRow>
          <FormRow controlId="month" label={t(tk.estimates.selectMonth)}>
            <div className="small-calendar">
              <Calendar
                onChange={value => {
                  setEstimateByMonth({
                    ...estimateByMonth,
                    date: setDate(value as Date),
                  });
                }}
                defaultView="year"
                minDetail="year"
                maxDetail="year"
              />
            </div>
          </FormRow>
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.handleClose}>
          {t(tk.button.close)}
        </Button>
        <Button variant="primary" onClick={createProfile} isLoading={isLoading}>
          {t(tk.button.add)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
