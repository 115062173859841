export default {
  role: {
    role: 'Role',
    roles: 'Roles',
    roleNew: 'New role',
    roleAdd: 'Adding new role',
    roleEdit: 'Role edit',
    noRoles: 'No roles in the system',
    permissions: 'Permissions',
    addToBudget: 'Assigned to budget',
    chosenPermissions: 'Selected permissions',
    askDelete: 'Are you sure you want to delete the role?',
    noPermissions: 'No permissions in the system',
  },
  permission: {
    employeesRoles: 'User role assignments',
    employeeRoleNew: 'New assignment',
    employeeRoleEdit: 'Edit user roles',
    employeeRoleAdd: 'Adding new user in roles',
    employeeRoleDelete: 'Delete assignment',
    employeeRole: 'Assignment',
    noEmployeeRoles: 'No user role assignments in the system',
    askDelete:
      'Are you sure you want to delete all permissions assigned to this user?',
  },
  employee: {
    employeesAssigned: 'Users assigned to the role',
    employees: 'Users in the role',
    employeeNew: 'New user',
    employeeEdit: 'Editing user assignment to the role',
    employeeAdd: 'Adding a new user to the role',
  },
} as const;
