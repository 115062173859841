import React from 'react';
import { Form } from 'react-bootstrap';

import { GetOrderExecutionDtoStatusEnum } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { OrderExecutionStatusEnumText } from '../../../common/pokCore/validation/schemas';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface OrderExecutionStatusFieldsProps {
  status?: GetOrderExecutionDtoStatusEnum;
  rejectedNote?: string;
  active?: boolean;
}

export const OrderExecutionStatusFields: React.FC<
  OrderExecutionStatusFieldsProps
> = ({ status, rejectedNote, active }) => {
  const { t, tk } = useTranslation('orderLng');
  return (
    <>
      {status && (
        <FormRow controlId="status" label={t(tk.common.status)}>
          <Form.Control
            value={
              OrderExecutionStatusEnumText[status] +
              (!active ? ` - ${t(tk.order.deleted)}` : '')
            }
            disabled
          />
        </FormRow>
      )}
      {rejectedNote && (
        <FormRow controlId="rejectedNote" label={t(tk.order.rejectedNote)}>
          <DynamicTextarea disabled readOnly value={rejectedNote} />
        </FormRow>
      )}
    </>
  );
};
