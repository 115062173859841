export default {
  excel: {
    // import configurations
    profile: 'Profile',
    profileCategory: 'Profile category',

    importConfigsByName: 'Data import configuration named',

    importConfig: 'Import configuration',
    importConfigs: 'External data import configurations',
    importConfigAdd: 'Adding a new configuration',
    importConfigNew: 'New configuration',
    importConfigEdit: 'Editing configuration',
    noImportConfigs: 'No import configurations in the system.',
    importConfigType: 'Type of import',
    importConfigDescription: 'Import instructions',
    companyIndependent: 'Company independent',
    updateExisting: 'Updating existing items',

    mappingNew: 'New mapping',
    mappingEdit: 'Editing mapping',
    mappingAdd: 'Adding new mapping',
    columnInPOK: 'Column in POK',
    columnInExcel: 'Column in Excel',
    columnOrder: 'Column order in the import table',
    noColumnMapping: 'No column mappings defined',

    mappingColumn: 'Column mapping',
    mappingPosition: 'Position mapping',
    positionInPok: 'Position in POK',
    positionInExcel: 'Position in Excel',
    positionParameters: 'Position parameters set',
    noPosition: 'No positions defined',
    parameters: 'Parameters',
  },
} as const;
