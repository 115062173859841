import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { DecisionSpecialInvoicingDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import FormRow from '../../../app/components/FormRow';
import {
  SpecialInvoicingStatus,
  SpecialInvoicingStatusText,
} from '../../../common/pokCore/validation/schemas';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface SpecialInvoicingDecisionFormProps {
  decisionSpecialInvoicing: DecisionSpecialInvoicingDto;
  propertyChange: (obj: Partial<DecisionSpecialInvoicingDto>) => void;
  readOnly: boolean;
}

const statusesOptions = [
  {
    value: SpecialInvoicingStatus.Accepted,
    label: SpecialInvoicingStatusText.Accepted,
  },
  {
    value: SpecialInvoicingStatus.Rejected,
    label: SpecialInvoicingStatusText.Rejected,
  },
  {
    value: SpecialInvoicingStatus.ToBeCorrected,
    label: SpecialInvoicingStatusText.ToBeCorrected,
  },
];

export const SpecialInvoicingDecisionForm: React.FC<
  SpecialInvoicingDecisionFormProps
> = props => {
  const { t, tk } = useTranslation('applicationLng');

  const onChangeStatus = (newStatus: SpecialInvoicingStatus | undefined) => {
    if (newStatus === SpecialInvoicingStatus.Accepted) {
      props.propertyChange({
        financeNote: !props.decisionSpecialInvoicing.financeNote
          ? props.decisionSpecialInvoicing.note
          : props.decisionSpecialInvoicing.financeNote,
        status: newStatus,
      });
    } else {
      props.propertyChange({
        status: newStatus,
        financeNote: undefined,
      });
    }
  };

  return (
    <Form className="d-grid gap-4 my-3">
      <hr />
      <FormRow controlId="applicationStatus" label={t(tk.common.status)}>
        <Select
          value={
            props.decisionSpecialInvoicing.status
              ? statusesOptions.find(
                  ({ value }) =>
                    props.decisionSpecialInvoicing?.status === value,
                )
              : null
          }
          options={statusesOptions}
          onChange={option => onChangeStatus(option?.value)}
          placeholder={t(tk.component.pointOut)}
          isDisabled={props.readOnly}
        />
      </FormRow>
      {props.decisionSpecialInvoicing.status ===
        SpecialInvoicingStatus.Rejected && (
        <FormRow controlId="rejectedNote" label={t(tk.app.rejectedNote)}>
          <DynamicTextarea
            value={props.decisionSpecialInvoicing.rejectedNote || ''}
            onChange={e =>
              props.propertyChange({
                rejectedNote: e.target.value || '',
              })
            }
            disabled={props.readOnly}
          />
        </FormRow>
      )}
      {props.decisionSpecialInvoicing.status ===
        SpecialInvoicingStatus.ToBeCorrected && (
        <FormRow controlId="correctionNote" label={t(tk.app.requiredChanges)}>
          <DynamicTextarea
            value={props.decisionSpecialInvoicing.correctionNote || ''}
            onChange={e =>
              props.propertyChange({
                correctionNote: e.target.value || '',
              })
            }
            disabled={props.readOnly}
          />
        </FormRow>
      )}
      {props.decisionSpecialInvoicing.status ===
        SpecialInvoicingStatus.Accepted && (
        <FormRow controlId="financeNote" label={t(tk.app.acceptedFinanceNote)}>
          <DynamicTextarea
            value={props.decisionSpecialInvoicing.financeNote || ''}
            onChange={e =>
              props.propertyChange({
                financeNote: e.target.value || '',
              })
            }
            disabled={props.readOnly}
          />
        </FormRow>
      )}
    </Form>
  );
};
