import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateExcelConfigHeaderDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { FieldSelector } from '../Selects/FieldSelector';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface HeaderEditorProps {
  id?: string;
  header: CreateUpdateExcelConfigHeaderDto;
  profile: GetProfileDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    header: CreateUpdateExcelConfigHeaderDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const HeaderEditor: React.FC<HeaderEditorProps> = props => {
  const [header, setHeader] = useState(props.header);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('excelImportLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setHeader(props.header);
    }
  }, [props.header, props.show]);

  const createHeader = async () => {
    setIsLoading(true);
    props
      .onCompleted(header, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateExcelConfigHeaderDto>) => {
    setHeader({ ...header, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id ? t(tk.excel.mappingEdit) : t(tk.excel.mappingAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-3">
            <FormRow controlId="pokColumnName" label={t(tk.excel.columnInPOK)}>
              <FieldSelector
                profile={props.profile}
                value={header?.pokColumnName}
                onChange={option =>
                  propertyChange({
                    pokColumnName: option?.value,
                    pokColumnNamePl: option?.label,
                  })
                }
              />
            </FormRow>
            <FormRow
              controlId="excelColumnName"
              label={t(tk.excel.columnInExcel)}
            >
              <Form.Control
                type="text"
                value={header?.excelColumnName || ''}
                onChange={e =>
                  propertyChange({ excelColumnName: e.target.value })
                }
              />
            </FormRow>
            <FormRow controlId="order" label={t(tk.excel.columnOrder)}>
              <Form.Control
                type="number"
                min={0}
                value={header?.order || ''}
                onChange={e =>
                  propertyChange({ order: parseInt(e.target.value, 10) })
                }
              />
            </FormRow>
            <FormRow controlId="active" label={t(tk.common.activeHe)}>
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={header?.active ?? true}
                onChange={e => propertyChange({ active: e.target.checked })}
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.excelConfigHeaders.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={createHeader}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
