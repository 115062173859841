/*eslint complexity: ["error", 15]*/
import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { usePokCore } from '../../../common/hooks/usePokCore';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface EstimateItemTabsFooterProps {
  id?: string;
  deleteItem: () => void;
  createEstimateItem: () => void;
  cloneItem: (id: string, withAmounts: boolean) => () => void;
  isSalesBlocked: boolean;
  isPurchaseBlocked: boolean;
  isLoadingSave: boolean;
  isLoadingDelete: boolean;
}

export const EstimateItemTabsFooter: React.FC<
  EstimateItemTabsFooterProps
> = props => {
  const pok = usePokCore();
  const { t, tk } = useTranslation('projectLng');

  return (
    <div className="side-by-side">
      <div className="left">
        {props.id && (
          <LastEditorInfo
            className="color-primary"
            id={props.id || ''}
            method={pok.estimateItems.getLastEditor}
            additionalText={t(tk.estimates.lastPositionModification)}
          />
        )}
      </div>
      <div className="right">
        {((!props.isSalesBlocked && !props.isPurchaseBlocked) || !props.id) && (
          <ConfirmationButton
            className="mx-1"
            variant="outline-danger"
            onOK={props.deleteItem}
            confirmation={t(tk.estimates.deletePositionConfirmation)}
            isLoading={props.isLoadingDelete}
          >
            {props.id
              ? t(tk.estimates.deletePosition)
              : t(tk.estimates.deleteNewPosition)}
          </ConfirmationButton>
        )}
        {props.id && (!props.isSalesBlocked || !props.isPurchaseBlocked) && (
          <DropdownButton
            title={t(tk.estimates.clone)}
            id="clone-dropdown"
            variant="outline-primary"
            className="mx-1 d-inline-block"
          >
            <Dropdown.Item
              onClick={props.cloneItem(props.id, false)}
              eventKey="1"
            >
              {t(tk.estimates.withoutAmounts)}
            </Dropdown.Item>
            {!props.isSalesBlocked && !props.isPurchaseBlocked && (
              <Dropdown.Item
                onClick={props.cloneItem(props.id, true)}
                eventKey="2"
              >
                {t(tk.estimates.withAmounts)}
              </Dropdown.Item>
            )}
          </DropdownButton>
        )}
        <Button
          variant="primary"
          onClick={props.createEstimateItem}
          className="mx-1"
          isLoading={props.isLoadingSave}
        >
          {props.id
            ? t(tk.estimates.saveChanges)
            : t(tk.estimates.saveNewPosition)}
        </Button>
      </div>
    </div>
  );
};
