import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import { TabsHistory } from '../../../app/components/TabsHistory';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { ProjectSearch } from '../../components/Projects/ProjectSearch';
import { usePokCore } from '../../../common/hooks/usePokCore';

export enum VerificationTabs {
  READY_TO_CORRECTION = 'readyForCorrection',
}

export const VerificationView: React.FC = () => {
  const { t, tk } = useTranslation('fvsLng');
  const pok = usePokCore();

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Weryfikacja</Card.Title>
        <TabsHistory
          activeKey={VerificationTabs.READY_TO_CORRECTION}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={VerificationTabs.READY_TO_CORRECTION}
            title={t(tk.prLang[VerificationTabs.READY_TO_CORRECTION])}
            className="m-3"
          >
            <ProjectSearch
              getData={pok.projects.getAllReadyToCorrectionByPortion}
              getCount={pok.projects.getAllReadyToCorrectionCount}
              showTotalDifferenceColumn
              totalDifferenceLabel="Kwota do korekty"
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
