import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateExcelConfigHeaderDto,
  GetExcelConfigDto,
  GetExcelConfigHeaderDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ExcelConfigHeaderContext';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { HeaderSearch } from './HeaderSearch';
import { HeaderEditor } from './HeaderEditor';

interface HeadersViewProps {
  config: GetExcelConfigDto;
  refresh(): void;
}
export const HeadersView: React.FC<HeadersViewProps> = props => {
  const pok = usePokCore();
  const [header, setHeader] = useState<GetExcelConfigHeaderDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t, tk } = useTranslation('excelImportLng');

  const handleAdd = () => {
    setShow(true);
    setHeader(undefined);
  };

  const addEditHeader = async (
    headerCU: CreateUpdateExcelConfigHeaderDto,
    id?: string,
  ) => {
    const status = await validate(headerCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.excelConfigHeaders.update(id, headerCU);
        } else {
          await pok.excelConfigHeaders.create(headerCU);
        }
        setShow(false);
        setRefresh(!refresh);
        props.refresh();
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const getHeader = await pok.excelConfigHeaders.getById(id);
    setHeader(getHeader);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.excel.mappingNew)}
        </Button>
        <HeaderSearch
          config={props.config}
          onRowClick={handleClick}
          refresh={refresh}
        />
        <HeaderEditor
          id={header?.id}
          header={convert(header, props.config.id)}
          profile={props.config.profile}
          onCompleted={addEditHeader}
          show={show}
          handleClose={handleClose}
        />
      </Card.Body>
    </Card>
  );
};
