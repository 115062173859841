import React from 'react';
import { Form } from 'react-bootstrap';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { ApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';
import { Option } from '../Selects/Selector';
import PurchaserSelector from '../Selects/PurchaserSelector';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ApplicationEditorBasicSpecificFormFieldsBrandProps {
  application: UpdateAdminApplicationDto;
  show?: boolean;
  isReadOnly?: boolean;
  propertyChange?: (
    obj: Partial<CreateUpdateClientDto | CreateUpdateApplicationDto>,
  ) => void;
}

export const ApplicationEditorBasicSpecificFormFieldsBrand: React.FC<
  ApplicationEditorBasicSpecificFormFieldsBrandProps
> = props => {
  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };
  const { t, tk } = useTranslation('applicationLng');

  return (
    <>
      {props.application.teamId &&
        props.application.applicationType === ApplicationTypeEnum.Brand && (
          <>
            <FormRow controlId="applicationName" label={t(tk.app.brandName)}>
              <Form.Control
                type="text"
                value={props.application.elementName ?? ''}
                onChange={e => propertyChange({ elementName: e.target.value })}
                disabled={props.isReadOnly}
              />
            </FormRow>
            <FormRow controlId="purchaserId" label={t(tk.common.purchaser)}>
              <PurchaserSelector
                role={PermissionsEnum.Projects}
                value={props.application.brandApplicationPurchaserId}
                teamIds={[props.application.teamId]}
                onChange={e =>
                  propertyChange({
                    brandApplicationPurchaserId: (e as Option).value,
                  })
                }
                readOnly={props.isReadOnly}
              />
            </FormRow>
          </>
        )}
    </>
  );
};
