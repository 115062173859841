/*eslint max-lines-per-function: ["error", 200]*/
import React, { useState } from 'react';

import {
  CreateUpdateProjectWithTeamDto,
  GetOrderDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { ProjectOrdersTabs } from '../../containers/OrdersView/OrdersView';
import { useNavigation } from '../../../common/navigation';
import {
  convertToCreateFromOrder,
  validate,
} from '../../../common/pokCore/contexts/ProjectContext';
import { ProjectEditor } from '../Projects/ProjectEditor';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  OrderStatusEnum,
  ProjectApplicationTypeEnum,
} from '../../../common/pokCore/validation/schemas';
import Button from '../../../common/components/Button/Button';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface OrderDecisionControlsProps {
  order?: GetOrderDto;
  setShowSaveErrors: (bool: boolean) => void;
  setSaveErrors: (errors: string[]) => void;
}

export const OrderDecisionControls: React.FC<
  OrderDecisionControlsProps
> = props => {
  const notifications = useNotifications();
  const pok = usePokCore();
  const nav = useNavigation();
  const auth = useAuth();
  const { t, tk } = useTranslation('orderLng');

  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [showProject, setShowProject] = useState(false);

  const authorized = props.order?.id
    ? auth.check(PermissionsEnum.Administration, pok.getCompanyId())
    : false;

  const isOrderCompleted =
    props.order?.status !== OrderStatusEnum.New || !props.order.active;

  const handleReject = (rejectedNote?: string) => {
    props.setShowSaveErrors(false);
    props.setSaveErrors([]);
    if (props.order) {
      setIsLoadingReject(true);
      pok.orders
        .reject(props.order.id, rejectedNote!)
        .then(() => {
          notifications.saveCompleted();
          nav.orders(ProjectOrdersTabs.WAITING);
        })
        .catch(async errorResponse => {
          props.setShowSaveErrors(true);
          props.setSaveErrors(
            await responseErrors(errorResponse as ResponseError),
          );
        })
        .finally(() => setIsLoadingReject(false));
    }
  };

  const handleAccept = (delayVerification?: boolean) => {
    props.setShowSaveErrors(false);
    props.setSaveErrors([]);
    if (props.order) {
      setIsLoadingAccept(true);
      pok.orders
        .accept(props.order.id!)
        .then(data => {
          if (data) {
            if (delayVerification) {
              nav.orders(ProjectOrdersTabs.WAITING);
            } else {
              nav.projectDetails(data);
            }
          } else {
            setShowProject(true);
          }
        })
        .catch(async response => {
          props.setShowSaveErrors(true);
          props.setSaveErrors(await responseErrors(response as ResponseError));
        })
        .finally(() => setIsLoadingAccept(false));
    }
  };

  const handleInactive = () => {
    props.setShowSaveErrors(false);
    props.setSaveErrors([]);
    if (props.order) {
      pok.orders
        .deactivate(props.order.id)
        .then(() => {
          notifications.saveCompleted();
          nav.orders(ProjectOrdersTabs.WAITING);
        })
        .catch(async errorResponse => {
          props.setShowSaveErrors(true);
          props.setSaveErrors(
            await responseErrors(errorResponse as ResponseError),
          );
        })
        .finally(() => setIsLoadingReject(false));
    }
  };

  const addEditProject = async (projectCU: CreateUpdateProjectWithTeamDto) => {
    const status = await validate(projectCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveUpdateProject(projectCU);
  };

  const saveUpdateProject = async (
    projectCU: CreateUpdateProjectWithTeamDto,
  ) => {
    try {
      const newProject = await pok.projects.create(projectCU);
      if (projectCU.delayVerification) {
        void sendEmail(newProject.id, ProjectApplicationTypeEnum.Delayed);
      }
      setShowProject(false);
      handleAccept(projectCU.delayVerification);

      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  const sendEmail = async (id: string, type: ProjectApplicationTypeEnum) => {
    pok.emails.sendProjectEmail(id, type).catch(err => {
      notifications.caughtError(err);
    });
  };

  const handleCloseProject = () => {
    setShowProject(false);
  };

  return (
    <>
      <div className="d-flex marginLeft gap-2">
        {authorized && props.order?.active && (
          <ConfirmationButton
            variant="outline-danger"
            confirmation={t(tk.order.askDeleteOrder)}
            confirmLabel={t(tk.order.deleteOrder)}
            rejectLabel={t(tk.button.cancel)}
            dynamicInput
            focusInput
            onOK={handleInactive}
          >
            {t(tk.order.deleteOrder)}
          </ConfirmationButton>
        )}
        <ConfirmationButton
          variant="outline-danger"
          confirmation={t(tk.order.orderRejection)}
          reasonText={t(tk.order.rejectionReason)}
          confirmLabel={t(tk.order.rejectOrder)}
          errorLabel={t(tk.order.fillRejectionReason)}
          rejectLabel={t(tk.button.cancel)}
          dynamicInput
          askForAReason
          reasonNotEmpty
          focusInput
          onOK={handleReject}
          disabled={isOrderCompleted || isLoadingAccept}
          isLoading={isLoadingReject}
        >
          {t(tk.order.rejectOrder)}
        </ConfirmationButton>
        <Button
          disabled={isOrderCompleted || isLoadingReject}
          onClick={() => handleAccept(false)}
          isLoading={isLoadingAccept}
        >
          {t(tk.order.acceptOrder)}
        </Button>
      </div>
      {props.order && showProject && (
        <ProjectEditor
          project={convertToCreateFromOrder(props.order, pok.getCompanyId())}
          onCompleted={addEditProject}
          show={showProject}
          handleClose={handleCloseProject}
          budgetId={props.order.project.purchaser.budget.id}
          isCreatedFromOrder
        />
      )}
    </>
  );
};
