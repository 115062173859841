import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateExcelConfigPositionDto,
  GetExcelConfigDto,
  GetExcelConfigPositionDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/ExcelConfigPositionContext';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PositionSearch } from './PositionSearch';
import { PositionEditor } from './PositionEditor';

interface PositionsViewProps {
  config: GetExcelConfigDto;
  refresh(): void;
}
export const PositionsView: React.FC<PositionsViewProps> = props => {
  const pok = usePokCore();
  const [position, setPosition] = useState<GetExcelConfigPositionDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { t, tk } = useTranslation('excelImportLng');

  const handleAdd = () => {
    setShow(true);
    setPosition(undefined);
  };

  const addEditPosition = async (
    positionCU: CreateUpdateExcelConfigPositionDto,
    id?: string,
  ) => {
    const status = await validate(positionCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.excelConfigPositions.update(id, positionCU);
        } else {
          await pok.excelConfigPositions.create(positionCU);
        }
        setShow(false);
        setRefresh(!refresh);
        props.refresh();
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setPosition(undefined);
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const getPosition = await pok.excelConfigPositions.getById(id);
    setPosition(getPosition);
    setShow(true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Button variant="outline-primary" onClick={handleAdd}>
          {t(tk.excel.mappingNew)}
        </Button>
        <PositionSearch
          config={props.config}
          onRowClick={handleClick}
          refresh={refresh}
        />
        {show && (
          <PositionEditor
            id={position?.id}
            position={convert(position, props.config.id)}
            profile={props.config.profile}
            teamIds={props.config.excelConfigTeams?.map(t => t.team.id) ?? []}
            onCompleted={addEditPosition}
            show={show}
            handleClose={handleClose}
          />
        )}
      </Card.Body>
    </Card>
  );
};
