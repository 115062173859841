import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  CreateUpdatePositionDto,
  GetClientDto,
  GetPositionExtendedDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  newPosition,
  validate,
} from '../../../common/pokCore/contexts/PositionContext';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PositionSearch } from './PositionSearch';
import { PositionEditor } from './PositionEditor';

interface PositionTabProps {
  client?: GetClientDto;
}

export const PositionTab: React.FC<PositionTabProps> = props => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState<GetPositionExtendedDto>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const pok = usePokCore();
  const notification = useNotifications();
  const { t, tk } = useTranslation('clientLng');

  const handleAdd = () => {
    setShow(true);
    setPosition(undefined);
  };

  const addEditPosition = async (
    positionCU: CreateUpdatePositionDto,
    id?: string,
  ) => {
    const status = await validate(positionCU);
    const clientCompanyId = await pok.companies.getCompanyIdByClientId(
      positionCU.clientId,
    );
    if (status.valid && (!clientCompanyId || positionCU.teamId)) {
      try {
        if (id) {
          await pok.positions.update(id, positionCU);
        } else {
          await pok.positions.create(positionCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      const errors = [...status.errors];
      if (clientCompanyId && !positionCU.teamId) {
        errors.push(t(tk.position.chooseSpecialTeam));
      }
      return {
        saved: false,
        errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setPosition(undefined);
  };

  const handleClick = async (id: string) => {
    pok.positions
      .getById(id)
      .then(getPosition => {
        setPosition(getPosition);
        setShow(true);
      })
      .catch(err => notification.caughtError(err));
  };

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.positions.getAllByPortionForClient(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          props.client?.id ? props.client?.id : undefined,
        )) as GridGetterFunction,
    [pok.positions, props.client?.id],
  );

  const getCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.positions.getAllCountForClient(
          filterText,
          filterColumns,
          props.client?.id ? props.client?.id : undefined,
        )) as GridCountFunction,
    [pok.positions, props.client?.id],
  );

  return (
    <>
      <Button variant="outline-primary" onClick={handleAdd}>
        {t(tk.position.positionNew)}
      </Button>
      <PositionSearch
        getData={getData}
        getCount={getCount}
        onRowClick={handleClick}
        hasClient={!!props.client?.id}
        refresh={refresh}
      />
      {show && (
        <PositionEditor
          id={position?.id}
          systemNumber={position?.systemNumber}
          position={
            position ? convert(position) : newPosition(props.client?.id)
          }
          client={props.client || position?.client}
          team={position?.team}
          teams={position?.teams}
          profiles={position?.profiles}
          onCompleted={addEditPosition}
          show={show}
          handleClose={handleClose}
          positionsProvider={pok.positions.getGroupNameOptionsByText}
        />
      )}
    </>
  );
};
