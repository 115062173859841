/*eslint max-lines-per-function: ["error", 176]*/
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdateInvoiceEstimateItemDto,
  CreateUpdateInvoiceEstimateItemDtoFromJSON,
  CreateUpdateInvoiceEstimateItemsDto,
  CreateUpdateSalesInvoiceDto,
  CreateUpdateSalesInvoiceDtoFromJSON,
  CreateUpdateSalesInvoicePositionDto,
  CreateUpdateSalesInvoicePositionDtoFromJSON,
  CreateUpdateSalesInvoicePositionsDto,
  DefaultApi,
  GetDictionaryDto,
  GetEstimateItemDto,
  GetExtendedSalesInvoiceDto,
  GetInvoiceEstimateItemDto,
  GetLastEditorDto,
  GetNumberingDto,
  GetProjectDto,
  GetSalesInvoiceDto,
  GetSalesInvoiceDtoTypeEnum,
  GetSalesInvoiceForItem,
  GetSalesInvoicePositionDto,
  GetSalesInvoiceWithAmountDto,
} from '../autogenerated/pokApiClient';
import { salesInvoiceForGrid } from '../../../pok/components/SalesInvoices/SalesInvoiceSearch';
import {
  InvoiceEstimateItemSchema,
  InvoiceStatusEnum,
  InvoiceTypeEnum,
  PaymentFormEnum,
  SalesInvoicePositionSchema,
  SalesInvoiceSchema,
  VatEnum,
} from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import mathUtils from '../../../utils/mathUtils';
import {
  calculateEstimateItemCommission,
  calculateEstimateItemSalesNet,
} from '../../../pok/components/SalesInvoices/calculateSalesInvoiceAmountsUtils';
import { FilterColumn } from '../../types/FilterColumn';
import {
  getMainCurrencySymbol,
  isMainCurrencyPLN,
  getMainCurrency,
} from '../../../utils/currencyFormatUtils';

export interface SalesInvoicesContextInterface {
  getById: (id: string) => Promise<GetExtendedSalesInvoiceDto>;
  getByIdForImport: (id: string) => Promise<GetExtendedSalesInvoiceDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    status?: InvoiceStatusEnum,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
    status?: InvoiceStatusEnum,
  ) => Promise<number>;
  getAllByPortionByNegativePositionsDiff: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllForCompanyYearByPortion: (
    companyId: string,
    status: InvoiceStatusEnum[],
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    year?: number,
  ) => Promise<GridRecord[]>;
  getAllCountByNegativePositionsDiff: (filterText?: string) => Promise<number>;
  getAllForCompanyYearCount: (
    companyId: string,
    status: InvoiceStatusEnum[],
    filterText?: string,
    year?: number,
  ) => Promise<number>;
  getAllByPackageXML: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    packageXMLId?: string,
  ) => Promise<GridRecord[]>;
  getCountByPackageXML: (
    filterText: string,
    packageXMLId?: string,
  ) => Promise<number>;
  getAllReadyToSentWithoutCorrectByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    type?: InvoiceTypeEnum,
  ) => Promise<GridRecord[]>;
  getAllReadyToSentWithoutCorrectCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
    type?: InvoiceTypeEnum,
  ) => Promise<number>;
  getAllSentToSymphonyByPortion: (
    sentToSymphonyHandel: boolean,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllSentToSymphonyCount: (
    sentToSymphonyHandel: boolean,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateSalesInvoiceDto) => Promise<void>;
  updateSummary: (
    id: string,
    dto: CreateUpdateSalesInvoiceDto,
  ) => Promise<GetSalesInvoiceDto>;
  create: (dto: CreateUpdateSalesInvoiceDto) => Promise<GetSalesInvoiceDto>;
  createCorrection: (parentId: string) => Promise<GetSalesInvoiceDto>;
  deactivate: (id: string) => Promise<void>;
  createEstimateItemPositions: (
    dto: CreateUpdateInvoiceEstimateItemsDto,
  ) => Promise<GetInvoiceEstimateItemDto[]>;
  createPositions: (
    dto: CreateUpdateSalesInvoicePositionsDto,
  ) => Promise<GetSalesInvoicePositionDto[]>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getByEstimateItem: (
    estimateItemId: string,
  ) => Promise<GetSalesInvoiceForItem[]>;
  getByProject: (projectId: string) => Promise<GetSalesInvoiceWithAmountDto[]>;
  findAvailableInvoiceNumbers: (
    companyId: string,
    isCorrection: boolean,
    type: InvoiceTypeEnum,
    invoiceDate: Date,
  ) => Promise<GetNumberingDto[]>;
}

// eslint-disable-next-line max-lines-per-function
export const SalesInvoiceContext = (
  api: DefaultApi,
): SalesInvoicesContextInterface => {
  return {
    getById: (id: string) => api.salesInvoiceControllerGet(id),
    getByIdForImport: (id: string) =>
      api.salesInvoiceControllerGetByIdForImport(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      status?: InvoiceStatusEnum,
    ) => {
      const data = await api.salesInvoiceControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
        status?.toString(),
      );

      return data.map(salesInvoiceForGrid);
    },
    getAllCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
      status?: InvoiceStatusEnum,
    ) => {
      return api.salesInvoiceControllerGetAllCount(
        filterText || '',
        filterColumns,
        status?.toString(),
      );
    },
    getAllByPortionByNegativePositionsDiff: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data =
        await api.salesInvoiceControllerGetAllByPortionByNegativePositionsDiff(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
        );

      return data.map(salesInvoiceForGrid);
    },
    getAllForCompanyYearByPortion: async (
      companyId: string,
      status: InvoiceStatusEnum[],
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      year?: number,
    ) => {
      const data =
        await api.salesInvoiceControllerGetAllForCompanyYearByPortion(
          companyId,
          status?.map(v => v.toString()) || [''],
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          year,
        );

      return data.map(salesInvoiceForGrid);
    },
    getAllCountByNegativePositionsDiff: (filterText?: string) => {
      return api.salesInvoiceControllerGetAllCountByNegativePositionsDiff(
        filterText || '',
      );
    },
    getAllForCompanyYearCount: (
      companyId: string,
      status: InvoiceStatusEnum[],
      filterText?: string,
      year?: number,
    ) => {
      return api.salesInvoiceControllerGetAllForCompanyYearCount(
        companyId,
        status?.map(v => v.toString()) || [''],
        filterText || '',
        year,
      );
    },
    getAllByPackageXML: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      packageXMLId?: string,
    ) => {
      const data = await api.salesInvoiceControllerGetAllByPackageXMLByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        packageXMLId || '',
      );

      return data.map(salesInvoiceForGrid);
    },
    getCountByPackageXML: (filterText?: string, packageXMLId?: string) => {
      return api.salesInvoiceControllerGetCountByPackageXML(
        filterText || '',
        packageXMLId || '',
      );
    },
    getAllReadyToSentWithoutCorrectByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      type?: InvoiceTypeEnum,
    ) => {
      const data =
        await api.salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
          type,
        );

      return data.map(salesInvoiceForGrid);
    },
    getAllReadyToSentWithoutCorrectCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
      type?: InvoiceTypeEnum,
    ) => {
      return api.salesInvoiceControllerGetAllReadyToSentWithoutCorrectCount(
        filterText || '',
        filterColumns,
        type,
      );
    },
    getAllSentToSymphonyByPortion: async (
      sentToSymphonyHandel: boolean,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data =
        await api.salesInvoiceControllerGetAllSentToSymphonyByPortion(
          sentToSymphonyHandel,
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          filterColumns,
        );

      return data.map(salesInvoiceForGrid);
    },
    getAllSentToSymphonyCount: (
      sentToSymphonyHandel: boolean,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      return api.salesInvoiceControllerGetAllSentToSymphonyCount(
        sentToSymphonyHandel,
        filterText || '',
        filterColumns,
      );
    },
    update: (id: string, dto: CreateUpdateSalesInvoiceDto) =>
      api.salesInvoiceControllerUpdate(id, dto),
    updateSummary: (id: string, dto: CreateUpdateSalesInvoiceDto) =>
      api.salesInvoiceControllerUpdateSummary(id, dto),
    create: (dto: CreateUpdateSalesInvoiceDto) =>
      api.salesInvoiceControllerCreate(dto),
    createCorrection: (parentId: string) =>
      api.salesInvoiceControllerCreateCorrection({ parentId }),
    deactivate: (id: string) => api.salesInvoiceControllerDeactivate(id),
    createEstimateItemPositions: (dto: CreateUpdateInvoiceEstimateItemsDto) =>
      api.invoiceEstimateItemControllerCreateMany(dto),
    createPositions: (dto: CreateUpdateSalesInvoicePositionsDto) =>
      api.salesInvoicePositionControllerCreateMany(dto),
    getLastEditor: (id: string) => api.salesInvoiceControllerGetLastEditor(id),
    getByEstimateItem: (estimateItemId: string) =>
      api.salesInvoiceControllerFindByEstimateItem(estimateItemId),
    getByProject: (projectId: string) =>
      api.salesInvoiceControllerFindByProject(projectId),
    findAvailableInvoiceNumbers: (
      companyId: string,
      isCorrection: boolean,
      type: InvoiceTypeEnum,
      invoiceDate: Date,
    ) =>
      api.salesInvoiceControllerFindAvailableInvoiceNumbers(
        companyId,
        isCorrection,
        type,
        invoiceDate,
      ),
  };
};

const isDomestic = (project: GetProjectDto) => {
  if (isMainCurrencyPLN()) {
    return project?.purchaser?.client.country === 'Polska';
  }

  //TODO: dorobić dla spółek zagranicznych
  return true;
};

export const newSalesInvoice = (
  project: GetProjectDto,
  isInternalClient?: boolean,
  gtuDefaultCodeId?: string,
  documentDefaultIds?: string[],
  payableDaysList?: GetDictionaryDto[],
) => {
  const isClientRelated = project?.purchaser.client.isRelated;
  const domestic = isDomestic(project);
  const payableDays = payableDaysList?.find(({ value }) =>
    isInternalClient ? value === '60' : value === '14',
  );

  return CreateUpdateSalesInvoiceDtoFromJSON({
    active: true,
    projectIds: [project.id],
    type: domestic ? InvoiceTypeEnum.Domestic : InvoiceTypeEnum.Foreign,
    currency: getMainCurrencySymbol(),
    status: InvoiceStatusEnum.Draft,
    paymentForm: PaymentFormEnum.BankTransfer,
    invoiceDate: new Date(),
    saleDate: new Date(),
    payableDaysId: payableDays?.id,
    printProjectNumber: true,
    isPaymentFromReceiptDate: false,
    gtuCodeId: gtuDefaultCodeId,
    documentCodeIds: isClientRelated ? documentDefaultIds : [],
    purchaserId: project?.purchaser?.id,
  });
};

export const newInvoiceEstimateItem = (
  json: CreateUpdateInvoiceEstimateItemDto,
) => CreateUpdateInvoiceEstimateItemDtoFromJSON(json);

export const newInvoicePosition = (json: CreateUpdateSalesInvoicePositionDto) =>
  CreateUpdateSalesInvoicePositionDtoFromJSON(json);

export const parseEstimateItemPositionToInvoicePosition = (
  json: GetInvoiceEstimateItemDto,
  order: number,
  type: GetSalesInvoiceDtoTypeEnum,
  projectId?: string,
): CreateUpdateSalesInvoicePositionDto[] => {
  const salesFinancialAccount =
    json.estimateItem.profileCategory?.salesFinancialAccount || '';
  const commissionFinancialAccount =
    json.estimateItem.profileCategory?.commissionFinancialAccount || '';

  const vat = getVat(type);
  if (salesFinancialAccount !== commissionFinancialAccount) {
    const result = [];
    const isAmount = mathUtils.Big(json.amount).toNumber() > 0;
    const isCommissionAmount =
      mathUtils.Big(json.commissionAmount).toNumber() > 0;

    if (isAmount) {
      result.push(
        newInvoicePosition({
          order,
          name: json.estimateItem.position.name,
          amount: json.amount,
          vat: vat,
          salesInvoiceId: json.salesInvoiceId,
          financialAccount: json.estimateItem.profileCategory?.name,
          profileCategoryId: json.estimateItem.profileCategory.id,
          projectId: projectId || '',
        }),
      );
    }

    if (isCommissionAmount) {
      result.push(
        newInvoicePosition({
          order: isAmount ? order + 1 : order,
          name: `${json.estimateItem.position.name} (Prowizja)`,
          amount: json.commissionAmount,
          vat: vat,
          salesInvoiceId: json.salesInvoiceId,
          financialAccount: `${json.estimateItem.profileCategory?.name} (pr.)`,
          profileCategoryId: json.estimateItem.profileCategory.id,
          projectId: projectId || '',
        }),
      );
    }

    return result;
  }

  return [
    newInvoicePosition({
      order,
      name: json.estimateItem.position.name,
      amount: mathUtils.add(json.amount, json.commissionAmount).toString(),
      vat: vat,
      salesInvoiceId: json.salesInvoiceId,
      financialAccount: json.estimateItem.profileCategory?.name,
      profileCategoryId: json.estimateItem.profileCategory.id,
      projectId: projectId || '',
    }),
  ];
};

export const validateInvoiceEstimateItem = (
  invoiceEstimateItem: CreateUpdateInvoiceEstimateItemDto,
) => validateAgainst(InvoiceEstimateItemSchema, invoiceEstimateItem);

export const validateInvoiceEstimateItems = async (
  salesInvoiceEstimateItems: CreateUpdateInvoiceEstimateItemDto[],
  availableEstimateItems?: GetEstimateItemDto[],
  salesInvoice?: GetSalesInvoiceDto,
): Promise<{ isValid: boolean; errors: string[] }> => {
  const isCorrection = !!salesInvoice?.parent;
  const errors: string[] = [];

  const validationResults = await Promise.all(
    salesInvoiceEstimateItems.map(async invoiceEstimateItem => ({
      ...(await validateInvoiceEstimateItem(invoiceEstimateItem)),
      item: invoiceEstimateItem,
    })),
  );
  const isMoreThanZeroEstimateItems = salesInvoiceEstimateItems.length > 0;
  const isAtLeastOneValueInPositionsFilled =
    isCorrection ||
    salesInvoiceEstimateItems.some(
      item =>
        mathUtils.Big(item.amount).gt(0) ||
        mathUtils.Big(item.commissionAmount).gt(0),
    );

  if (!isMoreThanZeroEstimateItems) {
    errors.push('Brak dostępnych pozycji do zafakturowania.');
  }

  if (!isAtLeastOneValueInPositionsFilled) {
    errors.push(
      'Przynajmniej jedna pozycja musi mieć Kwotę sprzedaży lub Kwotę prowizji większą od 0.',
    );
  }

  const isValid =
    validationResults.every(result => result.valid) &&
    isMoreThanZeroEstimateItems &&
    isAtLeastOneValueInPositionsFilled;

  validationResults.forEach(result => {
    if (!result.valid) {
      const item = availableEstimateItems?.find(
        estimateItem => estimateItem.id === result.item.estimateItemId,
      );
      errors.push(
        ...result.errors.map(
          error => `Pozycja '${item?.position?.name}': ${error}`,
        ),
      );
    }
  });

  return {
    isValid,
    errors,
  };
};

export const formatOriginalSalesInvoiceEstimateItem = (
  estimateItem: GetEstimateItemDto,
  salesInvoice?: GetSalesInvoiceDto,
) => {
  const amount =
    salesInvoice?.invoiceEstimateItems?.find(
      invoiceEstimateItem =>
        invoiceEstimateItem.estimateItem.id === estimateItem.id,
    )?.amount || '0';

  const commissionAmount =
    salesInvoice?.invoiceEstimateItems?.find(
      invoiceEstimateItem =>
        invoiceEstimateItem.estimateItem.id === estimateItem.id,
    )?.commissionAmount || '0';

  return newInvoiceEstimateItem({
    estimateItemId: estimateItem.id,
    amount,
    commissionAmount,
    salesInvoiceId: salesInvoice?.id || '',
  });
};

export const formatSalesInvoiceEstimateItem = (
  estimateItem: GetEstimateItemDto,
  salesInvoice?: GetSalesInvoiceDto,
  originalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[],
) => {
  const shouldFillDefaultValues = !salesInvoice?.invoiceEstimateItems?.length;

  const amount = shouldFillDefaultValues
    ? calculateEstimateItemSalesNet(
        estimateItem,
        originalEstimateItems,
      ).toString()
    : salesInvoice?.invoiceEstimateItems?.find(
        invoiceEstimateItem =>
          invoiceEstimateItem.estimateItem.id === estimateItem.id,
      )?.amount;

  const commissionAmount = shouldFillDefaultValues
    ? calculateEstimateItemCommission(
        estimateItem,
        originalEstimateItems,
      ).toString()
    : salesInvoice?.invoiceEstimateItems?.find(
        invoiceEstimateItem =>
          invoiceEstimateItem.estimateItem.id === estimateItem.id,
      )?.commissionAmount;

  return newInvoiceEstimateItem({
    estimateItemId: estimateItem.id,
    amount: amount || '0',
    commissionAmount: commissionAmount || '0',
    salesInvoiceId: salesInvoice?.id || '',
  });
};

export const validateInvoicePosition = (
  position: CreateUpdateSalesInvoicePositionDto,
) => validateAgainst(SalesInvoicePositionSchema, position);

export const validate = (salesInvoice: CreateUpdateSalesInvoiceDto) =>
  validateAgainst(SalesInvoiceSchema, salesInvoice);

export const convert = (
  salesInvoice?: GetSalesInvoiceDto,
  project?: GetProjectDto,
  isInternalClient?: boolean,
  gtuDefaultCodeId?: string,
  documentDefaultIds?: string[],
  payableDaysList?: GetDictionaryDto[],
) => {
  if (!salesInvoice && project) {
    return newSalesInvoice(
      project,
      isInternalClient,
      gtuDefaultCodeId,
      documentDefaultIds,
      payableDaysList,
    );
  }
  const converted = CreateUpdateSalesInvoiceDtoFromJSON(salesInvoice);
  if (salesInvoice) {
    converted.projectIds =
      salesInvoice?.salesInvoiceProjects?.map(({ project }) => project.id) ||
      [];
    converted.purchaserId = salesInvoice.purchaser.id;
    converted.companyId = salesInvoice.company.id;
    converted.payableDaysId = salesInvoice.payableDays?.id;
  }

  if (converted) {
    converted.parentId = salesInvoice?.parent?.id;
  }
  return converted;
};
export const convertInvoicePosition = (
  position: GetSalesInvoicePositionDto,
) => {
  const converted = CreateUpdateSalesInvoicePositionDtoFromJSON(position);
  converted.profileCategoryId = position.profileCategory?.id;
  converted.salesInvoiceId = position.salesInvoice?.id;

  return converted;
};

const checkIsZeroInvoice = (salesInvoice: GetSalesInvoiceDto) => {
  return (
    !!salesInvoice?.parent &&
    salesInvoice?.invoiceEstimateItems?.every(
      ({ amount, commissionAmount }) =>
        (amount === '0' && commissionAmount === '0') ||
        (!amount && !commissionAmount),
    )
  );
};

export const buildPositionsFromInvoiceEstimateItems = (
  salesInvoice?: GetSalesInvoiceDto,
  parentPositions?: GetSalesInvoicePositionDto[],
  estimateItems?: GetEstimateItemDto[],
) => {
  if (!salesInvoice?.invoiceEstimateItems) {
    return [];
  }

  const isZeroInvoice = checkIsZeroInvoice(salesInvoice);
  if (isZeroInvoice) {
    return (
      parentPositions?.map(position =>
        convertInvoicePosition({
          ...position,
          amount: '0',
        }),
      ) || []
    );
  }

  return salesInvoice.invoiceEstimateItems.reduce(
    (acc: CreateUpdateSalesInvoicePositionDto[], item) => {
      const startOrder = acc.length > 0 ? acc[acc.length - 1].order + 1 : 1;
      const foundProject = estimateItems?.find(
        estimateItem => estimateItem.id === item.estimateItem.id,
      )?.estimateByMonth.project;

      const newPositions = parseEstimateItemPositionToInvoicePosition(
        item,
        startOrder,
        salesInvoice.type,
        foundProject?.id,
      );

      if (newPositions.length > 1) {
        newPositions.forEach((np, npIndex) => {
          np.order = startOrder + npIndex;
        });
      }

      return acc.concat(newPositions);
    },
    [],
  );
};

const getVat = (type: string) => {
  const curr = getMainCurrency();
  if (curr.countryCode === 'pl-PL') {
    return type === GetSalesInvoiceDtoTypeEnum.Foreign
      ? VatEnum['0%']
      : VatEnum['23%'];
  }
  if (curr.countryCode === 'de-DE') {
    return VatEnum['19%'];
  }
  if (curr.countryCode === 'en-US') {
    return VatEnum['np.'];
  }
  return VatEnum['23%'];
};
