import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { CreateUpdateExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ExcelConfigForm } from './ExcelConfigForm';

interface ExcelConfigModalEditorProps {
  id?: string;
  config: CreateUpdateExcelConfigDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    config: CreateUpdateExcelConfigDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ExcelConfigModalEditor: React.FC<
  ExcelConfigModalEditorProps
> = props => {
  const [config, setConfig] = useState(props.config);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('excelImportLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setConfig(props.config);
    }
  }, [props.config, props.show]);

  const createConfig = async () => {
    setIsLoading(true);
    props
      .onCompleted(config, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateExcelConfigDto>) => {
    if (obj.companyIndependent !== undefined) {
      const companyId = obj.companyIndependent ? undefined : pok.getCompanyId();
      setConfig({ ...config, ...obj, companyId });
      return;
    }
    if (obj.profileId !== undefined) {
      setConfig({ ...config, ...obj, profileCategoryId: undefined });
      return;
    }
    setConfig({ ...config, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? t(tk.excel.importConfigEdit)
              : t(tk.excel.importConfigAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExcelConfigForm
            id={props.id}
            config={config}
            propertyChange={propertyChange}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.projectExcelConfigs.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={createConfig}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
