import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';
import DatePicker from 'react-date-picker';

import { Option } from '../Selects/Selector';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { CreateUpdateBlockadeMonthDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import CompanySelector from '../Selects/CompanySelector';
import TeamSelector from '../Selects/TeamSelector';

interface BlockadeMonthEditorProps {
  id?: string;
  blockadeMonth: CreateUpdateBlockadeMonthDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    blockadeMonth: CreateUpdateBlockadeMonthDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const BlockadeMonthEditor: React.FC<
  BlockadeMonthEditorProps
> = props => {
  const [blockadeMonth, setBlockadeMonth] = useState(props.blockadeMonth);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setBlockadeMonth(props.blockadeMonth);
    }
  }, [props.blockadeMonth, props.show]);

  const createBlockadeMonth = async () => {
    setIsLoading(true);
    props
      .onCompleted(blockadeMonth, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateBlockadeMonthDto>) => {
    setBlockadeMonth({ ...blockadeMonth, ...obj });
  };

  const onChangeTeams = (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && Array.isArray(option)) {
      propertyChange({
        teamIds: (option as Option[]).map(({ value }) => value),
      });
    }
  };

  const setDate = (date: Date | undefined) => {
    if (date) {
      //ten kalendarz inaczej ustawia godzinę niż nasz główny kalendarz
      //dlatego trzeba skorygować godzinę
      date.setMinutes(-date.getTimezoneOffset());
      return date;
    }
    return undefined;
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? 'Edycja blokady miesięcznej'
              : 'Dodawanie nowej blokady miesięcznej'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-3">
            <FormRow controlId="company" label="Spółka">
              <CompanySelector value={pok.getCompanyId()} readOnly />
            </FormRow>
            <FormRow controlId="team" label="Zespoły">
              <TeamSelector
                isMulti={true}
                value={blockadeMonth.teamIds}
                companyId={blockadeMonth.companyId}
                onChange={options => onChangeTeams(options)}
                clearable
              />
            </FormRow>
            <FormRow controlId="isSalesBlockade" label="Miesiąc">
              <DatePicker
                onChange={value =>
                  propertyChange({ date: setDate(value as Date) })
                }
                defaultView="year"
                minDetail="year"
                maxDetail="year"
                format="MM.yyyy"
                value={blockadeMonth.date}
                className="small-calendar"
              />
            </FormRow>
            <FormRow controlId="isSalesBlockade" label="Blokada sprzedaży">
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={blockadeMonth?.isSalesBlocked ?? true}
                onChange={e =>
                  propertyChange({ isSalesBlocked: e.target.checked })
                }
              />
            </FormRow>
            <FormRow controlId="isPurchaseBlockade" label="Blokada zakupu">
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={blockadeMonth?.isPurchaseBlocked ?? true}
                onChange={e =>
                  propertyChange({ isPurchaseBlocked: e.target.checked })
                }
              />
            </FormRow>
            <FormRow controlId="active" label="Aktywny">
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={blockadeMonth?.active ?? true}
                onChange={e => propertyChange({ active: e.target.checked })}
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.blockades.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            Zamknij
          </Button>
          <Button
            variant="primary"
            onClick={createBlockadeMonth}
            isLoading={isLoading}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
