import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import WorkDaysCalendar from '../../../app/components/Calendar/WorkDaysCalendar';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import momencik from '../../../common/momencik';
import YesNoModal from '../../../app/components/YesNoModal';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const CalendarView: React.FC = () => {
  const pok = usePokCore();
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [addDay, setAddDay] = useState<Date | null>();
  const [removeDay, setRemoveDay] = useState<Date | null>();
  const [showRemove, setShowRemove] = useState(false);
  const notifications = useNotifications();
  const [waiting, setWaiting] = useState<boolean>(false);
  const { t, tk } = useTranslation('administrationLng');

  useEffect(() => {
    pok.calendars
      .getAllForYear(year)
      .then(days => {
        setSelectedDays(days);
        setAddDay(null);
        setShowAdd(false);
        setRemoveDay(null);
        setShowRemove(false);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [pok.calendars, notifications, year, refresh]);

  const onAddDay = (date: Date) => {
    setAddDay(date);
    setShowAdd(true);
  };

  const onRemoveDay = (date: Date) => {
    setRemoveDay(date);
    setShowRemove(true);
  };

  const onAddDayAfterAsk = () => {
    pok.calendars
      .addDay(momencik(addDay, 'YYYY-MM-DD'))
      .then(() => setRefresh(!refresh))
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const onRemoveDayAfterAsk = () => {
    pok.calendars
      .removeDay(momencik(removeDay, 'YYYY-MM-DD'))
      .then(() => setRefresh(!refresh))
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const createNewYear = async (newYear: number) => {
    setWaiting(true);
    pok.calendars
      .createYear(newYear.toString())
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <>
      <Card className="main-card">
        <Card.Body className="m-3">
          <Card.Title className="pb-3">
            {t(tk.administration.calendarWorkingDay)}
          </Card.Title>

          {waiting && <Waiting />}
          {!waiting && (
            <div className="d-grid gap-2 mb-3">
              <WorkDaysCalendar
                initialSelectedDays={selectedDays}
                year={year}
                setYear={setYear}
                addDay={onAddDay}
                removeDay={onRemoveDay}
              />
              <YesNoModal
                header={t(tk.administration.addCalendar)}
                show={showAdd}
                onHide={() => setShowAdd(false)}
                onOk={onAddDayAfterAsk}
              >
                {t(tk.administration.askAdd)}{' '}
                {addDay ? momencik(addDay, 'YYYY-MM-DD') : ''}{' '}
                {t(tk.administration.to)}
              </YesNoModal>
              <YesNoModal
                header={t(tk.administration.deleteCalendar)}
                show={showRemove}
                onHide={() => setShowRemove(false)}
                onOk={onRemoveDayAfterAsk}
              >
                {t(tk.administration.askDelete)}{' '}
                {removeDay ? momencik(removeDay, 'YYYY-MM-DD') : ''}{' '}
                {t(tk.administration.from)}
              </YesNoModal>
            </div>
          )}

          <ConfirmationButton
            className="me-2"
            modalSize="lg"
            onOK={() => createNewYear(new Date().getFullYear())}
            confirmation={`${t(tk.administration.askAddYear)} ${new Date().getFullYear()} ${t(tk.administration.inSystem)} 
            ${t(tk.administration.warning)}`}
            variant="outline-danger"
          >
            {t(tk.administration.createYear)} {new Date().getFullYear()}
          </ConfirmationButton>
          <ConfirmationButton
            onOK={() => createNewYear(new Date().getFullYear() + 1)}
            confirmation={`${t(tk.administration.askDeleteYear)} ${new Date().getFullYear() + 1} ${t(tk.administration.inSystem)} 
            ${t(tk.administration.warning)}`}
            variant="outline-danger"
            modalSize="lg"
          >
            {t(tk.administration.createYear)} {new Date().getFullYear() + 1}
          </ConfirmationButton>
        </Card.Body>
      </Card>
    </>
  );
};
