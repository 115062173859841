import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import FormRow from '../../../app/components/FormRow';
import { ApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';
import { Option } from '../Selects/Selector';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { ForeignClientFormFields } from '../Clients/ForeignClientFormFields';
import { PolishClientFormFields } from '../Clients/PolishClientFormFields';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ApplicationEditorBasicFormFields } from './ApplicationEditorBasicFormFields';

interface ApplicationEditorFormFieldsProps {
  id?: string;
  application: UpdateAdminApplicationDto;
  createdDate?: Date | null;
  handleClose?: () => void;
  show?: boolean;
  isReadOnly?: boolean;
  findByNip?: (text: string) => void;
  propertyChange?: (
    obj: Partial<CreateUpdateClientDto | CreateUpdateApplicationDto>,
  ) => void;
}

export const ApplicationEditorFormFields: React.FC<
  ApplicationEditorFormFieldsProps
> = props => {
  const [showClientData, setShowClientData] = useState(false);
  const [countrySet, setCountrySet] = useState(false);

  const pok = usePokCore();
  const { t, tk } = useTranslation('applicationLng');

  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  const setCountry = useCallback(
    (country: string) => {
      setCountrySet(true);
      props.propertyChange && props.propertyChange({ country: country });
    },
    [setCountrySet, props],
  );

  useEffect(() => {
    if (!props.application.country && !countrySet) {
      setCountry('Polska');
    }
  }, [props.application.country, setCountry, countrySet]);

  useEffect(() => {
    const show =
      props.application.applicationType === ApplicationTypeEnum.Permission ||
      props.application.applicationType === ApplicationTypeEnum.Position ||
      props.application.applicationType === ApplicationTypeEnum.Purchaser;
    setShowClientData(show);
  }, [props.application.applicationType]);

  return (
    <>
      <ApplicationEditorBasicFormFields
        application={props.application}
        propertyChange={propertyChange}
        show={props.show}
        isReadOnly={props.isReadOnly}
      />
      {showClientData && (
        <Card className="card flex-grow-1">
          <Card.Header>{t(tk.app.clientData)}</Card.Header>
          <Card.Body className="m-3">
            <div className="d-grid gap-4 mb-3">
              <>
                <FormRow controlId="country" label={t(tk.common.country)}>
                  <CreatableClearableSelector
                    value={props.application?.country}
                    onChange={option =>
                      propertyChange({
                        country: option ? (option as Option).label : option,
                      })
                    }
                    provider={pok.clients.getCountryOptionsByText}
                    readOnly={props.isReadOnly}
                  />
                </FormRow>
                {showClientData &&
                  (props.application.country ?? 'Polska') === 'Polska' && (
                    <PolishClientFormFields
                      client={props.application}
                      propertyChange={propertyChange}
                      findByNip={props.findByNip}
                      readonly={props.isReadOnly}
                    />
                  )}
                {showClientData &&
                  (props.application.country ?? 'Polska') !== 'Polska' && (
                    <ForeignClientFormFields
                      client={props.application}
                      propertyChange={propertyChange}
                      readonly={props.isReadOnly}
                    />
                  )}
              </>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
