import React from 'react';

import { ApplicationTypeEnum } from '../../../common/pokCore/validation/schemas';
import FormRow from '../../../app/components/FormRow';
import PurchaserSelector from '../Selects/PurchaserSelector';
import PositionSelector from '../Selects/PositionSelector';
import { UpdateAdminApplicationDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import BrandSelector from '../Selects/BrandSelector';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ApplicationRecordSelectorProps {
  application: UpdateAdminApplicationDto;
  purchaserName?: string;
  positionName?: string;
  brandName?: string;
  propertyChange: (obj: Partial<UpdateAdminApplicationDto>) => void;
  readOnly?: boolean;
}

export const ApplicationRecordSelector: React.FC<
  ApplicationRecordSelectorProps
> = props => {
  const {
    application,
    purchaserName,
    positionName,
    brandName,
    propertyChange,
    readOnly,
  } = props;

  const { t, tk } = useTranslation('applicationLng');

  return (
    <>
      {application.applicationType === ApplicationTypeEnum.Purchaser && (
        <FormRow controlId="purchaserId" label={t(tk.common.purchaser)}>
          {!readOnly ? (
            <PurchaserSelector
              readOnly={readOnly}
              teamIds={[application.teamId]}
              value={application.purchaserId}
              onChange={option =>
                propertyChange({
                  purchaserId: option?.value,
                })
              }
              role={PermissionsEnum.Clients}
            />
          ) : (
            <DynamicTextarea value={purchaserName || ''} disabled={true} />
          )}
        </FormRow>
      )}
      {application.applicationType === ApplicationTypeEnum.Position && (
        <FormRow controlId="positionId" label={t(tk.common.profilePosition)}>
          {!readOnly ? (
            <PositionSelector
              readOnly={readOnly}
              teamIds={[application.teamId]}
              profileId={application.profileId}
              value={application.positionId}
              onChange={option =>
                propertyChange({
                  positionId: option?.value,
                })
              }
            />
          ) : (
            <DynamicTextarea value={positionName || ''} disabled={true} />
          )}
        </FormRow>
      )}
      {application.applicationType === ApplicationTypeEnum.Brand && (
        <FormRow controlId="brandId" label={t(tk.common.brand)}>
          {!readOnly ? (
            <BrandSelector
              readOnly={readOnly}
              purchaserId={props.application.brandApplicationPurchaserId}
              value={application.brandId}
              onChange={option =>
                propertyChange({
                  brandId: option?.value,
                })
              }
            />
          ) : (
            <DynamicTextarea value={brandName || ''} disabled={true} />
          )}
        </FormRow>
      )}
    </>
  );
};
