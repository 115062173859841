import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { ErrorResponse } from '@remix-run/router';

import { useAuth } from '../hooks/useAuth';
import { useNotifications } from '../hooks/useNotifications';
import { AuthProviderState } from '../auth';

import { Configuration, RequestOpts } from './autogenerated/pokApiClient';
import { DefaultApi } from './autogenerated/pokApiClient';
import {
  DocumentationContext,
  DocumentationContextInterface,
} from './contexts/DocumentationContext';
import {
  ClientContext,
  ClientContextInterface,
} from './contexts/ClientContext';
import {
  CompanyContext,
  CompanyContextInterface,
} from './contexts/CompanyContext';
import {
  DictionaryContext,
  DictionaryContextInterface,
} from './contexts/DictionaryContext';
import {
  DictionaryTypeContext,
  DictionaryTypeContextInterface,
} from './contexts/DictionaryTypeContext';
import {
  EmployeeContext,
  EmployeeContextInterface,
} from './contexts/EmployeeContext';
import { TeamContext, TeamContextInterface } from './contexts/TeamContext';
import { LoginContext, LoginContextInterface } from './contexts/LoginContext';
import { RoleContext, RoleContextInterface } from './contexts/RoleContext';
import {
  EmployeeRoleContext,
  EmployeeRoleContextInterface,
} from './contexts/EmployeeRoleContext';
import {
  BudgetContext,
  BudgetContextInterface,
} from './contexts/BudgetContext';
import { BrandContext, BrandContextInterface } from './contexts/BrandContext';
import {
  PurchaserContext,
  PurchaserContextInterface,
} from './contexts/PurchaserContext';
import {
  AttachmentContext,
  AttachmentContextInterface,
} from './contexts/AttachmentContext';
import {
  PositionContext,
  PositionContextInterface,
} from './contexts/PositionContext';
import {
  ProjectContext,
  ProjectContextInterface,
} from './contexts/ProjectContext';
import {
  SymphonyClientContext,
  SymphonyClientContextInterface,
} from './contexts/SymphonyClientContext';
import {
  ProfileContext,
  ProfileContextInterface,
} from './contexts/ProfileContext';
import {
  ProfileCategoryContext,
  ProfileCategoryContextInterface,
} from './contexts/ProfileCategoryContext';
import {
  ApplicationContext,
  ApplicationContextInterface,
} from './contexts/ApplicationContext';
import {
  PurchaseInvoiceContext,
  PurchaseInvoiceContextInterface,
} from './contexts/PurchaseInvoiceContext';
import {
  SalesInvoiceContext,
  SalesInvoicesContextInterface,
} from './contexts/SalesInvoicesContext';
import {
  ProjectApplicationContext,
  ProjectApplicationContextInterface,
} from './contexts/ProjectApplicationContext';
import {
  EstimateByMonthContext,
  EstimateByMonthContextInterface,
} from './contexts/EstimateByMonthContext';
import {
  EstimateItemContext,
  EstimateItemContextInterface,
} from './contexts/EstimateItemContext';
import {
  BankAccountContext,
  BankAccountContextInterface,
} from './contexts/BankAccountContext';
import {
  ExchangeApiContext,
  ExchangeApiContextInterface,
} from './contexts/ExchangeApiContext';
import { OrderContext, OrderContextInterface } from './contexts/OrderContext';
import {
  OrderExecutionContext,
  OrderExecutionContextInterface,
} from './contexts/OrderExecutionContext';
import { EmailContext, EmailContextInterface } from './contexts/EmailContext';
import {
  PdfPrinterContext,
  PdfPrinterContextInterface,
} from './contexts/PdfPrinterContext';
import {
  PackageInvoicesXMLContext,
  PackageInvoicesXMLContextInterface,
} from './contexts/PackageInvoicesXMLContext';
import {
  ReportContext,
  ReportContextInterface,
} from './contexts/ReportContext';
import {
  ApplicationUnblockContext,
  ApplicationUnblockContextInterface,
} from './contexts/ApplicationUnblockContext';
import {
  SymphonyContext,
  SymphonyContextInterface,
} from './contexts/SymphonyContext';
import {
  SavedPdfContext,
  SavedPdfContextInterface,
} from './contexts/SavedPdfContext';
import {
  ProjectExcelImportContext,
  ProjectExcelImportContextInterface,
} from './contexts/ProjectExcelImportContext';
import {
  ProjectExcelConfigContext,
  ProjectExcelConfigContextInterface,
} from './contexts/ProjectExcelConfigContext';
import { MemoContext, MemoContextInterface } from './contexts/MemoContext';
import {
  SpecialInvoicingContext,
  SpecialInvoicingContextInterface,
} from './contexts/SpecialInvoicingContext';
import {
  ExcelConfigHeaderContext,
  ExcelConfigHeaderContextInterface,
} from './contexts/ExcelConfigHeaderContext';
import {
  EmailConfigContext,
  EmailConfigContextInterface,
} from './contexts/EmailConfigContext';
import {
  ExcelConfigPositionContext,
  ExcelConfigPositionContextInterface,
} from './contexts/ExcelConfigPositionContext';
import {
  BlockadeMonthContext,
  BlockadeMonthContextInterface,
} from './contexts/BlockadeMonthContext';
import {
  JobConfigContext,
  JobConfigContextInterface,
} from './contexts/JobConfigContext';
import {
  CalendarContext,
  CalendarContextInterface,
} from './contexts/CalendarContext';
import {
  CurrencyContext,
  CurrencyContextInterface,
} from './contexts/CurrencyContext';

export interface PokCoreContextType {
  attachments: AttachmentContextInterface;
  applications: ApplicationContextInterface;
  docs: DocumentationContextInterface;
  clients: ClientContextInterface;
  companies: CompanyContextInterface;
  dictionaries: DictionaryContextInterface;
  dictionaryTypes: DictionaryTypeContextInterface;
  employees: EmployeeContextInterface;
  employeesRoles: EmployeeRoleContextInterface;
  exchangeApi: ExchangeApiContextInterface;
  teams: TeamContextInterface;
  budgets: BudgetContextInterface;
  brands: BrandContextInterface;
  logins: LoginContextInterface;
  purchasers: PurchaserContextInterface;
  profiles: ProfileContextInterface;
  profileCategories: ProfileCategoryContextInterface;
  positions: PositionContextInterface;
  symphonyClients: SymphonyClientContextInterface;
  orders: OrderContextInterface;
  ordersExecution: OrderExecutionContextInterface;
  projects: ProjectContextInterface;
  projectsApplication: ProjectApplicationContextInterface;
  roles: RoleContextInterface;
  purchaseInvoices: PurchaseInvoiceContextInterface;
  salesInvoices: SalesInvoicesContextInterface;
  estimatesByMonth: EstimateByMonthContextInterface;
  estimateItems: EstimateItemContextInterface;
  bankAccounts: BankAccountContextInterface;
  emails: EmailContextInterface;
  pdfPrinters: PdfPrinterContextInterface;
  packagesXML: PackageInvoicesXMLContextInterface;
  reports: ReportContextInterface;
  symphonies: SymphonyContextInterface;
  savedPdfs: SavedPdfContextInterface;
  projectExcelImports: ProjectExcelImportContextInterface;
  projectExcelConfigs: ProjectExcelConfigContextInterface;
  memos: MemoContextInterface;
  excelConfigHeaders: ExcelConfigHeaderContextInterface;
  excelConfigPositions: ExcelConfigPositionContextInterface;
  blockades: BlockadeMonthContextInterface;
  getCompanyId: () => string;
  setCompanyId: (unitId: string) => void;
  getLanguage: () => string;
  setLanguage: (lang: string) => void;
  registerConnectionHandler: (ensureConnected: () => Promise<void>) => void;
  applicationsUnblock: ApplicationUnblockContextInterface;
  specialInvoicing: SpecialInvoicingContextInterface;
  emailConfig: EmailConfigContextInterface;
  calendars: CalendarContextInterface;
  jobConfig: JobConfigContextInterface;
  currencies: CurrencyContextInterface;
}

const getContext: (
  api: DefaultApi,
  getCompanyId: () => string,
  companyId: string | undefined,
  companySetter: (unitId: string) => void,
  getLanguage: () => string,
  language: string | undefined,
  languageSetter: (lang: string) => void,
  registerConnectionHandler: (ensureConnected: () => Promise<void>) => void,
) => PokCoreContextType = (
  api,
  getCompanyId,
  companyId,
  companySetter,
  getLanguage,
  language,
  languageSetter,
  registerConnectionHandler,
) => {
  return {
    attachments: AttachmentContext(api),
    applications: ApplicationContext(api),
    docs: DocumentationContext(api),
    clients: ClientContext(api),
    companies: CompanyContext(api),
    dictionaries: DictionaryContext(api),
    dictionaryTypes: DictionaryTypeContext(api),
    employees: EmployeeContext(api),
    employeesRoles: EmployeeRoleContext(api),
    exchangeApi: ExchangeApiContext(api),
    teams: TeamContext(api),
    budgets: BudgetContext(api),
    brands: BrandContext(api),
    logins: LoginContext(api),
    orders: OrderContext(api),
    ordersExecution: OrderExecutionContext(api),
    purchasers: PurchaserContext(api),
    profiles: ProfileContext(api),
    profileCategories: ProfileCategoryContext(api),
    positions: PositionContext(api),
    projects: ProjectContext(api),
    projectsApplication: ProjectApplicationContext(api),
    symphonyClients: SymphonyClientContext(api),
    roles: RoleContext(api),
    purchaseInvoices: PurchaseInvoiceContext(api),
    salesInvoices: SalesInvoiceContext(api),
    estimatesByMonth: EstimateByMonthContext(api),
    estimateItems: EstimateItemContext(api),
    bankAccounts: BankAccountContext(api),
    emails: EmailContext(api),
    pdfPrinters: PdfPrinterContext(api),
    packagesXML: PackageInvoicesXMLContext(api),
    reports: ReportContext(api),
    symphonies: SymphonyContext(api),
    savedPdfs: SavedPdfContext(api),
    projectExcelImports: ProjectExcelImportContext(api),
    projectExcelConfigs: ProjectExcelConfigContext(api),
    memos: MemoContext(api),
    excelConfigHeaders: ExcelConfigHeaderContext(api),
    excelConfigPositions: ExcelConfigPositionContext(api),
    getCompanyId: getCompanyId,
    companyId,
    setCompanyId: companySetter,
    getLanguage: getLanguage,
    language,
    setLanguage: languageSetter,
    registerConnectionHandler,
    applicationsUnblock: ApplicationUnblockContext(api),
    specialInvoicing: SpecialInvoicingContext(api),
    emailConfig: EmailConfigContext(api),
    jobConfig: JobConfigContext(api),
    blockades: BlockadeMonthContext(api),
    calendars: CalendarContext(api),
    currencies: CurrencyContext(api),
  };
};

const notReadyApi = new DefaultApi();
const notReadyHandler = () => {
  // to be set later
};
const notReadyHandlerString = () => {
  // to be set later
  return '';
};

export const PokCoreContext = React.createContext(
  getContext(
    notReadyApi,
    notReadyHandlerString,
    undefined,
    notReadyHandler,
    notReadyHandlerString,
    undefined,
    notReadyHandler,
    notReadyHandler,
  ),
);

class APIWithHandler extends DefaultApi {
  constructor(
    configuration: Configuration,
    private responseExceptionHandler: (
      response: ErrorResponse,
    ) => ErrorResponse,
    private ensureConnected: () => Promise<void>,
    private auth: AuthProviderState,
  ) {
    super(configuration);
  }

  protected async request(context: RequestOpts): Promise<Response> {
    await this.ensureConnected(); //TODO czy potrzebne?
    let response = new Response();

    if (!this.auth) {
      throw new Error('No auth');
    }

    try {
      const token = localStorage.getItem('pok-jwt-token');
      context.headers['Authorization'] = 'Bearer ' + token;
      context.headers['pok-company'] =
        JSON.parse(sessionStorage.getItem('pok-company') || '{}')?.id || '';
      context.headers['pok-language'] =
        JSON.parse(sessionStorage.getItem('pok-language') || '{}') || '';
      response = await super.request(context);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (errorResponse: any) {
      if (errorResponse.response.status !== 401) {
        throw errorResponse;
      }

      if (context.path === '/login/refreshTokens') {
        this.auth.logout();
        throw errorResponse;
      }

      const firstRefreshToken = localStorage.getItem('pok-jwt-refresh-token');
      await this.auth.mutex?.runExclusive(async () => {
        const refreshToken = localStorage.getItem('pok-jwt-refresh-token');
        if (!refreshToken) {
          throw errorResponse;
        }
        try {
          if (refreshToken === firstRefreshToken) {
            const tokens = await this.loginControllerRefreshToken(refreshToken);
            localStorage.setItem('pok-jwt-refresh-token', tokens.refreshToken);
            localStorage.setItem('pok-jwt-token', tokens.accessToken);
          }
          const newToken = localStorage.getItem('pok-jwt-token');

          if (!newToken) {
            throw errorResponse;
          }
          context.headers['Authorization'] = 'Bearer ' + newToken;
          response = await super.request(context);
          return response;
        } catch (refreshError) {
          this.auth.logout();
          throw errorResponse;
        }
      });
    }
    return response;
  }
}

export const PokCoreProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  const notifications = useNotifications();
  const [companyId, setCompanyId] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [context, setDefaultContext] = useState(
    getContext(
      new APIWithHandler(
        new Configuration({
          basePath: process.env.REACT_APP_POKAPI_URL,
        }),
        (response: ErrorResponse) => {
          if (response.status === 403) {
            notifications.unauthorized();
          }
          throw response;
        },
        async () => {
          if (waitingRoomRef.current) {
            await waitingRoomRef.current();
          }
        },
        auth,
      ),
      () => JSON.parse(sessionStorage.getItem('pok-company') || '{}')?.id || '',
      companyId,
      notReadyHandler,
      () => JSON.parse(sessionStorage.getItem('pok-language') || '{}') || '',
      language,
      notReadyHandler,
      notReadyHandler,
    ),
  );
  const waitingRoomRef = useRef<() => Promise<void>>();

  useEffect(() => {
    const configuration = new Configuration({
      basePath: process.env.REACT_APP_POKAPI_URL,
    });

    const handler = (response: ErrorResponse) => {
      if (response.status === 403) {
        notifications.unauthorized();
      }
      throw response;
    };

    setDefaultContext(
      getContext(
        new APIWithHandler(
          configuration,
          handler,
          async () => {
            if (waitingRoomRef.current) {
              await waitingRoomRef.current();
            }
          },
          auth,
        ),
        () =>
          JSON.parse(sessionStorage.getItem('pok-company') || '{}')?.id || '',
        companyId,
        setCompanyId,
        () => JSON.parse(sessionStorage.getItem('pok-language') || '{}') || '',
        language,
        setLanguage,
        ensureConnected => (waitingRoomRef.current = ensureConnected),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth,
    notifications,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sessionStorage.getItem('pok-company'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sessionStorage.getItem('pok-language'),
  ]);

  return (
    <PokCoreContext.Provider value={context}>
      {/* {auth.mutex ? children : <Waiting />} */}
      {children}
    </PokCoreContext.Provider>
  );
};
