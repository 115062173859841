import React, { useEffect, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  convert,
  newApplication,
  validate,
} from '../../../common/pokCore/contexts/ApplicationContext';
import {
  GetApplicationDto,
  GetApplicationDtoApplicationStatusEnum,
  ResponseError,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { ApplicationEditor } from '../../components/Applications/ApplicationEditor';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { UserApplicationTab } from '../../components/Applications/UserApplicationTab';
import { CompanyApplicationTab } from '../../components/Applications/CompanyApplicationTab';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

enum ApplicationsTabs {
  CURRENT_USER = 'currentUser',
  COMPANY = 'company',
  ANNOUNCE = 'announce',
}

export const ApplicationsView: React.FC = () => {
  const [application, setApplication] = useState<GetApplicationDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('applicationLng');

  const addEditApplication = async (
    applicationCU: UpdateAdminApplicationDto,
    id?: string,
  ) => {
    const status = await validate(applicationCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.applications.update(id, applicationCU);
        } else {
          const app = await pok.applications.create(applicationCU);
          id = app.id;
        }
        void sendEmail(id);
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const sendEmail = async (id: string) => {
    pok.emails.sendApplicationEmail(id).catch(err => {
      notifications.caughtError(err);
    });
  };

  const onRowClick = async (id: string) => {
    const getApplication = await pok.applications.getById(id);
    setApplication(getApplication);
    setShow(true);
  };

  const handleAdd = () => {
    setApplication(undefined);
    setShow(true);
  };

  const handleClose = () => {
    setApplication(undefined);
    setShow(false);
  };

  useEffect(() => {
    const companyId = pok.getCompanyId();
    if (companyId) {
      pok.companies
        .getById(companyId)
        .then(company => {
          if (
            company.shortname === 'CH' ||
            company.shortname === 'MRE' ||
            company.shortname === 'UM'
          ) {
            setLink(
              'https://script.google.com/a/macros/changeserviceplan.pl/s/AKfycbwMFEpZnGkof7DAU9jjXn2_QCkq1pp10MnV3BPI1QXVvGV3nN_6hbFYvUG2asNUzjdRfw/exec',
            );
          } else {
            setLink(
              'https://script.google.com/a/macros/groupone.com.pl/s/AKfycbyWjqCjWilMUFMo_sqEebelGKntbesDvBrUkj4QuCIQcVsQliSBNswZf8HsZax3DVAs/exec',
            );
          }
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }
  }, [pok, notifications]);

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.app.applications)}</Card.Title>
        <Button className="mb-4" variant="outline-primary" onClick={handleAdd}>
          {t(tk.app.newApplication)}
        </Button>
        <TabsHistory
          activeKey={ApplicationsTabs.CURRENT_USER}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={ApplicationsTabs.CURRENT_USER}
            title={t(tk.app.my)}
            className="m-3"
          >
            <UserApplicationTab refresh={refresh} onRowClick={onRowClick} />
          </Tab>
          <Tab
            eventKey={ApplicationsTabs.COMPANY}
            title={t(tk.app.companies)}
            className="m-3"
          >
            <CompanyApplicationTab refresh={refresh} onRowClick={onRowClick} />
          </Tab>
          <Tab
            eventKey={ApplicationsTabs.ANNOUNCE}
            title={t(tk.app.announcements)}
            className="m-3"
          >
            <a href={link} target="_blank" rel="noopener noreferrer">
              {t(tk.app.reportingContractsForWork)}
            </a>
          </Tab>
        </TabsHistory>
        <ApplicationEditor
          id={application?.id}
          author={application?.author.name}
          application={
            application
              ? convert(application)
              : newApplication(pok.getCompanyId())
          }
          purchaserName={`${application?.purchaser?.name} (${application?.purchaser?.client?.name})`}
          positionName={`${application?.position?.name} (${application?.position?.client?.name})`}
          brandName={`${application?.brand?.name} (${application?.brand?.budget?.name})`}
          createdDate={application?.createdDate}
          onCompleted={addEditApplication}
          handleClose={handleClose}
          show={show}
          isReadOnly={
            application &&
            application.applicationStatus !==
              GetApplicationDtoApplicationStatusEnum.New
          }
        />
      </Card.Body>
    </Card>
  );
};
