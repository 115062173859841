import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useTranslation } from '../../../common/hooks/useTranslation';

interface DelayIconProps {
  delayVerification: boolean;
  active: boolean;
}

export const DelayIcon: React.FC<DelayIconProps> = props => {
  const { t, tk } = useTranslation('projectLng');

  return (
    <>
      {props.delayVerification && props.active && (
        <OverlayTrigger
          overlay={
            <Tooltip>{t(tk.projects.applicationVerificationWaiting)}</Tooltip>
          }
        >
          <div className="px-2">
            <Icon.AlarmFill className="icon-red" />
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};
