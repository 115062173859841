/*eslint complexity: ["error", 17]*/
import React from 'react';
import { Form } from 'react-bootstrap';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';

import ProfileFieldName from './ProfileFieldName';

interface OfflineProfileParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  language: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const OfflineProfileParams: React.FC<
  OfflineProfileParamsProps
> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    props.propertyChange && props.propertyChange(obj);
  };

  return (
    <>
      {schema?.numberOfMedia && (
        <ProfileFieldName
          controlId="numberOfMedia"
          schema={schema?.numberOfMedia}
          lang={props.language}
        >
          <Form.Control
            type="number"
            min={0}
            value={props.estimateItem?.numberOfMedia || undefined}
            onChange={e =>
              propertyChange({
                numberOfMedia: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}

      {schema?.technologyDictionary && (
        <ProfileFieldName
          controlId="technologyDictionary"
          schema={schema?.technologyDictionary}
          lang={props.language}
        >
          <DictionarySelector
            dictionary="OOH - technologia"
            value={props.estimateItem?.technologyDictionaryId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                technologyDictionaryId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.regionDictionary && (
        <ProfileFieldName
          controlId="regionDictionary"
          schema={schema?.regionDictionary}
          lang={props.language}
        >
          <DictionarySelector
            dictionary="OOH - rejon"
            value={props.estimateItem?.regionDictionaryId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                regionDictionaryId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.additionalDescription && (
        <ProfileFieldName
          controlId="additionalDescription"
          schema={schema?.additionalDescription}
          lang={props.language}
        >
          <Form.Control
            type="text"
            value={props.estimateItem?.additionalDescription ?? ''}
            onChange={e =>
              propertyChange({
                additionalDescription: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* PRASA */}
      {schema?.formatPress && (
        <ProfileFieldName
          controlId="formatPress"
          schema={schema?.formatPress}
          lang={props.language}
        >
          <DictionarySelector
            dictionary="Prasa - format"
            value={props.estimateItem?.formatPressId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatPressId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* RADIO */}
      {schema?.formatRadio && (
        <ProfileFieldName
          controlId="formatRadio"
          schema={schema?.formatRadio}
          lang={props.language}
        >
          <DictionarySelector
            dictionary="Radio - format"
            value={props.estimateItem?.formatRadioId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatRadioId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.emissionTypeRadio && (
        <ProfileFieldName
          controlId="emissionTypeRadio"
          schema={schema?.emissionTypeRadio}
          lang={props.language}
        >
          <DictionarySelector
            dictionary="Radio - typ emisji"
            value={props.estimateItem?.emissionTypeRadioId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                emissionTypeRadioId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* KINO */}
      {schema?.emissionTypeCinema && (
        <ProfileFieldName
          controlId="emissionTypeCinema"
          schema={schema?.emissionTypeCinema}
          lang={props.language}
        >
          <DictionarySelector
            dictionary="Kino - typ emisji"
            value={props.estimateItem?.emissionTypeCinemaId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                emissionTypeCinemaId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
    </>
  );
};
