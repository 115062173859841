import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import { GetProjectWithPurchaseInvoicesDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';
import numberFormatter from '../../../common/numberFormatter';

interface ProjectSearchProps {
  data?: GridRecord[];
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  readOnly: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  enableMultiSelect?: boolean;
  isEditorView?: boolean;
}

export const purchaseInvoiceProjectForGrid = (
  project: GetProjectWithPurchaseInvoicesDto,
) => {
  return {
    key: project.id,
    values: {
      'project.number': project.number,
      'project.name': project.name,
      'client.name': project.purchaser?.client?.name,
      'budget.name': project.purchaser?.budget?.name,
      'project.startDate': momencik(project.startDate),
      'project.endDate': momencik(project.endDate),
      'project.purchaseNetTotal': numberFormatter(
        project.purchaseNetTotal,
        true,
      ),
      'project.purchaseInvoiceRemainingSum': numberFormatter(
        project.purchaseInvoiceRemainingSum,
        true,
      ),
      'invoice.sumClientAmount': numberFormatter(
        project.projectPurchaseInvoicesAmountSum ?? '',
        true,
      ),
      'invoice.sumClientAmountWithoutPosition': numberFormatter(
        project.projectPurchaseInvoicesAmountWithoutPositionSum ?? '',
        true,
      ),
    },
    isInactive: !project.active,
    readOnly: project.delayVerification,
  };
};

export const PurchaseInvoiceProjectsSearch: React.FC<
  ProjectSearchProps
> = props => {
  const { t, tk } = useTranslation('fvzLng');
  const columnPrefix = props.getData ? 'project.' : '';

  const COLUMNS: GridColumn[] = [
    { header: 'Numer', property: `${columnPrefix}number` },
    { header: 'Nazwa', property: `${columnPrefix}name` },
    { header: 'Data rozpoczęcia', property: `${columnPrefix}startDate` },
    { header: 'Data zakończenia', property: `${columnPrefix}endDate` },
    {
      header: 'Netto zakup total',
      property: `${columnPrefix}purchaseNetTotal`,
      noSorting: true,
    },
    {
      header: 'Nierozliczona kwota',
      property: `${columnPrefix}purchaseInvoiceRemainingSum`,
      noSorting: true,
    },
    ...(props.isEditorView
      ? [
          {
            header: 'Kontrahent kampanii',
            property: 'client.name',
          },
          { header: 'Budżet', property: 'budget.name' },
          {
            header: 'Netto zakup total fakturującego',
            property: 'invoice.sumClientAmount',
            noSorting: true,
          },
          {
            header: 'Nierozliczona kwota fakturującego',
            property: 'invoice.sumClientAmountWithoutPosition',
            noSorting: true,
          },
        ]
      : []),
    ...(!props.isEditorView && !props.readOnly
      ? [{ header: '', property: 'deleteLink', noSorting: true }]
      : []),
  ];

  return (
    <Grid
      data={props.data}
      getData={props.getData}
      getDataCount={props.getCount}
      columns={COLUMNS}
      emptyGridMessage={t(tk.prLang.emptyProjectsList)}
      onRowClick={props.readOnly ? undefined : props.onRowClick}
      initialOrderColumn={`${columnPrefix}number`}
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      enableMultiSelect={props.enableMultiSelect}
      showFilter={props.isEditorView}
    />
  );
};
