import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

import {
  GetEstimateByMonthWithItemsDto,
  GetProjectDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { useTranslation } from '../../../common/hooks/useTranslation';

import {
  getItemsGridData,
  getItemsGridSummary,
} from './ProjectEstimateItemsGridData';

interface ProjectEstimateItemsGridProps {
  data: GetEstimateByMonthWithItemsDto[];
  schema?: ProfileSchemaDto;
  project?: GetProjectDto;
}

export const ProjectEstimateItemsGrid: React.FC<
  ProjectEstimateItemsGridProps
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const columns: GridColumn[] = [
    { header: t(tk.common.profile), property: 'profile' },
    { header: t(tk.common.month), property: 'month' },
    { header: t(tk.projects.salesNet), property: 'salesNet' },
    {
      header: t(tk.projects.salesCommissionAmount),
      property: 'salesCommissionAmount',
    },
    { header: t(tk.projects.salesTotal), property: 'salesNetTotal' },
    { header: t(tk.projects.salesInvoicesSum), property: 'salesInvoicesSum' },
    { header: t(tk.projects.purchaseTotal), property: 'purchaseNetTotal' },
    {
      header: t(tk.projects.purchaseInvoicesSum),
      property: 'purchaseInvoicesSum',
    },
    { header: t(tk.projects.purchaseDiff), property: 'purchaseAmountToAccept' },
    ...(props.project?.company?.showMargin
      ? [{ header: t(tk.projects.margin), property: 'margin' }]
      : []),
    { header: t(tk.common.status), property: 'status' },
    { header: t(tk.projects.salesLock), property: 'salesLock' },
    { header: t(tk.projects.purchaseLock), property: 'purchaseLock' },
  ];

  return (
    <Grid
      data={getItemsGridData(props.data)}
      columns={columns}
      showFilter={false}
      pageSize={20}
      initialOrderColumn="month"
      initialOrderDirection="ASC"
      emptyGridMessage={t(tk.projects.noPositions)}
      summaryData={getItemsGridSummary(props.data)}
      trClassName="amounts-align-right font-90"
      withFilterInColumns={true}
    />
  );
};
