import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { GetEstimateItemDocumentControlDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';

interface ProjectFinanceSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  showTotalDifferenceColumn?: boolean;
  totalDifferenceLabel?: string;
  withFilterInColumns?: boolean;
  refresh?: boolean;
}

export const estimateItemDocumentControlForGrid = (
  estimateItem: GetEstimateItemDocumentControlDto,
) => {
  return {
    key: estimateItem.id,
    values: {
      'project.name': estimateItem?.estimateByMonth?.project.name,
      'project.systemNumber':
        estimateItem?.estimateByMonth?.project.systemNumber,
      'project.startDate': momencik(
        estimateItem?.estimateByMonth?.project.startDate,
        'YYYY-MM-DD',
      ),
      'project.endDate': momencik(
        estimateItem?.estimateByMonth?.project.endDate,
        'YYYY-MM-DD',
      ),
      'project.number': estimateItem?.estimateByMonth?.project.number,
      'estimateByMonth.date': momencik(
        estimateItem?.estimateByMonth?.date,
        'YYYY-MM',
      ),
      'profile.name': estimateItem?.estimateByMonth?.profile?.name,
      'position.name': estimateItem?.position?.name,
      'client.name': estimateItem?.position?.client?.name,
      'estimateItem.salesCommissionAmount': numberFormatter(
        estimateItem?.salesCommissionAmount,
        true,
      ),
      'estimateItem.salesNet': numberFormatter(estimateItem?.salesNet, true),
      'estimateItem.salesNetTotal': numberFormatter(
        estimateItem?.salesNetTotal,
        true,
      ),
      'estimateItem.format':
        estimateItem?.formatRadio?.value ||
        estimateItem?.formatPress?.value ||
        estimateItem?.formatInternet?.value ||
        estimateItem?.formatInternetST?.value,
      'responsibleOwner.name':
        estimateItem?.estimateByMonth?.project?.responsibleOwner?.name,
      mpXlsx: estimateItem.mpXlsx,
      mpAssigned: estimateItem.mpAssigned,
      pmProject: estimateItem.pmProject,
      attachmentOrder: estimateItem.attachmentOrder,
      internalOrder: estimateItem.internalOrder,
      fvz: estimateItem.fvz,
      fvs: estimateItem.fvs,
    },
  };
};

export const DocumentControlSearch: React.FC<
  ProjectFinanceSearchProps
> = props => {
  const { t, tk } = useTranslation('reportLng');
  const columns: GridColumn[] = [
    { header: 'Miesiąc', property: 'estimateByMonth.date' },
    {
      header: `Nr sys. ${t(tk.prLang.genitiveProject)}`,
      property: 'project.systemNumber',
    },
    {
      header: `Numer ${t(tk.prLang.genitiveProject)}`,
      property: 'project.number',
    },
    {
      header: `Nazwa ${t(tk.prLang.genitiveProject)}`,
      property: 'project.name',
    },
    { header: 'Profil', property: 'profile.name' },
    { header: 'Pozycja nazwa', property: 'position.name' },
    { header: 'Pozycja kontrahent', property: 'client.name' },
    {
      header: 'Format',
      property: 'estimateItem.format',
      noFilter: true,
      noSorting: true,
    },
    {
      header: `Data rozpoczęcia ${t(tk.prLang.genitiveProject)}`,
      property: 'project.startDate',
    },
    {
      header: `Data zakończenia ${t(tk.prLang.genitiveProject)}`,
      property: 'project.endDate',
    },
    { header: 'Sprzedaż netto', property: 'estimateItem.salesNet' },
    {
      header: 'Sprzedaż prowizja kwota',
      property: 'estimateItem.salesCommissionAmount',
    },
    { header: 'Sprzedaż total netto', property: 'estimateItem.salesNetTotal' },
    { header: 'Osoba odpowiedzialna', property: 'responsibleOwner.name' },
    { header: 'MP xlsx', property: 'mpXlsx', noSorting: true },
    {
      header: 'MP podpisany',
      property: 'mpAssigned',
      noSorting: true,
    },
    {
      header: 'PB kampanii',
      property: 'pmProject',
      noSorting: true,
    },
    {
      header: 'Zlecenie od dostawcy',
      property: 'attachmentOrder',
      noSorting: true,
    },
    {
      header: 'Zaakceptowana realizacja zamówienia',
      property: 'internalOrder',
      noSorting: true,
    },
    {
      header: 'FV od dostawcy',
      property: 'fvz',
      noSorting: true,
    },
    {
      header: 'FV VM/ŻP',
      property: 'fvs',
      noSorting: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn={'project.number'}
      initialOrderDirection={'DESC'}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={'Brak pozycji do wyświetlenia'}
      onRowClick={props.onRowClick}
      withFilterInColumns={props.withFilterInColumns}
      withOverflowXAuto={true}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
