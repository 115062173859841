/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetPurchaseInvoiceDto } from './GetPurchaseInvoiceDto';
import {
    GetPurchaseInvoiceDtoFromJSON,
    GetPurchaseInvoiceDtoFromJSONTyped,
    GetPurchaseInvoiceDtoToJSON,
} from './GetPurchaseInvoiceDto';

/**
 * 
 * @export
 * @interface GetPurchaseInvoiceForItem
 */
export interface GetPurchaseInvoiceForItem {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceForItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceForItem
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceForItem
     */
    netAmount: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPurchaseInvoiceForItem
     */
    owner: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceForItem
     */
    ts: Date;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceForItem
     */
    attachmentId?: string;
    /**
     * 
     * @type {GetPurchaseInvoiceDto}
     * @memberof GetPurchaseInvoiceForItem
     */
    purchaseInvoice: GetPurchaseInvoiceDto;
}

/**
 * Check if a given object implements the GetPurchaseInvoiceForItem interface.
 */
export function instanceOfGetPurchaseInvoiceForItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "netAmount" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "ts" in value;
    isInstance = isInstance && "purchaseInvoice" in value;

    return isInstance;
}

export function GetPurchaseInvoiceForItemFromJSON(json: any): GetPurchaseInvoiceForItem {
    return GetPurchaseInvoiceForItemFromJSONTyped(json, false);
}

export function GetPurchaseInvoiceForItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPurchaseInvoiceForItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'netAmount': json['netAmount'],
        'owner': GetEmployeeDtoFromJSON(json['owner']),
        'ts': (new Date(json['ts'])),
        'attachmentId': !exists(json, 'attachmentId') ? undefined : json['attachmentId'],
        'purchaseInvoice': GetPurchaseInvoiceDtoFromJSON(json['purchaseInvoice']),
    };
}

export function GetPurchaseInvoiceForItemToJSON(value?: GetPurchaseInvoiceForItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'netAmount': value.netAmount,
        'owner': GetEmployeeDtoToJSON(value.owner),
        'ts': (value.ts.toISOString()),
        'attachmentId': value.attachmentId,
        'purchaseInvoice': GetPurchaseInvoiceDtoToJSON(value.purchaseInvoice),
    };
}

