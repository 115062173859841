export default {
  common: {
    locale: 'en-GB',
    company: 'Company',
    companies: 'Companies',
    team: 'Team',
    teams: 'Teams',
    budget: 'Budget',
    client: 'Client',
    profile: 'Profile',
    currency: 'Currency',
    employee: 'Employee',
    employees: 'Employees',
    user: 'User',
    year: 'Year',
    month: 'Month',
    status: 'Status',
    lp: 'No.',
    name: 'Name',
    shortname: 'Abbreviation',
    description: 'Description',
    sysNumber: 'Sys. No.',
    sysNumberLowerCase: 'sys. no.',
    history: 'History',
    lastModification: 'Last modification',
    who: 'Who',
    when: 'When',
    noInformation: 'No information',
    creation: 'Creation',
    activeShe: 'Active',
    activeHe: 'Active',
    nonActiveHe: 'Inactive',
    yes: 'Yes',
    no: 'No',
    yesUpperCase: 'YES',
    noUpperCase: 'NO',
    sumUpperCase: 'SUM',
    sum: 'Sum',
    creator: 'Creator',
    creationDate: 'Creation date',
    emails: 'Email addresses',
    noData: 'No data',
    downloadDocs: 'Download documents',
    selectMonth: 'Select month',
    positionName: 'Position Name',
    country: 'Country',
    purchaser: 'Purchaser',
    brand: 'Brand',
    profilePosition: 'Profile Position',
    type: 'Type',
    number: 'Number',
    startDate: 'Start date',
    endDate: 'End date',
  },
  component: {
    pointOut: 'Point out...',
    enter: 'Enter...',
    quickSearch: 'Quick search',
    searchInProgress: 'Searching...',
    numberOfRows: 'Number of rows',
    cleanFilter: 'Clear filter',
    noValues: 'No values',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    selected: 'Selected',
  },
  button: {
    clean: 'Clear',
    export: 'Export',
    close: 'Close',
    save: 'Save',
    delete: 'Delete',
    add: 'Add',
    edit: 'Edit',
    addDots: 'Add...',
    open: 'Open',
    cancel: 'Cancel',
    find: 'Find',
  },
  attachment: {
    attachments: 'Attachments',
    attachment: 'Attachment',
    attachmentNew: 'New attachment / note',
    calculatorCurrency: 'Currency calculator',
    note: 'Note',
    noteNew: 'New note',
    type: 'Type',
    title: 'Title',
    file: 'File',
    invoicing: 'Invoicing',
    positionsSum: 'Related positions - purchase sum',
    relatedItems: 'Related items',
  },
} as const;
