import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdatePositionDto,
  GetClientDto,
  GetProfileDto,
  GetTeamDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { Option } from '../Selects/Selector';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PositionEditorForm } from './PositionEditorForm';

interface PositionEditorProps {
  id?: string;
  systemNumber?: number;
  position: CreateUpdatePositionDto;
  client?: GetClientDto;
  team?: GetTeamDto;
  teams?: GetTeamDto[];
  profiles?: GetProfileDto[];
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    position: CreateUpdatePositionDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  positionsProvider: (text: string) => Promise<Option[]>;
}

export const PositionEditor: React.FC<PositionEditorProps> = props => {
  const [position, setPosition] = useState(props.position);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [companyId, setCompanyId] = useState<string | null | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('clientLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setPosition(props.position);
    }
  }, [props.position, props.show]);

  useEffect(() => {
    if (position.clientId) {
      pok.companies
        .getCompanyIdByClientId(position.clientId)
        .then(id => {
          setCompanyId(id);
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [pok.companies, position.clientId, notifications]);

  const createPosition = async () => {
    setIsLoading(true);
    props
      .onCompleted(position, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdatePositionDto>) => {
    setPosition({ ...position, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `${t(tk.position.positionEdit)} ${props.systemNumber}`
              : t(tk.position.positionAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PositionEditorForm
            id={props.id}
            client={props.client}
            position={position}
            team={props.team}
            teams={props.teams}
            profiles={props.profiles}
            companyId={companyId}
            propertyChange={propertyChange}
            positionsProvider={props.positionsProvider}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.positions.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={createPosition}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
