/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateDictionaryDto
 */
export interface CreateUpdateDictionaryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateDictionaryDto
     */
    shortname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateDictionaryDto
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateDictionaryDto
     */
    valueEn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateDictionaryDto
     */
    dictionaryTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateDictionaryDto
     */
    parentId?: string | null;
}

/**
 * Check if a given object implements the CreateUpdateDictionaryDto interface.
 */
export function instanceOfCreateUpdateDictionaryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dictionaryTypeId" in value;

    return isInstance;
}

export function CreateUpdateDictionaryDtoFromJSON(json: any): CreateUpdateDictionaryDto {
    return CreateUpdateDictionaryDtoFromJSONTyped(json, false);
}

export function CreateUpdateDictionaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateDictionaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shortname': !exists(json, 'shortname') ? undefined : json['shortname'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'valueEn': !exists(json, 'valueEn') ? undefined : json['valueEn'],
        'dictionaryTypeId': json['dictionaryTypeId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
    };
}

export function CreateUpdateDictionaryDtoToJSON(value?: CreateUpdateDictionaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shortname': value.shortname,
        'value': value.value,
        'valueEn': value.valueEn,
        'dictionaryTypeId': value.dictionaryTypeId,
        'parentId': value.parentId,
    };
}

